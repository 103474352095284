<template>
  <SessionsContent />
</template>

<script setup lang="ts">
import { computed, watch } from 'vue'

import groupsStore from '@/v1.5/features/groups/stores/groups/groups.store'
import SessionsContent from '@/v1.5/features/ui/pages/sessions/sessions-content/sessions-content.vue'
import type { SessionDetailsModalType } from '@/v1.5/features/ui/store/modal/modal.store'
import modalsStore from '@/v1.5/features/ui/store/modal/modal.store'
import { selectGetCurrentRoute } from '@/v1.5/store/route/route.selector'
import routeStore from '@/v1.5/store/route/route.store'
import { DO_NOT_ASK_REVIEW_AGAIN } from '@/v1.5/utils/config/constants'

const currentRouteRef = selectGetCurrentRoute(routeStore)

const routeQuerySessionIdRef = computed(() => currentRouteRef.value?.query.id)
const routeQueryGroupIdRef = computed(() => currentRouteRef.value?.query.groupId)

// if a sessionId is provided, fetch the session details and show the session details popup
// Primarily used in conjunction with emails. When a user clicks on an email containing a session to complete, they will be redirected to the session page with the sessionId in the query parameter.
watch(
  [routeQuerySessionIdRef, routeQueryGroupIdRef],
  ([sessionId, groupId]) => {
    if (sessionId && groupId) {
      // we don't want the review modal to be shown for this session, instead we want to show the session details popup
      window.sessionStorage.setItem(DO_NOT_ASK_REVIEW_AGAIN, 'true')

      // we first switch to the corresponding group
      groupsStore.setSelectedGroupId(groupId as string)

      // then we show the session details popup
      modalsStore.toggleModal('session-details', true)
      modalsStore.updateModalAttachedData<SessionDetailsModalType>('session-details', {
        sessionId: sessionId as string,
      })
    }
  },
  { immediate: true },
)
</script>
