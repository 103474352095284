<template>
  <div class="c-button" :class="[color, { loading: isLoading, disabled: isDisabled }]">
    <!-- Display content based on the prop usage -->
    <template v-if="usage === 'link'">
      <!-- If link is internal use router-link -->
      <router-link v-if="link?.isInternal" :to="link.url" custom v-slot="{ navigate }">
        <a @click="navigate" :class="linkClass">
          <InnerButton :text="text" :iconPosition="iconPosition" :isLoading="isLoading">
            <template #icon>
              <slot name="icon"></slot>
            </template>
          </InnerButton>
        </a>
      </router-link>

      <!-- If link is external use a normal anchor -->
      <a v-else :href="link?.url" target="_blank" rel="noreferrer noopener" :class="linkClass">
        <InnerButton :text="text" :iconPosition="iconPosition" :isLoading="isLoading">
          <slot name="icon"></slot>
        </InnerButton>
      </a>
    </template>
    <template v-else-if="usage === 'button'">
      <button @click="onClick" :class="btnClass" :disabled="isDisabled">
        <InnerButton :text="text" :iconPosition="iconPosition" :isLoading="isLoading">
          <template #icon>
            <slot name="icon"></slot>
          </template>
        </InnerButton>
      </button>
    </template>
  </div>
</template>

<script lang="ts" setup>
import InnerButton from './inner-button/inner-button.vue'

const emit = defineEmits(['emit:click'])

function onClick(e: Event) {
  emit('emit:click', e)
}

defineProps<{
  usage: 'button' | 'link'
  color: string // Define this based on your color types
  text?: string
  iconPosition?: 'left' | 'right'
  isDisabled?: boolean
  isLoading?: boolean
  link?: {
    url: string
    isInternal?: boolean
  }
}>()

const linkClass = ['link-button'] // Add additional link button classes if needed
const btnClass = ['btn-button'] // Add additional button classes if needed
</script>

<style lang="scss">
@import './custom-button.scss';
</style>
