<template>
  <div :class="`stat-and-icon c-color-${color} ${disabled ? 'stat-and-icon--disabled' : ''}`">
    <div :class="`stat-and-icon__info display--${displayRef}`">
      <div class="stat-and-icon-stat-wrapper">
        <div class="stat-and-icon-stat">{{ stat }}</div>
        <div v-if="total" class="stat-and-icon-total">/{{ total }}</div>
      </div>
      <div class="stat-and-icon-text">
        <span>{{ text }}</span>
      </div>
    </div>
    <div class="stat-and-icon__icon">
      <slot name="icon"></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps<{
  stat: string | number
  total?: string | number
  text: string
  display?: 'icon' | 'row'
  color: 'black' | 'success' | 'warning'
  disabled?: boolean
}>()

const displayRef = computed(() => props.display || 'icon')
</script>

<style lang="scss">
@import './stat-and-icon.scss';
</style>
