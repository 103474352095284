<!-- eslint-disable -->
<!-- @ts-nocheck -->
<template>
  <div>
    <hr class="my-4" />
    <p class="fw-bold">Avis de fin de session</p>
    <p>
      Afin que nous puissions suivre le bon déroulé des sessions de mentorat
      merci de remplir le formulaire suivant. <br />
      <span class="fst-italic"
        >Ces informations ne seront pas transmises
        {{
          $store.getters.userRole === 'ROLE_STUDENT' ? 'au mentor' : "à l'éleve"
        }}</span
      >.
    </p>
    <div class="d-flex align-items-end">
      <p class="mb-0 me-2">Noter la session*</p>
      <star-rating
        v-model="rating"
        :increment="1"
        :read-only="false"
        :star-size="25"
        active-color="#E59719"
        :show-rating="false"
      />
    </div>
    <div class="form-group mt-4">
      <label for="" class="form-label">Avis sur la session</label>
      <textarea
        v-model="review"
        class="form-control"
        rows="3"
        placeholder="Votre avis..."
      ></textarea>
    </div>
    <div class="mt-4 d-flex justify-content-between">
      <p class="mb-2">*Champs obligatoire</p>
      <div class="d-flex">
        <button class="btn btn-light">Ne pas laisser d'avis</button>
        <button
          @click="submitForm()"
          class="btn btn-primary ms-2"
          :class="isDeliverableUploading ? 'disabled' : ''"
        >
          Valider
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import StarRating from 'vue-star-rating'
import { mapGetters } from 'vuex'

import { addMenteeSessionReport } from '@/services/sessionService.js'

export default {
  name: 'SessionMenteeReview',
  components: {
    StarRating,
  },
  data() {
    return {
      rating: null,
      review: null,
      status: 'WAIT_REVIEWS',
    }
  },
  props: {
    sessionId: {
      type: String,
    },
  },
  computed: {
    ...mapGetters(['isDeliverableUploading']),
  },
  methods: {
    submitForm() {
      if (this.rating) {
        const data = {
          grade: this.rating,
          sessionId: this.sessionId,
        }
        if (this.review) data.review = this.review
        addMenteeSessionReport(data, this.$store.getters.authToken).then(() => {
          this.rating = null
          this.review = null
          this.$store.dispatch('updateSessionStatus', {
            sessionId: this.sessionId,
            status: 'FINISHED',
          })
        })
      } else {
        return this.$toast.error("Vous n'avez pas rempli tous les champs obligatoires", {
          timeout: 2000,
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
