<!-- @ts-nocheck -->
<!-- eslint-disable -->
<template>
  <Modal name="session-reservation" title="Planifier une session de mentorat">
    <div class="body">
      <p class="mb-0">Participants</p>
      <div class="row mt-4">
        <div
          class="px-0 col-lg-4 participant"
          v-if="$store.getters.selectedGroupId"
        >
          <SessionStatusLarge
            :mentoringGroupId="$store.getters.selectedGroupId"
          />
        </div>
      </div>
      <p class="mb-0 mt-4">1. Indiquez la date et l’heure</p>
      <div class="row mt-4">
        <div class="px-0 col-lg-5">
          <flat-pickr
            v-model="date"
            :config="dateConfig"
            class="form-control bg-input"
            placeholder="JJ/MM/AAAA"
          ></flat-pickr>
        </div>
      </div>
      <p class="form-text">{{ $store.getters.userTimezone }}</p>
      <div class="mt-4">
        <div class="d-flex row">
          <div class="col-lg-2 col-md-5 col-5">
            <flat-pickr
              v-model="timeFrom"
              :config="timeConfig"
              class="form-control bg-input"
              placeholder="HH:MM"
            ></flat-pickr>
          </div>
          <p
            class="mb-0 col-lg-1 col-md-2 col-2 d-flex justify-content-center align-items-center"
          >
            à
          </p>
          <div class="col-lg-2 col-md-5 col-5">
            <flat-pickr
              v-model="timeTo"
              :config="timeConfig"
              class="form-control bg-input"
              placeholder="HH:MM"
            ></flat-pickr>
          </div>
        </div>
      </div>
      <p class="mb-0 mt-4">2. Indiquez le lieu</p>
      <div class="form-check mt-4">
        <input
          value="visio"
          class="form-check-input"
          type="radio"
          name="flexRadioDefault"
          id="flexRadioDefault1"
          v-model="radio"
          checked
        />
        <label class="form-check-label" for="flexRadioDefault1">
          Visioconférence
        </label>
      </div>
      <div class="form-check mt-2" @click="fillInputLocation()">
        <input
          value="physique"
          class="form-check-input"
          type="radio"
          name="flexRadioDefault"
          id="flexRadioDefault2"
          v-model="radio"
        />
        <label class="form-check-label" for="flexRadioDefault2">
          Présentiel
        </label>
      </div>
      <div v-show="radio == 'physique'" class="row mt-4">
        <div class="px-0 col-lg-4">
          <div class="input-group">
            <input type="text" class="form-control" v-model="location" />
            <span class="input-group-text">
              <span class="material-symbols-outlined"> location_on </span>
            </span>
          </div>
        </div>
      </div>
      <button
        @click="SubmitSession()"
        class="btn btn-primary mt-4 fw-bold text-uppercase"
      >
        Réserver
      </button>
    </div>
  </Modal>
</template>

<script lang="ts">
import 'flatpickr/dist/flatpickr.css'

import { French } from 'flatpickr/dist/l10n/fr.js'
import moment from 'moment-timezone'
import flatPickr from 'vue-flatpickr-component'
import { mapActions } from 'vuex'

import Modal from '@/components/Modal.vue'
import SessionStatusLarge from '@/components/SessionStatusLarge.vue'
import mentorshipGroupsStore from '@/v1.5/features/groups/stores/groups/groups.store'
import { invalidateSessions } from '@/v1.5/utils/lib/vue-query'

export default {
  name: 'SessionReservationPopup',
  data() {
    return {
      errorMessage: '',
      location: '',
      dateConfig: {
        minDate: Date.now(),
        altFormat: 'j F Y',
        altInput: true,
        locale: French,
        allowInput: true,
      },
      timeConfig: {
        enableTime: true,
        noCalendar: true,
        // dateFormat: 'H:i',
        time_24hr: true,
        locale: French,
      },
      radio: 'visio',
      date: null,
      timeFrom: null,
      timeTo: null,
    }
  },
  methods: {
    ...mapActions(['switchModal']),
    async fillInputLocation() {
      await this.$store.dispatch('fetchMentorData', this.$store.getters.authUserId)
      if (this.$store.getters.mentorData.MentorLesson && this.$store.getters.mentorData.MentorLesson.location)
        this.location = this.$store.getters.mentorData.MentorLesson.location
    },
    checkFieldsAreFulled() {
      if (this.date && this.timeFrom && this.timeTo) {
        this.errorMessage = ''
      } else {
        this.errorMessage = 'Vous devez remplir tous les champs'
      }
    },
    checkDuration() {
      if (this.timeTo < this.timeFrom || this.timeFrom == this.timeTo) {
        this.errorMessage = 'La durée de session doit être supérieure à 0 minutes'
      } else {
        this.errorMessage = ''
      }
    },
    checkSessionIsInTheFuture() {
      const dateSessionUTC = this.parseToDatetimeUTC(this.date, this.timeFrom)
      const dateNowUTC = moment.utc(moment()).format()
      if (moment(dateNowUTC).isAfter(dateSessionUTC)) {
        this.errorMessage = 'La date de session doit être à venir'
      } else {
        this.errorMessage = ''
      }
    },
    bookSession() {
      // affiche le toaster de success
      if (this.errorMessage == 'La date de session doit être à venir') {
        this.$toast.error('La date de session doit être à venir', {
          timeout: 2000,
        })
      }
      if (this.errorMessage == 'La durée de session doit être supérieure à 0 minutes') {
        this.$toast.error('La durée de session doit être supérieure à 0 minutes', {
          timeout: 2000,
        })
      }
      if (this.errorMessage == 'Vous devez remplir tous les champs') {
        this.$toast.error('Vous devez remplir tous les champs', {
          timeout: 2000,
        })
      }
      const dateSessionUTC = this.parseToDatetimeUTC(this.date, this.timeFrom)
      const dateNowUTC = moment.utc(moment()).format()
      if (
        this.date &&
        this.timeFrom &&
        this.timeTo &&
        this.timeTo > this.timeFrom &&
        this.timeFrom != this.timeTo &&
        moment(dateSessionUTC).isAfter(dateNowUTC)
      ) {
        this.$store
          .dispatch('createSession', {
            mentoringGroupId: this.$store.getters.selectedGroupId,
            from: this.parseToDatetimeUTC(this.date, this.timeFrom),
            to: this.parseToDatetimeUTC(this.date, this.timeTo),
            location: this.radio == 'physique' ? this.location : 'visio',
          })
          .catch((data) => {
            this.$toast.error(data.message, {
              timeout: 2000,
            })
          })

          .then((session) => {
            const conversationId = this.$store.getters.mentoringGroup.find(
              (mentoringGroup) => mentoringGroup.id == this.$store.getters.selectedGroupId,
            ).conversationId
            this.$store
              .dispatch('createChatMessage', {
                conversationId,
                message: `***session=${session.id}`,
                // receiverId: this.$store.getters.profile.Identity.userId,
              })
              .then(() => {
                invalidateSessions({ mentorshipGroupId: mentorshipGroupsStore.selectedMentorshipGroupId })

                this.switchModal({ name: 'session-reservation', show: false })
                this.$store.commit('DELETE_CHAT_ROOMS')
                this.$store.dispatch('readAllChatRooms')

                this.$router.push(`/messages/${conversationId}`)
              })
          })
      }
    },

    async SubmitSession() {
      this.errorMessage = ''
      this.checkFieldsAreFulled()
      if (this.errorMessage === '') this.checkDuration()
      if (this.errorMessage === '') this.checkSessionIsInTheFuture()
      this.bookSession()
    },
    parseToDatetimeUTC(date, time) {
      const hourAndMinutes = time.split(':')
      const totalMinutes = parseInt(hourAndMinutes[0]) * 60 + parseInt(hourAndMinutes[1])
      const selectedDate = moment(date).tz(this.$store.getters.userTimezone).add(totalMinutes, 'minutes')
      return moment.utc(selectedDate).format()
    },
  },
  components: {
    Modal,
    flatPickr,
    SessionStatusLarge,
  },
}
</script>
<style lang="scss" scoped>
.body {
  padding: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;

  > * {
    width: 100%;
    .participant {
      width: 100%;
    }
  }
}
.bg-input {
  background-color: #f7f7f7 !important;
}
</style>
