<template>
  <Modal :modal="addToCalendarModalRef" :steps="[{ component: AddToCalendarModalContent, id: 'add-to-calendar' }]" />
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { watch } from 'vue'

import AddToCalendarModalContent from '@/v1.5/features/sessions/components/add-to-calendar-modal/add-to-calendar-modal-content/add-to-calendar-modal-content.vue'
import useSession from '@/v1.5/features/sessions/hooks/use-session.hook'
import Modal from '@/v1.5/features/ui/components/modal/modal.vue'
import { selectGetAddToCalendarModal } from '@/v1.5/features/ui/store/modal/modal.selectors'
import type { AddToCalendarModalType } from '@/v1.5/features/ui/store/modal/modal.store'
import modalsStore from '@/v1.5/features/ui/store/modal/modal.store'
import { selectGetCurrentRoute } from '@/v1.5/store/route/route.selector'
import routeStore from '@/v1.5/store/route/route.store'

const addToCalendarModalRef = selectGetAddToCalendarModal(modalsStore)

const currentRouteRef = selectGetCurrentRoute(routeStore)
const routerParamAddToCalendarSessionIdRef = computed(() => currentRouteRef.value?.query.addToCalendar as string)
const { sessionRef } = useSession(routerParamAddToCalendarSessionIdRef)

// if the session is present in the route query, open the modal
watch(
  [currentRouteRef, sessionRef],
  async () => {
    if (sessionRef.value && !addToCalendarModalRef.value.isOpen) {
      modalsStore.toggleModal('add-to-calendar', true)
      modalsStore.updateModalAttachedData<AddToCalendarModalType>('add-to-calendar', {
        session: sessionRef.value,
      })
    }
  },
  { immediate: true },
)
</script>
