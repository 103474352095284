<!-- eslint-disable -->
<!-- @ts-nocheck -->
<template>
  <Page>
    <template v-slot:middle>
      <div v-if="profile.Identity">
        <Visibility
          v-if="
            profile.Identity.userId == $store.getters.authUserId &&
            $store.getters.userRole == 'ROLE_MENTOR' &&
            $store.getters.userCanSwitchVisibility
          "
        />
        <ProfileHeader />
        <div class="row" v-if="profile.role && profile.role == 'ROLE_MENTOR'">
          <div class="col-lg-8">
            <ProfileDescription />
          </div>
          <div class="col-lg-4">
            <ProfileSubject />
          </div>
        </div>

        <div
          class="my-4 p-4 bg-light rounded"
          v-if="
            (!!careers?.length && profile.role === 'ROLE_MENTOR') ||
            profile.Identity.userId == $store.getters.authUserId
          "
        >
          <div class="d-flex justify-content-between align-items-center">
            <h2 class="text-uppercase fs-5">Études</h2>
            <div
              v-if="profile.Identity.userId == $store.getters.authUserId"
              class="edit rounded-circle c-pointer"
              @click="switchModal({ name: 'addSchool', show: true })"
            >
              <span class="material-symbols-outlined"> add </span>
            </div>
          </div>

          <template v-if="careers">
            <div
              v-for="(career, index) in careers"
              :key="index"
              class="d-flex justify-content-between align-items-center"
            >
              <div class="parcours-tile d-flex align-items-center mt-4">
                <img
                  v-if="career.logo"
                  class="img-logo rounded"
                  :src="career.logo"
                  alt=""
                />
                <div v-else class="img-logo-default rounded">
                  <span class="material-symbols-outlined"> school </span>
                </div>
                <div class="ms-4">
                  <p v-if="career.school" class="fw-bold mb-0">
                    {{ career.school }}
                  </p>
                  <p v-if="career.branch" class="text-uppercase mb-0">
                    {{ career.branch }}
                  </p>
                  <p v-if="career.specialization" class="mb-0">
                    {{ career.specialization.split('***/04565/***')[0] }}
                  </p>
                  <p
                    v-if="career.specialization?.split('***/04565/***')[1]"
                    class="mb-0"
                  >
                    {{ career.specialization?.split('***/04565/***')[1] }}
                  </p>
                  <p class="mb-0 text-muted form-text">
                    {{ career.yearStart }}
                    <template v-if="career.yearStart && career.yearEnd"
                      >-</template
                    >
                    {{ career.yearEnd }}
                  </p>
                </div>
              </div>
              <div
                v-if="profile.Identity.userId == $store.getters.authUserId"
                class="d-flex"
              >
                <div
                  class="edit rounded-circle c-pointer ms-3"
                  @click="removeCareer(career.id)"
                >
                  <span class="material-symbols-outlined"> delete </span>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div v-else>
        <span> Chargement... </span>
      </div>
    </template>
    <template v-slot:outside>
      <Modal name="updateProfilePicture" title="Votre plus beau profil">
        <UpdateProfilePicture />
      </Modal>
      <Modal name="addSchool" title="Ajouter une école">
        <AddSchool />
      </Modal>
      <Modal
        v-if="mentorData && profile"
        name="MentorProfilSubject"
        title="Matières, Disponibilités"
      >
        <FormSubject />
      </Modal>

      <template v-if="profile.role && profile.role == 'ROLE_MENTOR'">
        <Modal name="mentorProfilDescription" title="Titre, bio & expérience">
          <FormTitleBioXp />
        </Modal>
      </template>
    </template>
  </Page>
</template>

<script>
import ProfileHeader from '@/views/profile/ProfileHeader.vue'
import Page from '@/components/Page.vue'
import UpdateProfilePicture from '@/components/UpdateProfilePicture.vue'
import AddSchool from '@/components/AddSchool.vue'
import Modal from '@/components/Modal.vue'
import { mapGetters, mapActions } from 'vuex'
import ProfileDescription from '@/views/profile/ProfileDescription.vue'
import ProfileSubject from '@/views/profile/ProfileSubject.vue'
import { deleteCareer } from '@/services/userService'
import FormSubject from '@/components/forms/FormSubject.vue'
import Visibility from '@/components/mentorProfil/Visibility.vue'
import FormTitleBioXp from '@/components/forms/FormTitleBioXp.vue'

export default {
  name: 'IndexProfile',
  components: {
    Page,
    UpdateProfilePicture,
    Modal,
    AddSchool,
    ProfileDescription,
    ProfileSubject,
    ProfileHeader,
    FormSubject,
    Visibility,
    FormTitleBioXp,
  },
  computed: {
    ...mapGetters(['profile', 'careers', 'mentorData']),
  },
  watch: {
    async '$route.params.id'() {
      await this.loadData()
    },
  },
  async beforeMount() {
    await this.loadData()
  },
  mounted() {
    console.log(this.careers)
  },
  methods: {
    ...mapActions([
      'fetchProfile',
      'fetchCareer',
      'clearCareer',
      'clearProfile',
      'switchModal',
      'fetchMentorData',
      'clearMentorData',
    ]),

    async loadData() {
      await this.clearProfile()
      await this.clearCareer()
      await this.clearMentorData()
      await this.fetchProfile({ userId: this.$route.params.id })
      await this.fetchCareer({ userId: this.$route.params.id })
      if (this.profile.role && this.profile.role == 'ROLE_MENTOR')
        await this.fetchMentorData(this.$route.params.id)
    },
    removeCareer(careerId) {
      deleteCareer(
        careerId,
        this.$store.getters.authToken,
        this.$store.getters.authUserId
      ).then(() => {
        this.fetchCareer({ userId: this.$route.params.id })
      })
    },
  },

  destroyed() {
    this.clearProfile()
    this.clearCareer()
    this.clearMentorData()
  },
}
</script>

<style lang="scss" scoped>
.img-logo-default {
  width: 48px;
  height: 48px;
  color: var(--color-primary);
  background-color: var(--color-ligth-gray);
  display: flex;
  justify-content: center;
  align-items: center;
}
.img-logo {
  width: 48px;
  height: 48px;
  object-fit: contain;
  background-color: var(--color-ligth-gray);
  display: flex;
  justify-content: center;
}
.profile-body {
  @media all and (max-width: 991px) {
    margin-bottom: 7rem;
  }
}
.edit {
  background-color: #f1f2f5;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  &-profil-picture {
    position: absolute;
    bottom: 5px;
    right: 5px;
  }
  &:hover {
    background-color: var(--color-secondary);
    color: var(--color-primary);
  }
}

.modal-header {
  position: relative;
  &-close {
    position: absolute;
    top: 0;
    left: 0;
    padding: 24px;
  }
}

nav ul {
  list-style-type: none;
  a {
    text-decoration: none;
    color: #212529;
  }
  li {
    margin-right: 12px;
    cursor: pointer;
    padding: 12px;
    &:hover {
      background-color: #f1f2f5;
    }
  }
}

.profil-nav-active {
  background-color: var(--color-secondary);
  color: var(--color-primary);
}

.parcours-tile img {
  width: 48px;
  height: 48px;
}
</style>
