<!-- eslint-disable -->
<!-- @ts-nocheck -->
<template>
  <div class="profile-picture-wrapper">
    <img
      v-if="src"
      class="rounded-circle"
      :src="'https://profil-picture.s3.fr-par.scw.cloud/' + src"
      :alt="alt"
      style="width: 3rem; height: 3rem"
    />
    <div
      v-else
      class="initials-wrapper rounded-circle d-flex justify-content-center align-items-center"
      :style="`width:3rem;height:3rem;border: 1px solid #fff;`"
    >
      <p :style="`font-size:${this.width / 2}px`" class="mb-0 text-uppercase">
        {{ initials }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProfilePicture',
  computed: {
    initials() {
      if (this.firstname && this.lastname) return this.firstname[0] + this.lastname[0]
      else return ''
    },
  },
  props: {
    src: {
      type: String,
      default: null,
    },
    alt: {
      type: String,
      default: '',
    },
    width: {
      type: Number,
      default: 100,
    },
    height: {
      type: Number,
      default: 100,
    },
    firstname: {
      type: String,
      default: null,
    },
    lastname: {
      type: String,
      default: null,
    },
  },
}
</script>

<style lang="scss" scoped>
img {
  object-fit: cover;
}
</style>

<style lang="scss">
.initials-wrapper {
  position: relative;
  background-color: #fff;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.1;
    z-index: 1;
    border-radius: 100%;
  }
}
</style>
