<template>
  <div id="messages-block" :class="selectedConversationIdRef ? 'has-selected-conversation' : ''">
    <EmptyContent v-if="!selectedConversationIdRef" :text="t('chat.conversationsList.select')">
      <template #icon>
        <NoConversationSelectedIcon color="black" />
      </template>
    </EmptyContent>
    <LoadingContent v-else-if="isConversationPendingRef" />
    <div v-else class="messages-block__content">
      <div class="messages-block-header">
        <div class="messages-block-header__back-btn" @click="closeCurrentChat">
          <ArrowIcon color="black" :rotation="180" />
        </div>
        <div class="messages-block-header__avatar">
          <div v-if="isGroupRef" class="group-avatar">
            <GroupIcon color="white" filled />
          </div>
          <template v-else-if="correspondentRef">
            <Avatar :user="correspondentRef" />
          </template>
        </div>
        <div class="messages-block-header__title">
          <template v-if="isGroupRef"> {{ groupRef?.name }} </template>
          <template v-else-if="correspondentRef">
            {{ correspondentRef?.firstName }} {{ correspondentRef?.lastName }}
          </template>
        </div>
      </div>
      <div class="messages-block-feed" ref="feedRef">
        <div v-for="message in conversationRef?.messages" :key="message.id">
          <MessageSessionWidget
            v-if="isSessionWidgetMessage(message.content)"
            :sessionId="getSessionIdFromMessage(message.content)"
          />
          <Message v-else :message="message" />
        </div>
        <div class="first-message" v-if="isFirstMessageNotSentRef">
          <span v-html="t('chat.conversation.noMessagesSentYetInGroup')"></span>
        </div>
        <ConversationSeenBy v-if="conversationRef" :conversation="conversationRef" />
      </div>
      <div class="messages-block-footer">
        <div class="messages-block-footer__btns-wrapper">
          <div
            class="messages-block-footer__btns-wrapper__first-message-generation"
            v-if="
              isFirstMessageNotSentRef &&
              isFirstGroupMessageLoadingRef &&
              !isFirstGroupMessageErrorRef &&
              !hasUserStartedTypingRef
            "
          >
            <LoadingIcon color="primary" />
            <span>{{ t('chat.conversation.generatingAIMessage') }}</span>
          </div>
          <CustomTextarea
            ref="inputMessageRef"
            :placeholder="t('chat.conversation.placeholder.message')"
            id="send-message"
            type="textarea"
            :is-disabled="isFirstGroupMessageErrorRef"
            :errors="undefined"
            @emit:change="onChange"
            @emit:onkeyup="adjustTextAreaHeight"
            :value="messageRef"
            :on-change-debounce-delay="0"
          />
          <CustomButton
            :is-loading="isPendingRef"
            :text="t('ui.button.send')"
            icon-position="right"
            usage="button"
            type="button"
            color="primary"
            @emit:click="sendMessageHandler"
          >
            <template #icon>
              <ArrowIcon color="white" />
            </template>
          </CustomButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useMutation } from '@tanstack/vue-query'
import { computed, ref, watch } from 'vue'
import type { VNodeRef } from 'vue/types/vnode'

import MessageSessionWidget from './message-session-widget/message-session-widget.vue'

import useAccount from '@/v1.5/features/auth/hooks/use-account.hook'
import { markConversationAsReadMutation, sendMessageMutation } from '@/v1.5/features/chat/api'
import ConversationSeenBy from '@/v1.5/features/chat/components/messages-block/conversation-seen-by/conversation-seen-by.vue'
import Message from '@/v1.5/features/chat/components/messages-block/message/message.vue'
import useConversation from '@/v1.5/features/chat/hooks/use-conversation.hook'
import { useFirstGroupMessage } from '@/v1.5/features/chat/hooks/use-first-group-message.hook'
import useGroupConversationsWithoutFirstMessage from '@/v1.5/features/chat/hooks/use-group-conversations-without-first-message.hook'
import useGroup from '@/v1.5/features/groups/hooks/use-group.hook'
import { selectGetSelectedGroupId } from '@/v1.5/features/groups/stores/groups/groups.selectors'
import groupsStore from '@/v1.5/features/groups/stores/groups/groups.store'
import CustomButton from '@/v1.5/features/ui/components/button/custom-button.vue'
import CustomTextarea from '@/v1.5/features/ui/components/custom-textarea/custom-textarea.vue'
import EmptyContent from '@/v1.5/features/ui/components/empty-content/empty-content.vue'
import ArrowIcon from '@/v1.5/features/ui/components/icons/arrow-icon.vue'
import GroupIcon from '@/v1.5/features/ui/components/icons/group-icon.vue'
import LoadingIcon from '@/v1.5/features/ui/components/icons/loading-icon.vue'
import NoConversationSelectedIcon from '@/v1.5/features/ui/components/icons/no-conversation-selected-icon.vue'
import LoadingContent from '@/v1.5/features/ui/components/loading-content/loading-content.vue'
import Avatar from '@/v1.5/features/users/components/avatar/avatar.vue'
import useRouter from '@/v1.5/hooks/use-router.hook'
import useToast from '@/v1.5/hooks/use-toasts.hook'
import { useI18n } from '@/v1.5/lib/i18n'
import { selectGetCurrentRoute } from '@/v1.5/store/route/route.selector'
import routeStore from '@/v1.5/store/route/route.store'
import { invalidateConversations } from '@/v1.5/utils/lib/vue-query'

const { t } = useI18n()

const toast = useToast()
const router = useRouter()
const currentRouteRef = selectGetCurrentRoute(routeStore)

const { accountRef } = useAccount()

const selectedGroupIdRef = selectGetSelectedGroupId(groupsStore)
const { groupRef } = useGroup(selectedGroupIdRef)
const selectedConversationIdRef = computed(() => currentRouteRef.value?.params.roomId ?? null)
const { conversationRef, isPendingRef: isConversationPendingRef } = useConversation(selectedConversationIdRef)

// get all the groups without the first message sent and check if the current conversation is one of them
const { groupConversationsWithoutFirstMessageSentRef } = useGroupConversationsWithoutFirstMessage()
const isFirstMessageNotSentRef = computed(() =>
  groupConversationsWithoutFirstMessageSentRef.value.some((c) => c.conversationId === selectedConversationIdRef.value),
)
const hasUserStartedTypingRef = ref(false)

// watch for conversation changes and clear the input
watch(selectedConversationIdRef, (newVal) => {
  if (newVal && inputMessageRef.value) {
    inputMessageRef.value.clear()
  }
})

// adjust text area as we type (create fake div to get the height of the text)
function adjustTextAreaHeight() {
  const textArea = inputMessageRef.value.$el.querySelector('textarea')!
  if (textArea) {
    const div = document.createElement('div')
    div.style.whiteSpace = 'pre-wrap'
    div.innerHTML = textArea.value
    div.style.width = textArea.offsetWidth + 'px'

    document.documentElement.appendChild(div)

    textArea.style.height = `${div.scrollHeight + 20}px`
    div.remove()
  }
}

// if it's the case, get the first message generated by the AI
const {
  firstGroupMessageRef,
  isLoadingRef: isFirstGroupMessageLoadingRef,
  isErrorRef: isFirstGroupMessageErrorRef,
} = useFirstGroupMessage(selectedConversationIdRef, isFirstMessageNotSentRef)

// watch for the first mentorship group message to be generated (AI), set it in the input
// if the user hasn't started typing yet, we set the message in the input
watch(firstGroupMessageRef, (newVal) => {
  if (hasUserStartedTypingRef.value) {
    return
  }
  if (newVal?.message) {
    messageRef.value = newVal.message
    inputMessageRef.value.set(newVal.message)
    // adjust the height after the message is set
    setTimeout(() => {
      adjustTextAreaHeight()
    }, 5)
  } else {
    messageRef.value = null
    inputMessageRef.value?.clear()
  }
})

const isGroupRef = computed(() => conversationRef.value && conversationRef.value.users.length > 2)
const correspondentRef = computed(() => conversationRef.value?.users?.find((user) => user.id !== accountRef.value?.id))

const feedRef = ref<VNodeRef | null>(null)
const inputMessageRef = ref<VNodeRef | null>(null)
const messageRef = ref<string | null>(null)

function isSessionWidgetMessage(messageContent: string) {
  return messageContent.startsWith('***session=')
}

function getSessionIdFromMessage(messageContent: string) {
  return messageContent.split('***session=')[1]
}

// closing the current chat on mobile device (arrow)
function closeCurrentChat() {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  router?.push({ params: { roomId: null } }).catch(() => {})
}

// on message typing
function onChange(newVal: string) {
  hasUserStartedTypingRef.value = true
  messageRef.value = newVal
}

// on new messages, scroll to bottom + mark conversation as read
watch(
  [feedRef, conversationRef],
  ([feed]) => {
    if (feed) {
      feed.scrollTo({ top: feed.scrollHeight })
    }
    // if conversation selected and the user hasn't seen the conversation yet, mark it as read
    if (
      selectedConversationIdRef.value &&
      conversationRef.value &&
      !conversationRef.value.users.find((u) => u.id === accountRef.value?.id)?.hasSeen
    ) {
      markConversationAsRead({ conversationId: selectedConversationIdRef.value })
    }
  },
  { deep: true, immediate: true, flush: 'post' },
)

// send message
const { mutate: sendMessage, isPending: isPendingRef } = useMutation({
  mutationFn: sendMessageMutation,
  onSuccess: async () => {
    await invalidateConversations(selectedGroupIdRef.value!)
    messageRef.value = null
    inputMessageRef.value.clear()
    // adjust the height after the message is sent
    setTimeout(() => {
      adjustTextAreaHeight()
    }, 5)
  },
  onError: (error) => {
    console.error(error)
    toast?.error(t('error.common'))
  },
})

// sanitifize the message before sending
function sanitizeMessage(message: string) {
  return message.trim()
}

function sendMessageHandler() {
  // do stuff
  if (messageRef.value?.length && selectedConversationIdRef.value) {
    sendMessage({ message: sanitizeMessage(messageRef.value), conversationId: selectedConversationIdRef.value })
  }
}

const { mutate: markConversationAsRead } = useMutation({
  mutationFn: markConversationAsReadMutation,
  onSuccess: () => {
    invalidateConversations(selectedGroupIdRef.value!)
  },
})

// on mount, mark the conversation as read
watch(
  [selectedConversationIdRef, conversationRef],
  () => {
    // if conversation selected and the user hasn't seen the conversation yet, mark it as read
    if (
      selectedConversationIdRef.value &&
      conversationRef.value &&
      !conversationRef.value.users.find((u) => u.id === accountRef.value?.id)?.hasSeen
    ) {
      markConversationAsRead({ conversationId: selectedConversationIdRef.value })
    }
  },
  { immediate: true, flush: 'post' },
)
</script>

<style lang="scss">
@import './messages-block.scss';
</style>
