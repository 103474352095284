<!-- eslint-disable -->
<!-- @ts-nocheck -->
<template>
  <Modal
    :onCloseFn="closePopup"
    class="popup"
    name="mentorProfile"
    :title="
      isLoading
        ? 'Chargement du profil...'
        : 'Profil de ' +
          mentor?.Identity?.firstname +
          ' ' +
          mentor?.Identity?.lastname
    "
  >
    <div v-if="isLoading" class="content p-4">
      <p>Chargement...</p>
    </div>
    <div v-else class="content p-4">
      <div class="main-info">
        <div class="profile-info">
          <ProfilePicture
            class="profile-picture"
            :src="mentor?.PersonalInformation?.profilPicture"
            :firstname="mentor?.Identity?.firstname"
            :lastname="mentor?.Identity?.lastname"
            :width="120"
            :height="120"
          />
          <!-- <div class="picture">
            <img
              v-if="mentor?.PersonalInformation?.profilPicture"
              :src="
                'https://profil-picture.s3.fr-par.scw.cloud/' +
                mentor?.PersonalInformation?.profilPicture
              "
              alt="photo de profile"
            />
          </div> -->
          <div class="right-part">
            <p class="name">
              <span>
                {{ mentor?.Identity?.firstname }}
                {{ mentor?.Identity?.lastname }}
              </span>
              <a
                class="email material-symbols-outlined"
                :href="'mailto:' + mentor?.email"
                target="_blank"
              >
                email
              </a>
            </p>
            <p class="title">
              {{ mentor?.MentorProfil?.title }}
            </p>
          </div>
        </div>
        <div class="info-wrapper">
          <p>Bio</p>
          <p class="profile-description">
            {{ mentor?.MentorProfil?.description }}
          </p>
        </div>
        <div class="info-wrapper">
          <p>Expérience</p>
          <p class="profile-experience">
            {{ mentor?.MentorProfil?.experience }}
          </p>
        </div>
        <div class="info-wrapper">
          <p>Matières et notes</p>
          <ul
            class="profile-subjects"
            v-if="
              mentor?.MentorBranchSubjects &&
              !!mentor?.MentorBranchSubjects.length
            "
          >
            <li v-for="item in mentor?.MentorBranchSubjects" :key="item.id">
              <span
                >- {{ item.subjectname }}
                <strong>{{ item.grade }}</strong></span
              >
            </li>
          </ul>
          <p class="no-marks" v-else>
            <span>Aucune note</span>
          </p>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Modal from '@/components/Modal.vue'
import ProfilePicture from '@/components/ProfilePicture.vue'

export default {
  name: 'MentorProfilePopup',
  data() {
    return {
      mentor: null,
      isLoading: true,
      ...mapGetters(['isMentorProfilePopupOpen']),
    }
  },
  computed: {},
  methods: {
    ...mapActions(['switchModal', 'getMentorProfile', 'setMentorId']),

    closePopup() {
      this.switchModal({ name: 'mentorProfile', show: false })
      this.setMentorId(null)
      this.resetPopupData()
    },
    resetPopupData() {
      this.mentor = null
    },
  },
  watch: {
    async '$store.getters.mentorId'() {
      if (!this.$store.getters.mentorId) return
      this.mentor = await this.getMentorProfile(this.$store.getters.mentorId)
    },
    mentor(newMentor, oldMentor) {
      if (this.mentor) {
        this.isLoading = false
      } else {
        this.isLoading = true
      }
    },
  },
  components: {
    Modal,
    ProfilePicture,
  },
}
</script>
<style lang="scss" scoped>
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.modal-header {
  position: relative;
  &-close {
    position: absolute;
    top: 0;
    left: 0;
    padding: 24px;
  }
}
.content {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 16px;
  .main-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    .profile-info {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      gap: 40px;
      padding-bottom: 32px;
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 1px;
        width: 150%;
        background-color: var(--bs-gray);
      }

      .right-part {
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: 8px;
        .name {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 16px;
          font-size: 1.5rem;
          font-weight: 500;
          margin-bottom: 0;

          .email {
            color: var(--bs-primary);
            text-decoration: none;
          }
        }
        .title {
          font-style: italic;
          margin-bottom: 0;
          opacity: 0.6;
        }
      }
      .picture {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        background-color: var(--bs-gray);
        align-self: center;
        overflow: hidden;
        flex-shrink: 0;
        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
    }
    .info-wrapper {
      display: flex;
      flex-direction: column;
      align-items: start;
      gap: 8px;
      overflow: hidden;
      width: 100%;
      &:not(:first-child) {
        margin-top: 32px;
      }
      p {
        margin-bottom: 0;
        &:nth-child(1) {
          position: relative;
          font-weight: 600;
          color: var(--bs-primary);
          &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: calc(100% + 16px);
            transform: translateY(-50%);
            height: 1px;
            width: 800px;
            background-color: var(--bs-primary);
            opacity: 0.2;
          }
        }
        &:nth-child(2) {
          margin-left: 8px;
        }
      }
      ul {
        li {
          list-style-type: circle;
        }
      }
    }
  }
}
</style>
