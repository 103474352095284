import { computed, ref, watchEffect } from 'vue'

import { selectGetMoreActions } from '@/v1.5/features/ui/components/more-actions-menu/store/more-actions.selectors'
import type { MoreActionsStoreType } from '@/v1.5/features/ui/components/more-actions-menu/store/more-actions.store'
import moreActionsStore from '@/v1.5/features/ui/components/more-actions-menu/store/more-actions.store'
import useRouter from '@/v1.5/hooks/use-router.hook'
import useWindowSize from '@/v1.5/hooks/use-window-size.hook'
import { BREAKPOINTS } from '@/v1.5/utils/config/constants'
import sleep from '@/v1.5/utils/misc/sleep'

function useMoreActionsMenu() {
  const { widthRef } = useWindowSize()
  const dataActionIdRef = ref<MoreActionsStoreType['name'] | undefined>(undefined)
  const moreActionsRef = computed(() => selectGetMoreActions(moreActionsStore, dataActionIdRef.value).value)
  const { setAttachedData } = moreActionsStore
  const router = useRouter()

  watchEffect(
    (onCleanup) => {
      const menu = document.querySelector('.more-actions-menu') as HTMLElement
      let dataAction: MoreActionsStoreType['name'] | null = null
      let dataActionFadeOutTimeout: any

      // getting the route just to watch for changes
      if (router?.currentRoute.path) {
        //
      }

      const openClass = 'more-actions-menu--open'

      const handleClick = async (e: MouseEvent) => {
        const target = e.target as HTMLElement

        dataAction = (target.dataset.action || target.closest<HTMLElement>('[data-action]')?.dataset.action) as
          | MoreActionsStoreType['name']
          | null

        const dataActionAttachedData =
          target.dataset.attached || target.closest<HTMLElement>('[data-attached]')?.dataset.attached

        // check if the target or one of its parents has the data-action attribute
        // if it does, add the open class to the menu
        if (dataAction) {
          console.log(dataAction)
          e.preventDefault()
          e.stopPropagation()
          clearTimeout(dataActionFadeOutTimeout)

          if (dataActionAttachedData) {
            setAttachedData(dataAction, JSON.parse(dataActionAttachedData))
          }

          menu.classList.add(openClass)
          dataActionIdRef.value = dataAction

          // await 10ms because the dom might not be updated yet
          await sleep(10)

          // position the menu at the mouse position
          // be careful with the position, it should not go outside the window
          // on mobile device, the menu should be positioned at the bottom of the screen (CSS)
          // so this part should be run only on desktop
          if (widthRef.value >= BREAKPOINTS.DESKTOP) {
            const rect = menu.getBoundingClientRect()
            const x = e.clientX
            const y = e.clientY
            const windowHeight = window.innerHeight
            const safetyMargin = 32

            if (x < rect.width - safetyMargin) {
              menu.style.left = `${x}px`
            } else {
              menu.style.left = `${x - rect.width}px`
            }

            if (y < rect.height) {
              menu.style.top = `${y}px`
            } else if (y > windowHeight - rect.height - safetyMargin) {
              menu.style.top = `${y - rect.height}px`
            } else {
              menu.style.top = `${y}px`
            }
          } else {
            menu.style.left = ''
            menu.style.top = ''
          }
        } else {
          // if the target or one of its parents does not have the data-action attribute, remove the open class
          menu.classList.remove(openClass)
        }
      }

      const handleOnMouseLeave = () => {
        menu.classList.remove(openClass)
        dataActionFadeOutTimeout = setTimeout(() => {
          if (dataAction) {
            dataActionIdRef.value = undefined
            setAttachedData(dataAction, {})
            if (widthRef.value >= BREAKPOINTS.DESKTOP) {
              // moving th menu out of the screen
              menu.style.left = '-100px'
              menu.style.top = '-100px'
            }
          }
        }, 240) // transition duration (-10ms for safety)
      }

      document.addEventListener('click', handleClick)
      menu.addEventListener('mouseleave', handleOnMouseLeave)

      onCleanup(() => {
        document.removeEventListener('click', handleClick)
        menu.removeEventListener('mouseleave', handleOnMouseLeave)
        clearTimeout(dataActionFadeOutTimeout)
      })
    },
    { flush: 'post' },
  ) // update the event listener when the window width changes and reload on pathname changes

  return { moreActionsRef }
}

export default useMoreActionsMenu
