import type { ComputedRef } from 'vue'
import { computed } from 'vue'

import usePrograms from '@/v1.5/features/programs/hooks/use-programs.hook'

const useProgram = (programId: ComputedRef<string | null>) => {
  const { programsRef, isPendingRef } = usePrograms()

  const programRef = computed(() => programsRef.value.find((program) => program.id === programId.value))
  return {
    programRef,
    isPendingRef,
  }
}

export default useProgram
