import { getCurrentInstance } from 'vue'

function useToast() {
  const instance = getCurrentInstance()
  const toast = instance?.proxy.$toast as
    | {
        success: (message: string) => void
        error: (message: string) => void
      }
    | undefined

  return toast
}

export default useToast
