import { computed } from 'vue'

import useConversations from '@/v1.5/features/chat/hooks/use-conversations.hook'
import useGroups from '@/v1.5/features/groups/hooks/use-groups.hook'

function useGroupConversationsWithoutFirstMessage() {
  const { groupsRef } = useGroups()
  const { conversationsRef, isPendingRef: isConversationsPendingRef } = useConversations()

  // get the conversations that are group conversations and have no messages sent
  const groupConversationsWithoutFirstMessageSentRef = computed(() =>
    conversationsRef.value.filter((conversation) => {
      const group = groupsRef.value.find((mg) => mg.conversationId === conversation.conversationId)
      return group && !conversation.messages.length
    }),
  )

  return {
    groupConversationsWithoutFirstMessageSentRef,
    isConversationsPendingRef,
  }
}

export default useGroupConversationsWithoutFirstMessage
