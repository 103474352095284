<template>
  <ModalStep
    :id="'add-to-calendar-modal-content'"
    :header="{
      title: t('session.addToCalendarModal.title'),
    }"
  >
    <template #bodyInformation>
      <strong>
        {{ t('session.addToCalendarModal.bodyInformation') }}
      </strong>
    </template>
    <template #bodyContent>
      <div class="calendars-options-container">
        <div class="calendar-item" @click="openGoogleCalendar">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M16.7376 7.26279L12.474 6.78906L7.26288 7.26279L6.78906 12.0002L7.26279 16.7376L12.0002 17.3298L16.7376 16.7376L17.2113 11.8818L16.7376 7.26279Z"
              fill="white"
            />
            <path
              d="M9.20677 14.6127C8.85267 14.3735 8.60748 14.0241 8.47363 13.5622L9.29561 13.2235C9.37022 13.5077 9.50047 13.728 9.68643 13.8843C9.87122 14.0407 10.0962 14.1177 10.3592 14.1177C10.628 14.1177 10.859 14.0359 11.052 13.8725C11.245 13.709 11.3422 13.5005 11.3422 13.2483C11.3422 12.9902 11.2403 12.7793 11.0366 12.6159C10.8329 12.4526 10.5771 12.3707 10.2715 12.3707H9.7966V11.5571H10.2229C10.4858 11.5571 10.7073 11.4861 10.8873 11.344C11.0674 11.2019 11.1574 11.0076 11.1574 10.7601C11.1574 10.5398 11.0768 10.3645 10.9158 10.2331C10.7548 10.1017 10.551 10.0353 10.3034 10.0353C10.0619 10.0353 9.86996 10.0993 9.72783 10.2284C9.58571 10.3575 9.48265 10.5162 9.41757 10.7033L8.60397 10.3646C8.71171 10.059 8.90955 9.78897 9.19965 9.55567C9.48985 9.32236 9.86051 9.20508 10.3106 9.20508C10.6433 9.20508 10.943 9.26908 11.2083 9.39815C11.4736 9.52722 11.682 9.70608 11.8325 9.93344C11.9829 10.162 12.0575 10.4179 12.0575 10.702C12.0575 10.9922 11.9876 11.2373 11.8478 11.4387C11.7081 11.64 11.5363 11.794 11.3326 11.9018V11.9503C11.6015 12.0628 11.8206 12.2346 11.9935 12.4655C12.1652 12.6965 12.2516 12.9725 12.2516 13.2946C12.2516 13.6167 12.1699 13.9045 12.0064 14.1568C11.843 14.4091 11.6168 14.608 11.3302 14.7525C11.0424 14.897 10.7191 14.9704 10.3602 14.9704C9.94458 14.9716 9.56087 14.852 9.20677 14.6127Z"
              fill="#1A73E8"
            />
            <path
              d="M14.2503 10.5337L13.3526 11.1863L12.9014 10.5018L14.5204 9.33398H15.141V14.8423H14.2503V10.5337Z"
              fill="#1A73E8"
            />
            <path
              d="M16.7375 21.0021L21.0011 16.7385L18.8693 15.791L16.7375 16.7385L15.79 18.8703L16.7375 21.0021Z"
              fill="#EA4335"
            />
            <path d="M6.31543 18.872L7.26288 21.0038H16.7376V16.7402H7.26288L6.31543 18.872Z" fill="#34A853" />
            <path
              d="M4.42019 3C3.63504 3 2.99902 3.63601 2.99902 4.42117V16.7383L5.13082 17.6858L7.26263 16.7383V7.2636H16.7374L17.6848 5.1318L16.7375 3H4.42019Z"
              fill="#4285F4"
            />
            <path
              d="M2.99902 16.7402V19.5827C2.99902 20.3679 3.63504 21.0038 4.42019 21.0038H7.26263V16.7402H2.99902Z"
              fill="#188038"
            />
            <path d="M16.7373 7.26385V16.7386H21.0009V7.26385L18.8691 6.31641L16.7373 7.26385Z" fill="#FBBC04" />
            <path
              d="M21.0009 7.2636V4.42117C21.0009 3.63592 20.3649 3 19.5797 3H16.7373V7.2636H21.0009Z"
              fill="#1967D2"
            />
          </svg>
        </div>
        <div class="calendar-item" @click="openOutlookCalendar">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M21.8037 11.8751L18.2227 9.78906V15.5861H22.0002V12.2171C22.0002 12.0766 21.9252 11.9461 21.8037 11.8751Z"
              fill="#1A237E"
            />
            <path
              d="M16.9588 4.42H21.4818V3.866C21.4818 3.3875 21.0943 3 20.6163 3H8.8328C8.3548 3 7.9668 3.3875 7.9668 3.866V4.42H12.4693H16.9588Z"
              fill="#0C4999"
            />
            <path
              d="M16.96 16.7619H20.617C21.095 16.7619 21.483 16.3744 21.483 15.8959V12.4824H16.96V16.7619Z"
              fill="#0F73D9"
            />
            <path
              d="M7.96777 12.4824V15.8959C7.96777 16.3739 8.35527 16.7619 8.83377 16.7619H12.4703V12.4829L7.96777 12.4824Z"
              fill="#0F439D"
            />
            <path d="M16.96 4.41992H21.483V8.43342H16.96V4.41992Z" fill="#2ECDFD" />
            <path d="M7.96777 4.41992H12.4703V8.43342H7.96777V4.41992Z" fill="#1C5FB0" />
            <path d="M12.4707 12.4824H16.9602V16.7614H12.4707V12.4824Z" fill="#1467C7" />
            <path d="M12.4707 4.41992H16.9602V8.43342H12.4707V4.41992Z" fill="#1690D5" />
            <path d="M16.96 8.43359H21.483V12.4816H16.96V8.43359Z" fill="#1BB4FF" />
            <path d="M7.96777 8.43359H12.4703V12.4816H7.96777V8.43359Z" fill="#074DAF" />
            <path d="M12.4707 8.43359H16.9602V12.4816H12.4707V8.43359Z" fill="#2076D4" />
            <path
              d="M7.72057 20.9998C7.95207 20.9998 21.1556 20.9998 21.1556 20.9998C21.6221 20.9998 22.0001 20.6218 22.0001 20.1553V12.2188C22.0001 12.2188 21.9851 12.5478 21.1246 13.0273C20.4746 13.3893 7.37207 20.7828 7.37207 20.7828C7.37207 20.7828 7.48907 20.9998 7.72057 20.9998Z"
              fill="#2ED0FF"
            />
            <path
              d="M21.1395 20.9982C21.059 20.9982 7.8445 20.9997 7.8445 20.9997C7.378 20.9997 7 20.6217 7 20.1552V12.5332L21.6815 20.8142C21.559 20.9122 21.4035 20.9982 21.1395 20.9982Z"
              fill="#139FE2"
            />
            <path
              d="M11.1595 17H2.8405C2.3765 17 2 16.6235 2 16.1595V7.8405C2 7.3765 2.3765 7 2.8405 7H11.1595C11.6235 7 12 7.3765 12 7.8405V16.1595C12 16.6235 11.6235 17 11.1595 17Z"
              fill="#00488D"
            />
            <path
              d="M6.95772 9.36719C5.39222 9.36719 4.44922 10.5632 4.44922 12.0387C4.44922 13.5142 5.38872 14.7097 6.95772 14.7097C8.52722 14.7097 9.46622 13.5137 9.46622 12.0387C9.46622 10.5632 8.52322 9.36719 6.95772 9.36719ZM6.95772 13.8082C6.06972 13.8082 5.53872 13.0162 5.53872 12.0387C5.53872 11.0612 6.07222 10.2692 6.95772 10.2692C7.84322 10.2692 8.37722 11.0617 8.37722 12.0387C8.37722 13.0157 7.84522 13.8082 6.95772 13.8082Z"
              fill="white"
            />
          </svg>
        </div>
        <div class="calendar-item" @click="downloadICS">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M4.90476 4.85742C3.85276 4.85742 3 5.71456 3 6.76218V20.0955C3 21.1431 3.85276 22.0003 4.90476 22.0003H10.619H12.5238H18.2381C19.2905 22.0003 20.1429 21.1431 20.1429 20.0955V6.76218C20.1429 5.71456 19.2905 4.85742 18.2381 4.85742H12.5238H10.619H4.90476Z"
              fill="#BDC3C7"
            />
            <path
              d="M4.90476 3.9043C3.85276 3.9043 3 4.75706 3 5.80906V19.1424C3 20.1948 3.85276 21.0472 4.90476 21.0472H10.619H12.5238H18.2381C19.2905 21.0472 20.1429 20.1948 20.1429 19.1424V5.80906C20.1429 4.75706 19.2905 3.9043 18.2381 3.9043H12.5238H10.619H4.90476Z"
              fill="#ECF0F1"
            />
            <path
              d="M4.90476 3.9043C3.85276 3.9043 3 4.75706 3 5.80906V8.6662V9.61858H20.1429V8.6662V5.80906C20.1429 4.75706 19.2905 3.9043 18.2381 3.9043H12.5238H10.619H4.90476Z"
              fill="#E74C3C"
            />
            <path
              d="M7.28585 6.28599C7.28585 6.4736 7.2489 6.65936 7.17711 6.83268C7.10532 7.00601 7.00009 7.16349 6.86743 7.29615C6.73478 7.4288 6.5773 7.53403 6.40397 7.60582C6.23065 7.67761 6.04488 7.71456 5.85728 7.71456C5.66968 7.71456 5.48391 7.67761 5.31059 7.60582C5.13727 7.53403 4.97978 7.4288 4.84713 7.29615C4.71447 7.16349 4.60925 7.00601 4.53745 6.83268C4.46566 6.65936 4.42871 6.4736 4.42871 6.28599C4.42871 6.09839 4.46566 5.91262 4.53745 5.7393C4.60925 5.56598 4.71447 5.4085 4.84713 5.27584C4.97978 5.14319 5.13727 5.03796 5.31059 4.96617C5.48391 4.89437 5.66968 4.85742 5.85728 4.85742C6.04488 4.85742 6.23065 4.89437 6.40397 4.96617C6.5773 5.03796 6.73478 5.14319 6.86743 5.27584C7.00009 5.4085 7.10532 5.56598 7.17711 5.7393C7.2489 5.91262 7.28585 6.09839 7.28585 6.28599Z"
              fill="#C0392B"
            />
            <path
              d="M5.85668 2C5.33068 2 4.9043 2.42638 4.9043 2.95238V5.80952C4.9043 6.33552 5.33068 6.7619 5.85668 6.7619C6.38268 6.7619 6.80906 6.33552 6.80906 5.80952V2.95238C6.80906 2.42638 6.38268 2 5.85668 2Z"
              fill="#BDC3C7"
            />
            <path
              d="M18.7146 6.28599C18.7146 6.4736 18.6776 6.65936 18.6058 6.83268C18.534 7.00601 18.4288 7.16349 18.2961 7.29615C18.1635 7.4288 18.006 7.53403 17.8327 7.60582C17.6594 7.67761 17.4736 7.71456 17.286 7.71456C17.0984 7.71456 16.9126 7.67761 16.7393 7.60582C16.566 7.53403 16.4085 7.4288 16.2758 7.29615C16.1432 7.16349 16.038 7.00601 15.9662 6.83268C15.8944 6.65936 15.8574 6.4736 15.8574 6.28599C15.8574 6.09839 15.8944 5.91262 15.9662 5.7393C16.038 5.56598 16.1432 5.4085 16.2758 5.27584C16.4085 5.14319 16.566 5.03796 16.7393 4.96617C16.9126 4.89437 17.0984 4.85742 17.286 4.85742C17.4736 4.85742 17.6594 4.89437 17.8327 4.96617C18.006 5.03796 18.1635 5.14319 18.2961 5.27584C18.4288 5.4085 18.534 5.56598 18.6058 5.7393C18.6776 5.91262 18.7146 6.09839 18.7146 6.28599Z"
              fill="#C0392B"
            />
            <path
              d="M17.2864 2C16.7607 2 16.334 2.38095 16.334 2.95238V5.80952C16.334 6.28571 16.7607 6.7619 17.2864 6.7619C17.8121 6.7619 18.2387 6.28571 18.2387 5.80952V2.95238C18.2387 2.38095 17.8121 2 17.2864 2Z"
              fill="#BDC3C7"
            />
            <path
              d="M4.90527 11.5234V13.4282H6.81004V11.5234H4.90527ZM7.76242 11.5234V13.4282H9.66718V11.5234H7.76242ZM10.6196 11.5234V13.4282H12.5243V11.5234H10.6196ZM13.4767 11.5234V13.4282H15.3815V11.5234H13.4767ZM16.3338 11.5234V13.4282H18.2386V11.5234H16.3338Z"
              fill="#BDC3C7"
            />
            <path
              d="M4.90527 14.3809V16.2856H6.81004V14.3809H4.90527ZM7.76242 14.3809V16.2856H9.66718V14.3809H7.76242ZM10.6196 14.3809V16.2856H12.5243V14.3809H10.6196ZM13.4767 14.3809V16.2856H15.3815V14.3809H13.4767ZM16.3338 14.3809V16.2856H18.2386V14.3809H16.3338Z"
              fill="#BDC3C7"
            />
            <path
              d="M4.90527 17.2383V19.143H6.81004V17.2383H4.90527ZM7.76242 17.2383V19.143H9.66718V17.2383H7.76242ZM10.6196 17.2383V19.143H12.5243V17.2383H10.6196ZM13.4767 17.2383V19.143H15.3815V17.2383H13.4767ZM16.3338 17.2383V19.143H18.2386V17.2383H16.3338Z"
              fill="#BDC3C7"
            />
          </svg>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="c-btns-container c-btn-center">
        <CustomButton
          usage="button"
          type="submit"
          color="white"
          :text="t('ui.button.close')"
          @emit:click="closeModal"
        />
      </div>
    </template>
  </ModalStep>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { generateAddToCalendarOptions } from '@/v1.5/features/sessions/utils'
import CustomButton from '@/v1.5/features/ui/components/button/custom-button.vue'
import ModalStep from '@/v1.5/features/ui/components/modal/modal-step/modal-step.vue'
import { selectGetAddToCalendarModal } from '@/v1.5/features/ui/store/modal/modal.selectors'
import modalsStore from '@/v1.5/features/ui/store/modal/modal.store'
import useToast from '@/v1.5/hooks/use-toasts.hook'
import { useI18n } from '@/v1.5/lib/i18n'
import { downloadFile } from '@/v1.5/utils/client/download-file'

const toast = useToast()
const { t } = useI18n()

const addToCalendarModalRef = selectGetAddToCalendarModal(modalsStore)
const sessionRef = computed(() => addToCalendarModalRef.value.attachedData.session)

const closeModal = () => {
  modalsStore.toggleModal('add-to-calendar', false)
}

const downloadICS = async () => {
  const { icsUrl } = await generateAddToCalendarOptions(sessionRef.value!, t)
  downloadFile(icsUrl, 'session.ics')
}

const openGoogleCalendar = async () => {
  const { googleCalendarUrl } = await generateAddToCalendarOptions(sessionRef.value!, t)
  window.open(googleCalendarUrl, '_blank')
}

const openOutlookCalendar = async () => {
  const { outlookCalendarUrl } = await generateAddToCalendarOptions(sessionRef.value!, t)
  window.open(outlookCalendarUrl, '_blank')
}
</script>

<style lang="scss">
@import './add-to-calendar-modal-content.scss';
</style>
