<!-- eslint-disable -->
<!-- @ts-nocheck -->
<template>
  <Page>
    <template v-slot:middle>
      <div id="meet"></div>
    </template>
  </Page>
</template>

<script>
import { mapActions } from 'vuex'

import Page from '@/components/Page.vue'
import { apiAuthMeeting } from '@/services/MeetingService.js'

export default {
  name: 'Visio',
  data() {
    return {
      elm: null,
      window: {
        width: 0,
        height: 0
      },
      meeting: {
        roomUrl: null
      },
      meetingTracker: null
    }
  },
  components: {
    Page
  },
  methods: {
    ...mapActions(['setSelectedSessionId', 'switchModal']),
    handleResize() {
      this.window.width = window.innerWidth
      this.window.height = window.innerHeight
    },
    async initJitsiMeet() {
      // temporary sidebar modal fix (to remove when v2 sidebar is implemented)
      this.switchModal({ name: 'session-details', show: false })
      this.setSelectedSessionId(null)
      // end of temporary sidebar modal fix

      const result = await apiAuthMeeting(this.$store.getters.authToken, this.$route.params.id)
      const domain = 'meet.constel-education.com'
      const options = {
        roomName: this.$route.params.id,
        width: '100%',
        height: this.window.height - 48,
        parentNode: document.querySelector('#meet'),
        lang: 'fr',
        jwt: result.token
      }

      console.log('options', options)

      const api = new JitsiMeetExternalAPI(domain, options)
      const _this = this

      api.addListener('readyToClose', function (e) {
        _this.$router.push({ name: 'IndexMentoringGroup' })
        _this.$toast.success('Vous avez quitté la visioconférence')
      })
    }
  },
  async mounted() {
    const jitsiScript = document.createElement('script')
    jitsiScript.setAttribute('src', 'https://meet.constel-education.com/external_api.js')
    jitsiScript.setAttribute('src', 'https://meet.constel-education.com/external_api.js')
    document.head.appendChild(jitsiScript)

    jitsiScript.onload = () => {
      this.initJitsiMeet()
    }
  },
  created() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  }
}
</script>

<style lang="scss" scoped>
whereby-embed {
  min-height: calc(100vh - 3rem);
}
</style>

<style lang="scss">
#meet {
  height: 100%;
  iframe {
    height: 100% !important;
  }
}
</style>
