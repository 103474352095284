<!-- eslint-disable -->
<!-- @ts-nocheck -->
<template>
  <div
    class="mt-4"
    v-if="
      profile.role == 'ROLE_MENTOR' &&
      (!profile.MentorProfil ||
        profile.MentorProfil.visible ||
        profile.Identity.userId == $store.getters.authUserId)
    "
  >
    <div
      v-if="
        (mentorData &&
          mentorData.MentorProfil &&
          mentorData.MentorProfil.title) ||
        (mentorData &&
          mentorData.MentorProfil &&
          mentorData.MentorProfil.description) ||
        profile.Identity.userId == $store.getters.authUserId
      "
      class="bg-light p-4 rounded border-main"
    >
      <div class="d-flex justify-content-between">
        <h2
          class="text-uppercase fs-5"
          v-if="
            (mentorData &&
              mentorData.MentorProfil &&
              mentorData.MentorProfil.title) ||
            profile.Identity.userId == $store.getters.authUserId
          "
        >
          {{
            mentorData &&
            mentorData.MentorProfil &&
            mentorData.MentorProfil.title
              ? mentorData.MentorProfil.title
              : 'Votre titre..'
          }}
        </h2>
        <div
          v-if="profile.Identity.userId == $store.getters.authUserId"
          class="edit rounded-circle c-pointer"
          @click="switchModal({ name: 'mentorProfilDescription', show: true })"
        >
          <span class="material-symbols-outlined"> edit </span>
        </div>
      </div>
      <p
        v-if="
          (mentorData &&
            mentorData.MentorProfil &&
            mentorData.MentorProfil.description) ||
          profile.Identity.userId == $store.getters.authUserId
        "
        class="wp-wrap mt-4"
      >
        {{
          mentorData &&
          mentorData.MentorProfil &&
          mentorData.MentorProfil.description
            ? mentorData.MentorProfil.description
            : 'Votre bio..'
        }}
      </p>
    </div>
    <div
      v-if="
        (mentorData &&
          mentorData.MentorProfil &&
          mentorData.MentorProfil.experience) ||
        profile.Identity.userId == $store.getters.authUserId
      "
      class="bg-light p-4 rounded border-main mt-4"
    >
      <div class="d-flex justify-content-between">
        <h2 class="text-uppercase fs-5">Expériences</h2>
        <div
          v-if="profile.Identity.userId == $store.getters.authUserId"
          class="edit rounded-circle c-pointer"
          @click="switchModal({ name: 'mentorProfilDescription', show: true })"
        >
          <span class="material-symbols-outlined"> edit </span>
        </div>
      </div>
      <p class="wp-wrap mt-4">
        {{
          mentorData &&
          mentorData.MentorProfil &&
          mentorData.MentorProfil.experience
            ? mentorData.MentorProfil.experience
            : ''
        }}
      </p>
    </div>
    <div
      v-if="profile.Identity.userId == $store.getters.authUserId"
      @click="show()"
      class="update"
    >
      <p class="text-center">
        <i class="fas fa-pencil-alt"></i> <br />
        Modifier
      </p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'MentorProfilDescription',
  computed: {
    ...mapGetters(['profile']),
    ...mapState(['mentorData']),
  },
  methods: {
    ...mapActions(['switchModal']),
  },
}
</script>

<style lang="scss" scoped>
.update {
  display: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  background-color: rgba(26, 35, 127, 0.4);
  height: 100%;
  justify-content: center;
  align-items: center;
  color: #fff;
  transition-duration: 250ms;

  left: calc(var(--bs-gutter-x) * 0.5);
  width: calc(100% - calc(var(--bs-gutter-x) * 0.5 * 2));
}

.wp-wrap {
  white-space: pre-wrap;
}

.edit {
  background-color: #f1f2f5;
  min-width: 36px;
  min-height: 36px;
  max-height: 36px;
  max-width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  &-profil-picture {
    position: absolute;
    bottom: 5px;
    right: 5px;
  }
  &:hover {
    background-color: var(--color-secondary);
    color: var(--color-primary);
  }
}

.modal-header {
  position: relative;
  &-close {
    position: absolute;
    top: 0;
    left: 0;
    padding: 24px;
  }
}
</style>
