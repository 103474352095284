import { getCurrentInstance } from 'vue'

function useRouter() {
  const instance = getCurrentInstance()
  const router = instance?.proxy.$router

  return router
}

export default useRouter
