<template>
  <loadingContent v-if="isAdminDashboardAnalyticsPendingRef" :text="t('admin.dashboard.loading')" />
  <DashboardMatching v-else-if="dashboardTypeRef === 'MATCHING'" />
  <DashboardFeedback v-else-if="dashboardTypeRef === 'FEEDBACK'" />
  <EmptyContent v-else :text="t('admin.dashboard.unavailable')" />
</template>

<script setup lang="ts">
import { computed } from 'vue'

import useAdminDashboardAnalytics from '@/v1.5/features/admin/analytics/hooks/use-dashboard-analytics.hook'
import useProgram from '@/v1.5/features/programs/hooks/use-program.hook'
import { selectGetSelectedProgramId } from '@/v1.5/features/programs/stores/programs/programs.selectors'
import programsStore from '@/v1.5/features/programs/stores/programs/programs.store'
import EmptyContent from '@/v1.5/features/ui/components/empty-content/empty-content.vue'
import loadingContent from '@/v1.5/features/ui/components/loading-content/loading-content.vue'
import DashboardFeedback from '@/v1.5/features/ui/pages/admin/dashboard/dashboard-content/dashboard-types/dashboard-feedback/dashboard-feedback.vue'
import DashboardMatching from '@/v1.5/features/ui/pages/admin/dashboard/dashboard-content/dashboard-types/dashboard-matching/dashboard-matching.vue'
import { useI18n } from '@/v1.5/lib/i18n'

const { t } = useI18n()

const { isPendingRef: isAdminDashboardAnalyticsPendingRef } = useAdminDashboardAnalytics()
const selectedProgramIdRef = selectGetSelectedProgramId(programsStore)
const { programRef } = useProgram(selectedProgramIdRef)

const dashboardTypeRef = computed(() => {
  if (!programRef.value) return null
  return programRef.value.settings.adminDashboardType
})
</script>
