<template>
  <div
    :class="`message-session-widget message-session-widget--${sessionStatusClassNamesMap[sessionRef.status]}`"
    v-if="!isSessionPendingRef && sessionRef && !isAccountPendingRef"
  >
    <div class="message-session-widget__content">
      <div class="message-session-widget__content__header">
        <div class="widget-header-info">
          <div class="widget-header-info__date">
            <span>{{ extractShortDay(sessionRef.from) }}</span>
            <span>{{ extractDayNumber(sessionRef.from) }}</span>
          </div>
          <div class="widget-header-info__name">{{ t('chat.conversation.session.request') }}</div>
        </div>
        <div class="widget-header-status">
          <StatusBadge :status="sessionRef.status" />
        </div>
      </div>
      <div class="message-session-widget__content__body">
        <span>
          {{ t('chat.conversation.session.place') }}:
          {{ sessionRef.location === 'visio' ? t('session.type.online') : sessionRef.location }}
        </span>
        <span>
          <span>
            {{ getDurationRange(sessionRef.from, sessionRef.to) }}
          </span>
          <br />
          <span>
            {{ getDate(sessionRef.from) }}
          </span>
        </span>
      </div>
      <div class="message-session-widget__content__footer" v-if="mentorRef">
        <UserBadge :user="mentorRef" />
        <UsersBadgesStack :users="menteesRef" />
      </div>
    </div>
    <div
      class="message-session-widget__actions c-btns-container"
      v-if="accountRef?.role === RoleEnumType.MENTEE && sessionRef.status === SessionStatusEnumType.WAIT_CONFIRMATION"
    >
      <CustomButton
        :is-loading="isUpdateSessionStatusPendingRef"
        usage="button"
        type="button"
        color="danger"
        :text="t('ui.button.decline')"
        @emit:click="acceptOrDecline(false)"
      />
      <CustomButton
        :is-loading="isUpdateSessionStatusPendingRef"
        usage="button"
        type="button"
        color="primary"
        :text="t('ui.button.accept')"
        @emit:click="acceptOrDecline(true)"
      />
    </div>

    <CustomButton
      v-if="sessionRef.status === SessionStatusEnumType.PLANNED"
      class="c-btns-container c-btn-center"
      :is-loading="isUpdateSessionStatusPendingRef"
      usage="button"
      type="button"
      color="secondary"
      :text="t('chat.conversation.session.addToCalendar')"
      @emit:click="openCalendarModal"
    >
      <template #icon>
        <CalendarIcon color="white" filled />
      </template>
    </CustomButton>
  </div>
</template>

<script setup lang="ts">
import { useMutation } from '@tanstack/vue-query'
import { computed } from 'vue'

import useAccount from '@/v1.5/features/auth/hooks/use-account.hook'
import { RoleEnumType } from '@/v1.5/features/auth/types'
import { selectGetSelectedGroupId } from '@/v1.5/features/groups/stores/groups/groups.selectors'
import groupsStore from '@/v1.5/features/groups/stores/groups/groups.store'
import { getMentees, getMentor } from '@/v1.5/features/groups/utils'
import { V1UpdateSessionStatusMutation } from '@/v1.5/features/sessions/api'
import UserBadge from '@/v1.5/features/sessions/components/user-badge/user-badge.vue'
import UsersBadgesStack from '@/v1.5/features/sessions/components/users-badges-stack/users-badges-stack.vue'
import useSession from '@/v1.5/features/sessions/hooks/use-session.hook'
import { SessionStatusEnumType } from '@/v1.5/features/sessions/types'
import { sessionStatusClassNamesMap } from '@/v1.5/features/sessions/utils'
import CustomButton from '@/v1.5/features/ui/components/button/custom-button.vue'
import CalendarIcon from '@/v1.5/features/ui/components/icons/calendar-icon.vue'
import StatusBadge from '@/v1.5/features/ui/components/status-badge/status-badge.vue'
import type { AddToCalendarModalType } from '@/v1.5/features/ui/store/modal/modal.store'
import modalsStore from '@/v1.5/features/ui/store/modal/modal.store'
import useToast from '@/v1.5/hooks/use-toasts.hook'
import { useI18n } from '@/v1.5/lib/i18n'
import { extractDayNumber, extractShortDay, getDate, getDurationRange } from '@/v1.5/utils/dates'
import { invalidateSession, invalidateSessions } from '@/v1.5/utils/lib/vue-query'

const props = defineProps<{
  sessionId: string
}>()

const { t } = useI18n()

const toast = useToast()
const sessionIdRef = computed(() => props.sessionId)

const selectedGroupIdRef = selectGetSelectedGroupId(groupsStore)

const { accountRef, isPendingRef: isAccountPendingRef } = useAccount()

const { sessionRef, isPendingRef: isSessionPendingRef } = useSession(sessionIdRef)
const mentorRef = computed(() => sessionRef.value?.users.find(getMentor))
const menteesRef = computed(() => sessionRef.value?.users.filter(getMentees) || [])

// update session
const { mutate: updateSessionStatus, isPending: isUpdateSessionStatusPendingRef } = useMutation({
  mutationFn: V1UpdateSessionStatusMutation,
  onError: (error) => {
    console.error(error)
    toast?.error(t('error.common'))
  },
  onSuccess: async (data, variables) => {
    await invalidateSessions({ groupId: selectedGroupIdRef.value! })
    await invalidateSession(sessionIdRef.value)

    if (variables.status === SessionStatusEnumType.CANCELLED) {
      toast?.success(t('chat.conversation.session.declined'))
    } else {
      toast?.success(t('chat.conversation.session.accepted'))
    }
  },
})

// handle accept and decline
function acceptOrDecline(accept: boolean) {
  if (sessionRef.value) {
    updateSessionStatus({
      sessionId: sessionRef.value.id,
      status: accept ? SessionStatusEnumType.PLANNED : SessionStatusEnumType.CANCELLED,
    })
  }
}

function openCalendarModal() {
  modalsStore.toggleModal('add-to-calendar', true)
  modalsStore.updateModalAttachedData<AddToCalendarModalType>('add-to-calendar', {
    session: sessionRef.value!,
  })
}
</script>

<style lang="scss">
@import './message-session-widget.scss';
</style>
