<!-- eslint-disable -->
<!-- @ts-nocheck -->
<template>
  <Page>
    <template v-slot:middle>
      <div class="col-lg-10">
        <router-link
          class="bg-light text-decoration-none d-flex align-items-center p-3 rounded text-dark"
          :to="{
            name: 'IndexProfile',
            params: { id: $store.getters.authUserId },
          }"
        >
          <span class="material-symbols-outlined"> arrow_back </span>
          <span class="ms-2">Retour vers mon profil</span>
        </router-link>
        <div class="row mb-4">
          <div class="col-lg-6 mt-4">
            <div class="bg-light rounded p-4">
              <SettingPhone />
            </div>
            <div
              v-if="!$store.getters.authSaml"
              class="bg-light rounded p-4 mt-4"
            >
              <ChangePassword />
            </div>
          </div>
          <div class="col-lg-6 mt-4">
            <div class="bg-light rounded p-4">
              <Identity />
            </div>
          </div>
        </div>
      </div>
    </template>
  </Page>
</template>

<script>
import Page from '@/components/Page.vue'
import SettingPhone from '@/views/settings/SettingPhone.vue'
import ChangePassword from '@/views/settings/ChangePassword.vue'
import Identity from '@/views/settings/Identity.vue'

export default {
  name: 'settings',
  components: {
    Page,
    SettingPhone,
    ChangePassword,
    Identity,
  },
}
</script>

<style lang="scss" scoped></style>
