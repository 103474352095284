import { create } from 'zustand'
import { combine } from 'zustand/middleware'
import { persist } from 'zustand/middleware'

import { resetGroupsStoreAction, setSelectedGroupIdAction } from '@/v1.5/features/groups/stores/groups/groups.actions'
import subscribeToStore from '@/v1.5/lib/zustand'

export type GroupsStoreValuesType = {
  selectedGroupId: string | null
}

export const INITIAL_MENTORSHIP_GROUPS_STORE_VALUES: GroupsStoreValuesType = {
  selectedGroupId: null,
}

export const MENTORSHIP_GROUPS_STORAGE_KEY = 'groups'

const groupsStore = subscribeToStore(
  create(
    persist(
      combine(INITIAL_MENTORSHIP_GROUPS_STORE_VALUES, (set, get) => ({
        setSelectedGroupId: (groupId: string | null) => setSelectedGroupIdAction(get, set, groupId),
        reset: () => resetGroupsStoreAction(set),
      })),
      {
        name: MENTORSHIP_GROUPS_STORAGE_KEY, // unique name to store the data under in local storage
      },
    ),
  ),
)
export default groupsStore
