<!-- eslint-disable -->
<!-- @ts-nocheck -->
<template>
  <div class="mentors-table">
    <vue-good-table
      styleClass="vgt-table striped"
      compactMode
      :columns="columns"
      :rows="mentees"
      :search-options="{
        enabled: true,
        skipDiacritics: true,
        searchFn: searchFn,
        placeholder: 'Recherche',
      }"
      :pagination-options="paginationOptions"
      :sort-options="{
        enabled: false,
      }"
    >
      <div slot="emptystate">
        {{ isLoading ? 'Chargement...' : 'Aucune donnée disponible' }}
      </div>
      <template slot="table-row" slot-scope="props">
        <div v-if="props.column.field === 'id'">
          <button
            class="btn btn-primary mentors-table-button"
            @click="openChangeMentorPopup(props.row)"
          >
            <span class="material-symbols-outlined"> rule_settings </span>
            <span>Modifier</span>
          </button>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'MenteesTable',
  data() {
    return {
      mentees: [],
      isLoading: true,
      windowSize: { width: window.innerWidth },
      columns: [
        {
          label: 'Prénom, Nom',
          field: 'fullname',
          type: 'string',
          width: '45%',
        },
        {
          label: 'Mentor associé',
          field: 'mentor.fullname',
          type: 'string',
          width: '45%',
        },
        {
          label: 'Action',
          field: 'id',
          type: 'string',
          width: '10%',
        },
      ],
    }
  },
  computed: {
    paginationOptions() {
      if (this.windowSize.width <= 576) {
        // 576 is the value used for the table breakpoint
        return {
          enabled: true,
          mode: 'records',
          perPage: 5,
          perPageDropdownEnabled: false,
          dropdownAllowAll: false,
          jumpFirstOrLast: false,
          nextLabel: '',
          prevLabel: '',
          ofLabel: 'de',
          infoFn: (params) =>
            `Page ${params.currentPage} sur ${params.totalPage}`,
        }
      } else {
        return {
          enabled: true,
          mode: 'records',
          perPage: 10,
          perPageDropdownEnabled: false,
          dropdownAllowAll: false,
          jumpFirstOrLast: false,
          nextLabel: 'suivant',
          prevLabel: 'précédent',
          ofLabel: 'de',
          infoFn: (params) =>
            `Page ${params.currentPage} sur ${params.totalPage}`,
        }
      }
    },
  },

  watch: {
    '$store.getters.mentees': function () {
      this.mentees = this.$store.getters.mentees
    },
  },
  methods: {
    ...mapActions(['getMentees', 'getMentors', 'switchModal', 'setMenteeId']),
    // used to handle the table breakpoints
    handleResize() {
      this.windowSize.width = window.innerWidth
    },
    // used to search in the table
    searchFn(row, col, cellValue, searchTerm) {
      const possibleSearchValues = [row.fullname, row.mentor.fullname]
      return possibleSearchValues
        .map((v) => v.toLowerCase())
        .join(' ')
        .includes(searchTerm.toLowerCase())
    },
    // used to open the mentee change mentor popup
    async openChangeMentorPopup(mentee) {
      try {
        this.setMenteeId(mentee.id)
        await this.getMentors()
        this.switchModal({ name: 'changeMentor', show: true })
      } catch (e) {
        this.$toast.error('Erreur lors du chargement des mentors')
        console.error('Error getting the list of mentors', e)
      }
    },
  },

  async mounted() {
    // getting the list of mentors
    try {
      await this.getMentees()
    } catch (e) {
      this.$toast.error('Erreur lors du chargement des mentorés')
      console.error('Error getting the mentees', e)
    } finally {
      this.isLoading = false
    }
    // handle resize event for the table breakpoints
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
}
</script>

<style lang="scss">
// fix overflow issue when hovering members tooltip
.vgt-responsive {
  overflow-x: initial !important;
}
</style>
