<!-- eslint-disable -->
<!-- @ts-nocheck -->
<template>
  <div class="p-4">
    <!-- FILIERES -->
    <div class="form-group">
      <label class="form-label" for="filiere">Filière*</label>
      <input
        v-show="!branch"
        :disabled="branch ? true : false"
        v-model="textBranch"
        type="text"
        :class="{ 'is-invalid': displayErrorBranch ? true : false }"
        class="form-control"
        id="filiere"
        :placeholder="branchPlaceholder"
      />
      <div class="invalid-feedback">Vous devez renseigner votre filière</div>
    </div>
    <div
      v-show="textBranch && !branch"
      class="search mt-2 px-4 pt-4 pb-3 rounded"
    >
      <ais-instant-search
        :search-client="searchClient"
        index-name="branches_v2"
      >
        <ais-configure :hits-per-page.camel="50" :filters="filter" />
        <div v-show="false">
          <ais-search-box v-model="textBranch" />
        </div>
        <ais-hits>
          <div
            @click="selectBranch(item.id)"
            slot="item"
            slot-scope="{ item }"
            class="search-tile rounded mb-3 d-flex align-items-center bg-light"
          >
            <div class="mb-0 d-flex align-items-center">
              <p class="mb-0 ms-3">{{ item.branch }}</p>
            </div>
          </div>
        </ais-hits>
        <div
          @click="branchNotFind"
          class="search-tile rounded mb-3 d-flex align-items-center bg-light"
        >
          <div
            class="d-flex hide-image rounded justify-content-center align-items-center me-3"
          >
            <span class="material-symbols-outlined"> add </span>
          </div>
          <p class="mb-0">Ajouter cette filière</p>
        </div>
      </ais-instant-search>
    </div>

    <div
      v-if="branch"
      class="rounded p-3 selected-school d-flex align-items-center mt-3"
    >
      <p class="mb-0">
        {{ branch.branch }}
      </p>
      <div
        class="c-pointer remove-picture rounded-circle"
        @click="branch = null"
      >
        <span class="material-symbols-outlined"> delete </span>
      </div>
    </div>

    <div class="form-group mt-4">
      <label class="form-label" for="school">Nom de l'établissement</label>
      <input
        v-show="!school"
        placeholder="Ex: Ginette / Skema / Lycée du Parc"
        type="text"
        v-model="text"
        class="form-control"
        id="school"
        :disabled="school ? true : false"
      />
    </div>
    <div
      ref="search"
      v-show="text && !school"
      class="search mt-2 px-4 pt-4 pb-3 rounded"
    >
      <ais-instant-search :search-client="searchClient" index-name="schools_v2">
        <ais-configure :hits-per-page.camel="10" />
        <div v-show="false">
          <ais-search-box v-model="text" />
        </div>
        <ais-hits>
          <div
            @click="selectSchool(item.id)"
            slot="item"
            slot-scope="{ item }"
            class="search-tile rounded mb-3 d-flex align-items-center bg-light"
          >
            <img
              v-if="item.logo"
              class="rounded me-3"
              :src="
                'https://constel-groups-cover.s3.fr-par.scw.cloud/' + item.logo
              "
              alt=""
              style="width: 48px"
            />
            <div
              v-else
              class="text-primary d-flex img-logo-default rounded justify-content-center align-items-center me-3"
            >
              <span class="material-symbols-outlined"> school </span>
            </div>
            <div class="mb-0 d-flex align-items-center">
              <div v-if="item.country">
                <country-flag :country="item.country" size="normal" />
              </div>
              <p class="mb-0 ms-3">
                {{ item.name }} <span v-if="item.city">-</span> {{ item.city }}
              </p>
            </div>
          </div>
        </ais-hits>
        <div
          @click="schoolNotFind"
          class="search-tile rounded mb-3 d-flex align-items-center bg-light"
        >
          <div
            class="d-flex hide-image rounded justify-content-center align-items-center me-3"
          >
            <span class="material-symbols-outlined"> add </span>
          </div>
          <p class="mb-0">Ajouter cette Ecole</p>
        </div>
      </ais-instant-search>
    </div>

    <div
      v-if="school"
      class="rounded p-3 selected-school d-flex align-items-center mt-3"
    >
      <div
        class="bg-light d-flex hide-image rounded justify-content-center align-items-center me-3"
      >
        <span class="material-symbols-outlined"> school </span>
      </div>
      <p class="mb-0">
        {{ school.name }}
        <span v-if="school.city">{{ `- ${school.city}` }}</span>
      </p>
      <div
        class="c-pointer remove-picture rounded-circle"
        @click="school = null"
      >
        <span class="material-symbols-outlined"> delete </span>
      </div>
    </div>

    <div class="form-group mt-4">
      <label class="form-label" for="specialisation"
        >Spécialisation et parcours</label
      >
      <input
        v-model="specialization"
        type="text"
        id="specialisation"
        class="form-control"
        :placeholder="specializationPlaceholder"
      />
    </div>

    <div class="row mt-4">
      <div class="col-6">
        <div class="form-group">
          <label class="form-label" for="start-at">Année de début</label>
          <input
            placeholder="Année"
            type="number"
            v-model="yearStart"
            class="form-control"
            id="start-at"
          />
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label class="form-label" for="end-at"
            >Année de fin (ou prévue)</label
          >
          <input
            placeholder="Année"
            type="number"
            v-model="yearEnd"
            class="form-control"
            id="end-at"
          />
        </div>
      </div>
    </div>
    <div
      class="mt-4 d-flex justify-content-between align-items-center flex-wrap"
    >
      <p class="mb-0">* champs obligatoires</p>
      <div class="ms-auto">
        <button
          v-if="!register"
          @click="switchModal({ name: 'addSchool', show: false })"
          class="btn btn-light light-blue"
        >
          Annuler
        </button>
        <button @click="submitForm" class="btn btn-primary ms-2">
          {{ register ? 'Suivant' : 'Ajouter' }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import algoliasearch from 'algoliasearch/lite'
import CountryFlag from 'vue-country-flag'
import {
  getBranchList,
  apiCreateBranchList,
} from '@/services/BranchListService.js'
import {
  apiReadSchool,
  apiCreateSchool,
  apiCreateCareer,
} from '@/services/userService.js'

export default {
  name: 'AddSchool',
  data() {
    return {
      searchClient: algoliasearch(
        '7LVHY3ES88',
        '5bcc32d4b5752dc7b5a095e220b2b3b6'
      ),
      text: null,
      mouseScrollInterval: null,
      school: null,
      branches: null,
      textBranch: null,
      branch: null,
      yearStart: null,
      yearEnd: null,
      specialization: null,
      displayErrorBranch: false,
      branchPlaceholder: '',
      filter: '',
      specializationPlaceholder: '',
    }
  },
  props: {
    register: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CountryFlag,
  },
  async mounted() {
    this.branches = await getBranchList()
    await this.preselectSchool()
    this.defineFilterForSchool()
    this.defineBranchPlaceholder()
    this.defineSpecializationPlaceholder()
  },
  methods: {
    ...mapActions(['switchModal', 'fetchCareer']),
    isParisPantheonAssas() {
      return this.$store.getters.appCompanyName == 'Paris-Panthéon-Assas'
    },
    isRouteRegisterBranch() {
      return this.$route.name == 'RegisterBranch'
    },
    async preselectSchool() {
      if (this.isParisPantheonAssas() && this.isRouteRegisterBranch()) {
        await this.selectSchool(34)
      }
    },
    defineFilterForSchool() {
      if (this.isParisPantheonAssas() && this.isRouteRegisterBranch()) {
        this.filter = `id = 15 OR id = 17 OR id = 165 OR id = 135 OR id = 136`
      }
    },
    defineBranchPlaceholder() {
      if (this.isParisPantheonAssas() && this.isRouteRegisterBranch()) {
        this.branchPlaceholder =
          "Ex : Université de droit, Université d'économie..."
      } else {
        this.branchPlaceholder =
          'Ex: Fac de droit / Prépa ECG / École de commerce etc..'
      }
    },
    defineSpecializationPlaceholder() {
      if (this.isParisPantheonAssas() && this.isRouteRegisterBranch()) {
        this.specializationPlaceholder =
          "Ex : Droit et Métiers de l'entreprise, droit public comparé..."
      }
    },
    async selectSchool(schoolId) {
      this.school = await apiReadSchool(this.$store.getters.authToken, schoolId)
      this.text = `${this.school.name} ${
        this.school.city ? ' - ' + this.school.city : ''
      }`
    },
    selectBranch(branchId) {
      this.displayErrorBranch = false
      this.branches.forEach((branch) => {
        if (branch.id === branchId) {
          this.branch = branch
          this.textBranch = branch.branch
        }
      })
    },
    schoolNotFind() {
      this.school = { name: this.text }
      this.text = this.school.name
    },
    branchNotFind() {
      this.branch = { branch: this.textBranch }
      this.textBranch = this.branch.branch
    },
    async submitForm() {
      if (!this.branch) {
        this.displayErrorBranch = true
        return
      }

      let newSchool = null
      let newBranch = null
      // add school with axios if user not find his school
      if (this.school && !this.school.id && this.school.name)
        newSchool = await apiCreateSchool(
          { name: this.school.name },
          this.$store.getters.authToken
        )

      if (this.branch && !this.branch.id && this.branch.branch)
        newBranch = await apiCreateBranchList(
          { branch: this.branch.branch },
          this.$store.getters.authToken
        )

      const data = {}

      if (newSchool) data.schoolId = newSchool.school.id
      else if (!newSchool && this.school && this.school.id)
        data.schoolId = this.school.id
      else data.schoolId = null

      await apiCreateCareer(
        {
          ...data,
          branchId: newBranch ? newBranch.branch.id : this.branch.id,
          yearStart: this.yearStart,
          yearEnd: this.yearEnd,
          specialization: this.specialization,
        },
        this.$store.getters.authToken
      )
      if (this.register) {
        this.$emit('next')
        return
      }
      this.school = null
      this.branch = null
      this.text = null
      this.textBranch = null
      this.yearStart = null
      this.yearEnd = null
      this.fetchCareer({ userId: this.$route.params.id })
      this.switchModal({ name: 'addSchool', show: false })
    },
  },
}
</script>

<style lang="scss" scoped>
.img-logo-default {
  width: 48px;
  height: 48px;
  color: var(--color-primary);
  background-color: var(--color-ligth-gray);
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-light {
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
}

.hide-image {
  background-color: #f1f2f5;
  min-width: 48px;
  min-height: 48px;
}

.search {
  background-color: #f1f2f5;
  max-height: 270px;
  overflow-y: scroll;
  scrollbar-color: #6969dd #e0e0e0;
  &-tile {
    padding: calc(1rem - 2px);
    border: 2px solid #fff;
    &:hover {
      cursor: pointer;
      border: 2px solid var(--color-primary);
    }
  }
}

.selected-school {
  position: relative;
  background-color: #f1f2f5;
}

.remove-picture {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -15px;
  background-color: var(--color-secondary);
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: var(--color-primary);
    color: #fff;
  }
}

.search::-webkit-scrollbar {
  width: 10px;
}

.search::-webkit-scrollbar-track {
  background-color: #abb5be;
  border-radius: 0.25rem;
}

.search::-webkit-scrollbar-thumb {
  background-color: var(--color-primary);
  border-radius: 0.25rem;
}
</style>
