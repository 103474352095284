<!-- eslint-disable -->
<!-- @ts-nocheck -->
<template>
  <div v-if="session">
    <!-- v-if="session && session.status === null" -->
    <div
      class="rounded p-4"
      :class="{
        'constel-light': constelLight,
        'constel-secondary': !constelLight,
      }"
    >
      <template v-if="session.status === 'PLANNED'">
        <div class="badge bg-success mb-4 d-flex align-items-center">
          <span class="material-symbols-outlined"> done </span>
          <template v-if="$store.getters.userRole === 'ROLE_STUDENT'">
            <span class="ms-2">Vous avez confirmé votre présence</span>
          </template>
          <template v-if="$store.getters.userRole === 'ROLE_MENTOR'">
            <span class="ms-2">Vous avez planifié la session</span>
          </template>
        </div>
      </template>

      <template v-if="session.status === 'CANCELLED'">
        <div class="badge bg-danger mb-4 d-flex align-items-center">
          <span class="material-symbols-outlined"> cancel </span>
          <template v-if="$store.getters.userRole === 'ROLE_MENTOR'">
            <span class="ms-2">Session annulée</span>
          </template>
          <template v-if="$store.getters.userRole === 'ROLE_STUDENT'">
            <span class="ms-2">Vous avez décliné votre participation</span>
          </template>
        </div>
      </template>

      <p class="d-flex align-items-center">
        <template v-if="session.status === 'WAIT_CONFIRMATION'">
          <span class="material-symbols-outlined"> event </span>
          <span class="ms-2">Demande de réservation</span>
        </template>
        <template v-if="session.status === 'PLANNED'">
          <span class="material-symbols-outlined"> event </span>
          <span class="ms-2">Planifiée</span>
        </template>
        <template v-if="session.status === 'CANCELLED'">
          <span class="material-symbols-outlined"> event_busy </span>
          <span class="ms-2">Annulée</span>
        </template>
      </p>
      <div class="p-4 bg-light rounded mb-4">
        <p class="mb-0">Date : {{ formatDate(session.from) }}</p>
        <p class="mb-0">Durée : {{ session.duration }}min</p>
        <p class="mb-0">
          Lieu :
          {{
            session.location == 'visio' ? 'Visioconférence' : session.location
          }}
        </p>
      </div>

      <div class="p-4 bg-light rounded mb-4">
        <SessionStatusSamll :sessionId="session.id" />
      </div>
      <!-- <div class="d-flex justify-content-center">
        <p class="text-primary c-pointer mb-0 text-decoration-underline" v-if="session && session.status">Ajouter au calendrier</p>
      </div> -->
      <div
        class="d-flex justify-content-between"
        v-if="
          session.status === 'WAIT_CONFIRMATION' &&
          $store.getters.userRole === 'ROLE_STUDENT'
        "
      >
        <button @click="changeSessionStatus('CANCELLED')" class="btn btn-light">
          Décliner
        </button>
        <button @click="changeSessionStatus('PLANNED')" class="btn btn-primary">
          Accepter
        </button>
      </div>
      <div v-if="session.status === 'PLANNED'" class="d-grid">
        <button @click="changeSessionStatus('CANCELLED')" class="btn btn-light">
          Annuler
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone'
import { mapState } from 'vuex'

import SessionStatusSamll from '@/components/SessionStatusSmall.vue'

export default {
  name: 'DiscussionWidgetSession',
  props: {
    sessionId: {
      type: String,
    },
    constelLight: {
      type: Boolean,
    },
  },
  components: {
    SessionStatusSamll,
  },
  computed: {
    ...mapState(['userData']),
    session() {
      return this.$store.getters.sessions.find((session) => session.id === this.sessionId)
    },
  },
  methods: {
    formatDate(date) {
      return moment(date).tz(this.$store.getters.userTimezone).format('D MMMM YYYY à HH:mm')
    },
    changeSessionStatus(status) {
      this.$store.dispatch('updateSessionStatus', {
        sessionId: this.sessionId,
        status,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.constel-light {
  color: #212529 !important;
  background-color: rgba(var(--color-black), 0.1);
}

.constel-secondary {
  color: #212529 !important;
  background-color: rgba(var(--color-black), 0.1);
}
</style>
