<template>
  <div class="matching-suggestions-slider" ref="sliderRef">
    <div
      class="slider-arrow slider-arrow__left"
      ref="sliderLeftArrowRef"
      :style="`opacity: ${mentorsProfilesRef.length > 1 ? 1 : 0}`"
    >
      <ArrowIcon color="primary" :rotation="180" />
    </div>
    <div v-if="mentorsProfilesRef.length" class="matching-suggestions-slider__slides-wrapper">
      <div v-for="(mentorDataRef, key) in mentorsProfilesRef" :key="mentorDataRef.id" class="mentor-card-wrapper">
        <div class="slide-number">{{ key + 1 }} / {{ mentorsProfilesRef.length }}</div>
        <div class="mentor-card">
          <div class="mentor-card-info" v-if="mentorDataRef">
            <Avatar :user="mentorDataRef" />
            <div class="mentor-card-info__info">
              <span class="user-name">{{ mentorDataRef.firstName }} {{ mentorDataRef.lastName }}</span>
              <p class="user-sub-info">{{ t('common.mentor.capitalize') }}</p>
            </div>
          </div>
          <p class="mentor-criteria">
            <strong>{{ t('matching.matchingCriteria') }}</strong
            ><br /><br />
            <span
              v-html="mentorDataRef.matchingCriteria || `Une erreur est survenue lors du chargement de la description`"
            >
            </span>
          </p>
          <div class="c-btns-container c-btn-center">
            <CustomButton
              usage="button"
              type="button"
              color="primary"
              text="Choisir ce mentor"
              @emit:click="confirmMatch(mentorDataRef.id)"
            />
          </div>
        </div>
      </div>
    </div>
    <LoadingContent v-else />
    <div
      class="slider-arrow slider-arrow__right"
      ref="sliderRightArrowRef"
      :style="`opacity: ${mentorsProfilesRef.length > 1 ? 1 : 0}`"
    >
      <ArrowIcon color="primary" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'

import useAccount from '@/v1.5/features/auth/hooks/use-account.hook'
import type { MenteeMatchingResponseType } from '@/v1.5/features/matching/types'
import { confirmMatchFn } from '@/v1.5/features/matching/utils'
import { selectGetSelectedProgramId } from '@/v1.5/features/programs/stores/programs/programs.selectors'
import programsStore from '@/v1.5/features/programs/stores/programs/programs.store'
import CustomButton from '@/v1.5/features/ui/components/button/custom-button.vue'
import ArrowIcon from '@/v1.5/features/ui/components/icons/arrow-icon.vue'
import LoadingContent from '@/v1.5/features/ui/components/loading-content/loading-content.vue'
import { selectGetNewMatchingModal } from '@/v1.5/features/ui/store/modal/modal.selectors'
import modalsStore from '@/v1.5/features/ui/store/modal/modal.store'
import { V1GetUser } from '@/v1.5/features/users/api'
import Avatar from '@/v1.5/features/users/components/avatar/avatar.vue'
import { V1ToV2UserDataMiddleware } from '@/v1.5/features/users/hooks/use-user.hook'
import type { V2UserDetailsDataType } from '@/v1.5/features/users/types'
import useRouter from '@/v1.5/hooks/use-router.hook'
import useToast from '@/v1.5/hooks/use-toasts.hook'
import useWindowSize from '@/v1.5/hooks/use-window-size.hook'
import { useI18n } from '@/v1.5/lib/i18n'

const props = defineProps<{
  mentors: MenteeMatchingResponseType['mentors']
}>()

const { widthRef } = useWindowSize()

const mentorsIdsRef = computed(() => props.mentors.map((mentor) => mentor.mentorId))
const mentorsProfilesRef = ref<Partial<V2UserDetailsDataType>[]>([])

const sliderLeftArrowRef = ref<HTMLElement | null>(null)
const sliderRightArrowRef = ref<HTMLElement | null>(null)
const sliderRef = ref<HTMLElement | null>(null)

// we load all mentors profiles
watch(
  mentorsIdsRef,
  async (newMentorsIds) => {
    if (newMentorsIds.length) {
      mentorsProfilesRef.value = await Promise.all(
        newMentorsIds.map(async (mentorId) => {
          const mentorProfile = await V1GetUser(mentorId)
          return V1ToV2UserDataMiddleware(mentorProfile)!
        }),
      )
    }
  },
  { immediate: true },
)

// slider logic
watch(
  [mentorsProfilesRef, widthRef],
  () => {
    let currentSlide = 0
    const leftArrow = sliderLeftArrowRef.value!
    const rightArrow = sliderRightArrowRef.value!

    const slider = sliderRef.value
    if (!slider) return

    const slides = slider.querySelectorAll<HTMLDivElement>('.mentor-card-wrapper')!
    const slideWidth = slides[0].clientWidth

    Array.from(slides).forEach((slide) => {
      slide.style.transform = ``
    })

    if (mentorsProfilesRef.value.length) {
      const updateSlides = () => {
        Array.from(slides).forEach((slide) => {
          slide.style.transform = `translateX(${-currentSlide * slideWidth}px)`
        })
      }

      const nextSlide = () => {
        if (currentSlide >= slides.length - 1) {
          currentSlide = 0
        } else {
          currentSlide++
        }
        updateSlides()
      }

      const prevSlide = () => {
        if (currentSlide <= 0) {
          currentSlide = slides.length - 1
        } else {
          currentSlide--
        }
        updateSlides()
      }

      leftArrow.addEventListener('click', prevSlide)
      rightArrow.addEventListener('click', nextSlide)
    }
  },
  { immediate: true, flush: 'post' },
)

const selectedProgramIdRef = selectGetSelectedProgramId(programsStore)

const newMatchingModalRef = selectGetNewMatchingModal(modalsStore)
const matchedGroupIdRef = computed(() => newMatchingModalRef.value.attachedData.groupId)

const toast = useToast()
const { accountRef } = useAccount()
const router = useRouter()
const { t } = useI18n()

const isSubmittingRef = ref(false)

async function confirmMatch(selectedMentorId: string | undefined) {
  if (!selectedMentorId) {
    toast?.error(t('error.common'))
    return
  }
  await confirmMatchFn({
    accountRef,
    isSubmittingRef,
    matchedGroupIdRef,
    selectedMentorId,
    selectedProgramIdRef,
    t,
    router,
    toast,
  })
}
</script>

<style lang="scss">
@import './matching-suggestions-slider.scss';
</style>
