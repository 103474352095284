// export async function apiImportUsers(authToken, programId, periodId, users) {
//   const url = `${
//     import.meta.env.VITE_API_URL
//   }/v1/auth/register/batch/?programId=${programId}&periodId=${periodId}`
//   const res = await axios.post(
//     url,
//     { users },
//     {
//       headers: {
//         authorization: `Bearer ${authToken}`,
//       },
//     }
//   )
// }

import type {
  GetUserNotesDTOType,
  GetUserNotesResponseType,
  GetUsersDTOType,
  GetUsersResponseType,
  ImportUsersDTOType,
  UpdateUserNotesDTOType,
} from '@/v1.5/features/admin/users/types'
import fetchClient from '@/v1.5/lib/fetch'

export async function importAdminUsersMutation({ programId, periodId, users }: ImportUsersDTOType): Promise<any> {
  return fetchClient(`/v1/auth/register/batch?programId=${programId}&periodId=${periodId}`, {
    method: 'POST',
    body: JSON.stringify({ users }),
  })
}

export const USER_NOTE_QUERY_KEY = 'USER_NOTE_QUERY_KEY'
export function GetUserNotes(data: GetUserNotesDTOType): Promise<GetUserNotesResponseType> {
  return fetchClient(`/v1/user/notes/${data.userId}`, {
    method: 'GET',
  })
}

export function UpdateUserNotesMutation(data: UpdateUserNotesDTOType): Promise<any> {
  return fetchClient(`/v1/user/notes/`, {
    method: 'POST',
    body: JSON.stringify(data),
  })
}

export const USERS_QUERY_KEY = 'users'
export async function getUsers({
  programId,
  page,
  perPage,
  role,
  hasGroup,
  search,
}: GetUsersDTOType): Promise<GetUsersResponseType> {
  return fetchClient(
    `/v1/user/all?programId=${programId}&page=${page}&perPage=${perPage}&role=${role}&search=${search}&hasGroup=${hasGroup}`,
    {
      method: 'GET',
    },
  )
}
