<!-- eslint-disable -->
<!-- @ts-nocheck -->
<template>
   <Page>
    <template v-slot:middle>
      <Dashboard/>
    </template>
    <template v-slot:outside>
      <ProgramCriteriasModal/>
    </template>
  </Page>
</template>

<script>
import Page from '@/components/Page.vue'
import Dashboard from '@/v1.5/(routes)/(protected)/(app)/dashboard/page.vue'
import ProgramCriteriasModal from '@/v1.5/features/ui/pages/dashboard/dashboard-content/mentor/program-criterias-modal/program-criterias-modal.vue'

export default {
  name: 'IndexMentoringGroup',
  components: {
    Page,
    ProgramCriteriasModal,
    Dashboard,
  },
}
</script>

<style lang="scss" scoped></style>
