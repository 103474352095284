import { useQuery } from '@tanstack/vue-query'
import { computed } from 'vue'

import useAccount from '@/v1.5/features/auth/hooks/use-account.hook'
import useIsAuth from '@/v1.5/features/auth/hooks/use-is-auth.hook'
import useConversations from '@/v1.5/features/chat/hooks/use-conversations.hook'
import useGroupConversationsWithoutFirstMessage from '@/v1.5/features/chat/hooks/use-group-conversations-without-first-message.hook'
import { getGroupsNotifications, V1_MENTORSHIP_GROUPS_NOTIFICATIONS_QUERY_KEY } from '@/v1.5/features/groups/api'
import { selectGetSelectedGroupId } from '@/v1.5/features/groups/stores/groups/groups.selectors'
import groupsStore from '@/v1.5/features/groups/stores/groups/groups.store'
import useSessions from '@/v1.5/features/sessions/hooks/use-sessions.hook'
import { getUncompletedSessions } from '@/v1.5/features/sessions/utils'

function useNotifications() {
  const { accountRef } = useAccount()
  const { sessionsRef } = useSessions()
  const { conversationsRef } = useConversations()
  const selectedGroupIdRef = selectGetSelectedGroupId(groupsStore)

  const { isAuthRef } = useIsAuth()

  const { data } = useQuery({
    queryKey: [V1_MENTORSHIP_GROUPS_NOTIFICATIONS_QUERY_KEY],
    queryFn: () => getGroupsNotifications(),
    enabled: !!isAuthRef,
  })

  // group conversations without first message sent
  const { groupConversationsWithoutFirstMessageSentRef } = useGroupConversationsWithoutFirstMessage()

  // group conversations without seen messages
  const groupConversationsNotSeenRef = computed(() =>
    conversationsRef.value.filter((conversation) => {
      return !conversation.users.find((user) => user.id === accountRef.value?.id)?.hasSeen
    }),
  )

  const toCompleteSessionsRef = computed(() => sessionsRef.value.filter(getUncompletedSessions))

  // all messages notifications
  const messagesNotificationsRef = computed(
    () => groupConversationsNotSeenRef.value.length + groupConversationsWithoutFirstMessageSentRef.value.length,
  )

  // all sessions notifications
  const sessionsNotificationsRef = computed(() => toCompleteSessionsRef.value.length)

  // all pending actions
  const pendingActionsRef = computed(
    () => groupConversationsWithoutFirstMessageSentRef.value.length + toCompleteSessionsRef.value.length,
  )

  // selected group notifications
  const groupNotificationsRef = computed(
    () => data.value?.find((group) => group.mentorshipGroupId === selectedGroupIdRef.value)?.notifications,
  )

  return {
    allNotificationsPerGroupRef: data,
    groupNotificationsRef,
    groupConversationsWithoutFirstMessageSentRef,
    groupConversationsNotSeenRef,
    messagesNotificationsRef,
    sessionsNotificationsRef,
    pendingActionsRef,
  }
}

export default useNotifications
