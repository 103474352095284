import { nextTick, onMounted, watchEffect } from 'vue'

import v1Store from '@/store'
import useGroups from '@/v1.5/features/groups/hooks/use-groups.hook'
import { selectGetSelectedGroupId } from '@/v1.5/features/groups/stores/groups/groups.selectors'
import groupsStore from '@/v1.5/features/groups/stores/groups/groups.store'
import useProgram from '@/v1.5/features/programs/hooks/use-program.hook'
import usePrograms from '@/v1.5/features/programs/hooks/use-programs.hook'
import {
  selectGetSelectedPeriodId,
  selectGetSelectedProgramId,
} from '@/v1.5/features/programs/stores/programs/programs.selectors'
import programsStore from '@/v1.5/features/programs/stores/programs/programs.store'

function useApp() {
  const { programsRef } = usePrograms()
  const selectedProgramIdRef = selectGetSelectedProgramId(programsStore)
  const { programRef: selectedProgramRef } = useProgram(selectedProgramIdRef)
  const { groupsRef } = useGroups()

  const selectedPeriodIdRef = selectGetSelectedPeriodId(programsStore)

  const selectedGroupIdRef = selectGetSelectedGroupId(groupsStore)

  // on mount, if there is no selectedGroup, set the first period in the list as selected
  watchEffect(() => {
    if (!selectedGroupIdRef.value && groupsRef.value.length > 0) {
      groupsStore.setSelectedGroupId(groupsRef.value[0].id)
    }
  })

  // if there is no selectedPeriod, set the first period in the list as selected
  watchEffect(() => {
    if (
      !selectedPeriodIdRef.value &&
      selectedProgramRef.value?.periods &&
      selectedProgramRef.value?.periods.length > 0 &&
      selectedProgramRef.value?.latestPeriod
    ) {
      programsStore.setSelectedPeriodId(selectedProgramRef.value.latestPeriod.id)
    }
  })

  // on mount, if there is no selectedProgram, set the first program in the list as selected
  watchEffect(() => {
    if (!selectedProgramIdRef.value && programsRef.value.length > 0) {
      programsStore.setSelectedProgramId(programsRef.value[0].id)
    }
  })

  // todo remove the on mount logic when v1.5 is fully migrated
  // used for the admin views and view pages still not migrated to v1.5
  onMounted(async () => {
    await v1Store.dispatch('storeProgramGetListProgram')
    await nextTick()

    const doesProgramExist = programsRef.value.some((program) => program.id === selectedProgramIdRef.value)
    const doesPeriodExist = selectedProgramRef.value?.periods?.some((period) => period.id === selectedPeriodIdRef.value)
    const doesGroupExist = groupsRef.value.some((group) => group.id === selectedGroupIdRef.value)

    // if the selected program or period does not exist, reset the store (it will trigger the watchEffect to set the first program/period in the list as selected)
    if (
      (programsRef.value.length > 0 && !doesProgramExist) ||
      (selectedProgramRef.value?.periods && !doesPeriodExist)
    ) {
      programsStore.reset()
    }

    // if the selected group does not exist, reset the store (it will trigger the watchEffect to set the first group in the list as selected)
    if (groupsRef.value.length > 0 && !doesGroupExist) {
      groupsStore.reset()
    }

    // otherwise, update the current program and period with the selected ids
    if (selectedProgramIdRef.value) {
      await v1Store.dispatch('storeProgramUpdateCurrentProgramWithId', selectedProgramIdRef.value)
    }
    if (selectedPeriodIdRef.value) {
      await v1Store.dispatch('storeProgramUpdateCurrentPeriodWithId', selectedPeriodIdRef.value)
    }
  })
}

export default useApp
