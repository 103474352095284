<!-- eslint-disable -->
<!-- @ts-nocheck -->
<template>
  <form @submit.prevent="sendForm" :class="{ 'p-4': !register }">
    <div class="form-group">
      <p :class="{ 'fs-4': register }" class="mt-3">
        Quelles sont vos matières fortes ?
      </p>

      <!-- Recherche de sujet avec Algolia -->
      <ais-instant-search
        :search-client="searchClient"
        index-name="subjects_v2"
      >
        <ais-search-box
          placeholder="Ex: Maths, Histoire, Microéconomie"
          v-model="text"
          :class-names="{
            'ais-SearchBox-input': 'form-control',
            'ais-SearchBox-reset': 'd-none',
            'ais-SearchBox-submit': 'd-none',
          }"
        />
        <div v-show="text" class="search mt-2 px-4 pt-4 pb-3 rounded">
          <ais-configure :hits-per-page.camel="50" />
          <ais-hits>
            <div
              @click="addInSubjects(item)"
              slot="item"
              slot-scope="{ item }"
              class="search-tile rounded mb-3 d-flex align-items-center bg-light"
            >
              <div class="mb-0 d-flex align-items-center">
                <p class="mb-0 ms-3">{{ item.name }}</p>
              </div>
            </div>
          </ais-hits>
          <div
            @click="subjectNotFind(text)"
            class="search-tile rounded mb-3 d-flex align-items-center bg-light"
          >
            <div
              class="d-flex hide-image rounded justify-content-center align-items-center me-3"
            >
              <span class="material-symbols-outlined"> add </span>
            </div>
            <p class="mb-0">Ajouter cette matière</p>
          </div>
        </div>
      </ais-instant-search>

      <!-- Affiche les checkbox des sujets -->
      <div class="mt-4">
        <div
          v-for="(subject, index) in subjects"
          :key="`${subject.Subject.name}${index}`"
          class="col-lg-6 d-flex align-items-center"
        >
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              :id="`check${index}`"
              v-model="subject.value"
              @input="SubjectCheck(subject)"
            />
            <label class="pl-2 form-check-label" :for="`check${index}`">
              {{ subject.Subject.name }}
            </label>
          </div>
        </div>
      </div>
    </div>

    <div v-if="subjects" class="form-group mb-3">
      <div class="row mt-3">
        <p class="mb-0">Quelles notes avez-vous obtenues ?</p>
        <div
          v-for="(subject, index) in subjects"
          :key="index"
          :class="{
            'col-lg-6 mt-2': subject.value == true ? true : false,
            'd-none': subject.value == true ? false : true,
          }"
        >
          <div v-if="subject.value == true">
            <label for="" class="form-text mb-2">{{
              subject.Subject.name
            }}</label>
            <input
              type="text"
              class="form-control"
              :placeholder="subject.Subject.name"
              v-model="subject.grade"
            />
          </div>
        </div>
      </div>
    </div>

    <label :class="{ 'fs-4': register }" class="form-label"
      >Où souhaitez-vous donner vos sessions de mentorat ?</label
    >
    <div class="form-check mb-2">
      <input
        v-model="isOnline"
        class="form-check-input"
        type="checkbox"
        id="isOnline"
      />
      <label class="form-check-label" for="isOnline">Visioconférence </label>
    </div>
    <div class="form-check mb-3">
      <input
        v-model="isPhysical"
        class="form-check-input"
        type="checkbox"
        id="isPhysical"
      />
      <label class="form-check-label" for="isPhysical">Présentiel</label>
    </div>
    <div v-if="isPhysical" class="mb-3">
      <input
        v-model="location"
        type="text"
        class="form-control"
        placeholder="Un lieu, une salle de classe, une ville..."
      />
    </div>
    <div class="d-flex justify-content-end">
      <button
        v-if="!register"
        type="button"
        class="btn btn-outline-primary"
        @click="switchModal({ name: 'MentorProfilSubject', show: false })"
      >
        Annuler
      </button>
      <button type="submit" class="btn btn-primary ms-2">
        {{ register ? 'Suivant' : 'Mettre à jour' }}
      </button>
    </div>
  </form>
</template>

<script>
import {
  post as postMentor,
  addSubject,
  getMentorSubjects,
  deleteMentorSubject,
  updateMentorSubject,
} from '@/services/MentorService.js'
import { mapActions, mapState } from 'vuex'
import algoliasearch from 'algoliasearch/lite'

export default {
  name: 'FormSubject',
  data() {
    return {
      searchClient: algoliasearch(
        '7LVHY3ES88',
        '5bcc32d4b5752dc7b5a095e220b2b3b6'
      ),
      branchList: null,
      isPhysical: null,
      isOnline: null,
      subjects: null,
      lessons: null,
      branch: null,
      location: null,
      text: '',
    }
  },
  computed: {
    ...mapState(['mentorData']),
  },
  watch: {
    '$route.params.id'() {
      this.asignData()
    },
  },
  props: {
    register: {
      type: Boolean,
      default: false,
    },
  },
  async mounted() {
    this.asignData()
  },
  methods: {
    ...mapActions(['switchModal', 'fetchProfile']),
    SubjectCheck(subject) {
      this.subjects.forEach((elem, index) => {
        if (elem.Subject.name == subject.Subject.name) {
          if (subject.id) {
            deleteMentorSubject(subject.id, this.$store.getters.authToken)
          }
          this.subjects.splice(index, 1)
        }
      })
    },
    addInSubjects(subject) {
      addSubject({ subjectId: subject.id }, this.$store.getters.authToken).then(
        () => {
          this.asignData()
          this.text = null
        }
      )
    },
    async subjectNotFind(subject) {
      addSubject({ name: subject }, this.$store.getters.authToken).then(() => {
        this.asignData()
        this.text = null
      })
    },
    async asignData() {
      this.subjects = await getMentorSubjects(
        this.$route.params.id || localStorage.getItem('userId'),
        this.$store.getters.authToken
      )
      this.subjects.forEach((subject) => {
        subject.value = true
      })
      if (this.mentorData.MentorLesson) {
        if (this.mentorData.MentorLesson.location) {
          this.location = this.mentorData.MentorLesson.location
        }
        if (this.mentorData.MentorLesson.type) {
          this.lessons = this.mentorData.MentorLesson.type
          this.lessons.forEach((lesson) => {
            if (lesson == 'Visio-conference') {
              this.isOnline = true
            }
            if (lesson == 'Physique') {
              this.isPhysical = true
            }
          })
        }
      }
    },
    async sendForm() {
      // Met à jour le mode de mentorat (physique ou en ligne) et la ville
      const lesson = []
      if (this.isPhysical) {
        lesson.push('Physique')
      }
      if (this.isOnline) {
        lesson.push('Visio-conference')
      }
      await postMentor(
        {
          lesson: {
            type: lesson,
            location: this.location && this.isPhysical ? this.location : null,
          },
        },
        this.$store.getters.authToken
      )
      // Met à jour les grade des matière
      await this.subjects.forEach(async (subject) => {
        await updateMentorSubject(
          subject.id,
          subject.grade,
          this.$store.getters.authToken
        )
      })
      // affiche le toaster de success
      if (!this.register) {
        await this.$toast.success('Profil mis à jour', {
          timeout: 2000,
        })
      }

      this.fetchProfile({
        userId: this.$route.params.id || localStorage.getItem('userId'),
      })
      this.switchModal({ name: 'MentorProfilSubject', show: false })

      this.$emit('next')
    },
  },
}
</script>

<style lang="scss" scoped>
.search {
  background-color: #f1f2f5;
  max-height: 270px;
  overflow-y: scroll;
  scrollbar-color: #6969dd #e0e0e0;
  &-tile {
    padding: calc(1rem - 2px);
    border: 2px solid #fff;
    &:hover {
      cursor: pointer;
      border: 2px solid var(--color-primary);
    }
  }
}

.search::-webkit-scrollbar {
  width: 10px;
}

.search::-webkit-scrollbar-track {
  background-color: #abb5be;
  border-radius: 0.25rem;
}

.search::-webkit-scrollbar-thumb {
  background-color: var(--color-primary);
  border-radius: 0.25rem;
}
</style>
