// @ts-nocheck
// eslint-disable
import { addDeliverableRateFn, getAllDeliverablesFn, getDeliverableURL } from '../../services/sessionService'

import {
  deleteDeliverableFn,
  getDeliverableByUserIdFn,
  getSessionsDetails,
  uploadDeliverable,
} from '@/services/sessionService'
import {
  addSession,
  endpointAddSessionPresences,
  endpointAddSessionReport,
  updateSession,
} from '@/services/sessionService.js'
import mentorshipGroupsStore from '@/v1.5/features/groups/stores/groups/groups.store'
import { invalidateSessions } from '@/v1.5/utils/lib/vue-query'

function initialState() {
  return {
    activeTab: 'pills-profile',
    sessions: [],
    sessionsDetails: [],
    sessionTotalWaitConfirmation: 0,
    sessionTotalPlanned: 0,
    sessionTotalWaitReviews: 0,
    sessionTotalFinished: 0,
    sessionTotalCancelled: 0,
    isDeliverableUploading: false,
    selectedSessionInReview: {
      attendees: [],
    },
    selectedGroupId: null,
    selectedSessionId: null,
  }
}

const state = initialState()

const getters = {
  activeTab: (state) => state.activeTab,
  sessions: (state) => state.sessions,
  sessionsDetails: (state) => state.sessionsDetails,
  sessionTotalWaitConfirmation: (state) => state.sessionTotalWaitConfirmation,
  sessionTotalPlanned: (state) => state.sessionTotalPlanned,
  sessionTotalWaitReviews: (state) => state.sessionTotalWaitReviews,
  sessionTotalFinished: (state) => state.sessionTotalFinished,
  sessionTotalCancelled: (state) => state.sessionTotalCancelled,
  isDeliverableUploading: (state) => state.isDeliverableUploading,
  selectedSessionInReview: (state) => state.selectedSessionInReview,
  selectedGroupId: (state) => state.selectedGroupId,
  selectedSessionId: (state) => state.selectedSessionId,
}

const actions = {
  // {at, duration, mentorId, studentId}
  async createSession({ commit }, payload) {
    const data = await addSession(
      {
        mentoringGroupId: payload.mentoringGroupId,
        from: payload.from,
        to: payload.to,
        location: payload.location,
      },
      this.getters.authToken,
    )
    commit('ADD_SESSION', data.session)
    await this.dispatch('storeReadSessions')
    this.dispatch('countSessionsStatus')
    return data.session
  },
  async storeReadSessions({ commit }) {
    // if (this.getters.sessions.length > 0) commit('CLEAR_SESSION')
    // const sessions = await getAllSession(this.getters.authToken)
    // // add session status of auth user to session
    // for (let i = 0; i < sessions.length; i++) {
    //   for (let j = 0; j < sessions[i].users.length; j++) {
    //     if (sessions[i].users[j].id === this.getters.authUserId) {
    //       sessions[i].status = sessions[i].users[j].sessionStatus
    //     }
    //   }
    // }
    // sessions.forEach((session) => {
    //   commit('ADD_SESSION', session)
    // })
    // this.dispatch('countSessionsStatus')
  },
  // {status, sessionId}
  async updateSessionStatus({ commit }, payload) {
    await updateSession(
      {
        status: payload.status,
        sessionId: payload.sessionId,
      },
      this.getters.authToken,
    )
    this.dispatch('storeReadSessions')
    await invalidateSessions({ mentorshipGroupId: mentorshipGroupsStore.selectedMentorshipGroupId })
    // commit('UPDATE_SESSION_STATUS', payload)
  },
  countSessionsStatus({ commit }) {
    commit('CLEAR_SESSION_TOTAL_WAIT_CONFIRMATION')
    commit('CLEAR_SESSION_TOTAL_PLANNED')
    commit('CLEAR_SESSION_TOTAL_WAIT_REVIEWS')
    commit('CLEAR_SESSION_TOTAL_FINISHED')
    commit('CLEAR_SESSION_TOTAL_CANCELLED')

    this.getters.sessions.forEach((session) => {
      if (session.users) {
        session.users.forEach((user) => {
          if (user.id === this.getters.authUserId) {
            switch (user.sessionStatus) {
              case 'WAIT_CONFIRMATION':
                commit('INCREMENT_SESSION_TOTAL_WAIT_CONFIRMATION')
                break
              case 'PLANNED':
                commit('INCREMENT_SESSION_TOTAL_PLANNED')
                break
              case 'WAIT_REVIEWS':
                commit('INCREMENT_SESSION_TOTAL_WAIT_REVIEWS')
                break
              case 'WAIT_REVIEW':
                commit('INCREMENT_SESSION_TOTAL_WAIT_REVIEWS')
                break
              case 'FINISHED':
                commit('INCREMENT_SESSION_TOTAL_FINISHED')
                break
              case 'CANCELLED':
                commit('INCREMENT_SESSION_TOTAL_CANCELLED')
                break
              default:
                break
            }
          }
        })
      }
    })
  },
  async sessionAddPresences({ commit }, payload) {
    endpointAddSessionPresences(
      {
        sessionId: payload.sessionId,
        presences: payload.presences,
      },
      this.getters.authToken,
    )
  },
  async SessionAddReport({ commit }, payload) {
    endpointAddSessionReport(
      {
        sessionId: payload.sessionId,
        comment: payload.comment,
        privateComment: payload.privateComment,
      },
      this.getters.authToken,
    )
  },
  updateSelectedSessionInReview({ commit }, session) {
    commit('UPDATE_SELECTED_SESSION_IN_REVIEW', session)
  },
  storeSessionResetState({ commit }) {
    commit('SESSION_RESET_STATE')
  },
  setActiveTab({ commit }, tab) {
    commit('SET_ACTIVE_TAB', tab)
  },
  setSelectedGroupId({ commit }, groupId) {
    commit('SET_SELECTED_GROUP_ID', groupId)
  },
  setSelectedSessionId({ commit }, sessionId) {
    commit('SET_SELECTED_SESSION_ID', sessionId)
  },

  async getSessionsDetails({ commit }) {
    const sessionsDetails = await getSessionsDetails(this.getters.authToken)
    commit('SET_SESSIONS_DETAILS', sessionsDetails.sessions)
  },
  setIsDeliverableUploading({ commit }, isUploading) {
    commit('SET_IS_DELIVERABLE_UPLOADING', isUploading)
  },
  async uploadDeliverable({ commit }, payload) {
    const formData = new FormData()
    const data = await uploadDeliverable(
      payload.sessionId,
      payload.mimetype,
      this.getters.authUserId,
      this.getters.authToken,
    )
    return data
  },
  async getAllDeliverables({ commit }, sessionId) {
    const data = await getAllDeliverablesFn(sessionId, this.getters.authToken)
    return data
  },
  async getDeliverableByUserId({ commit }, payload) {
    const data = await getDeliverableByUserIdFn(payload.sessionId, payload.userId, this.getters.authToken)
    return data
  },
  async getDeliverable({ commit }, payload) {
    const data = await getDeliverableURL(
      payload.sessionId,
      payload.userId,
      payload.deliverableId,
      this.getters.authToken,
    )
    return data
  },
  async deleteDeliverable({ commit }, payload) {
    const data = await deleteDeliverableFn(
      payload.sessionId,
      payload.deliverableId,
      this.getters.authUserId,
      this.getters.authToken,
    )
    return data
  },
  async addDeliverableRate({ commit }, payload) {
    console.log('payload', payload)
    const data = await addDeliverableRateFn(payload.sessionId, payload.rate, payload.userId, this.getters.authToken)
    return data
  },
}

const mutations = {
  // {status, sessionId}
  UPDATE_SESSION_STATUS(state, payload) {
    state.sessions.forEach((session) => {
      if (session.id === payload.sessionId) {
        session.status = payload.status
        session.users.forEach((user) => {
          if (user.id === this.getters.authUserId) user.sessionStatus = payload.status
        })
      }
    })
    this.dispatch('countSessionsStatus')
  },
  UPDATE_SELECTED_SESSION_IN_REVIEW(state, session) {
    state.selectedSessionInReview = session
  },
  ADD_SESSION(state, session) {
    state.sessions.push(session)
  },
  INCREMENT_SESSION_TOTAL_WAIT_CONFIRMATION(state) {
    state.sessionTotalWaitConfirmation++
  },
  INCREMENT_SESSION_TOTAL_PLANNED(state) {
    state.sessionTotalPlanned++
  },
  INCREMENT_SESSION_TOTAL_WAIT_REVIEWS(state) {
    state.sessionTotalWaitReviews++
  },

  INCREMENT_SESSION_TOTAL_FINISHED(state) {
    state.sessionTotalFinished++
  },
  INCREMENT_SESSION_TOTAL_CANCELLED(state) {
    state.sessionTotalCancelled++
  },
  CLEAR_SESSION_TOTAL_WAIT_CONFIRMATION(state) {
    state.sessionTotalWaitConfirmation = 0
  },
  CLEAR_SESSION_TOTAL_PLANNED(state) {
    state.sessionTotalPlanned = 0
  },
  CLEAR_SESSION_TOTAL_WAIT_REVIEWS(state) {
    state.sessionTotalWaitReviews = 0
  },
  CLEAR_SESSION_TOTAL_FINISHED(state) {
    state.sessionTotalFinished = 0
  },
  CLEAR_SESSION_TOTAL_CANCELLED(state) {
    state.sessionTotalCancelled = 0
  },
  CLEAR_SESSION(state) {
    state.sessions = []
  },
  SESSION_RESET_STATE(state) {
    // acquire initial state
    const s = initialState()
    Object.keys(s).forEach((key) => {
      state[key] = s[key]
    })
  },
  SET_ACTIVE_TAB(state, tab) {
    state.activeTab = tab
  },
  SET_IS_DELIVERABLE_UPLOADING(state, isUploading) {
    state.isDeliverableUploading = isUploading
  },
  SET_SELECTED_SESSION_ID(state, sessionId) {
    state.selectedSessionId = sessionId
  },
  SET_SELECTED_GROUP_ID(state, groupId) {
    state.selectedGroupId = groupId
  },
  SET_SESSIONS_DETAILS(state, sessionsDetails) {
    state.sessionsDetails = sessionsDetails
  },
}

export const moduleSession = {
  state,
  getters,
  actions,
  mutations,
}
