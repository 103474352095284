<template>
  <div :class="`loading-screen ${loadedRef ? 'loading-screen--disabled' : ''}`">
    <LoadingIcon color="primary" />
    <p v-if="isI18nLoaded()">{{ isAuthRef ? t('auth.loadingGuard.login') : t('ui.message.loading') }}</p>
    <div class="bottom-info">
      <p v-if="isI18nLoaded()">
        {{ t('auth.loadingGuard.help.title') }}
        <br />
        <a href="#brevoConversationsExpanded">{{ t('auth.loadingGuard.help.body') }}</a>
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watchEffect } from 'vue'

import useAccount from '@/v1.5/features/auth/hooks/use-account.hook'
import useIsAuth from '@/v1.5/features/auth/hooks/use-is-auth.hook'
import { RoleEnumType } from '@/v1.5/features/auth/types'
import LoadingIcon from '@/v1.5/features/ui/components/icons/loading-icon.vue'
import useRouter from '@/v1.5/hooks/use-router.hook'
import useWhiteLabel from '@/v1.5/hooks/use-white-label.hook'
import { useI18n } from '@/v1.5/lib/i18n'
import { ROUTES } from '@/v1.5/utils/config/constants'

const { isAuthRef, isAuthLoadingRef } = useIsAuth()
const { whiteLabelRef, isPendingRef: isWhiteLabelPendingRef, isErrorRef } = useWhiteLabel()
const { accountRef } = useAccount()
const router = useRouter()

const { t, messages, locale } = useI18n()

/**
 * Check if the i18n is loaded
 */
function isI18nLoaded() {
  return Object.keys(messages.value[locale.value]).length > 0
}

const loadedRef = ref(false)

// all conditions to pass to remove the loading screen
const conditionsRef = computed(() => ({
  onLogoutRoute: router?.currentRoute.path === ROUTES.LOGOUT, // if the user is on the logout page
  onAuthRoute: router?.currentRoute.path.startsWith('/auth'), // if the user is on the auth page
  unfinishedRegistration: isAuthRef.value && accountRef.value?.role === RoleEnumType.GUEST && !isAuthRef.value, // if the user is logged in but his registration is not finished (redirect to onboarding)
  whiteLabelDataLoaded: !isErrorRef.value && !!whiteLabelRef.value && !isWhiteLabelPendingRef.value, // if the white label data is loaded
}))

watchEffect((onCleanUp) => {
  let loadingTimeout: any

  // general condition to check if the app is correctly loaded
  if (!isAuthLoadingRef.value && conditionsRef.value.whiteLabelDataLoaded && !conditionsRef.value.onLogoutRoute) {
    loadingTimeout = setTimeout(() => {
      loadedRef.value = true
    }, 500)
  }

  // // redirect the user if he is logged in but his registration is not finished
  // if (conditionsRef.value.unfinishedRegistration && !conditionsRef.value.onLogoutRoute) {
  //   router!.push(ROUTES.ONBOARDING)
  //   return
  // }

  // // redirect the user if he is already logged in
  // if (
  //   isAuthRef.value &&
  //   !conditionsRef.value.unfinishedRegistration &&
  //   !conditionsRef.value.onLogoutRoute &&
  //   conditionsRef.value.onAuthRoute
  // ) {
  //   router!.push(ROUTES.DASHBOARD)
  //   return
  // }

  // // redirect the user if he is not logged in
  // if (!isAuthRef.value && conditionsRef.value.whiteLabelDataLoaded && !conditionsRef.value.onAuthRoute) {
  //   router!.push(ROUTES.LOGIN)
  //   return
  // }

  onCleanUp(() => {
    clearTimeout(loadingTimeout)
  })
})
</script>

<style lang="scss">
@import './loading-guard.scss';
</style>
