import { useQuery } from '@tanstack/vue-query'
import { computed, type ComputedRef } from 'vue'

import { getAuthMeeting, V1_MEETING_QUERY_KEY } from '@/v1.5/features/meetings/api'

function useMeeting(sessionIdRef: ComputedRef<string | null | undefined>) {
  const isSessionIdDefinedRef = computed(() => !!sessionIdRef.value)

  const { data, isPending } = useQuery({
    queryKey: [V1_MEETING_QUERY_KEY, sessionIdRef],
    queryFn: () => getAuthMeeting({ sessionId: sessionIdRef.value! }),
    enabled: isSessionIdDefinedRef,
  })

  return {
    meetingTokenRef: computed(() => data?.value?.token),
    isPendingRef: isPending,
  }
}

export default useMeeting
