<template>
  <div class="matching-information-container">
    <div
      v-if="matchingInformationRef"
      v-html="prepareMatchingInformationHTML(matchingInformationRef, userAlreadyFilledCriteriaRef)"
    ></div>
    <div v-else class="matching-content-loading">
      <LoadingIcon color="primary" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import useMatchingInformation from '@/v1.5/features/matching/hooks/use-matching-information.hook'
import { MATCHING_CRITERIA_LOCAL_STORAGE_KEY } from '@/v1.5/features/matching/hooks/use-matching-information.hook'
import LoadingIcon from '@/v1.5/features/ui/components/icons/loading-icon.vue'

const { matchingInformationRef, userCriteriaRef, prepareMatchingInformationHTML } = useMatchingInformation({
  onSubmitCallback: () => null,
})

const userAlreadyFilledCriteriaRef = computed(
  () => userCriteriaRef.value || window.localStorage.getItem(MATCHING_CRITERIA_LOCAL_STORAGE_KEY),
)
</script>

<style lang="scss"></style>
