// @ts-nocheck
// eslint-disable
import { endpointMatchingMatchStudent } from '@/services/matching.endpoints.js'
import { addStudentSubject, deleteStudentSubject, getStudentSubject } from '@/services/StudentService'
import { apiUpdateUserRole, readUser } from '@/services/userService.js'

function initialState() {
  return {
    user: {},
    userEmail: null,
    userRole: null,
    userFirstname: null,
    userLastname: null,
    userPhoneNumber: null,
    userPhonePrefix: null,
    userPhoneCountry: null,
    userProfilePicture: null,
    userTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    lastUserSubject: null,
    userSubject: null,
    matchingMentor: null,
  }
}

const state = initialState()

const getters = {
  user: (state) => state.user,
  userFirstname: (state) => state.userFirstname,
  userLastname: (state) => state.userLastname,
  userPhoneNumber: (state) => state.userPhoneNumber,
  userPhonePrefix: (state) => state.userPhonePrefix,
  userPhoneCountry: (state) => state.userPhoneCountry,
  userEmail: (state) => state.userEmail,
  userRole: (state) => state.userRole,
  userProfilePicture: (state) => state.userProfilePicture,
  userTimezone: (state) => state.userTimezone,
  lastUserSubject: (state) => state.lastUserSubject,
  userSubject: (state) => state.userSubject,
}

const actions = {
  async storeUpdateUserRole({ commit }, payload) {
    const response = await apiUpdateUserRole({ role: payload.role }, this.getters.authToken)
    if (response.role) {
      commit('SET_USER_ROLE', response.role)
    }
  },
  async readModuleUserProfilePicture({ commit }) {
    // const profilePicture = await readApiUserProfilePicture(this.getters.authToken, this.getters.authUserId)
    // commit('SET_USER_PROFILE_PICTURE', profilePicture.src)
  },
  updateModuleUserProfilePicture({ commit }, profilePicture = null) {
    commit('SET_USER_PROFILE_PICTURE', profilePicture)
  },
  async fetchUser({ commit }) {
    const user = await readUser(this.getters.authToken)
    if (user) commit('SET_USER_EMAIL', user.email)
    if (user.role) commit('SET_USER_ROLE', user.role)
  },
  async fetchUserIdentity({ commit }) {
    // const identity = await readIdentity(this.getters.authToken, this.getters.authUserId)
    // commit('SET_USER_IDENTITY', identity)
    // if (identity?.firstname) commit('SET_USER_FIRSTNAME', identity.firstname)
    // if (identity?.lastname) commit('SET_USER_LASTNAME', identity.lastname)
  },
  async fetchUserPhone({ commit }) {
    // let phone = await getPhone(this.getters.authToken)
    // if (phone.error) phone = null
    // commit('SET_USER_PHONE', phone)
    // if (phone) {
    //   commit('SET_USER_PHONE_NUMBER', phone.number)
    //   commit('SET_USER_PHONE_PREFIX', phone.prefix)
    //   commit('SET_USER_PHONE_COUNTRY', phone.country)
    // }
    // return phone
  },
  userUpdateEmail({ commit }, payload) {
    commit('SET_USER_EMAIL', payload.email)
  },
  userUpdateFirstname({ commit }, payload) {
    commit('SET_USER_FIRSTNAME', payload.firstname)
  },
  userUpdateLastname({ commit }, payload) {
    commit('SET_USER_LASTNAME', payload.lastname)
  },
  async storeAddStudentSubject({ commit }, payload) {
    const response = await addStudentSubject(this.getters.authToken, payload)
    if (response) {
      commit('SET_LAST_STUDENT_SUBJECT', response)
    }
  },
  async storeGetStudentSubject({ commit }) {
    const response = await getStudentSubject(this.getters.authToken)
    if (response) {
      commit('SET_STUDENT_SUBJECT', response)
    }
  },
  async storeDeleteStudentSubject({ commit }, payload) {
    const response = await deleteStudentSubject(this.getters.authToken, payload)
    if (response) {
      commit('SET_STUDENT_SUBJECT', response)
    }
  },
  async storeMatching({ commit }) {
    const response = await endpointMatchingMatchStudent(this.getters.authToken)
    if (response) {
      commit('SET_MATCHING_MENTOR', response)
    }
  },
  storeUserResetState({ commit }) {
    commit('USER_RESET_STATE')
  },
}

const mutations = {
  SET_USER_IDENTITY(state, identity) {
    state.user.identity = identity
  },
  SET_USER_PHONE(state, phone) {
    state.user.phone = phone
  },
  SET_USER_FIRSTNAME(state, firstname) {
    state.userFirstname = firstname
  },
  SET_USER_LASTNAME(state, lastname) {
    state.userLastname = lastname
  },
  SET_USER_PHONE_NUMBER(state, number) {
    state.userPhoneNumber = number
  },
  SET_USER_PHONE_PREFIX(state, prefix) {
    state.userPhonePrefix = prefix
  },
  SET_USER_PHONE_COUNTRY(state, country) {
    state.userPhoneCountry = country
  },
  SET_USER_EMAIL(state, email) {
    state.userEmail = email
  },
  SET_USER_ROLE(state, role) {
    state.userRole = role
  },
  SET_USER_PROFILE_PICTURE(state, profilePicture) {
    state.userProfilePicture = profilePicture
  },
  SET_LAST_STUDENT_SUBJECT(state, lastUserSubject) {
    state.lastUserSubject = lastUserSubject
  },
  SET_STUDENT_SUBJECT(state, userSubject) {
    state.userSubject = userSubject
  },
  SET_MATCHING_MENTOR(state, matchingMentor) {
    state.matchingMentor = matchingMentor
  },
  USER_RESET_STATE(state) {
    // acquire initial state
    const s = initialState()
    Object.keys(s).forEach((key) => {
      state[key] = s[key]
    })
  },
}

export const moduleUser = {
  state,
  getters,
  actions,
  mutations,
}
