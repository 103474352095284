import { useQuery } from '@tanstack/vue-query'
import type { Ref } from 'vue'
import { computed, watch } from 'vue'

import v1Store from '@/store'
import useAccount from '@/v1.5/features/auth/hooks/use-account.hook'
import { PermissionsGroupsEnumType } from '@/v1.5/features/auth/types'
import { selectGetSelectedGroupId } from '@/v1.5/features/groups/stores/groups/groups.selectors'
import groupsStore from '@/v1.5/features/groups/stores/groups/groups.store'
import {
  selectGetSelectedPeriodId,
  selectGetSelectedProgramId,
} from '@/v1.5/features/programs/stores/programs/programs.selectors'
import programsStore from '@/v1.5/features/programs/stores/programs/programs.store'
import { getSessions, V1_SESSIONS_QUERY_KEY } from '@/v1.5/features/sessions/api'
import type { GetSessionsResponseType } from '@/v1.5/features/sessions/types'
import { SessionStatusEnumType, type V2SessionType } from '@/v1.5/features/sessions/types'
import { setAdminSessionStatus } from '@/v1.5/features/sessions/utils'
import checkPermissionsGroup from '@/v1.5/features/users/utils'
import { MAX_SESSIONS_PER_PAGE } from '@/v1.5/utils/config/constants'
function V1ToV2DataMiddleware({
  data,
  userId,
}: {
  data: GetSessionsResponseType | undefined
  userId: string | undefined
}): V2SessionType[] {
  const sessions = data?.sessions?.map((session) => {
    const users = session.users
      // get ONLY the mentor and mentees
      .filter((user) => checkPermissionsGroup(user.role, PermissionsGroupsEnumType.USER))
      .map((user) => ({
        id: user.id,
        role: user.role,
        firstName: user.identity.firstname,
        lastName: user.identity.lastname,
        picture: user.personalInformation?.profilPicture ?? null,
        email: user.email,
        sessionStatus: session.statuses?.find((status) => status.userId === user.id)!.status,
      }))

    let sessionStatus: SessionStatusEnumType
    const isUserInSession = users.some((user) => user.id === userId)

    // if the user is in the session, we get his status
    if (isUserInSession) {
      sessionStatus = session.statuses.find((status) => status.userId === userId)?.status || SessionStatusEnumType.NONE
      // else if it's admin, we get the status of the session
    } else {
      sessionStatus = setAdminSessionStatus(session)
    }

    return {
      id: session.id,
      mentoringGroupId: session.mentoringGroupId,
      meetingRoomId: session.mentorshipGroup.meetingRoomId,
      at: session.from,
      from: session.from,
      to: session.to,
      location: session.location,
      duration: session.duration,
      status: sessionStatus,
      report: session.report ?? null,
      users,
      createdAt: session.createdAt,
      updatedAt: session.updatedAt,
      reviews: session.reviews,
      presences: session.presences.map((presence) => ({
        userId: presence.userId,
        wasPresent: presence.isPresent,
      })),
      deliverables: session.deliverables,
    } satisfies V2SessionType
  })

  return sessions || []
}

const useSessions = ({
  pageRef,
  perPageRef,
  filterEmailSearchRef,
}: {
  pageRef?: Ref<number>
  perPageRef?: Ref<number>
  filterEmailSearchRef?: Ref<string | null>
} = {}) => {
  const { accountRef } = useAccount()
  const selectedGroupIdRef = selectGetSelectedGroupId(groupsStore)
  const selectedProgramIdRef = selectGetSelectedProgramId(programsStore)
  const selectedPeriodIdRef = selectGetSelectedPeriodId(programsStore)

  const getSessionsPageRef = computed(() => pageRef?.value || 1)
  const getSessionsPerPageRef = computed(() => perPageRef?.value || 20)
  const getSessionsFilterEmailSearchRef = computed(() => filterEmailSearchRef?.value || '')

  // Check if all the required data is available to make the query
  const isSessionsQueryEnabledRef = computed(
    () =>
      // we check for the program, period, account and group (required to make the query)
      !!selectedProgramIdRef.value &&
      !!selectedPeriodIdRef.value &&
      !!accountRef.value &&
      // either the user is admin or the user is a user and the group is selected
      // if user is admin, we don't need the groupId
      // if user is user, we need the groupId to fetch the group sessions
      (checkPermissionsGroup(accountRef.value.role, PermissionsGroupsEnumType.ADMIN) ||
        (checkPermissionsGroup(accountRef.value.role, PermissionsGroupsEnumType.USER) && !!selectedGroupIdRef.value)),
  )

  const sessionsQuery = useQuery({
    queryKey: [
      V1_SESSIONS_QUERY_KEY,
      {
        periodId: selectedPeriodIdRef,
        groupId: selectedGroupIdRef,
        perPage: getSessionsPerPageRef,
        page: getSessionsPageRef,
        filterEmail: getSessionsFilterEmailSearchRef,
      },
    ],
    queryFn: () =>
      getSessions({
        programId: selectedProgramIdRef.value!,
        periodId: selectedPeriodIdRef.value!,
        mentorshipGroupId: selectedGroupIdRef.value,

        // including the page metadata
        page: getSessionsPageRef.value!,
        perPage: getSessionsPerPageRef.value ?? MAX_SESSIONS_PER_PAGE,
        filterEmail: getSessionsFilterEmailSearchRef.value!,
      }),
    enabled: isSessionsQueryEnabledRef,
  })

  const data = computed(() => {
    return V1ToV2DataMiddleware({
      data: sessionsQuery.data.value,
      userId: accountRef.value?.id,
    })
  })

  // todo remove when transition to v2 finished
  watch(
    sessionsQuery.data,
    () => {
      if (!sessionsQuery.data.value) return
      v1Store.dispatch('adminGetSessions', {
        sessions: sessionsQuery.data.value?.sessions,
        totalSessions: sessionsQuery.data.value?.totalSessions,
        totalPages: sessionsQuery.data.value?.totalPages,
      })
    },
    { immediate: true },
  )

  const totalPagesRef = computed(() => sessionsQuery.data.value?.totalPages)
  const totalSessionsRef = computed(() => sessionsQuery.data.value?.totalSessions)

  return {
    sessionsRef: data,
    totalPagesRef,
    totalSessionsRef,
    isPendingRef: sessionsQuery.isPending,
  }
}

export default useSessions
