<template>
  <Dropdown
    class-name="session-report"
    :title="t('session.report.title')"
    :default-open="false"
    :is-loaded="!isPendingRef"
  >
    <p v-if="sessionRef?.report" class="session-report__report">"{{ sessionRef.report.comment }}"</p>
    <EmptyContent v-else :text="t('session.report.empty')" />
  </Dropdown>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import useSession from '@/v1.5/features/sessions/hooks/use-session.hook'
import Dropdown from '@/v1.5/features/ui/components/dropdown/dropdown.vue'
import EmptyContent from '@/v1.5/features/ui/components/empty-content/empty-content.vue'
import { selectGetSessionDetailsModal } from '@/v1.5/features/ui/store/modal/modal.selectors'
import modalsStore from '@/v1.5/features/ui/store/modal/modal.store'
import { useI18n } from '@/v1.5/lib/i18n'

const { t } = useI18n()

const sessionDetailsModalRef = selectGetSessionDetailsModal(modalsStore)

const sessionIdRef = computed(() => sessionDetailsModalRef.value.attachedData.sessionId ?? null)

const { sessionRef, isPendingRef } = useSession(sessionIdRef)
</script>

<style lang="scss">
@import './session-report.scss';
</style>
