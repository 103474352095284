import { computed } from 'vue'

import type {
  GroupsPopoverType,
  PeriodsPopoverType,
  PopoversStoreValuesType,
  ProgramsPopoverType,
} from '@/v1.5/features/ui/store/popover/popover.store'

export function selectGetAllPopovers(state: PopoversStoreValuesType) {
  return computed(() => state.popovers as PopoversStoreValuesType['popovers'])
}

export function selectGetProgramsPopover(state: PopoversStoreValuesType) {
  return computed(() => state.popovers['programs'] as ProgramsPopoverType)
}

export function selectGetPeriodsPopover(state: PopoversStoreValuesType) {
  return computed(() => state.popovers['periods'] as PeriodsPopoverType)
}

export function selectGetGroupsPopover(state: PopoversStoreValuesType) {
  return computed(() => state.popovers['groups'] as GroupsPopoverType)
}
