<template>
  <ModalStep
    :id="'program-criterias-modal-content'"
    :header="{
      title: t('dashboard.mentor.programCriteriasModal.title'),
    }"
  >
    <template #bodyInformation>
      {{ t('dashboard.mentor.programCriteriasModal.bodyInformation') }}
    </template>
    <template #bodyContent>
      <MatchingInformationContainer />
    </template>
    <template #footer>
      <div class="c-btns-container">
        <CustomButton usage="button" type="button" color="white" @emit:click="closeModal" :text="t('ui.button.cancel')">
        </CustomButton>
        <CustomButton
          usage="button"
          type="button"
          color="primary"
          @emit:click="onSubmit"
          :isLoading="isAddMentorMatchCriteriaPendingRef || isUpdateMentorMatchCriteriaPendingRef"
          :text="t('ui.button.confirm')"
        ></CustomButton>
      </div>
    </template>
  </ModalStep>
</template>

<script setup lang="ts">
import { useMutation } from '@tanstack/vue-query'
import { computed } from 'vue'

import useAccount from '@/v1.5/features/auth/hooks/use-account.hook'
import { updateMentorMatchCriteriaMutation } from '@/v1.5/features/matching/api'
import { mentorMatchFillCriteriaMutation } from '@/v1.5/features/matching/api'
import MatchingInformationContainer from '@/v1.5/features/matching/components/matching-modals/matching-modal-content/matching-information-container/matching-information-container.vue'
import useMatchingInformation from '@/v1.5/features/matching/hooks/use-matching-information.hook'
import { selectGetSelectedProgramId } from '@/v1.5/features/programs/stores/programs/programs.selectors'
import programsStore from '@/v1.5/features/programs/stores/programs/programs.store'
import CustomButton from '@/v1.5/features/ui/components/button/custom-button.vue'
import ModalStep from '@/v1.5/features/ui/components/modal/modal-step/modal-step.vue'
import modalsStore from '@/v1.5/features/ui/store/modal/modal.store'
import useUser from '@/v1.5/features/users/hooks/use-user.hook'
import useToast from '@/v1.5/hooks/use-toasts.hook'
import { useI18n } from '@/v1.5/lib/i18n'
import { invalidateUser } from '@/v1.5/utils/lib/vue-query'

const { t } = useI18n()
const toast = useToast()
const { accountRef } = useAccount()
const accountIdRef = computed(() => accountRef.value?.id)
const { userRef: userAccountRef } = useUser(accountIdRef)
const selectedProgramIdRef = selectGetSelectedProgramId(programsStore)

const { onSubmit } = useMatchingInformation({
  onSubmitCallback,
})

function closeModal() {
  modalsStore.toggleModal('program-criterias', false)
}

const { mutate: updateMentorMatchCriteria, isPending: isUpdateMentorMatchCriteriaPendingRef } = useMutation({
  mutationFn: updateMentorMatchCriteriaMutation,
  onError: (error) => {
    console.error(error)
    toast?.error(t('error.common'))
  },
  onSuccess: async () => {
    await invalidateUser(accountRef.value!.id!)
    toast?.success(t('dashboard.mentor.programCriteriasModal.success'))
    closeModal()
  },
})

const { mutate: addMentorMatchCriteria, isPending: isAddMentorMatchCriteriaPendingRef } = useMutation({
  mutationFn: mentorMatchFillCriteriaMutation,
  onError: (error) => {
    console.error(error)
    toast?.error(t('error.common'))
  },
  onSuccess: async () => {
    await invalidateUser(accountRef.value!.id!)
    toast?.success(t('dashboard.mentor.programCriteriasModal.success'))
    closeModal()
  },
})

async function onSubmitCallback(filledCriteria: string) {
  // if the user has already a criteria, we update it
  if (userAccountRef.value?.matchingCriteria) {
    await updateMentorMatchCriteria({
      programId: selectedProgramIdRef.value!,
      criteria: filledCriteria,
    })
  } else {
    // if the user has no criteria, we add it
    await addMentorMatchCriteria({
      programId: selectedProgramIdRef.value!,
      criteria: filledCriteria,
    })
  }
}
</script>

<style lang="scss">
@import './program-criterias-modal-content.scss';
</style>
