<!-- eslint-disable -->
<!-- @ts-nocheck -->
<template>
  <div>
    <hr class="my-4" />
    <p class="fw-bold">Compte-rendu</p>
    <p>
      Votre compte-rendu de session permet de garder une trace écrite de vos
      séances de mentorat. <br />
      Veuillez détailler la notation que vous avez attribuée à vos mentorés.
    </p>
    <div class="form-group mt-4">
      <textarea
        v-model="report"
        class="form-control"
        rows="3"
        placeholder="Principaux sujets abordés, Objectifs SMART, Conclusions..."
        style="height: 200px"
      >
      </textarea>
    </div>
    <template v-if="session.status !== 'FINISHED'">
      <hr class="my-4" />
      <p class="fw-bold">Écrivez un commentaire privé</p>
      <p>
        Votre mentoré n'aura pas accès à ce commentaire. Il peut tout aussi bien
        servir à faire remonter un problème quelconque, <br />
        un mentoré en difficulté pendant la session tout comme une altercation.
        En application des règles contre la protection des données, veillez à ce
        que votre commentaire ne soit jamais excessif ni insultant.
      </p>

      <div class="form-group mt-4">
        <textarea
          v-model="privateComment"
          class="form-control"
          rows="3"
          placeholder="Votre commentaire..."
        ></textarea>
      </div>
    </template>
    <div class="mt-4 d-flex justify-content-between">
      <p class="mb-2">*Champs obligatoire</p>
      <div class="d-flex">
        <!-- <button class="btn btn-light">Ne pas laisser d'avis</button> -->
        <button @click="submitForm()" class="btn btn-primary ms-2">
          {{
            session.status === 'FINISHED'
              ? 'Valider les modifications'
              : 'Valider'
          }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import StarRating from 'vue-star-rating'
import { mapActions } from 'vuex'
export default {
  name: 'SessionReview',
  components: {
    StarRating,
  },
  data() {
    return {
      privateComment: '',
      report: null,
      attendees: [],
      status: 'WAIT_REVIEWS',
    }
  },
  watch: {
    '$store.getters.selectedSessionInReview.attendees'() {
      this.attendees = this.$store.getters.selectedSessionInReview.attendees
    },
  },
  computed: {
    session() {
      return this.$store.getters.sessions.find((session) => session.id === this.sessionId)
    },
  },
  props: {
    sessionId: {
      type: String,
    },
  },
  mounted() {
    if (this.session.report) {
      this.report = this.session.report
      return
    }

    // get the report for the program setting
    if (this.session.status === 'WAIT_REVIEWS') {
      const sessionMentoringGroup = this.$store.getters.mentoringGroup.find(
        (group) => group.id === this.session.mentoringGroupId,
      )

      const program = this.$store.getters.userPrograms.find(
        (program) => program.id === sessionMentoringGroup.program.id,
      )

      const reportTemplate = program.settings.find((setting) => setting.name === 'MENTOR_REPORT_TEMPLATE')
      if (reportTemplate) {
        this.report = reportTemplate.value
      }
    }
  },
  methods: {
    ...mapActions(['switchModal']),
    async submitForm() {
      const sessionId = this.sessionId
      const comment = this.report
      const privateComment = this.privateComment
      const presences = this.attendees.map((attendee) => {
        return {
          userId: attendee.userId,
          isPresent: attendee.isPresent,
        }
      })
      const rates = this.attendees.map((attendee) => {
        return {
          sessionId: sessionId,
          rate: attendee.rate,
          userId: attendee.userId,
        }
      })

      // add rates
      rates.forEach(async (ratePayload) => {
        // do not send rate if payload is null
        if (typeof ratePayload.rate !== 'number') return
        await this.$store.dispatch('addDeliverableRate', ratePayload)
      })

      // add report
      await this.$store.dispatch('SessionAddReport', {
        sessionId,
        comment,
        privateComment,
      })

      if (this.session.status === 'FINISHED') {
        await this.$store.dispatch('updateSessionStatus', {
          sessionId,
          status: 'FINISHED',
        })
        this.$toast.success(`Les modifications ont bien été enregistrées !`)
      } else {
        // add presences only if the session is not finished
        // update session status
        await this.$store.dispatch('sessionAddPresences', {
          sessionId,
          presences,
        })
        await this.$store.dispatch('updateSessionStatus', {
          sessionId,
          status: 'FINISHED',
        })
        this.$toast.success(`La session a bien été complétée !`)
        this.switchModal({ name: 'session-details', show: false })
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
