import type { DefaultOptions } from '@tanstack/vue-query'
import { QueryClient } from '@tanstack/vue-query'

const queryConfig: DefaultOptions = {
  queries: {
    staleTime: 1000 * 30,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: true,
    retry: (failureCount, error) => {
      // we retry the query if the error is a network error and failureCount is less than 3
      return (error as Error)?.message?.includes('Network Error') && failureCount < 3
    },
    retryDelay: 1500,
  },
  mutations: {
    onError: (error: any) => {
      console.error('An error occured: ', { error })
      // toast.error(`Une erreur est survenue, merci de réessayer plus tard`)
    },
  },
}

const queryClient = new QueryClient({
  defaultOptions: queryConfig,
})

export default queryClient
