// @ts-nocheck
// eslint-disable

function initialState() {
  return {
    mentoringGroup: [],
  }
}

const state = initialState()

const getters = {
  mentoringGroup: (state) => state.mentoringGroup,
}

const actions = {
  async storeGetMentoringGroup({ commit }) {
    // const response = await endpointGetMentoringGroup(this.getters.authToken)
    // commit('SET_MENTORING_GROUP', response)
  },
}

const mutations = {
  SET_MENTORING_GROUP(state, group) {
    state.mentoringGroup = group
  },
}

export const moduleMentoringGroup = {
  state,
  getters,
  actions,
  mutations,
}
