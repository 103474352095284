<template>
  <Dropdown
    class-name="session-deliverables"
    :title="t('session.deliverables.title')"
    :default-open="false"
    :is-loaded="!isPendingRef"
  >
    <template v-if="sessionRef && sessionRef.deliverables.length > 0">
      <!-- check if there are deliverables with a rating -->
      <p
        class="deliverables-rating-average"
        v-if="
          sessionRef.deliverables.length > 0 &&
          sessionRef.deliverables.some((deliverable) => typeof deliverable.rating === 'number')
        "
      >
        <span>{{ t('session.deliverables.averageOfGrades') }}</span>
        <span>
          <!-- take only the deliverables that have a rating (filter) and calculate the average (reduce) -->
          {{
            Math.round(
              (sessionRef?.deliverables
                .filter((deliverable) => typeof deliverable.rating === 'number')
                .reduce((acc, deliverable) => acc + (deliverable.rating ?? 0), 0) /
                sessionRef?.deliverables.filter((deliverable) => typeof deliverable.rating === 'number').length) *
                10,
            ) / 10
          }}/20
        </span>
      </p>
      <div class="deliverables-listing">
        <div v-for="mentee in menteesRef" :key="mentee?.id" class="deliverable-card no-actions">
          <div class="deliverable-card-top-row">
            <UserInfo
              v-if="mentee"
              :user="mentee"
              :sub-info="mentee?.role === RoleEnumType.MENTEE ? 'Mentoré' : 'Mentor'"
            />
            <p class="deliverable-rating">
              <span
                v-if="
                  typeof returnDeliverableByUserId({ deliverables: deliverablesRef, menteeId: mentee?.id })?.rating ===
                  'number'
                "
                >{{
                  returnDeliverableByUserId({
                    deliverables: deliverablesRef,
                    menteeId: mentee?.id,
                  })?.rating
                }}/20</span
              >
              <span v-else>{{ t('session.deliverables.notGraded') }}</span>
            </p>
          </div>
          <Deliverable :session-id="sessionIdRef" :user-id="mentee?.id" />
        </div>
      </div>
      <div class="c-btns-container c-btn-center">
        <CustomButton
          v-if="accountRef?.role === RoleEnumType.MENTOR"
          usage="button"
          color="primary"
          :text="t('session.deliverables.modifyRating')"
          @emit:click="onClickModifyRating"
        >
          <template #icon>
            <EditIcon color="white" />
          </template>
        </CustomButton>
      </div>
    </template>
    <EmptyContent v-else :text="t('session.deliverables.noGradeAndDeliverable')" />
  </Dropdown>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import useAccount from '@/v1.5/features/auth/hooks/use-account.hook'
import { RoleEnumType } from '@/v1.5/features/auth/types'
import { getMentees } from '@/v1.5/features/groups/utils'
import Deliverable from '@/v1.5/features/sessions/components/deliverable/deliverable.vue'
import useDeliverables from '@/v1.5/features/sessions/hooks/use-deliverables.hook'
import useSession from '@/v1.5/features/sessions/hooks/use-session.hook'
import { returnDeliverableByUserId } from '@/v1.5/features/sessions/utils'
import CustomButton from '@/v1.5/features/ui/components/button/custom-button.vue'
import Dropdown from '@/v1.5/features/ui/components/dropdown/dropdown.vue'
import EmptyContent from '@/v1.5/features/ui/components/empty-content/empty-content.vue'
import EditIcon from '@/v1.5/features/ui/components/icons/edit-icon.vue'
import { selectGetSessionDetailsModal } from '@/v1.5/features/ui/store/modal/modal.selectors'
import type { ReviewSessionModalType } from '@/v1.5/features/ui/store/modal/modal.store'
import modalsStore from '@/v1.5/features/ui/store/modal/modal.store'
import UserInfo from '@/v1.5/features/users/components/user-info/user-info.vue'
import { useI18n } from '@/v1.5/lib/i18n'

const { t } = useI18n()
const { accountRef } = useAccount()

const sessionDetailsModalRef = selectGetSessionDetailsModal(modalsStore)

const sessionIdRef = computed(() => sessionDetailsModalRef.value.attachedData.sessionId ?? null)

const { sessionRef, isPendingRef } = useSession(sessionIdRef)

const menteesRef = computed(() => sessionRef.value?.users.filter(getMentees) ?? [])

const { deliverablesRef } = useDeliverables(sessionIdRef)

const onClickModifyRating = () => {
  modalsStore.toggleModal('review-session', true)
  modalsStore.toggleModal('session-details', false)

  modalsStore.setModalStep<ReviewSessionModalType>('review-session', 'deliverables-rating')
  modalsStore.updateModalAttachedData<ReviewSessionModalType>('review-session', {
    sessionId: sessionIdRef.value!,
  })
}
</script>

<style lang="scss">
@import './session-deliverables.scss';
</style>
