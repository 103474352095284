<!-- eslint-disable -->
<!-- @ts-nocheck -->
<template>
  <div v-if="attendees.length">
    <p class="mb-0 fw-bold">Notez le travail de vos mentorés *</p>
    <p>Attribuez une note sur 20 aux travaux de vos mentorés.</p>
    <div>
      <div class="users-container">
        <div class="user" v-for="user in users" :key="user.id">
          <div class="d-flex align-items-center">
            <div :class="session ? 'profile-picture_session-status-badge' : ''">
              <ProfilePicture
                class="profile-picture"
                :src="user.profilePicture"
                :firstname="user.firstname"
                :lastname="user.lastname"
                :width="40"
                :height="40"
              />
            </div>
            <div>
              <router-link
                class="ms-2"
                :to="{ name: 'IndexProfile', params: { id: user.id } }"
              >
                {{ user.firstname }} {{ user.lastname }}
              </router-link>
              <p v-if="user.role === 'ROLE_MENTOR'" class="mb-0 ms-2">Mentor</p>
              <p v-else-if="user.role === 'ROLE_STUDENT'" class="mb-0 ms-2">
                Mentoré
              </p>
            </div>
          </div>
          <div class="deliverables-container">
            <div
              class="deliverable"
              v-if="getDeliverableByUserId(user.id)?.isImported"
            >
              <span class="fw-bold">Travail rendu :</span>
              <button
                class="btn btn-primary btn-sm"
                @click="downloadDeliverable(user)"
              >
                <span class="material-symbols-outlined"> description </span>
                <span>Télécharger</span>
              </button>
            </div>
            <div v-else class="no-deliverable">
              <span class="fw-bold">Aucun travail rendu.</span>
            </div>
          </div>
          <div>
            <div
              v-if="typeof getDeliverableByUserId(user.id)?.rating === 'number'"
              class="notation-container"
            >
              <span>
                Note attribuée :
                {{ getDeliverableByUserId(user.id)?.rating }}
                / 20
              </span>
            </div>
            <div v-else class="notation-container">
              <input
                placeholder="00"
                @change="
                  updateAttendees(
                    user,
                    $event.target.value,
                    getDeliverableByUserId(user.id)
                  )
                "
                type="number"
                min="0"
                max="20"
              />
              <span>/ 20</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div
      v-if="
        // is the session is finished and any of the users has no rating
        session.status === 'FINISHED' &&
        users.find(
          (user) => typeof getDeliverableByUserId(user.id)?.rating !== 'number'
        )
      "
      class="mt-4 d-flex justify-content-end"
    >
      <div class="d-flex">
        <button @click="sendRates()" class="btn btn-primary ms-2">
          Valider le changement des notes
        </button>
      </div>
    </div> -->
  </div>
</template>
<script>
import { mapActions } from 'vuex'

import ProfilePicture from '@/components/ProfilePicture.vue'
import SessionStatusBadge from '@/components/SessionStatusBadge.vue'
export default {
  name: 'AttendanceConfirmation',
  data() {
    return {
      attendees: [],
      deliverables: [],
    }
  },
  methods: {
    ...mapActions(['updateSelectedSessionInReview', 'getAllDeliverables', 'getDeliverable']),
    async fetchDeliverables() {
      if (!this.sessionId) return
      const deliverables = await this.getAllDeliverables(this.sessionId)
      this.deliverables = deliverables
    },
    getDeliverableByUserId(userId) {
      return this.deliverables.find((d) => d.userId === userId)
    },
    async downloadDeliverable(user) {
      const data = await this.getDeliverable({
        userId: user.id,
        sessionId: this.sessionId,
        deliverableId: this.deliverables.find((d) => d.userId === user.id).id,
      })
      const url = data.url

      const link = document.createElement('a')
      link.href = url
      link.target = '_blank'
      link.download = ''
      document.body.appendChild(link)
      link.click()
      setTimeout(() => {
        link.remove()
      }, 200)
    },
    // update the attendees list in the session review store
    updateAttendees(attendee, rate, deliverable) {
      const decimalRateFixed = rate.replace(',', '.')

      console.log('decimalRateFixed', decimalRateFixed)

      this.attendees = this.attendees.map((a) => {
        const baseNote = typeof a.rate === 'number' ? a.rate : null
        const baseDeliverable = a.deliverable ?? null
        if (a.userId === attendee.id) {
          return {
            ...a,
            rate: Math.min(Math.max(decimalRateFixed, 0), 20),
            deliverable,
          }
        } else {
          return {
            ...a,
            rate: baseNote,
            deliverable: baseDeliverable,
          }
        }
      })

      this.updateSelectedSessionInReview({
        attendees: this.attendees,
      })
    },

    async sendRates() {
      const sessionId = this.sessionId

      const rates = this.attendees.map((attendee) => {
        return {
          sessionId: sessionId,
          rate: attendee.rate,
          userId: attendee.userId,
        }
      })

      for (const ratePayload of rates) {
        await this.$store.dispatch('addDeliverableRate', ratePayload)
      }

      // invalidate deliverables
      await this.fetchDeliverables()

      this.$toast.success('Les notes ont bien été enregistrées !')
    },
  },
  computed: {
    mentoringGroup() {
      return this.$store.getters.mentoringGroup.find((mentoringGroup) => mentoringGroup.id === this.mentoringGroupId)
    },
    session() {
      return this.$store.getters.sessions.find((session) => session.id === this.sessionId)
    },
    users() {
      return this.mentoringGroup.users.filter((u) => u.role === 'ROLE_STUDENT') ?? []
    },
  },
  watch: {
    '$store.getters.selectedSessionInReview'() {
      this.attendees = this.$store.getters.selectedSessionInReview.attendees
    },
  },

  // update the attendees list only when the status of the session is FINISHED
  // because when the status is in WAIT_REVIEWS, AttendanceConfirmation.vue takes care of that
  // duplicate code, should be refactored on newer version
  mounted() {
    this.fetchDeliverables()

    if (this.session.status === 'FINISHED') {
      // we set and update the attendees presence
      this.attendees = this.users.map((u) => ({
        userId: u.id,
        isPresent: true,
      }))
    }
  },
  props: {
    mentoringGroupId: {
      type: String,
      required: true,
    },
    sessionId: {
      type: String,
      default: null,
    },
  },
  components: {
    SessionStatusBadge,
    ProfilePicture,
  },
}
</script>
<style lang="scss" scoped>
.users-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 48px;
  margin: 32px 0;
  .user {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    .notation-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 4px;
      input {
      }
    }
  }
}
</style>

<!-- shared styles -->
<style lang="scss" global>
.deliverables-container {
  display: flex;
  flex-direction: row;
  height: 100%;
  gap: 64px;
  .deliverable {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    .btn {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 4px;
    }
  }
}
.no-deliverable {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  gap: 8px;
}
</style>
