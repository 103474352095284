import Vue from 'vue'
import type { StoreApi } from 'zustand/vanilla'

// vue subscribe to zustand store
const subscribeToStore = <T extends object>(store: StoreApi<T>) => {
  const state = store.getState()
  const observable = Vue.observable(state)

  store.subscribe((newState) => {
    Object.assign(observable, newState)
  })

  return observable
}

export default subscribeToStore
