<template>
  <ModalStep
    id="no-program"
    :header="{
      title: t('program.noProgramModal.title'),
    }"
  >
    <template #bodyInformation>
      <strong>
        {{ t('program.noProgramModal.body') }}
      </strong>
    </template>

    <template #footer>
      <div class="c-btns-container">
        <CustomButton
          usage="link"
          type="submit"
          color="white"
          :text="t('ui.button.support')"
          :link="{
            url: '#brevoConversationsExpanded',
            isInternal: true,
          }"
        >
          <template #icon>
            <SupportIcon color="white" filled />
          </template>
        </CustomButton>
        <CustomButton
          usage="button"
          color="black"
          :text="t('program.noProgramModal.viewProfil')"
          @emit:click="handleClick"
        />
      </div>
    </template>
  </ModalStep>
</template>

<script setup lang="ts">
import CustomButton from '@/v1.5/features/ui/components/button/custom-button.vue'
import SupportIcon from '@/v1.5/features/ui/components/icons/support-icon.vue'
import ModalStep from '@/v1.5/features/ui/components/modal/modal-step/modal-step.vue'
import modalsStore from '@/v1.5/features/ui/store/modal/modal.store'
import useRouter from '@/v1.5/hooks/use-router.hook'
import { useI18n } from '@/v1.5/lib/i18n'
import { ROUTES } from '@/v1.5/utils/config/constants'

const { t } = useI18n()
const router = useRouter()

function handleClick() {
  modalsStore.toggleShouldHideMatchingModals(true)
  router?.push(ROUTES.PROFILE)
}
</script>
