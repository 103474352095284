<!-- eslint-disable -->
<!-- @ts-nocheck -->
<template>
  <div class="rounded d-flex flex-column bg-light d-flex p-4">
    <div class="d-flex justify-content-between">
      <div class="container-profil">
        <div class="profil-picture">
          <ProfilePicture
            :src="
              $route.params.id == $store.getters.authUserId
                ? $store.getters.userProfilePicture
                : profile.PersonalInformation &&
                  profile.PersonalInformation.profilPicture
                ? profile.PersonalInformation.profilPicture
                : null
            "
            :width="160"
            :height="160"
            :firstname="profile.Identity.firstname"
            :lastname="profile.Identity.lastname"
          ></ProfilePicture>
          <div
            v-if="
              profile.Identity.userId == $store.getters.authUserId &&
              !isRoute(`/settings`)
            "
            class="edit edit-profil-picture rounded-circle c-pointer"
            @click="switchModal({ name: 'updateProfilePicture', show: true })"
          >
            <span class="material-symbols-outlined"> photo_camera </span>
          </div>
        </div>

        <div class="ms-4 container-profil-text me-4">
          <p class="fs-1 mb-0">
            {{ profile.Identity.firstname }} {{ profile.Identity.lastname }}
          </p>

          <p v-if="careers && careers[0]" class="fs-4 mb-0 d-flex">
            <span
              class="material-symbols-outlined me-2"
              style="margin-top: 0.3rem"
            >
              school
            </span>
            Actuellement à {{ careers[0].school }}
          </p>
        </div>
      </div>
    </div>

    <hr />

    <nav>
      <ul class="d-flex align-items-center ps-0 mb-0">
        <router-link
          :to="{
            name: 'IndexProfile',
            params: { id: profile.Identity.userId },
          }"
        >
          <li
            :class="{ 'profil-nav-active': isRoute(`/user`) ? true : false }"
            class="rounded"
          >
            Profil
          </li>
        </router-link>

        <li
          v-if="
            profile.Identity.userId != $store.getters.authUserId &&
            this.$store.getters.userRole != 'ROLE_ADMIN'
          "
          @click="openChat()"
        >
          Contacter
        </li>

        <!-- <li class="rounded">Publications</li> -->

        <router-link
          v-if="profile.Identity.userId == $store.getters.authUserId"
          :to="{ name: 'Settings' }"
        >
          <li
            :class="{
              'profil-nav-active': isRoute(`/settings`) ? true : false,
            }"
            class="rounded d-flex align-items-center"
          >
            <span class="material-symbols-outlined me-2">settings</span>

            <span class="d-none d-lg-block"> Mon compte </span>
          </li>
        </router-link>

      </ul>
    </nav>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import ProfilePicture from '@/components/ProfilePicture.vue'

export default {
  name: 'HeaderProfil',
  computed: {
    ...mapGetters(['profile', 'careers']),
  },
  mounted() {
    if (!this.profile.PersonalInformation) {
      this.fetchProfile({ userId: this.$store.getters.authUserId })
      this.fetchCareer({ userId: this.$store.getters.authUserId })
    }
  },
  components: {
    ProfilePicture,
  },
  methods: {
    ...mapActions(['switchModal', 'fetchProfile', 'fetchCareer']),
    isRoute(route) {
      if (this.$route.path.search(route)) {
        return false
      }
      return true
    },
    async openChat() {
      const result = await this.$store.dispatch('createChatRoom', {
        receiverId: this.$route.params.id,
      })
      this.$router.push(`/messages/${result.conversationId}`)
    },
    async logout() {
      this.$store.dispatch('storeAuthLogout')
      return this.$router.push({ name: 'Login' })
    },
  },
}
</script>

<style lang="scss" scoped>
.container-profil {
  display: flex;
  align-items: center;
  @media all and (max-width: 575px) {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
  &-text {
    @media all and (max-width: 575px) {
      margin-left: 0 !important;
    }
    &-school {
      @media all and (max-width: 575px) {
        justify-content: center;
      }
    }
  }
}

.profil-picture {
  position: relative;
  min-width: 168px;
  min-height: 168px;
  max-width: 168px;
  max-height: 168px;
  border: 1px solid var(--color-primary);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  .profile-picture-wrapper {
    width: 100%;
  }

  // img {
  //   width: 160px;
  //   height: 160px;
  //   object-fit: cover;
  // }
}

.edit {
  background-color: #f1f2f5;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  &-profil-picture {
    position: absolute;
    bottom: 5px;
    right: 5px;
  }
  &:hover {
    background-color: var(--color-secondary);
    color: var(--color-primary);
  }
}

.modal-header {
  position: relative;
  &-close {
    position: absolute;
    top: 0;
    left: 0;
    padding: 24px;
  }
}

nav ul {
  list-style-type: none;
  a {
    text-decoration: none;
    color: #212529;
  }
  li {
    margin-right: 12px;
    cursor: pointer;
    padding: 12px;
    &:hover {
      background-color: #f1f2f5;
    }
  }
}

.profil-nav-active {
  background-color: rgba(var(--color-white-label-primary), 0.1);
  color: rgb(var(--color-white-label-primary));
  &:hover {
    background-color: rgba(var(--color-white-label-primary), 0.2);
  }
}

.parcours-tile img {
  width: 48px;
  height: 48px;
}
</style>

<style lang="scss">
.container-profil {
  .profil-picture {
    background-color: var(--color-primary);
    .initials-wrapper {
      width: 100% !important;
      height: 100% !important;
      aspect-ratio: 1 / 1;
    }
  }
  .rounded-circle {
    border: none !important;
  }
}
</style>
