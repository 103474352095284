/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
// eslint-disable
const apiURL = import.meta.env.VITE_API_URL

export async function endpointGetListProgram(authToken) {
  const req = await fetch(`${apiURL}/v1/programs`, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${authToken}`,
    },
  })

  if (!req.ok) {
    throw new Error('Failed to fetch programs')
  }

  return await req.json()
}

export async function endpointGetUserPrograms(userId, authToken) {
  const req = await fetch(`${apiURL}/v1/programs?userId=${userId}`, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${authToken}`,
    },
  })
  return await req.json()
}
