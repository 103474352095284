<!-- eslint-disable -->
<!-- @ts-nocheck -->
<template>
  <Page>
    <template v-slot:middle>
      <Dashboard/>
    </template>
    <template v-slot:outside>
    </template>
  </Page>
 </template>


<script>
import Page from '@/components/Page.vue'
import Dashboard from '@/v1.5/(routes)/(protected)/(app)/admin/dashboard/page.vue'

export default {
  name: 'AdminDashboard',
  data() {
    return {}
  },
  components: {
    Page,
    Dashboard,
  },
}
</script>
