<template>
  <div :class="`popover-row ${isActive ? 'selected' : ''}`" @click="onClick">
    <slot name="content"></slot>
  </div>
</template>

<script setup lang="ts">
defineProps<{
  isActive?: boolean
}>()

const emit = defineEmits(['emit:click'])

function onClick(e: Event) {
  emit('emit:click', e)
}
</script>

<style lang="scss">
@import './popover-row.scss';
</style>
