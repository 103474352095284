<!-- eslint-disable -->
<!-- @ts-nocheck -->
<template>
  <div v-if="mentoringGroup && mentoringGroup.users">
    <div v-for="user in mentoringGroup.users">
      <div v-if="user.role === 'ROLE_MENTOR'" class="me-4 mb-4 d-flex gap-4">
        <SessionStatusLargeProfile :user="user" :session="session" />
        <button
          @click="openChat(user.id)"
          v-if="isRoleMentee && contactButton"
          class="btn btn-primary mt-2 d-flex justify-content-center align-items-center"
        >
          <span class="material-symbols-outlined"> chat </span>
        </button>
      </div>
    </div>
    <div class="d-flex flex-wrap">
      <div v-for="user in mentoringGroup.users">
        <div v-if="user.role === 'ROLE_STUDENT'" class="me-4 mb-4">
          <SessionStatusLargeProfile :user="user" :session="session" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProfilePicture from '@/components/ProfilePicture.vue'
import SessionStatusLargeProfile from '@/components/SessionStatusLargeProfile.vue'

export default {
  name: 'SessionStatusLarge',
  computed: {
    isRoleMentee() {
      return this.$store.getters.userRole === 'ROLE_STUDENT'
    },
    mentoringGroup() {
      return this.$store.getters.mentoringGroup.find((mentoringGroup) => mentoringGroup.id === this.mentoringGroupId)
    },
    session() {
      return this.$store.getters.sessions.find((session) => session.id === this.sessionId)
    },
  },
  methods: {
    async openChat(userId) {
      const result = await this.$store.dispatch('createChatRoom', {
        receiverId: userId,
      })
      this.$router.push(`/messages/${result.conversationId}`)
    },
  },
  props: {
    contactButton: {
      type: Boolean,
      required: false,
    },
    mentoringGroupId: {
      type: String,
      required: true,
    },
    sessionId: {
      type: String,
      default: null,
    },
  },
  components: { ProfilePicture, SessionStatusLargeProfile },
}
</script>

<style lang="scss" scoped></style>
