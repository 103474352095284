import { computed, ref, watch } from 'vue'

import authStore from '@/v1.5/features/auth/stores/auth/auth.store'

function useIsAuth() {
  const authToken = computed(() => authStore.authToken)
  const isAuthRef = ref(false)
  const isAuthLoadingRef = ref(true)

  // watch for changes in the authToken
  watch(
    authToken,
    () => {
      isAuthLoadingRef.value = typeof authToken.value === 'undefined'
      isAuthRef.value = !!authToken.value
    },
    { immediate: true },
  )

  return { isAuthRef, isAuthLoadingRef }
}

export default useIsAuth
