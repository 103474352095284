<template>
  <div :id="`grid-block-${id}`" class="grid-block-base">
    <div class="grid-block-base__header">
      <div v-if="slots.icon" class="grid-block-base__header__icon">
        <slot name="icon"></slot>
      </div>
      <div class="grid-block-base__header__info" v-if="title">
        <p class="grid-block-base-title">{{ title }}</p>
        <p v-if="subtitle" class="grid-block-base-subtitle">{{ subtitle }}</p>
      </div>
      <div class="grid-block-base__header__extra">
        <slot name="headerExtra"></slot>
      </div>
    </div>
    <div class="grid-block-base__content">
      <slot name="body"></slot>
    </div>
    <div v-if="slots.footer" class="grid-block-base__footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useSlots } from 'vue'

const slots = useSlots()

defineProps<{
  id: string
  title?: string
  subtitle?: string
}>()
</script>

<style lang="scss">
@import './grid-block-base.scss';
</style>
