<template>
  <ModalStep
    id="mentee-matching-loading"
    :header="{
      title: t('matching.menteeMatchingLoading.title'),
    }"
  >
    <template #headerBeforeElement>
      <ProgramReminder :programId="selectedProgramIdRef" />
    </template>
    <template #bodyContent>
      <div class="mentee-matching-content">
        <LoadingIcon color="primary" />
        <p>{{ t('matching.menteeMatchingLoading.loading') }}</p>
      </div>
    </template>
  </ModalStep>
</template>

<script setup lang="ts">
import ProgramReminder from '@/v1.5/features/programs/components/program-reminder/program-reminder.vue'
import { selectGetSelectedProgramId } from '@/v1.5/features/programs/stores/programs/programs.selectors'
import programsStore from '@/v1.5/features/programs/stores/programs/programs.store'
import LoadingIcon from '@/v1.5/features/ui/components/icons/loading-icon.vue'
import ModalStep from '@/v1.5/features/ui/components/modal/modal-step/modal-step.vue'
import { useI18n } from '@/v1.5/lib/i18n'

const selectedProgramIdRef = selectGetSelectedProgramId(programsStore)

const { t } = useI18n()
</script>

<style lang="scss">
@import './mentee-matching-loading.scss';
</style>
