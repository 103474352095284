<!-- eslint-disable -->
<!-- @ts-nocheck -->
<template>
  <form @submit.prevent="sendForm" class="p-4">
    <div class="form-group mb-3">
      <label for="" class="form-label">Votre titre</label>
      <input v-model="title" type="text" class="form-control" />
    </div>
    <div class="form-group mb-3">
      <label for="" class="form-label">Votre bio en quelques mots</label>
      <textarea
        v-model="description"
        cols="30"
        rows="10"
        class="form-control"
      ></textarea>
    </div>
    <div class="form-group mb-3">
      <label for="" class="form-label">Vos expérience(s)</label>
      <textarea
        v-model="experience"
        cols="30"
        rows="5"
        class="form-control"
      ></textarea>
    </div>
    <div class="d-flex justify-content-end">
      <button
        @click="switchModal({ name: 'mentorProfilDescription', show: false })"
        type="button"
        class="btn btn-outline-primary"
      >
        Annuler
      </button>
      <button type="submit" class="ms-2 btn btn-primary">Mettre à jour</button>
    </div>
  </form>
</template>

<script>
import { post } from '@/services/MentorService.js'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'FormTitleBioXp',
  data() {
    return {
      title: null,
      description: null,
      experience: null,
    }
  },
  watch: {
    '$route.params.id'() {
      this.asignData()
    },
  },
  mounted() {
    this.asignData()
  },
  computed: {
    ...mapGetters(['profile']),
    ...mapState(['mentorData', 'userId']),
  },
  methods: {
    ...mapActions(['switchModal', 'fetchMentorData', 'fetchProfile']),
    async sendForm() {
      await post(
        {
          profil: {
            title: this.title,
            description: this.description,
            experience:
              this.experience && this.experience.length > 0
                ? this.experience
                : null,
          },
        },
        this.$store.getters.authToken
      )
      this.$toast.success('Profil mis à jour', {
        timeout: 2000,
      })
      await this.fetchProfile({ userId: this.$route.params.id })
      await this.fetchMentorData(this.$route.params.id)
      // this.asignData()
      this.switchModal({ name: 'mentorProfilDescription', show: false })
    },
    asignData() {
      this.title =
        this.profile.MentorProfil && this.profile.MentorProfil.title
          ? this.profile.MentorProfil.title
          : ''
      this.description =
        this.profile.MentorProfil && this.profile.MentorProfil.description
          ? this.profile.MentorProfil.description
          : ''
      this.experience =
        this.profile.MentorProfil && this.profile.MentorProfil.experience
          ? this.profile.MentorProfil.experience
          : ''
    },
  },
}
</script>

<style lang="scss" scoped></style>
