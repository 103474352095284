<template>
  <LoadingContent v-if="isPending && selectedGroupIdRef" />
  <div class="sessions-list" v-else-if="!!sessions.length">
    <div v-for="sessionBlock in sessionsByMonthRef" :key="sessionBlock.month" class="sessions-list__month">
      <p class="sessions-list__month__date">{{ dayjsClient(sessionBlock.month, 'MM-YYYY').format('MMMM YYYY') }}</p>
      <div class="sessions-list__month__content">
        <SessionCard v-for="session in sessionBlock.sessions" :key="session.id" :session="session" />
      </div>
    </div>
  </div>
  <EmptyContent v-else :text="emptyText || t('session.listing.empty')">
    <template #icon>
      <CalendarIcon color="black" filled />
    </template>
  </EmptyContent>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { selectGetSelectedGroupId } from '@/v1.5/features/groups/stores/groups/groups.selectors'
import groupsStore from '@/v1.5/features/groups/stores/groups/groups.store'
import SessionCard from '@/v1.5/features/sessions/components/session-card/session-card.vue'
import type { V2SessionType } from '@/v1.5/features/sessions/types'
import { sortSessionsByDate } from '@/v1.5/features/sessions/utils'
import EmptyContent from '@/v1.5/features/ui/components/empty-content/empty-content.vue'
import CalendarIcon from '@/v1.5/features/ui/components/icons/calendar-icon.vue'
import LoadingContent from '@/v1.5/features/ui/components/loading-content/loading-content.vue'
import dayjsClient from '@/v1.5/lib/dayjs'
import { useI18n } from '@/v1.5/lib/i18n'

const selectedGroupIdRef = selectGetSelectedGroupId(groupsStore)
const { t } = useI18n()

const props = defineProps<{
  sessions: V2SessionType[]
  isPending: boolean
  emptyText?: string
  order?: 'asc' | 'desc'
}>()

const sessionsByMonthRef = computed(() => {
  const sessionsToBeSorted = props.sessions
  return sessionsToBeSorted
    .sort((sessionA, sessionB) => sortSessionsByDate(sessionA, sessionB, props.order))
    .reduce(
      (acc, session) => {
        const monthYear = dayjsClient(session.from).format('MM-YYYY')
        const monthYearSessions = acc.find((block) => block.month === monthYear)

        if (monthYearSessions) {
          monthYearSessions.sessions.push(session)
        } else {
          acc.push({
            month: monthYear,
            sessions: [session],
          })
        }

        return acc
      },
      [] as { month: string; sessions: V2SessionType[] }[],
    )
})

console.log('sessionsByMonthRef', sessionsByMonthRef)
</script>

<style lang="scss">
@import './sessions-list.scss';
</style>
