<!-- eslint-disable -->
<!-- @ts-nocheck -->
<template>
  <Page>
    <template v-slot:middle>
      <Sessions />
    </template>
    <template v-slot:outside>
      <SessionDetailsPopup />
    </template>
  </Page>
</template>

<script>
import SessionDetailsPopup from './SessionDetailsPopup.vue'

import Page from '@/components/Page.vue'
import Sessions from '@/v1.5/(routes)/(protected)/(app)/sessions/page.vue'

export default {
  name: 'SessionIndex',
  data() {
    return {}
  },
  components: {
    Page,
    Sessions,
    SessionDetailsPopup,
  },
}
</script>

<style lang="scss" scoped></style>
