export function downloadFile(url: string, filename?: string) {
  const link = document.createElement('a')

  link.href = url
  link.download = filename || ''

  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)

  URL.revokeObjectURL(url)
}
