<!-- eslint-disable -->
<!-- @ts-nocheck -->
<template>
  <div class="d-flex members-status">
    <SessionStatusSamllTile
      :users="usersFinish"
      badgeClass="bg-success"
      badgeText="Terminé"
    />
    <SessionStatusSamllTile
      :users="usersPlanned"
      badgeClass="bg-success"
      badgeText="Participe"
    />
    <SessionStatusSamllTile
      :users="usersWaiting"
      badgeClass="bg-secondary"
      badgeText="En attente"
    />
    <SessionStatusSamllTile
      :users="usersCanlelled"
      badgeClass="bg-danger"
      badgeText="Annulé"
    />
    <SessionStatusSamllTile
      :users="usersWaitingReview"
      badgeClass="bg-secondary"
      badgeText="En attente d'avis"
    />
  </div>
</template>

<script>
import SessionStatusSamllTile from '@/components/SessionStatusSmallTile.vue'

export default {
  name: 'SessionStatusSamll',
  components: {
    SessionStatusSamllTile,
  },
  data() {
    return {
      session: null,
      usersPlanned: [],
      usersFinish: [],
      usersCanlelled: [],
      usersWaiting: [],
      usersWaitingReview: [],
    }
  },
  mounted() {
    this.session = this.$store.getters.sessions.find((session) => session.id === this.sessionId)
  },
  methods: {
    sortUserPerStatus() {
      for (let i = 0; i < this.session.users.length; i++) {
        if (this.session.users[i].sessionStatus === 'PLANNED') this.usersPlanned.push(this.session.users[i])
        else if (this.session.users[i].sessionStatus === 'FINISHED') this.usersFinish.push(this.session.users[i])
        else if (this.session.users[i].sessionStatus === 'CANCELLED') this.usersCanlelled.push(this.session.users[i])
        else if (this.session.users[i].sessionStatus === 'WAIT_CONFIRMATION')
          this.usersWaiting.push(this.session.users[i])
        else if (this.session.users[i].sessionStatus === 'WAIT_REVIEWS')
          this.usersWaitingReview.push(this.session.users[i])
      }
    },
    addUsersData(userStatus) {
      const users = this.$store.getters.mentoringGroup.find(
        (mentoringGroup) => mentoringGroup.id === this.session.mentoringGroupId,
      )?.users
      for (let i = 0; i < users.length; i++) {
        for (let j = 0; j < userStatus.length; j++) {
          if (users[i].id === userStatus[j].id) {
            userStatus[j].firstname = users[i].firstname
            userStatus[j].lastname = users[i].lastname
            if (users[i].profilePicture) userStatus[j].profilePicture = users[i].profilePicture
          }
        }
      }
    },
  },
  watch: {
    '$store.getters.sessions'() {
      const selectedSession = this.$store.getters.sessions.find((session) => session.id === this.sessionId)
      if (!selectedSession) return

      this.session = selectedSession
    },
    'session'() {
      this.sortUserPerStatus()
      this.addUsersData(this.usersPlanned)
      this.addUsersData(this.usersFinish)
      this.addUsersData(this.usersCanlelled)
      this.addUsersData(this.usersWaiting)
      this.addUsersData(this.usersWaitingReview)
    },
  },
  props: {
    sessionId: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.members-status {
  justify-content: space-around;
}
@media screen and (max-width: 768px) {
  .members-status {
    margin-right: 48px;
  }
}
</style>
