<template>
  <ModalStep
    id="group-settings-content"
    :header="{
      title: t('groups.settingsModal.title'),
    }"
  >
    <template #bodyContent>
      <form @submit.prevent="submitForm">
        <CustomInput
          id="name"
          type="text"
          :label="t('groups.settingsModal.name.label')"
          :placeholder="t('groups.settingsModal.name.placeholder')"
          :value="groupNameRef"
          @emit:change="changeGroupNameHandler"
        />
      </form>
    </template>
    <template #footer>
      <div class="c-btns-container">
        <CustomButton usage="button" type="button" color="white" @emit:click="goBack" :text="t('ui.button.back')" />
        <CustomButton
          usage="button"
          type="button"
          color="primary"
          @emit:click="submitForm"
          :isDisabled="!groupNameRef"
          :isLoading="isPendingRef"
          :text="t('ui.button.confirm')"
        />
      </div>
    </template>
  </ModalStep>
</template>

<script setup lang="ts">
import { useMutation } from '@tanstack/vue-query'
import { computed, ref, watch } from 'vue'
import type { ZodError } from 'zod'
import { z } from 'zod'

import { updateGroupMutation } from '@/v1.5/features/groups/api'
import useGroup from '@/v1.5/features/groups/hooks/use-group.hook'
import { selectGetSelectedProgramId } from '@/v1.5/features/programs/stores/programs/programs.selectors'
import programsStore from '@/v1.5/features/programs/stores/programs/programs.store'
import CustomButton from '@/v1.5/features/ui/components/button/custom-button.vue'
import CustomInput from '@/v1.5/features/ui/components/input/custom-input.vue'
import ModalStep from '@/v1.5/features/ui/components/modal/modal-step/modal-step.vue'
import { selectGetGroupSettingsModal } from '@/v1.5/features/ui/store/modal/modal.selectors'
import modalsStore from '@/v1.5/features/ui/store/modal/modal.store'
import useToast from '@/v1.5/hooks/use-toasts.hook'
import { useI18n } from '@/v1.5/lib/i18n'
import { invalidateGroups } from '@/v1.5/utils/lib/vue-query'

const toast = useToast()
const { t } = useI18n()

const groupNameRef = ref<null | string>(null)
const selectedProgramIdRef = selectGetSelectedProgramId(programsStore)

const groupSettingsModalRef = selectGetGroupSettingsModal(modalsStore)
const groupIdRef = computed(() => groupSettingsModalRef.value?.attachedData.groupId || null)
const { groupRef } = useGroup(groupIdRef)

function changeGroupNameHandler(value: string) {
  groupNameRef.value = value
}

// watch for the group name, and set it if already exists
watch(
  groupRef,
  () => {
    if (groupRef.value?.name) {
      groupNameRef.value = groupRef.value.name
    }
  },
  { immediate: true },
)

const validationSchema = z.object({
  name: z.string().optional(),
})

const { mutate: updateGroup, isPending: isPendingRef } = useMutation({
  mutationFn: updateGroupMutation,
  onError: () => {
    toast?.error(t('error.common'))
  },
  onSuccess: async () => {
    toast?.success(t('groups.settingsModal.success'))
    invalidateGroups(selectedProgramIdRef.value!)
    modalsStore.toggleModal('group-settings', false)
  },
})

function goBack() {
  modalsStore.toggleModal('group-settings', false)
}

async function submitForm() {
  try {
    const data = await validationSchema.parseAsync({ name: groupNameRef.value })
    updateGroup({
      id: groupIdRef.value!,
      name: data.name,
    })
  } catch (e: any) {
    // handle zod errors
    const error: ZodError = e
    error.errors.forEach((err) => {
      toast?.error(err.message)
    })
  }
}
</script>

<style lang="scss" scoped>
@import './group-settings-modal-content.scss';
</style>
