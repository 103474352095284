// @ts-nocheck
// eslint-disable
const apiURL = import.meta.env.VITE_API_URL

// mentee session report
// TODO endpoint renamed from /report to /mentee-report
export const addMenteeSessionReport = async (report, authToken) => {
  const response = await fetch(
    `${apiURL}/v1/user/${localStorage.getItem('userId')}/session/${report.sessionId}/report`,
    {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`,
      },
      body: JSON.stringify(report),
    },
  )
  return response.json()
}

// mentor session report
// TODO new endpoint
export const addMentorSessionReport = async (report, authToken) => {
  const response = await fetch(
    `${apiURL}/v1/user/${localStorage.getItem('userId')}/session/${report.sessionId}/mentor-report`,
    {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`,
      },
      body: JSON.stringify(report),
    },
  )
  return response.json()
}

// 👊 ✓
export async function addSession(session, authToken) {
  const req = await fetch(`${apiURL}/v2/sessions`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${authToken}`,
    },
    body: JSON.stringify(session),
  })

  const data = await req.json()

  if (!req.ok) {
    throw new Error(`${data.message}`)
  }

  return data
}

// ?status=true&exceeded=true
// 👊 ✓
export async function getAllSession(authToken, query = null) {
  const req = await fetch(`${apiURL}/v1/user/${localStorage.getItem('userId')}/session${query ? '?' + query : ''}`, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${authToken}`,
    },
  })
  return await req.json()
}

// 👊 ✓
export async function updateSession(session, authToken) {
  const req = await fetch(`${apiURL}/v1/user/${localStorage.getItem('userId')}/session/${session.sessionId}`, {
    method: 'PATCH',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${authToken}`,
    },
    body: JSON.stringify(session),
  })

  return await req.json()
}

export async function endpointAddSessionPresences(payload, authToken) {
  const req = await fetch(`${apiURL}/v2/sessions/presence`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${authToken}`,
    },
    body: JSON.stringify(payload),
  })
  return await req.json()
}

export async function endpointAddSessionReport(payload, authToken) {
  const req = await fetch(`${apiURL}/v1/user/session/${payload.sessionId}/report`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${authToken}`,
    },
    body: JSON.stringify(payload),
  })
  return await req.json()
}

export async function getSessionsDetails(authToken) {
  const req = await fetch(`${apiURL}/v1/admin/session`, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${authToken}`,
    },
  })
  return await req.json()
}

export async function getAllDeliverablesFn(sessionId, authToken) {
  const req = await fetch(`${apiURL}/v2/sessions/${sessionId}/deliverables`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
  })
  return await req.json()
}

export async function getDeliverableByUserIdFn(sessionId, userId, authToken) {
  const req = await fetch(`${apiURL}/v2/sessions/${sessionId}/users/${userId}/deliverable`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
  })
  return await req.json()
}

export async function uploadDeliverable(sessionId, mimetype, userId, authToken) {
  const req = await fetch(`${apiURL}/v2/sessions/${sessionId}/users/${userId}/deliverable`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${authToken}`,
    },
    body: JSON.stringify({
      mimetype,
    }),
  })
  if (!req.ok) {
    throw new Error('Error uploading deliverable')
  }
  return await req.json()
}

export async function getDeliverableURL(sessionId, userId, deliverableId, authToken) {
  const req = await fetch(`${apiURL}/v2/sessions/${sessionId}/users/${userId}/deliverables/${deliverableId}/url`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
  })
  return await req.json()
}

export async function checkDeliverableImportationFn(deliverableId, authToken) {
  const req = await fetch(`${apiURL}/v2/sessions/deliverables/check/is-imported?deliverableId=${deliverableId}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
  })
  return await req.json()
}

export async function deleteDeliverableFn(sessionId, deliverableId, userId, authToken) {
  const req = await fetch(`${apiURL}/v2/sessions/${sessionId}/users/${userId}/deliverables/${deliverableId}`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
  })
  return await req.json()
}

export async function addDeliverableRateFn(sessionId, rate, userId, authToken) {
  const req = await fetch(`${apiURL}/v2/sessions/${sessionId}/users/${userId}/deliverable/rate`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${authToken}`,
    },
    body: JSON.stringify({ rate }),
  })
  return await req.json()
}

export async function apiExportSessions(authToken, programId, periodId) {
  const req = await fetch(
    `${import.meta.env.VITE_API_URL}/v2/sessions/export?programId=${programId}&periodId=${periodId}`,
    {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`,
      },
    },
  )
  return await req.json()
}
