import type { StoreApi } from 'zustand'

import { type AuthStoreValuesType } from './auth.store'

export async function setAuthTokenAction(
  get: StoreApi<AuthStoreValuesType>['getState'],
  set: StoreApi<AuthStoreValuesType>['setState'],
  authToken: string | null,
) {
  if (!authToken) {
    window.localStorage.removeItem('token')
  } else {
    window.localStorage.setItem('token', authToken)
  }
  set({
    authToken,
  })
}
