<!-- eslint-disable -->
<!-- @ts-nocheck -->
<template>
  <div :class="{ 'p-4': !register }">
    <div
      v-if="!files.length && !imgLoad"
      :class="{ 'bg-primary text-light': draghover }"
      class="drag-and-drop rounded p-4 d-flex flex-column align-items-center"
      @dragenter="draghover = !draghover"
      @dragleave="draghover = !draghover"
      @drop.prevent="addFile"
      @dragover.prevent
    >
      <p class="text-center">
        <span class="material-symbols-outlined"> file_download </span>
        <br />
        Glissez et déposer votre photo ici
      </p>
      <button
        @click="clickInputWhenClickButton"
        :class="{ 'btn-light': draghover, 'btn-primary': !draghover }"
        class="btn d-flex align-items-center"
      >
        <span class="material-symbols-outlined me-2"> cloud_upload </span>
        Télécharger une photo
      </button>
      <input
        hidden
        @input="previewFiles"
        type="file"
        name="profilePicture"
        ref="inputProfilePicture"
      />
    </div>
    <div
      v-if="imgLoad"
      class="d-flex justify-content-center align-items-center p-4"
    >
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div
      v-if="files.length"
      class="profil-picture rounded d-flex justify-content-center"
    >
      <div class="container-profil-picture">
        <img
          class="rounded-circle"
          v-if="this.files.length"
          :src="this.files[0].src"
          alt=""
        />
        <div
          class="c-pointer remove-picture rounded-circle"
          @click="files = []"
        >
          <span class="material-symbols-outlined"> delete </span>
        </div>
      </div>
    </div>
    <p class="fw-bold mt-4 mb-1">Exemples de bonnes photos</p>
    <p>
      Une photo professionnelle est nécessaire pour apparaître sur la
      plateforme. Dans le cas contraire, votre profil sera refusé.
    </p>
    <div class="mt-4 d-flex align-items-center photo-examples">
      <img src="@/assets/images/upload-profil-picture-exemple-1.png" alt="" />
      <img
        class="ms-4"
        src="@/assets/images/upload-profil-picture-exemple-2.png"
        alt=""
      />
      <img
        class="ms-4"
        src="@/assets/images/upload-profil-picture-exemple-3.png"
        alt=""
      />
    </div>
    <p class="mb-0 mt-4">
      <span class="text-muted">Format :</span> JPEG, PNG, HEIC
    </p>
    <p class="mb-0">
      <span class="text-muted">Dimensions :</span> 500*500px minimum
    </p>
    <div class="d-flex justify-content-end align-items-center">
      <div
        v-if="sendLoad"
        class="spinner-border text-primary me-2"
        role="status"
      >
        <span class="visually-hidden">Loading...</span>
      </div>
      <button v-if="register" @click="sendForm" class="btn btn-primary">
        Suivant
      </button>
      <button
        v-else
        @click="sendForm"
        class="btn btn-primary"
        :disabled="files.length ? false : true"
      >
        Mettre à jour
      </button>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import imageCompression from 'browser-image-compression'
import heic2any from 'heic2any'
import { mapActions } from 'vuex'

export default {
  name: 'UpdateProfilePicture',
  data() {
    return {
      files: [],
      draghover: false,
      imgLoad: false,
      sendLoad: false,
    }
  },
  props: {
    register: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions(['switchModal', 'fetchProfile']),
    async addFile(file) {
      this.draghover = false
      const data = {
        target: {
          files: [file.dataTransfer.files[0]],
        },
      }
      await this.previewFiles(data)
    },
    clickInputWhenClickButton() {
      this.$refs.inputProfilePicture.click()
    },
    // preview, compress and convert file
    async previewFiles(event) {
      this.imgLoad = true
      for (let i = 0; i < event.target.files.length; i++) {
        if (event.target.files[i].type === 'image/heic') {
          // convert HEIC to JPG
          const data = await heic2any({
            blob: event.target.files[i],
            toType: 'image/jpg',
            quality: 1,
          })
          // compress image
          const compressedFile = await imageCompression(data, {
            maxSizeMB: 0.29,
            maxWidthOrHeight: 400,
          })
          // add file to array
          this.files.push({
            file: compressedFile,
            src: URL.createObjectURL(compressedFile),
          })
        } else {
          // compress image
          const compressedFile = await imageCompression(event.target.files[i], {
            maxSizeMB: 0.29,
            maxWidthOrHeight: 400,
          })
          this.files.push({
            src: URL.createObjectURL(compressedFile),
            file: compressedFile,
          })
        }
      }
      this.imgLoad = false
    },
    sendForm() {
      if (!this.files.length && this.register) this.$emit('next')
      if (!this.files.length) return
      this.sendLoad = true
      const formData = new FormData()
      this.files.forEach((file) => {
        formData.append('files', file.file)
      })
      axios
        .patch(`${import.meta.env.VITE_API_URL}/v1/user/profil/picture`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'authorization': `Bearer ${this.$store.getters.authToken}`,
          },
        })
        .then((result) => {
          this.$store.dispatch('updateModuleUserProfilePicture', result.data.src)
          // this.fetchProfile({ userId: this.$route.params.id })
          this.files = []
          this.sendLoad = false
          this.switchModal({ name: 'updateProfilePicture', show: false })
          if (this.register) {
            this.$emit('next')
          }
        })
        .catch(() => {
          this.sendLoad = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.drag-and-drop {
  background-color: var(--color-secondary);
}

.photo-examples {
  img {
    height: 4rem;
    width: 4rem;
    object-fit: cover;
  }
}

.remove-picture {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: #f1f2f5;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: var(--color-secondary);
    color: var(--color-primary);
  }
}
</style>

<style lang="scss">
.container-profil {
  position: relative;
  .profil-picture {
    img {
      width: 160px !important;
      height: 160px !important;
      object-fit: cover;
    }
  }
}

.container-profil-picture {
  position: relative;
  img {
    width: 160px !important;
    height: 160px !important;
    object-fit: cover;
  }
}
</style>
