<template>
  <ModalStep
    id="mentee-matching-confirmation"
    :header="{
      title: t('matching.newMatchingModal.confirmation.title'),
    }"
  >
    <template #headerBeforeElement>
      <ProgramReminder :programId="selectedProgramIdRef" />
    </template>

    <template #headerAfterElement>
      <p style="text-align: center">
        {{ t('matching.newMatchingModal.confirmation.headerSubInfo') }}
      </p>
    </template>

    <template #bodyContent>
      <MatchingSuggestionsSlider :mentors="mentorsSuggestionsRef" />
    </template>

    <template #footer>
      <div class="c-btns-container c-btn-center">
        <CustomButton
          usage="button"
          type="submit"
          color="secondary"
          :text="t('matching.newMatchingModal.confirmation.button.change')"
          @emit:click="cancelMatch"
        >
          <template #icon>
            <ChangeIcon color="primary" />
          </template>
        </CustomButton>
      </div>
    </template>
  </ModalStep>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import MatchingSuggestionsSlider from '@/v1.5/features/matching/components/matching-modals/mentee-matching-modal/mentee-matching-confirmation/matching-suggestions-slider/matching-suggestions-slider.vue'
import ProgramReminder from '@/v1.5/features/programs/components/program-reminder/program-reminder.vue'
import { selectGetSelectedProgramId } from '@/v1.5/features/programs/stores/programs/programs.selectors'
import programsStore from '@/v1.5/features/programs/stores/programs/programs.store'
import CustomButton from '@/v1.5/features/ui/components/button/custom-button.vue'
import ChangeIcon from '@/v1.5/features/ui/components/icons/change-icon.vue'
import ModalStep from '@/v1.5/features/ui/components/modal/modal-step/modal-step.vue'
import { selectGetNewMatchingModal } from '@/v1.5/features/ui/store/modal/modal.selectors'
import type { NewMatchingModalType } from '@/v1.5/features/ui/store/modal/modal.store'
import modalsStore from '@/v1.5/features/ui/store/modal/modal.store'
import useToast from '@/v1.5/hooks/use-toasts.hook'
import { useI18n } from '@/v1.5/lib/i18n'

const selectedProgramIdRef = selectGetSelectedProgramId(programsStore)

const newMatchingModalRef = selectGetNewMatchingModal(modalsStore)
const mentorsSuggestionsRef = computed(() => newMatchingModalRef.value?.attachedData.mentors || [])

const toast = useToast()
const { t } = useI18n()

// cancel the match and go back to the matching information step
function cancelMatch() {
  modalsStore.setModalStep<NewMatchingModalType>('new-matching', 'new-matching-info')
  modalsStore.updateModalAttachedData<NewMatchingModalType>('new-matching', {
    mentors: [],
  })
  toast?.error(t('matching.newMatchingModal.confirmation.cancel'))
}
</script>

<style lang="scss">
@import './new-matching-confirmation.scss';
</style>
