<!-- eslint-disable -->
<!-- @ts-nocheck -->
<template>
  <form @submit.prevent="sendForm">
    <h2 class="text-uppercase fs-5">Mot de passe</h2>

    <div class="form-group mt-4">
      <label for="oldPassword" class="form-label">Ancien mot de passe</label>

      <input
        autocomplete="current-password"
        required
        v-model="oldPassword"
        id="oldPassword"
        type="password"
        class="form-control"
        placeholder="Aa"
      />
    </div>

    <div class="form-group mt-2">
      <label for="password" class="form-label">Nouveau mot de passe</label>

      <input
        autocomplete="new-password"
        required
        v-model="password"
        id="password"
        type="password"
        class="form-control"
        placeholder="Aa"
      />
    </div>

    <div class="form-group mt-2">
      <label for="confirmPassword" class="form-label"
        >Confirmation du mot de passe</label
      >

      <input
        autocomplete="new-password"
        required
        v-model="confirmPassword"
        id="confirmPassword"
        type="password"
        class="form-control"
        placeholder="Aa"
      />
    </div>

    <div class="mt-4 d-flex justify-content-end">
      <button type="submit" class="btn btn-primary">
        <span
          v-show="loading"
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>

        <span v-show="loading" class="visually-hidden">Loading...</span>
        Enregistrer
      </button>
    </div>
  </form>
</template>

<script>
import { changePassword } from '@/services/userService'

export default {
  name: 'ChangePassword',
  data() {
    return {
      oldPassword: null,
      password: null,
      confirmPassword: null,
      loading: false,
    }
  },
  methods: {
    sendForm() {
      this.loading = true
      changePassword(
        this.oldPassword,
        this.password,
        this.confirmPassword,
        this.$store.getters.authToken,
        this.$store.getters.authUserId
      )
        .then((res) => {
          this.oldPassword = null
          this.password = null
          this.confirmPassword = null

          if (res.error)
            this.$toast.error(res.error, {
              timeout: 2000,
            })
          else if (res.message)
            this.$toast.success(res.message, {
              timeout: 2000,
            })
        })
        .catch((error) => {
          console.log('error', error)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped></style>
