<!-- eslint-disable -->
<!-- @ts-nocheck -->
<template>
  <Page>
    <template v-slot:middle>
      <div class="row" style="height: 100%;">
        <div class="col-lg-3">
          <DiscussionList />
        </div>
        <div class="col-lg-9" style="height: 100%;">
          <Discussion />
        </div>
      </div>
    </template>
    <template v-slot:end>
      <div class="d-none d-md-block col-md-7 col-lg-7"></div>
    </template>
    <template v-slot:outside>
      <Modal name="discussion" class="d-md-none" :hasDefaultHeader="false">
        <Discussion />
      </Modal>
      <SessionReservationPopup/>
    </template>
  </Page>
</template>

<script>
import Modal from '@/components/Modal.vue'
import Page from '@/components/Page.vue'
import SessionReservationPopup from '@/components/SessionReservationPopup.vue'
import Discussion from '@/views/discussion/Discussion.vue'
import DiscussionList from '@/views/discussion/DiscussionList.vue'

export default {
  name: 'DiscussionIndex',
  components: {
    Page,
    DiscussionList,
    Discussion,
    Modal,
    SessionReservationPopup,
  },
}
</script>

<style lang="scss" scoped></style>
