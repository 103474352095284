// @ts-nocheck
// eslint-disable
import { createConversation, getConversation, sendMessage } from '@/services/ChatService.js'

const initialState = () => ({
  chatRooms: [],
  chatIdRoomSelected: null,
  chatScroll: false,
})

const state = initialState()

const getters = {
  chatRooms: (state) => state.chatRooms,
  chatIdRoomSelected: (state) => state.chatIdRoomSelected,
  chatScroll: (state) => state.chatScroll,
}
const actions = {
  async readAllChatRooms({ commit }) {
    if (this.getters.chatRooms.length) commit('DELETE_CHAT_ROOMS')
    const rooms = await getConversation(this.getters.authToken)
    rooms.forEach((room) => {
      commit('ADD_CHAT_ROOM', {
        id: room.conversationId,
        users: room.users,
        messages: room.messages,
      })
    })
  },
  // {receiverId}
  async createChatRoom({ commit }, payload) {
    const result = await createConversation(payload.receiverId, this.getters.authToken)
    commit('DELETE_CHAT_ROOMS')
    this.dispatch('readAllChatRooms')
    return result
  },
  // { sendAt, message, receiverId }
  async createChatMessage({ commit }, payload) {
    const message = await sendMessage(
      { conversationId: payload.conversationId, message: payload.message },
      this.getters.authToken,
    )

    commit('ADD_CHAT_ROOM_MESSAGE', {
      roomId: message.conversationId,
      id: message.id,
      userId: message.userId,
      content: message.content,
      sendAt: message.createdAt,
    })
    commit('CHANGE_CHAT_ROOM_FIRST_POSITION', message.conversationId)
    commit('START_CHAT_SCROLL')
    return message
  },
  storeChatResetState({ commit }) {
    commit('CHAT_RESET_STATE')
  },
}

const mutations = {
  CHANGE_CHAT_ROOM_FIRST_POSITION(state, roomId) {
    const oldIndex = state.chatRooms.findIndex((room) => room.id === roomId)
    if (0 >= state.chatRooms.length) {
      var k = 0 - state.chatRooms.length + 1
      while (k--) {
        state.chatRooms.push(undefined)
      }
    }
    state.chatRooms.splice(0, 0, state.chatRooms.splice(oldIndex, 1)[0])
  },
  ADD_CHAT_ROOM(state, room) {
    state.chatRooms.push(room)
  },
  UPDATE_CHAT_ID_ROOM_SELECTED(state, payload) {
    state.chatIdRoomSelected = payload
  },
  // {roomId, id, userId, content, sendAt}
  ADD_CHAT_ROOM_MESSAGE(state, payload) {
    state.chatRooms.forEach((room) => {
      if (room.id == payload.roomId) {
        const data = {}
        if (payload.id) data.id = payload.id
        if (payload.userId) data.userId = payload.userId
        if (payload.content) data.content = payload.content
        if (payload.sendAt) data.sendAt = payload.sendAt
        room.messages.push(data)
      }
    })
  },
  START_CHAT_SCROLL(state) {
    state.chatScroll = !state.chatScroll
  },
  DELETE_CHAT_ROOMS(state) {
    state.chatRooms = []
  },
  CHAT_RESET_STATE(state) {
    const s = initialState()
    Object.keys(s).forEach((key) => {
      state[key] = s[key]
    })
  },
}

export const moduleChat = {
  state,
  getters,
  actions,
  mutations,
}

// les nom de recupération des données sont basé sur le CRUD
// Create Read ReadAll Update Delete
