<template>
  <div
    :class="`meeting-window-wrapper ${isResizedRef ? 'resized' : ''}`"
    :style="isMeetingModalOpenRef ? 'display: block' : 'display: none'"
  >
    <div class="meeting-backdrop"></div>
    <div class="meeting-window">
      <div class="meeting-wrapper"></div>
      <div class="meeting-window__actions-container">
        <div class="meeting-window__close-btn" @click="closeModal">
          <CallOffIcon color="white" />
        </div>
        <div class="meeting-window__resize-btn" @click="onClickResizeHandler">
          <ResizeIcon color="black" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'

import useIsAuth from '@/v1.5/features/auth/hooks/use-is-auth.hook'
import useMeeting from '@/v1.5/features/meetings/hooks/use-meeting.hook'
import CallOffIcon from '@/v1.5/features/ui/components/icons/call-off-icon.vue'
import ResizeIcon from '@/v1.5/features/ui/components/icons/resize-icon.vue'
import { selectGetMeetingModal } from '@/v1.5/features/ui/store/modal/modal.selectors'
import type { MeetingModalType } from '@/v1.5/features/ui/store/modal/modal.store'
import modalsStore from '@/v1.5/features/ui/store/modal/modal.store'
import useRouter from '@/v1.5/hooks/use-router.hook'
import useToast from '@/v1.5/hooks/use-toasts.hook'
import { useI18n } from '@/v1.5/lib/i18n'
import { selectGetCurrentRoute } from '@/v1.5/store/route/route.selector'
import routeStore from '@/v1.5/store/route/route.store'

const router = useRouter()
const toast = useToast()
const { t } = useI18n()
const { isAuthRef } = useIsAuth()
const meetingModalRef = selectGetMeetingModal(modalsStore)
const isMeetingModalOpenRef = computed(() => meetingModalRef.value?.isOpen)
const isResizedRef = computed(() => meetingModalRef.value?.attachedData.isResized)

function leaveMeeting() {
  jitsiApiRef.value?.dispose()
  router?.push(window.location.pathname).catch(() => {})
  toast?.success(t('meeting.actions.left'))
  modalsStore.toggleModal('meeting', false)
}

function closeModal() {
  leaveMeeting()
}

function onClickResizeHandler() {
  modalsStore.updateModalAttachedData<MeetingModalType>('meeting', { isResized: !isResizedRef.value })
}

const currentRouteRef = selectGetCurrentRoute(routeStore)

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
const jitsiApiRef = ref<Window['JitsiMeetExternalAPI'] | null>(null)
const jistiLoadedRef = ref(false)

const routerParamSessionIdRef = computed(
  () => (currentRouteRef.value?.query.meetingSessionId || currentRouteRef.value?.query.meetingRoomId) as string,
)

const { meetingTokenRef, isPendingRef: isMeetingTokenPendingRef } = useMeeting(routerParamSessionIdRef)

// start the meeting inside the jitsi meeting wrapper
async function startJitsiMeeting() {
  const meetingWrapper = document.querySelector<HTMLDivElement>('.meeting-wrapper')!
  const domain = 'meet.constel-education.com'
  const options = {
    roomName: routerParamSessionIdRef.value,
    width: '100%',
    height: '100%',
    parentNode: meetingWrapper,
    lang: 'fr',
    jwt: meetingTokenRef.value,
    configOverwrite: {
      lobby: {
        enableChat: false,
      },
      toolbarButtons: ['camera', 'microphone', 'desktop', 'raisehand', 'settings'],
    },
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  if (window.JitsiMeetExternalAPI === undefined) {
    console.log('JitsiMeetExternalAPI is not defined')
    return
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  jitsiApiRef.value = new window.JitsiMeetExternalAPI(domain, options)
  jitsiApiRef.value.addListener('readyToClose', leaveMeeting)
}

// init jitsi when the session id is fetched and user auth
watch(
  [routerParamSessionIdRef, isMeetingModalOpenRef, isAuthRef, jitsiApiRef],
  async ([sessionId, isMeetingModalOpen, isAuth, jitsiApi]) => {
    if (sessionId && !isMeetingModalOpen && isAuth && !jitsiApi) {
      initJitsiAPI()
      modalsStore.toggleModal('meeting', true)
    }
  },
  { immediate: true },
)

// start the meeting when the token is fetched
watch(
  [isMeetingModalOpenRef, meetingTokenRef, isMeetingTokenPendingRef, jistiLoadedRef, jitsiApiRef],
  ([isMeetingModalOpen, meetingToken, isMeetingTokenPending, jitsiLoaded, jitsiApi]) => {
    if (isMeetingModalOpen && meetingToken && !isMeetingTokenPending && jitsiLoaded && !jitsiApi) {
      startJitsiMeeting()
    }
  },
)

// init and append the jitsi script
function initJitsiAPI() {
  const isJistiScriptExist = document.getElementById('jitsi-meet-script')
  if (isJistiScriptExist) {
    return
  }

  const jitsiScript = document.createElement('script')
  jitsiScript.id = 'jitsi-meet-script'
  jitsiScript.async = true
  jitsiScript.setAttribute('src', 'https://meet.constel-education.com/external_api.js')
  document.head.appendChild(jitsiScript)

  jitsiScript.onload = () => {
    jistiLoadedRef.value = true
  }
}
</script>

<style lang="scss">
@import './meeting-window.scss';
</style>
