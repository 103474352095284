<!-- eslint-disable -->
<!-- @ts-nocheck -->
<template>
  <div>{{ message }}</div>
</template>

<script>
import { apiUpdateMentorVisibilityTokenEmail } from '@/services/MentorService.js'

export default {
  name: 'EmailMentorVisibility',
  data() {
    return {
      message: '',
    }
  },
  mounted() {
    apiUpdateMentorVisibilityTokenEmail({ token: this.$route.query.t })
      .then((result) => {
        if (result.message) {
          this.message = 'Votre profil est maintenant visible'
        } else {
          this.message = 'Une erreur est survenue'
        }
      })
      .catch(() => {
        this.message = 'Une erreur est survenue'
      })
  },
}
</script>

<style lang="scss" scoped></style>
