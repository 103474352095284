<template>
  <div v-if="accountRef?.role === RoleEnumType.MENTOR" class="mentor-add-session" @click="onClickHandler">
    <p>Planifier une session</p>
    <AddCalendarIcon color="primary" filled />
  </div>
</template>

<script setup lang="ts">
import useAccount from '@/v1.5/features/auth/hooks/use-account.hook'
import { RoleEnumType } from '@/v1.5/features/auth/types'
import AddCalendarIcon from '@/v1.5/features/ui/components/icons/add-calendar-icon.vue'
import modalsStore from '@/v1.5/features/ui/store/modal/modal.store'

const { accountRef } = useAccount()

function onClickHandler() {
  modalsStore.toggleModal('new-session', true)
}
</script>

<style lang="scss">
@import './mentor-add-session.scss';
</style>
