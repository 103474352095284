<template>
  <ModalStep
    id="program-goal-reminder"
    :header="{
      title: t('matching.goalReminder.title'),
    }"
  >
    <template #headerBeforeElement>
      <ProgramsDropdown />
    </template>

    <template #bodyInformation>
      <p>
        <strong>
          {{ selectedProgramRef?.description ?? t('matching.goalReminder.noGoal') }}
        </strong>
      </p>
    </template>

    <template #footer>
      <div class="c-btns-container">
        <CustomButton
          usage="button"
          type="submit"
          color="white"
          :text="t('ui.button.fillLater')"
          @emit:click="fillLater"
        />
        <CustomButton
          usage="button"
          type="submit"
          color="primary"
          :text="t('ui.button.continue')"
          iconPosition="right"
          @emit:click="nextStep"
        >
          <template #icon>
            <ArrowIcon color="white" filled />
          </template>
        </CustomButton>
      </div>
    </template>
  </ModalStep>
</template>

<script setup lang="ts">
import useAccount from '@/v1.5/features/auth/hooks/use-account.hook'
import { RoleEnumType } from '@/v1.5/features/auth/types'
import ProgramsDropdown from '@/v1.5/features/programs/components/programs-dropdown/programs-dropdown.vue'
import useProgram from '@/v1.5/features/programs/hooks/use-program.hook'
import { selectGetSelectedProgramId } from '@/v1.5/features/programs/stores/programs/programs.selectors'
import programsStore from '@/v1.5/features/programs/stores/programs/programs.store'
import CustomButton from '@/v1.5/features/ui/components/button/custom-button.vue'
import ArrowIcon from '@/v1.5/features/ui/components/icons/arrow-icon.vue'
import ModalStep from '@/v1.5/features/ui/components/modal/modal-step/modal-step.vue'
import type { MenteeMatchingModalType, MentorMatchingModalType } from '@/v1.5/features/ui/store/modal/modal.store'
import modalsStore from '@/v1.5/features/ui/store/modal/modal.store'
import { useI18n } from '@/v1.5/lib/i18n'

const { t } = useI18n()
const { accountRef } = useAccount()
const selectedProgramIdRef = selectGetSelectedProgramId(programsStore)
const { programRef: selectedProgramRef } = useProgram(selectedProgramIdRef)

function nextStep() {
  if (accountRef.value?.role === RoleEnumType.MENTOR) {
    modalsStore.setModalStep<MentorMatchingModalType>('mentor-matching', 'fill-criteria')
  } else if (accountRef.value?.role === RoleEnumType.MENTEE) {
    modalsStore.setModalStep<MenteeMatchingModalType>('mentee-matching', 'fill-criteria')
  }
}

function fillLater() {
  modalsStore.toggleShouldHideMatchingModals(true)
}
</script>

<style lang="scss">
@import './program-goal-reminder.scss';
</style>
