import fetchClient from '@/v1.5/lib/fetch'

export async function resetDb() {
  return fetchClient('/v2/system/reset-db', {
    method: 'GET',
  })
}

export async function runFixtures() {
  return fetchClient('/v2/system/run-fixtures', {
    method: 'GET',
  })
}
