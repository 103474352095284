<!-- eslint-disable -->
<!-- @ts-nocheck -->
<template>
  <div class="mentors-table">
    <vue-good-table
      styleClass="vgt-table striped"
      compactMode
      :columns="columns"
      :rows="mentors"
      :search-options="{
        enabled: true,
        skipDiacritics: true,
        searchFn: searchFn,
        placeholder: 'Recherche',
      }"
      :pagination-options="paginationOptions"
      :sort-options="{
        enabled: false,
      }"
    >
      <div slot="emptystate">
        {{ isLoading ? 'Chargement...' : 'Aucune donnée disponible' }}
      </div>

      <template slot="table-row" slot-scope="props">
        <div v-if="props.column.field === 'Identity.firstname'">
          <span>
            {{ props.row.Identity.firstname }}
            {{ props.row.Identity.lastname }}
          </span>
        </div>
        <!-- <div v-else-if="props.column.field === 'MentorProfil.visible'">
          <div class="checkbox-container">
            <input
              type="checkbox"
              :checked="props.row.MentorProfil.visible"
              @change="updateVisibility(props.row.id, $event.target.checked)"
            />
            <div class="checkbox-style"></div>
          </div>
        </div> -->

        <div v-else-if="props.column.field === 'id'">
          <button
            class="btn btn-primary mentors-table-button"
            @click="seeProfile(props.row)"
          >
            <span class="material-symbols-outlined"> visibility </span>
            <span>Voir</span>
          </button>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'MentorsTable',
  data() {
    return {
      mentors: [],
      isLoading: true,
      windowSize: { width: window.innerWidth },
      columns: [
        {
          label: 'Prénom, Nom',
          field: 'Identity.firstname',
          type: 'string',
          width: '90%',
        },
        {
          label: 'Profil',
          field: 'id',
          type: 'string',
          width: '10%',
        },
      ],
    }
  },
  computed: {
    paginationOptions() {
      if (this.windowSize.width <= 576) {
        // 576 is the value used for the table breakpoint
        return {
          enabled: true,
          mode: 'records',
          perPage: 5,
          perPageDropdownEnabled: false,
          dropdownAllowAll: false,
          jumpFirstOrLast: false,
          nextLabel: '',
          prevLabel: '',
          ofLabel: 'de',
          infoFn: (params) =>
            `Page ${params.currentPage} sur ${params.totalPage}`,
        }
      } else {
        return {
          enabled: true,
          mode: 'records',
          perPage: 10,
          perPageDropdownEnabled: false,
          dropdownAllowAll: false,
          jumpFirstOrLast: false,
          nextLabel: 'suivant',
          prevLabel: 'précédent',
          ofLabel: 'de',
          infoFn: (params) =>
            `Page ${params.currentPage} sur ${params.totalPage}`,
        }
      }
    },
  },
  watch: {
    '$store.getters.mentors': function () {
      this.mentors = this.$store.getters.mentors
    },
  },
  methods: {
    ...mapActions([
      'getMentors',
      'updateMentorApproval',
      'setMentorId',
      'switchModal',
    ]),
    // when clicking on the profile button
    seeProfile(mentor) {
      this.setMentorId(mentor.id)
      this.switchModal({ name: 'mentorProfile', show: true })
    },
    // used to handle the table breakpoints
    handleResize() {
      this.windowSize.width = window.innerWidth
    },
    // used to search in the table
    searchFn(row, col, cellValue, searchTerm) {
      const possibleSearchValues = [
        row.Identity.firstname,
        row.Identity.lastname,
      ]
      return possibleSearchValues
        .map((v) => v.toLowerCase())
        .join(' ')
        .includes(searchTerm.toLowerCase())
    },
    // updated the mentor visibility
    async updateVisibility(id, value) {
      try {
        await this.updateMentorApproval({
          userId: id,
          value,
        })
        this.$toast.success('La visibilité du mentor a été mise à jour')
        // we invalidate the mentors list to force the update
        await this.$store.dispatch('getMentors')
      } catch (e) {
        this.$toast.error('Erreur lors de la mise à jour de la visibilité')
        console.error('Error updating the mentor visibility', e)
      }
    },
  },

  async mounted() {
    // getting the list of mentors
    try {
      await this.getMentors()
    } catch (e) {
      this.$toast.error('Erreur lors de la récupération des mentors')
      console.error('Error getting the mentors', e)
    } finally {
      this.isLoading = false
    }
    // handle resize event for the table breakpoints
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
}
</script>

<style lang="scss" scoped>
.mentors-table {
  .checkbox-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 32px;
    @media screen and (max-width: 576px) {
      // mobile view for the table
      transform: translateX(32vw);
    }
    input {
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;
      z-index: 1000;
      cursor: pointer;
    }
    .checkbox-style {
      position: relative;
      width: 100%;
      height: 100%;
      background-color: var(--bs-gray);
      border-radius: 16px;
      transition: background-color 0.3s ease;
      &:before {
        content: '';
        position: absolute;
        top: 2px;
        left: 2px;
        width: 28px;
        height: 28px;
        background-color: var(--bs-white);
        border-radius: 50%;
        transition: all 0.3s ease;
        pointer-events: none;
      }
    }

    input:checked + .checkbox-style:before {
      transform: translateX(32px);
    }
    input:checked + .checkbox-style {
      background-color: var(--bs-primary);
    }
  }
}
</style>

<style lang="scss">
[aria-sort] {
  button {
    display: none;
  }
}
.mentors-table-button {
  border: none;
  height: 32px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 0 8px;
  @media screen and (max-width: 576px) {
    // mobile view for the table
    transform: translateX(30vw);
  }
}
</style>
