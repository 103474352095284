import { useQuery } from '@tanstack/vue-query'
import { computed } from 'vue'

import { ADMIN_ANALYTICS_QUERY_KEY, V1GetAdminDashboardAnalytics } from '@/v1.5/features/admin/analytics/api'
import type {
  V1GetAdminDashboardAnalyticsResponseType,
  V2GetAdminDashboardAnalyticsResponseType,
} from '@/v1.5/features/admin/analytics/types'
import useAccount from '@/v1.5/features/auth/hooks/use-account.hook'
import { PermissionsGroupsEnumType } from '@/v1.5/features/auth/types'
import {
  selectGetSelectedPeriodId,
  selectGetSelectedProgramId,
} from '@/v1.5/features/programs/stores/programs/programs.selectors'
import programsStore from '@/v1.5/features/programs/stores/programs/programs.store'
import checkPermissionsGroup from '@/v1.5/features/users/utils'

function V1ToV2DataMiddleware(
  data: V1GetAdminDashboardAnalyticsResponseType | undefined,
): Partial<V2GetAdminDashboardAnalyticsResponseType> | undefined {
  // no transformation for now

  const [total, goal] = data?.totalPeriodSessions.split('/').map(Number) || [0, 1]

  return {
    ...data,
    totalPeriodSessions: {
      total,
      goal,
    },
  }
}

export default function seAdminDashboardAnalytics() {
  const { accountRef } = useAccount()
  const selectedProgramIdRef = selectGetSelectedProgramId(programsStore)
  const selectedPeriodIdRef = selectGetSelectedPeriodId(programsStore)

  const isAdminDashboardAnalyticsQueryEnabledRef = computed(
    () =>
      !!selectedPeriodIdRef.value &&
      !!selectedProgramIdRef.value &&
      checkPermissionsGroup(accountRef.value?.role, PermissionsGroupsEnumType.ADMIN),
  )

  const queryResult = useQuery({
    queryKey: [ADMIN_ANALYTICS_QUERY_KEY, { programId: selectedProgramIdRef, periodId: selectedPeriodIdRef }],
    queryFn: () =>
      V1GetAdminDashboardAnalytics({
        programId: selectedProgramIdRef.value!,
        periodId: selectedPeriodIdRef.value!,
      }),
    enabled: isAdminDashboardAnalyticsQueryEnabledRef,
  })

  const data = computed(() => V1ToV2DataMiddleware(queryResult.data.value))

  return {
    adminDashboardAnalyticsRef: data,
    isPendingRef: queryResult.isPending,
  }
}
