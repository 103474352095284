// @ts-nocheck 
// eslint-disable
import axios from 'axios'

const apiURL = import.meta.env.VITE_API_URL
const instance = axios.create({
  baseURL: `${apiURL}/v1/student`,
})
// PATCH "/"
// 👊 ✓
export async function apiStudentUpdate(data, authToken) {
  const res = await instance.patch(
    '/',
    {
      personalInformation: { ...data.personalInformation },
    },
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }
  )

  return res.data
}

// 👊 ✓
export async function addStudentSubject(authToken, subject) {
  const res = await instance.post(
    '/subject',
    {
      subjectId: subject.id,
    },
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }
  )

  return res.data
}

// 👊 ✓
export async function getStudentSubject(authToken) {
  const res = await instance.get('/subject', {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  })

  return res.data
}

// 👊 ✓
export async function deleteStudentSubject(authToken, subject) {
  const res = await instance.delete('/subject', {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
    data: {
      subjectId: subject,
    },
  })

  return res.data
}
