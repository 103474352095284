<template>
  <Modal
    id="session-details"
    :modal="sessionDetailsModalRef"
    :steps="[{ component: SessionDetailsModalContent, id: 'session-details' }]"
  />
</template>

<script setup lang="ts">
import SessionDetailsModalContent from '@/v1.5/features/sessions/components/session-details-modal/session-details-modal-content/session-details-modal-content.vue'
import Modal from '@/v1.5/features/ui/components/modal/modal.vue'
import { selectGetSessionDetailsModal } from '@/v1.5/features/ui/store/modal/modal.selectors'
import modalsStore from '@/v1.5/features/ui/store/modal/modal.store'

const sessionDetailsModalRef = selectGetSessionDetailsModal(modalsStore)
</script>
