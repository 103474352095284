<!-- eslint-disable -->
<!-- @ts-nocheck -->
<template>
  <button @click="downloadDeliverable" class="btn btn-primary">
    Télécharger
  </button>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'DownloadDelivrable',
  methods: {
    ...mapActions(['getDeliverable']),
    async downloadDeliverable() {
      const data = await this.getDeliverable({
        userId: this.$route.params.userId,
        sessionId: this.$route.params.sessionId,
        deliverableId: this.$route.params.deliverableId,
      })
      const url = data.url

      const link = document.createElement('a')
      link.href = url
      link.target = '_blank'
      link.download = ''
      document.body.appendChild(link)
      link.click()
      setTimeout(() => {
        link.remove()
      }, 200)
    },
  },
}
</script>

<style lang="scss" scoped></style>
