import { PermissionsGroupsEnumType, RoleEnumType } from '@/v1.5/features/auth/types'
import type { V1GroupDataType, V2GroupType } from '@/v1.5/features/groups/types'
import checkPermissionsGroup from '@/v1.5/features/users/utils'

export function getMentees(user: V2GroupType['users'][number] | V1GroupDataType['users'][number]) {
  return user.role === RoleEnumType.MENTEE
}

export function getMentor(user: V2GroupType['users'][number] | V1GroupDataType['users'][number]) {
  return user.role === RoleEnumType.MENTOR
}

export function getManager(user: V2GroupType['users'][number] | V1GroupDataType['users'][number]) {
  return user.role === RoleEnumType.MANAGER
}

export function formGroupName(group: V1GroupDataType) {
  // if the group has a name, return it
  if (group.name) {
    return group.name
  }

  // list of names like : mentor firstname, mentee firstname, mentee firstname"
  // mentor firstname should be the first in the group name
  // should take only the first 4 users and add a "+X" at the end if there are more than 4 users
  const USERS_LIMIT = 4
  return group.users
    .filter((user) => checkPermissionsGroup(user.role, PermissionsGroupsEnumType.USER))
    .sort((a, b) => {
      if (getMentor(a)) return -1
      if (getMentor(b)) return 1
      return 0
    })
    .slice(0, USERS_LIMIT)
    .map((user) => user.identity.firstname)
    .join(', ')
    .concat(group.users.length > USERS_LIMIT ? `, +${group.users.slice(USERS_LIMIT, group.users.length).length}` : '')
}
