import { useQuery } from '@tanstack/vue-query'
import { computed } from 'vue'

import useAccount from '@/v1.5/features/auth/hooks/use-account.hook'
import { PermissionsGroupsEnumType } from '@/v1.5/features/auth/types'
import { V1_PROGRAMS_QUERY_KEY, V1GetPrograms } from '@/v1.5/features/programs/api'
import type { V1PeriodType, V1ProgramType, V2PeriodType, V2ProgramType } from '@/v1.5/features/programs/types'
import checkPermissionsGroup from '@/v1.5/features/users/utils'
import dayjsClient from '@/v1.5/lib/dayjs'

function getLatestPeriod({ periods }: { periods: V1PeriodType[] }): V2PeriodType | undefined {
  const now = dayjsClient()

  // First, try to find a period that currently includes the present moment.
  let latestPeriod = periods.find((period) => now.isBetween(dayjsClient(period.startAt), dayjsClient(period.endAt)))

  // If no current period is found, find the most recent past period.
  if (!latestPeriod) {
    latestPeriod = periods.reduce<V1PeriodType | undefined>((prev, curr) => {
      const currEnd = dayjsClient(curr.endAt)

      // If current end date is in the future compared to 'now', skip it.
      if (currEnd.isAfter(now)) return prev

      // Compare only past end dates and select the one closest to 'now'.
      return !prev || currEnd.isAfter(dayjsClient(prev.endAt)) ? curr : prev
    }, undefined) // Initialize accumulator with undefined for cases when all periods are in the future.
  }

  if (latestPeriod) {
    return {
      id: latestPeriod.id,
      name: latestPeriod.name,
      sessionsGoal: latestPeriod.totalSession,
      startAt: latestPeriod.startAt,
      endAt: latestPeriod.endAt,
    }
  }
  return undefined
}

function V1ToV2DataMiddleware({ data }: { data?: V1ProgramType[] }): V2ProgramType[] {
  const programs = data?.map((program) => {
    const programSettings = {
      mentorMatchingCriteria: program.settings.find((setting) => setting.name === 'MATCHING_MENTOR_CRITERIA')?.value,
      menteeMatchingCriteria: program.settings.find((setting) => setting.name === 'MATCHING_MENTEE_CRITERIA')?.value,
      isMatchingEnabled: program.settings.find((setting) => setting.name === 'MATCHING')?.value === 'true',
      maxUserPerGroup: Number(program.settings.find((setting) => setting.name === 'MAX_USER_PER_GROUP')?.value),
      shouldMentorReviewSessionDeliverableReport:
        program.settings.find((setting) => setting.name === 'MENTOR_REVIEW_SESSION_DELIVERABLE_REPORT_STEP')?.value ===
        'true',
      shouldMentorReviewSessionDeliverableRate:
        program.settings.find((setting) => setting.name === 'MENTOR_REVIEW_SESSION_DELIVERABLE_RATE_STEP')?.value ===
        'true',
      shouldMentorReviewSessionPresence:
        program.settings.find((setting) => setting.name === 'MENTOR_REVIEW_SESSION_PRESENCE_STEP')?.value === 'true',
      shouldReviewSessionRating:
        program.settings.find((setting) => setting.name === 'REVIEW_SESSION_RATING_STEP')?.value === 'true',
      shouldReviewSessionReview:
        program.settings.find((setting) => setting.name === 'REVIEW_SESSION_REVIEW_STEP')?.value === 'true',
      shouldReviewSessionDeliverable:
        program.settings.find((setting) => setting.name === 'REVIEW_SESSION_DELIVERABLE_STEP')?.value === 'true',
      adminDashboardType: program.settings.find((setting) => setting.name === 'ADMIN_DASHBOARD_TYPE')?.value,
      driveUrl: program.settings.find((setting) => setting.name === 'DRIVE_URL')?.value,
      mentorReportTemplate: program.settings.find((setting) => setting.name === 'MENTOR_REPORT_TEMPLATE')?.value,
      menteeCanFindNewGroup:
        program.settings.find((setting) => setting.name === 'MENTEE_CAN_FIND_NEW_GROUP')?.value === 'true',
    }

    return {
      id: program.id,
      name: program.name,
      description: program.description,
      latestPeriod: getLatestPeriod({ periods: program.periods }),
      periods: program.periods,
      settings: programSettings,
      users: program.users?.map((user) => ({ id: user.userId })) || [],
    }
  })

  return programs || []
}

const usePrograms = () => {
  const { accountRef } = useAccount()

  const roleRef = computed(() => accountRef.value?.role)
  const userIdRef = computed(() => accountRef.value?.id)
  const isProgramsQueryEnabledRef = computed(() => !!userIdRef.value && !!roleRef.value)

  const query = useQuery({
    queryKey: [V1_PROGRAMS_QUERY_KEY],
    queryFn: () => {
      if (checkPermissionsGroup(roleRef.value, PermissionsGroupsEnumType.ADMIN)) {
        return V1GetPrograms()
      }

      return V1GetPrograms(userIdRef.value!)
    },
    enabled: isProgramsQueryEnabledRef,
  })

  const programsDataRef = computed(() =>
    V1ToV2DataMiddleware({
      data: query.data.value,
    }),
  )

  return {
    programsRef: programsDataRef,
    isPendingRef: query.isPending,
    isFetchedRef: query.isFetched,
  }
}

export default usePrograms
