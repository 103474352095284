<template>
  <Modal
    id="edit-session"
    :modal="editSessionModalRef"
    :steps="[{ component: NewSessionContent, id: 'edit-session' }]"
    :onExit="onClose"
  />
</template>

<script setup lang="ts">
import NewSessionContent from '@/v1.5/features/sessions/components/new-session-modal/new-session-content/new-session-content.vue'
import Modal from '@/v1.5/features/ui/components/modal/modal.vue'
import { selectGetEditSessionModal } from '@/v1.5/features/ui/store/modal/modal.selectors'
import type { EditSessionModalType } from '@/v1.5/features/ui/store/modal/modal.store'
import modalsStore from '@/v1.5/features/ui/store/modal/modal.store'

const editSessionModalRef = selectGetEditSessionModal(modalsStore)

function onClose() {
  console.log('onClose')
  modalsStore.updateModalAttachedData<EditSessionModalType>('edit-session', { sessionId: null })
}
</script>
