// @ts-nocheck 
// eslint-disable
import axios from 'axios'

const apiURL = import.meta.env.VITE_API_URL
const instance = axios.create({
  baseURL: `${apiURL}/v1/mentor`,
})
// ✓
// 👊 ✓
export async function updateMentorSubject(subjectId, grade, authToken) {
  const res = await instance.patch(
    `/subject/${subjectId}`,
    {
      grade: grade,
    },
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }
  )

  return res.data
}

// 👊 ✓
export async function deleteMentorSubject(subjectId, authToken) {
  const res = await instance.delete(`/subject/${subjectId}`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  })
  return res.data
}

// // GET "/"
// export async function getList() {
//   const res = await instance.get()
//   return res.data
// }
// 👊 ✓
export async function getOneMentor(mentorId, authToken) {
  const res = await instance.get(
    `/${mentorId}${authToken ? '?token=Bearer ' + authToken : ''}`
  )
  return res.data
}

// 👊 ✓
export async function getMentorSubjects(mentorId, authToken) {
  const subjects = await instance.get(`/${mentorId}/subjects`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  })
  return subjects.data
}

// 👊 ✓
export async function addSubject(data, authToken) {
  const res = await instance.post(
    `/subject`,
    {
      ...data,
    },
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }
  )
  return res.data
}

/**
 * Ajoute le mentor sur algolia
 */
// 👊 ✓
export async function switchVisible(visible, authToken) {
  const res = await instance
    .post(
      '/algolia/add/one',
      { visible: visible },
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    )
    .catch(function (error) {
      if (error.response) {
        return error.response
      }
    })
  return res.data
}

// POST "/" create or update
// 👊 ✓
export async function post(data, authToken) {
  let res = null
  try {
    res = await instance.post(
      '/',
      { ...data },
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    )
  } catch (error) {
    console.error(error)
    return
  }
  return res.data
}
// {userId}
// 👊 ✓
export async function apiUpdateMentorVisibilityTokenEmail(payload) {
  const req = await fetch(`${apiURL}/v1/mentor/available/month/visibility`, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  })
  return req.json()
}
