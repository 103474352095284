// @ts-nocheck 
// eslint-disable
const apiURL = import.meta.env.VITE_API_URL
// 👊 ✓
export async function apiPhoneAdd(phone, authToken) {
  const req = await fetch(`${apiURL}/v1/phone`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify({ phone }),
  })
  return await req.json()
}
// 👊 ✓
export async function getPhone(authToken) {
  const req = await fetch(`${apiURL}/v1/phone`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
  })
  return await req.json()
}
// 👊 ✓
export async function updatePhone(phone, authToken) {
  const req = await fetch(`${apiURL}/v1/phone`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify({ phone }),
  })
  return await req.json()
}
