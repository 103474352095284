<template>
  <div class="avatar">
    <img v-if="user?.picture" :src="profilePicture" alt="avatar" />
    <div class="avatar__initials">{{ initials }}</div>
  </div>
</template>

<script setup lang="ts">
import { computed, defineProps } from 'vue'

import type { V2UserDetailsDataType } from '@/v1.5/features/users/types'
import { PROFILE_PICTURE_BUCKET_URL } from '@/v1.5/utils/config/constants'

const props = defineProps<{
  user: Partial<V2UserDetailsDataType> | undefined
}>()

const initials = computed(() => `${props.user?.firstName?.[0] ?? ''} ${props.user?.lastName?.[0] ?? ''}`)
const profilePicture = computed(() => PROFILE_PICTURE_BUCKET_URL + props.user?.picture)
</script>

<style scoped lang="scss">
@import './avatar.scss';
</style>
