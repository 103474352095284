import type {
  AddUserToGroupDTOType,
  CreateGroupDTOType,
  CreateGroupResponseType,
  RemoveUserFromGroupDTOType,
} from '@/v1.5/features/admin/groups/types'
import fetchClient from '@/v1.5/lib/fetch'

export function addUserToGroupMutation(data: AddUserToGroupDTOType): Promise<any> {
  return fetchClient(`/v2/groups/${data.groupId}/user/${data.userId}`, {
    method: 'POST',
    body: JSON.stringify(data),
  })
}

export function removeUserFromGroupMutation(data: RemoveUserFromGroupDTOType): Promise<any> {
  return fetchClient(`/v2/groups/${data.groupId}/user/${data.userId}`, {
    method: 'DELETE',
  })
}

export function createGroupMutation(data: CreateGroupDTOType): Promise<CreateGroupResponseType> {
  return fetchClient(`/v2/groups`, {
    method: 'POST',
    body: JSON.stringify(data),
  })
}
