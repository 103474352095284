import type { ComputedRef } from 'vue'
import { computed } from 'vue'

import useConversations from '@/v1.5/features/chat/hooks/use-conversations.hook'

const useConversation = (conversationIdRef: ComputedRef<string | null>) => {
  const { conversationsRef, isPendingRef } = useConversations()

  const conversationRef = computed(() =>
    conversationsRef.value.find((c) => c.conversationId === conversationIdRef.value),
  )

  return {
    conversationRef,
    isPendingRef,
  }
}

export default useConversation
