<!-- eslint-disable -->
<!-- @ts-nocheck -->
<template>
  <Modal
    :title="sessionTitleSwitch[session?.status] ?? 'Session'"
    name="session-details"
    :onCloseFn="onClose"
  >
    <template v-if="session">
      <div class="bg-light p-4 rounded">
        <div class="d-flex">
          <SessionStatusIcon :status="session.status" />
          <div class="ms-4">
            <p
              class="fw-bold d-inline-block px-3 py-2 bg-light-gray mb-0 rounded"
            >
              Mentorat
            </p>
            <p
              class="mb-0 mt-2"
              :class="{
                'text-decoration-line-through': session.status === 'CANCELLED',
              }"
            >
              {{ formatDate(session.from) }} à
              {{ addMinutes(session.from, session.duration) }}
            </p>
          </div>
        </div>

        <hr class="my-4" />

        <template
          v-if="
            (session.status == 'WAIT_REVIEWS' &&
              $store.getters.userRole === 'ROLE_STUDENT') ||
            session.status != 'WAIT_REVIEWS'
          "
        >
          <p class="mb-0 fw-bold">Participants</p>
          <SessionStatusLarge
            :mentoringGroupId="session.mentoringGroupId"
            :sessionId="session.id"
            class="mt-4"
          >
          </SessionStatusLarge>
        </template>

        <template
          v-if="
            (session.status === 'WAIT_REVIEWS' ||
              session.status === 'WAIT_MENTOR_REVIEW') &&
            $store.getters.userRole === 'ROLE_MENTOR'
          "
        >
          <AttendanceConfirmation
            :mentoringGroupId="session.mentoringGroupId"
            :sessionId="session.id"
          />
        </template>
        <template
          v-if="
            (session.status === 'WAIT_REVIEWS' ||
              session.status === 'WAIT_MENTOR_REVIEW' ||
              session.status === 'FINISHED') &&
            $store.getters.userRole === 'ROLE_MENTOR'
          "
        >
          <MenteesNotation
            :mentoringGroupId="session.mentoringGroupId"
            :sessionId="session.id"
          />
        </template>

        <template v-if="session.status === 'PLANNED'">
          <hr class="my-4" />
          <template v-if="session.location != 'visio'">
            <p class="mb-4 fw-bold">Lieu de rendez-vous</p>
            <p class="d-flex align-items-center">
              <span class="material-symbols-outlined"> location_on </span>
              <span class="ms-2">{{ session.location }}</span>
            </p>
          </template>
          <template v-else>
            <p class="mb-4 fw-bold">Lien de la réunion</p>
            <p
              @click="openMeeting"
              class="meeting-link text-dark"
            >
            {{ meetingLink }}
          </p>
          </template>
        </template>

        <template
          v-if="
            (session.status === 'WAIT_REVIEWS' ||
              session.status === 'WAIT_MENTOR_REVIEW' ||
              session.status === 'FINISHED') &&
            $store.getters.userRole === 'ROLE_MENTOR'
          "
        >
          <SessionMentorReview :sessionId="session.id" />
        </template>

        <template
          v-if="
            (session.status === 'WAIT_REVIEWS' ||
              session.status === 'FINISHED' ||
              session.status === 'WAIT_STUDENT_REVIEW') &&
            $store.getters.userRole === 'ROLE_STUDENT'
          "
        >
          <SessionMenteeDeliverable :sessionId="session.id" />
        </template>
        <template
          v-if="
            (session.status === 'WAIT_REVIEWS' ||
              session.status === 'WAIT_STUDENT_REVIEW') &&
            $store.getters.userRole === 'ROLE_STUDENT'
          "
        >
          <SessionMenteeReview :sessionId="session.id" />
        </template>
      </div>
      <div class="d-flex justify-content-end mt-4 mx-4">
        <div>
          <button
            class="btn btn-primary"
            v-if="session.status === 'WAIT_CONFIRMATION'"
            @click="
              $store.dispatch('updateSessionStatus', {
                sessionId: session.id,
                status: 'PLANNED',
              })
            "
          >
            Accepter
          </button>
          <button
            class="btn btn-danger"
            v-if="session.status === 'CANCELLED' && isRoleMentee"
            @click="
              $store.dispatch('updateSessionStatus', {
                sessionId: session.id,
                status: 'PLANNED',
              })
            "
          >
            Révoquer l'annulation et accepter
          </button>
          <button
            class="btn btn-danger ms-2"
            v-if="
              session.status === 'PLANNED' ||
              session.status === 'WAIT_CONFIRMATION'
            "
            @click="
              $store.dispatch('updateSessionStatus', {
                sessionId: session.id,
                status: 'CANCELLED',
              })
            "
          >
            <template v-if="isRoleMentor"> Annuler </template>
            <template v-if="isRoleMentee"> Décliner </template>
          </button>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import moment from 'moment-timezone'
import { mapActions } from 'vuex'

import AttendanceConfirmation from '@/components/AttendanceConfirmation.vue'
import MenteesNotation from '@/components/MenteesNotation.vue'
import Modal from '@/components/Modal.vue'
import Page from '@/components/Page.vue'
import SessionStatusLarge from '@/components/SessionStatusLarge.vue'
import SessionMenteeDeliverable from '@/views/session/SessionMenteeDeliverable.vue'
import SessionMenteeReview from '@/views/session/SessionMenteeReview.vue'
import SessionMentorReview from '@/views/session/SessionMentorReview.vue'
import SessionStatusIcon from '@/views/session/SessionStatusIcon.vue'
export default {
  name: 'SessionDetailsPopup',
  data() {
    return {
      baseUrl: window.location.origin,
      session: null,
      sessionTitleSwitch: {
        PLANNED: 'Session planifiée',
        WAIT_CONFIRMATION: 'Session en attente de confirmation',
        WAIT_REVIEWS: 'Session à compléter',
        FINISHED: 'Session complétée',
        CANCELLED: 'Session annulée',
      },
    }
  },
  components: {
    Page,
    SessionStatusIcon,
    SessionMenteeReview,
    SessionMentorReview,
    SessionMenteeDeliverable,
    SessionStatusLarge,
    AttendanceConfirmation,
    MenteesNotation,
    Modal,
  },
  watch: {
    '$store.getters.selectedSessionId'() {
      const sessionId = this.$store.getters.selectedSessionId
      this.session = this.$store.getters.sessions.find((session) => session.id === sessionId)
    },
    '$store.getters.sessions'() {
      const sessionId = this.$store.getters.selectedSessionId
      this.session = this.$store.getters.sessions.find((session) => session.id === sessionId)
    },
  },
  computed: {
    meetingLink() {
      return `${this.baseUrl}/dashboard?meetingSessionId=${this.session.id}`
    },
    isRoleMentor() {
      return this.$store.getters.userRole === 'ROLE_MENTOR'
    },
    isRoleMentee() {
      return this.$store.getters.userRole === 'ROLE_STUDENT'
    },
  },
  methods: {
    ...mapActions(['setSelectedSessionId', 'switchModal']),

    openMeeting() {
      this.$router.push(`${window.location.pathname}?meetingSessionId=${this.session.id}`)
      this.switchModal({ name: 'session-details', show: false })
      this.onClose()
    },
    onClose() {
      this.setSelectedSessionId(null)
      this.session = null
    },
    formatDate(date) {
      return moment(date).locale('FR').format('D MMMM YYYY, HH:mm')
    },
    addMinutes(date, minutes) {
      return moment(date).add(minutes, 'minutes').format('HH:mm')
    },
  },
}
</script>

<style lang="scss" scoped>
.bg-light-gray {
  background-color: var(--color-ligth-gray);
}
.meeting-link {
  text-decoration: underline;
  cursor: pointer;
}
</style>
