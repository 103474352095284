// @ts-nocheck
// eslint-disable
const apiURL = import.meta.env.VITE_API_URL

export async function endpointMatchingCreateCriteria(payload, authToken) {
  const req = await fetch(`${apiURL}/v2/groups/matchmaking/criteria`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${authToken}`,
    },
    body: JSON.stringify(payload),
  })
  return await req.json()
}

export async function endpointMatchingMatchStudent(authToken) {
  const req = await fetch(`${apiURL}/v1/matching/match/student`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${authToken}`,
    },
    body: JSON.stringify(payload),
  })
  return await req.json()
}

export async function match(payload, authToken) {
  console.log('payload', payload)
  console.log('authToken', authToken)
  const req = await fetch(`${apiURL}/v2/groups/matchmaking/match`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${authToken}`,
    },
    body: JSON.stringify(payload),
  })
  if (!req.ok) {
    const result = await req.json()
    if (result.message === 'No mentor available') {
      throw new Error(result.message)
    }
    throw new Error('Error matching')
  }
  return await req.json()
}
