<!-- eslint-disable -->
<!-- @ts-nocheck -->
<template>
  <Page>
    <template v-slot:middle>
      <div class="mentors-page-container rounded bg-light p-4">
        <div class="info">
          <h1>Tous vos mentors</h1>
          <h2>Visualisez vos mentors inscrits sur la plateforme</h2>
        </div>
        <hr />
        <MentorsTable />
      </div>
    </template>
    <template v-slot:outside>
      <MentorProfilePopup />
    </template>
  </Page>
</template>

<script>
import Page from '@/components/Page.vue'
import MentorsTable from '@/components/admin/mentors/MentorsTable.vue'
import MentorProfilePopup from '@/components/admin/mentors/MentorProfilePopup.vue'

export default {
  name: 'AdminMentors',
  data() {
    return {}
  },
  components: {
    Page,
    MentorsTable,
    MentorProfilePopup,
  },
  computed: {},
  methods: {},
}
</script>

<style lang="scss">
.mentors-page-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.info {
  display: flex;
  flex-direction: column;
  color: var(--bs-dark);
  h1 {
    font-weight: 500;
    font-size: 1.25rem;
  }
  h2 {
    font-weight: 400;
    font-size: 1rem;
    opacity: 0.75;
  }
}
</style>
