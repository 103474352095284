<!-- eslint-disable -->
<!-- @ts-nocheck -->
<template>
  <PageOnboarding class="page-onboarding" pictureLeft="student-write.jpg">
    <template v-slot:right>
      <h1 class="fs-5 mb-4">
        Sélectionnez le rôle que vous souhaitez endosser
      </h1>
      <div class="d-flex">
        <p
          @click="SelectStudent()"
          :class="{
            'role-tile--selected bg-secondary text-primary fw-bold':
              studentSelected,
          }"
          class="role-tile text-center d-flex justify-content-center align-items-center flex-column rounded me-3"
          style="background-color: #f1f2f5"
        >
          <span class="material-symbols-outlined"> school </span>
          <span class="mt-2">Élève</span>
        </p>
        <p
          @click="SelectMentor()"
          :class="{
            'role-tile--selected bg-secondary text-primary fw-bold':
              mentorSelected,
          }"
          class="role-tile text-center p-4 rounded"
          style="background-color: #f1f2f5"
        >
          <span class="material-symbols-outlined">
            temp_preferences_custom
          </span>
          <br />
          Mentor
        </p>
      </div>
      <div class="w-100 d-flex justify-content-end">
        <button @click="sendForm()" class="btn btn-primary">Suivant</button>
      </div>
    </template>
  </PageOnboarding>
</template>

<script>
import PageOnboarding from '@/components/PageOnboarding.vue'

export default {
  name: 'RegisterRole',
  components: {
    PageOnboarding,
  },
  data() {
    return {
      studentSelected: false,
      mentorSelected: false,
    }
  },
  watch: {
    '$store.getters.userRole'(value) {
      if (value === 'ROLE_STUDENT') {
        this.studentSelected = true
        this.mentorSelected = false
      } else if (value === 'ROLE_MENTOR') {
        this.studentSelected = false
        this.mentorSelected = true
      }
    },
  },
  mounted() {
    if (this.$store.getters.userRole) {
      if (this.$store.getters.userRole === 'ROLE_STUDENT') {
        this.studentSelected = true
      } else if (this.$store.getters.userRole === 'ROLE_MENTOR') {
        this.mentorSelected = true
      }
    }
  },
  methods: {
    SelectStudent() {
      this.mentorSelected = false
      this.studentSelected = true
    },
    SelectMentor() {
      this.studentSelected = false
      this.mentorSelected = true
    },
    async sendForm() {
      let role
      if (this.mentorSelected) role = 'ROLE_MENTOR'
      else if (this.studentSelected) role = 'ROLE_STUDENT'
      await this.$store.dispatch('storeUpdateUserRole', { role })
      this.$store.dispatch('authUpdateUserRole', role)

      // full clean reload on onboarding finish
      window.location.href = '/'
    },
  },
}
</script>

<style lang="scss" scoped>
.role-tile {
  transition-duration: 200ms;
  width: 110px;
  height: 110px;
  cursor: pointer;
  &--selected {
    transform: scale(1.2);
  }
}
</style>
