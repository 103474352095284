<template>
  <div class="user-row" @click="openProfileDetails">
    <div class="user-row__info">
      <UserInfo :user="user" :sub-info="shownEmailRef" :is-sub-info-copyable="true" />
    </div>
    <div class="user-row__role">
      <span v-if="user.role" class="c-status-badge c-status-badge--black">
        {{ t(userRolesNamesMap[user.role]) }}
      </span>
    </div>
    <div class="user-row__group">
      <span v-if="user.role" :class="`c-status-badge c-status-badge--${user.hasGroup ? 'info' : 'danger'}`">
        {{ user.hasGroup ? t('admin.users.user.inAGroup') : t('admin.users.user.withoutGroup') }}
      </span>
    </div>
    <div
      class="user-row__actions"
      data-action="user-row"
      :data-attached="JSON.stringify({ user: user, program: programRef })"
    >
      <MoreOptionsIcon color="black" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import useProgram from '@/v1.5/features/programs/hooks/use-program.hook'
import { selectGetSelectedProgramId } from '@/v1.5/features/programs/stores/programs/programs.selectors'
import programStore from '@/v1.5/features/programs/stores/programs/programs.store'
import MoreOptionsIcon from '@/v1.5/features/ui/components/icons/more-options-icon.vue'
import type { UserProfileModalType } from '@/v1.5/features/ui/store/modal/modal.store'
import modalsStore from '@/v1.5/features/ui/store/modal/modal.store'
import UserInfo from '@/v1.5/features/users/components/user-info/user-info.vue'
import type { V2UserAdminDataType } from '@/v1.5/features/users/types'
import { userRolesNamesMap } from '@/v1.5/features/users/utils'
import { useI18n } from '@/v1.5/lib/i18n'

const { t } = useI18n()

const shownEmailRef = computed(() => props.user.email)
const selectedProgramIdRef = selectGetSelectedProgramId(programStore)
const { programRef } = useProgram(selectedProgramIdRef)

const props = defineProps<{
  user: V2UserAdminDataType
}>()

function openProfileDetails(e: Event) {
  // guard to not open the modal when clicking on the more options icon
  // guard to not open the modal when clicking on the sub info and this one is copyable
  if (
    (e.target as HTMLElement).closest('[data-action="user-row"]') ||
    (e.target as HTMLElement).closest('[data-sub-info-copyable="true"]')
  ) {
    return
  }

  modalsStore.toggleModal('user-profile', true)
  modalsStore.updateModalAttachedData<UserProfileModalType>('user-profile', { userId: props.user.id! })
}
</script>

<style lang="scss">
@import './user-row.scss';
</style>
