<template>
  <div :class="`switch-wrapper ${isCheckedRef ? 'checked' : ''} ${isDisabled ? 'disabled' : ''}`">
    <input type="checkbox" :checked="isCheckedRef" @click="onClick" />
    <div class="switch"></div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps<{
  checked: boolean
  isDisabled?: boolean
}>()
const isCheckedRef = computed(() => props.checked)

const emit = defineEmits(['emit:click'])

const onClick = (event: Event) => {
  const isChecked = (event.target as HTMLInputElement).checked
  // Emit the new value to the parent
  emit('emit:click', isChecked)
}
</script>

<style lang="scss">
@import './custom-switch.scss';
</style>
