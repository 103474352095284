import { nextTick } from 'vue'
import VueI18n from 'vue-i18n'
import type { I18n, UseI18nOptions } from 'vue-i18n-bridge'
import { castToVueI18n, createI18n, useI18n as useI18nDefault } from 'vue-i18n-bridge'

import { i18nInstance } from '@/main'
import type { TranslationsFileType } from '@/v1.5/locales'
import { I18N_DEFAULT_LOCALE, I18N_LOCAL_STORAGE_KEY } from '@/v1.5/utils/config/constants'

export const SUPPORT_LOCALES = ['en', 'fr']

export type SupportedLocalesType = (typeof SUPPORT_LOCALES)[number]
export type TranslationKeysType = keyof TranslationsFileType

export function setupI18n() {
  const i18n = castToVueI18n(
    createI18n({ locale: I18N_DEFAULT_LOCALE, fallbackLocale: 'fr', legacy: false, warnHtmlMessage: false }, VueI18n),
  )
  return i18n
}

// set i18n language
export function setI18nLanguage(i18n: I18n, locale: SupportedLocalesType) {
  if (i18n.mode === 'legacy') {
    i18n.global.locale = locale
  } else {
    i18n.global.locale.value = locale
  }
  document.querySelector('html')?.setAttribute('lang', locale)
  window.localStorage.setItem(I18N_LOCAL_STORAGE_KEY, locale)
}

export async function loadLocaleMessages(i18n: I18n, locale: SupportedLocalesType) {
  const localeToLoad = SUPPORT_LOCALES.includes(locale) ? locale : 'en'

  // load locale messages with dynamic import
  const messages = await import(`../locales/${localeToLoad}.ts`)

  // set locale and locale message
  i18n.global.setLocaleMessage(locale, messages.default)

  return nextTick()
}

export async function switchI18nLanguage(i18n: I18n, locale: SupportedLocalesType) {
  await loadLocaleMessages(i18n, locale)
  setI18nLanguage(i18n, locale)
}

interface I18nOptionsType extends UseI18nOptions {
  messages: Record<string, TranslationsFileType>
}
export function useI18n(options?: I18nOptionsType | undefined) {
  const I18nComposition = useI18nDefault<I18nOptionsType>(options)

  return { ...I18nComposition, i18n: i18nInstance as unknown as I18n }
}

export type I18nTType = ReturnType<typeof useI18n>['t']
