<!-- eslint-disable -->
<!-- @ts-nocheck -->
<template>
  <div style="height: 100%;">
    <div
      v-if="room"
      class="rounded bg-light d-flex flex-column justify-content-between chat"
    >
      <div class="modal-header">
        <span
          class="c-pointer material-symbols-outlined d-md-none"
          @click="closeChatRoom()"
        >
          west
        </span>

        <div class="c-pointer" @click="goToProfile()">
          <DiscussionName :room="room"></DiscussionName>
          <!-- <ProfilePicture
            :firstname="room.users[0].firstname"
            :lastname="room.users[0].lastname"
            :src="room.users[0].profilePicture"
            :width="40"
            :height="40"
          />

          <p class="fs-5 mb-0 ms-2 text-dark">
            {{ room.users[0].firstname }} {{ room.users[0].lastname }}
          </p> -->
        </div>
      </div>

      <!-- .chat-container -->
      <div ref="chat" class="chat-container p-3">
          <div
            v-for="(message, index) in room.messages"
            :key="index"
            class="row"
          >
            <div>
              <p class="form-text text-center my-4">
                {{ formatDate(message.sendAt) }}
              </p>

              <template v-if="message.content.includes('***session=')">
                <p class="text-center">
                  <template v-if="$store.getters.userRole === 'ROLE_MENTOR'">
                    🔥 Vous venez de planifier une session de mentorat. Vous
                    pouvez envoyer un message à vos mentorés pour donner plus de
                    précision sur votre accompagnement ou simplement leur dire
                    bonjour.
                  </template>
                  <template v-if="$store.getters.userRole === 'ROLE_STUDENT'">
                    ℹ️ Votre mentor vous propose une date de session de
                    mentorat. Vous pouvez l'accepter ou la décliner. Dans le cas
                    où vous la déclinez, arrangez-vous avec votre mentor pour
                    convenir d'une autre date.
                  </template>
                </p>
              </template>

              <!-- MESSAGE -->
              <div
                :class="{
                  'offset-2 justify-content-end':
                    $store.getters.authUserId == message.userId,
                }"
                class="col-10 d-flex align-items-end"
              >
                <template v-for="(user, index) in room.users">
                  <template v-if="user.id == message.userId">
                    <div class="mb-3 me-2">
                      <ProfilePicture
                        v-if="$store.getters.authUserId != message.userId"
                        :firstname="user.firstname"
                        :lastname="user.lastname"
                        :src="user.profilePicture"
                        :width="30"
                        :height="30"
                      />
                    </div>

                    <template v-if="message.content.includes('***session=')">
                      <DiscussionWidgetSession
                        :constelLight="
                          $store.getters.authUserId != message.userId
                        "
                        :sessionId="message.content.replace('***session=', '')"
                      />
                    </template>

                    <div v-else>
                      <!-- firstname + role badge -->
                      <p
                        v-if="$store.getters.authUserId != message.userId"
                        class="form-text d-flex align-items-center"
                      >
                        <span class="me-2">{{ user.firstname }}</span>

                        <template v-if="user.role == 'ROLE_STUDENT'">
                          <span class="badge bg-success"> Mentoré </span>
                        </template>
                        <template v-else>
                          <span class="badge bg-danger"> 👑 Mentor </span>
                        </template>
                      </p>
                      <!-- text message -->
                      <p
                        v-html="formatMessage(message.content)"
                        style="white-space: pre-wrap"
                        :class="{
                          'constel-light':
                            $store.getters.authUserId != message.userId,
                          'constel-secondary':
                            $store.getters.authUserId == message.userId,
                        }"
                        class="chat-content text-light p-2 d-inline-block rounded"
                      ></p>
                    </div>
                  </template>
                </template>
              </div>
            </div>
          </div>
          <div class="first-message"
          v-if="$store.getters.mentoringGroup?.some(mg => mg.conversationId === $route.params.roomId) && !room.messages.length">
            <span>
              Vous n'avez pas encore échangé avec votre groupe de mentorat.<br/>
              Envoyez un premier message pour vous présenter.
            </span>
        </div>
      </div>

      <form @submit.prevent="sendMessage" class="d-flex p-3">
        <div
          ref="input"
          @input="setMessage"
          class="form-control input"
          contenteditable="true"
          placeholder="Aa"
        ></div>

        <div class="d-flex align-items-end">
          <button type="submit" class="ms-2 btn btn-primary">Envoyer</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import linkifyHtml from 'linkify-html'
import moment from 'moment-timezone'
import { mapActions } from 'vuex'

import DiscussionName from './DiscussionName.vue'

import ProfilePicture from '@/components/ProfilePicture.vue'
import DiscussionWidgetSession from '@/views/discussion/DiscussionWidgetSession.vue'

export default {
  name: 'Discussion',
  components: {
    DiscussionWidgetSession,
    ProfilePicture,
    DiscussionName,
  },
  data() {
    return {
      message: '',
      options: { defaultProtocol: 'https' },
    }
  },
  watch: {
    'room'() {
      this.scollToBottom()
    },
    '$store.getters.chatScroll'() {
      this.scollToBottom()
    },
    '$route.params.roomId'() {
      const width = window.innerWidth
      if (width < 768 && this.$route.params.roomId) this.switchModal({ name: 'discussion', show: true })
    },
  },
  // beforeDestroy() {
  //   if (this.socket) {
  //     if (import.meta.env.VITE_ENV === 'development')
  //       console.log('🥲 socket disconnect')
  //     this.socket.disconnect()
  //   }
  // },
  mounted() {
    const width = window.innerWidth
    if (width < 768 && this.$route.params.roomId) this.switchModal({ name: 'discussion', show: true })
  },
  computed: {
    room() {
      return this.$store.getters.chatRooms.find((chatRoom) => chatRoom.id == this.$route.params.roomId)
    },
  },
  methods: {
    ...mapActions(['switchModal']),
    closeChatRoom() {
      this.switchModal({ name: 'discussion', show: false })
      // this.$router.push({ name: 'DiscussionIndex' })
    },
    goToProfile() {
      this.switchModal({ name: 'discussion', show: false })
      if (this.room.users.length > 2) {
        if (this.$store.getters.userRole === 'ROLE_STUDENT')
          return this.$router.push({
            name: 'IndexMentoringGroup',
          })
        return this.$router.push({
          name: 'IndexMentoringGroup',
        })
      }
      return this.$router.push(`/user/${this.room.users[0].id}`)
    },
    formatDate(date) {
      return moment(date).locale('fr').calendar()
    },
    setMessage(e) {
      return (this.message = e.target.innerText)
    },
    async sendMessage() {
      if (this.message.trim().length != 0) {
        this.$store
          .dispatch('createChatMessage', {
            conversationId: this.$route.params.roomId,
            message: this.message,
          })
          .then(() => {
            // await invalidateConversations(mentorshipgr.select)
          })
        this.message = ''
        this.$refs.input.innerText = ''
      }
    },
    scollToBottom() {
      this.$nextTick(() => {
        if (this.$refs.chat) {
          this.$refs.chat.scrollTop = this.$refs.chat.scrollHeight
        }
      })
    },
    formatMessage(message) {
      message = linkifyHtml(message, {
        defaultProtocol: 'https',
        attributes: {
          target: '_blank',
          rel: 'noopener',
        },
      })
      return message.trim()
    },
  },
}
</script>

<style lang="scss" scoped>
.input {
  max-height: 110px;
  overflow: hidden;
}

.input[placeholder]:empty:before {
  content: attr(placeholder);
  color: #555;
}

.chat {
  height: 100%;
  @media all and (max-width: 767px) {
    height: 100dvh;
    margin: 0 !important;
  }
}

.chat-container {
  position: relative;
  overflow: hidden;
  overflow-y: scroll;
  height: 100%;
}

textarea {
  resize: none;
}

.conversation-item__profil-picture {
  width: 45px;
  height: 45px;
  object-fit: cover;
  &--chat {
    min-width: 25px;
    min-height: 25px;
    max-width: 25px;
    max-height: 25px;
    object-fit: cover;
  }
}

.chat-content {
  overflow-wrap: break-word;
  max-width: 100%;
}

.constel-light {
  color: #212529 !important;
  background-color: rgba(var(--color-black), 0.1);
}

.constel-secondary {
  color: #212529 !important;
  background-color: rgba(var(--color-white-label-primary), 0.1);
}

.first-message {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  margin-top: 20px;
  background-color: rgba(var(--color-white-label-primary), 0.1);
  font-size: 16px;
  border-radius: 4px;
  color: rgb(var(--color-white-label-primary));
  font-weight: bold;
  padding: 16px 8px;
}
</style>
