<template>
  <section class="admin-users-content">
    <SearchBar
      :placeholder="t('admin.users.listing.searchPlaceholder')"
      :value="searchRef"
      @emit:change="onSearchChange"
    />
    <GridBlockBase id="sessions-listing">
      <template #icon>
        <UserIcon color="black" filled />
      </template>
      <template #body>
        <UsersTable :users="usersRef" :is-pending="isPendingRef" />
      </template>
      <template #headerExtra>
        <div class="filters-container">
          <CustomSelect
            id="user-role"
            :value="selectedRoleRef"
            :options="filerRoleOptionsRef"
            @emit:change="onFilterRole"
          />
          <CustomSelect
            id="user-has-group"
            :value="selectedHasGroupRef"
            :options="filterHasGroupOptionsRef"
            @emit:change="onFilterHasGroup"
          />
        </div>
        <CustomButton
          icon-position="right"
          color="primary"
          :text="t('admin.users.excelConfirmationModal.addUser')"
          usage="button"
          data-action="admin-users-import"
        >
          <template #icon>
            <DropdownIcon color="black" />
          </template>
        </CustomButton>
      </template>
      <template #footer>
        <div>
          <span class="table-sub-info">
            {{
              t('ui.pagination.resultsOfOn', {
                start: Math.min(Math.max(1, perPageRef * (pageRef - 1) + 1), totalUsersRef ?? 1),
                end: Math.min(perPageRef * pageRef, totalUsersRef ?? 1),
                total: totalUsersRef,
              })
            }}
          </span>
        </div>
        <div class="page-select-wrapper">
          <CustomSelect id="page-select" :value="`${pageRef}`" :options="pagesOptionsRef" @emit:change="onPageChange" />
          <span class="table-sub-info">{{ t('ui.pagination.totalPages', { total: totalPagesRef }) }}</span>
        </div>
      </template>
    </GridBlockBase>
  </section>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import useUsers from '@/v1.5/features/admin/users/hooks/use-users.hook'
import { RoleEnumType } from '@/v1.5/features/auth/types'
import CustomButton from '@/v1.5/features/ui/components/button/custom-button.vue'
import GridBlockBase from '@/v1.5/features/ui/components/grid-block-base/grid-block-base.vue'
import DropdownIcon from '@/v1.5/features/ui/components/icons/dropdown-icon.vue'
import UserIcon from '@/v1.5/features/ui/components/icons/user-icon.vue'
import SearchBar from '@/v1.5/features/ui/components/search-bar/search-bar.vue'
import CustomSelect from '@/v1.5/features/ui/components/select/custom-select.vue'
import UsersTable from '@/v1.5/features/ui/pages/admin/users/users-content/users-table/users-table.vue'
import { useI18n } from '@/v1.5/lib/i18n'

const { t } = useI18n()
const searchRef = ref<string | null>(null)
const pageRef = ref<number>(1)
const perPageRef = ref<number>(20)

// current selected role to filter
const selectedRoleRef = ref<RoleEnumType | 'ALL'>('ALL')

// on select change, fitler the users
function onFilterRole(value: RoleEnumType | 'ALL') {
  selectedRoleRef.value = value
}

const filerRoleOptionsRef = computed(
  () =>
    [
      { value: 'ALL', label: t('admin.users.listing.all') },
      { value: RoleEnumType.MENTOR, label: t('common.mentor.capitalize', 2) },
      { value: RoleEnumType.MENTEE, label: t('common.mentee.capitalize', 2) },
    ] satisfies { value: string; label: string }[],
)

// current selected has group to filter
const selectedHasGroupRef = ref<'ALL' | 'YES' | 'NO'>('ALL')

// on select change, fitler the users
function onFilterHasGroup(value: 'ALL' | 'YES' | 'NO') {
  selectedHasGroupRef.value = value
}

const filterHasGroupOptionsRef = computed(
  () =>
    [
      { value: 'ALL', label: t('admin.users.listing.withOrWithoutGroup') },
      { value: 'YES', label: t('admin.users.listing.withGroup') },
      { value: 'NO', label: t('admin.users.listing.withoutGroup') },
    ] satisfies { value: string; label: string }[],
)

const { usersRef, totalPagesRef, totalUsersRef, isPendingRef } = useUsers({
  pageRef,
  perPageRef,
  searchRef,
  selectedRoleRef,
  selectedHasGroupRef,
})

const pagesOptionsRef = computed(() => {
  return Array.from({ length: totalPagesRef.value ?? 0 }, (_, i) => ({
    label: `${t('ui.pagination.page')} ${i + 1}`,
    value: `${i + 1}`,
  })) satisfies { label: string; value: string }[]
})

function onSearchChange(value: string) {
  pageRef.value = 1
  searchRef.value = value
}

function onPageChange(value: number) {
  pageRef.value = value
}
</script>

<style lang="scss">
@import './users-content.scss';
</style>
