<template>
  <NewGlobalLayout />
</template>

<script>
import 'moment/dist/locale/fr'

import NewGlobalLayout from '@/v1.5/(routes)/global-layout.vue'

export default {
  components: {
    NewGlobalLayout,
  },
}
</script>

<style lang="scss">
@import url('https://use.fontawesome.com/releases/v5.15.3/css/all.css');
// @import 'bootstrap';
@import './assets/css/bootstrap.css';

.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

@media (max-width: 575px) {
  .container-fluid {
    padding: 0 !important;
  }
}

// #f5f5f5
:root {
  --color-ligth-gray: #f1f2f5;
}

$vt-color-success: red;
$vt-text-color-success: #000;

.container-fluid {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  width: calc(100% - 1.5rem);
}

body {
  // padding-top: 66px;
  background-color: #f1f2f5 !important;
}

.banner {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 80px;
  padding: 0 10px;
}

.logo {
  display: flex;
  align-items: center;
  & img {
    height: 40px;
    @media all and (max-width: 576px) {
      height: 55px;
    }
  }
  &_text {
    @media all and (max-width: 576px) {
      font-size: 0.7em;
    }
  }
}

.ais-Hits-list {
  list-style-type: none;
  padding: 0;
  // display: flex;
  // justify-content: space-between;
  // flex-wrap: wrap;
}

.container {
  max-width: 1160px;
}

.colorYellow {
  color: #ffc100;
}

.color-yellow {
  color: #ffc100;
}

.c-pointer {
  cursor: pointer;
}

#app {
  // font-family: 'Roboto', sans-serif;
  background-color: #fff;
}
.form-control,
.form-control:focus {
  background-color: #f7f7f7;
}

.card {
  border: none;
  transition-duration: 250ms;
}

.border-main {
  box-shadow: 0 0 35px 0 rgb(0 0 0 / 4%);
  transition-duration: 250ms;
  &--shadow {
    &:hover {
      box-shadow: 0 0 35px 0 rgb(0 0 0 / 20%);
    }
  }
}

.accordion h3 {
  font-size: 1em;
}

// supprime les erreurs sur la video de la bibli si jamais elle n'est pas presente
.vjs-modal-dialog-content,
.vjs-error-display {
  display: none !important;
}

// position for calendor in profil mentor page
.flatpickr-calendar {
  margin: 0 auto;
}

.d-desktop {
  @media all and (max-width: 576px) {
    display: none;
  }
}

.d-mobile {
  display: none;
  @media all and (max-width: 576px) {
    display: block;
  }
}

.constel-menu {
  &:hover {
    background-color: var(--color-ligth-gray);
    cursor: pointer;
  }
}

.brevo-conversations {
  visibility: visible !important;
  opacity: 1 !important;
}

@import './v1.5/styles/global.scss';

// .btn-light {
//   border-color: #212529;
// }
</style>
