<!-- eslint-disable -->
<!-- @ts-nocheck -->
<template>
  <div class="vh-100 vw-100 d-flex justify-content-center pt-4">
    <div class="d-flex flex-column align-items-center">
      <img :src="$store.getters.logo" alt="" />
      <p class="mb-0 mt-4 fs-5 fw-bold">
        Nous recherchons le mentor le plus adapté à votre profil.
      </p>
      <!-- <div class="spinner-border mt-4 text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div> -->
      <div style="width: 320px" class="mt-4">
        <div class="progress">
          <div
            class="progress-bar progress-bar-striped progress-bar-animated"
            role="progressbar"
            aria-label="Animated striped example"
            :aria-valuenow="percentageProgress"
            aria-valuemin="0"
            aria-valuemax="100"
            :style="`width: ${percentageProgress}%`"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MatchingLoading',
  data() {
    return {
      percentageProgress: 0,
    }
  },
  mounted() {
    this.$store.dispatch('storeMatching')
    this.progress()
  },
  methods: {
    progress() {
      this.percentageProgress += 1
      if (this.percentageProgress < 100) {
        setTimeout(this.progress, 20)
      } else {
        setTimeout(() => {
          this.$router.push({ name: 'IndexMentoringGroup' })
        }, 1000)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
img {
  width: 168px;
}
</style>
