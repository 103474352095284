<template>
  <Modal
    v-if="selectedProgramRef"
    :on-exit="() => modalsStore.toggleShouldHideMatchingModals(true)"
    :modal="menteeMatchingModalRef"
    :steps="
      selectedProgramRef?.settings.isMatchingEnabled
        ? [
            { component: programGoalReminder, id: 'goal-reminder' },
            { component: MatchingModalContent, id: 'fill-criteria' },
            { component: MenteeMatchingLoading, id: 'matching-loading' },
            { component: MenteeMatchingConfirmation, id: 'matching-confirmation' },
          ]
        : [{ component: ClosedMatchingContent, id: 'matching-no-groups' }]
    "
  />
</template>

<script setup lang="ts">
import { computed, watchEffect } from 'vue'

import MenteeMatchingConfirmation from './mentee-matching-confirmation/mentee-matching-confirmation.vue'
import MenteeMatchingLoading from './mentee-matching-loading/mentee-matching-loading.vue'

import useAccount from '@/v1.5/features/auth/hooks/use-account.hook'
import { RoleEnumType } from '@/v1.5/features/auth/types'
import useGroups from '@/v1.5/features/groups/hooks/use-groups.hook'
import ClosedMatchingContent from '@/v1.5/features/matching/components/matching-modals/no-groups-matching-content/no-groups-matching-content.vue'
import MatchingModalContent from '@/v1.5/features/matching/components/matching-modals/matching-modal-content/matching-modal-content.vue'
import programGoalReminder from '@/v1.5/features/matching/components/matching-modals/program-goal-reminder/program-goal-reminder.vue'
import useProgram from '@/v1.5/features/programs/hooks/use-program.hook'
import { selectGetSelectedProgramId } from '@/v1.5/features/programs/stores/programs/programs.selectors'
import programsStore from '@/v1.5/features/programs/stores/programs/programs.store'
import Modal from '@/v1.5/features/ui/components/modal/modal.vue'
import {
  selectGetMenteeMatchingModal,
  selectGetShouldHideMatchingModals,
} from '@/v1.5/features/ui/store/modal/modal.selectors'
import modalsStore from '@/v1.5/features/ui/store/modal/modal.store'

const { accountRef } = useAccount()
const roleRef = computed(() => accountRef.value?.role)
const menteeMatchingModalRef = selectGetMenteeMatchingModal(modalsStore)

const selectedProgrmaIdRef = selectGetSelectedProgramId(programsStore)

const { programRef: selectedProgramRef } = useProgram(selectedProgrmaIdRef)

const { groupsRef, isPendingRef: isGroupsPendingRef } = useGroups()

const shouldHideMatchingModalsRef = selectGetShouldHideMatchingModals(modalsStore)

// on mount, if it's a mentor, check if they have a mentorship group for the current program
// if the mentor doesn't have a mentorship group for the current program, show the mentor matching modal
watchEffect(() => {
  if (roleRef.value === RoleEnumType.MENTEE) {
    // if it's pending or the user manually select to hide matching modals, close the modal
    if (isGroupsPendingRef.value || shouldHideMatchingModalsRef.value || shouldHideMatchingModalsRef.value) {
      modalsStore.toggleModal('mentee-matching', false)
      return
    }

    if (!menteeMatchingModalRef.value.isOpen && groupsRef.value.length === 0) {
      modalsStore.toggleModal('mentee-matching', true)
    } else if (menteeMatchingModalRef.value.isOpen && groupsRef.value.length > 0) {
      modalsStore.toggleModal('mentee-matching', false)
    }
  }
})
</script>

<style lang="scss"></style>
