<!-- eslint-disable -->
<!-- @ts-nocheck -->
<template>
  <form @submit.prevent="sendForm">
    <h2 class="text-uppercase fs-5">{{ title }}</h2>
    <p class="">{{ description }}</p>
    <vue-tel-input
      :inputOptions="tel_options"
      :defaultCountry="country ? country : 'FR'"
      class="mt-4"
      v-model="input"
      @validate="validEvent"
    ></vue-tel-input>
    <div class="d-flex justify-content-end mt-4">
      <button type="submit" class="btn btn-primary" :disabled="loading">
        <span
          v-show="loading"
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
        <span v-show="loading" class="visually-hidden">Loading...</span>
        Enregistrer
      </button>
    </div>
  </form>
</template>

<script>
import { VueTelInput } from 'vue-tel-input'
import { apiPhoneAdd, updatePhone } from '@/services/PhoneService'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'SettingPhone',
  data() {
    return {
      input: null,
      phone: null,
      country: null,
      loading: false,
      tel_options: {
        placeholder: 'Téléphone',
      },
    }
  },
  components: {
    VueTelInput,
  },
  async mounted() {
    if (this.$store.getters.user.phone) {
      this.input = this.user.phone?.number
      this.country = this.user.phone?.country
    } else {
      const phone = await this.fetchUserPhone()
      this.input = phone?.number
      this.country = phone?.country
    }
  },
  computed: {
    ...mapGetters(['user']),
  },
  methods: {
    ...mapActions(['fetchUserPhone']),
    sendForm() {
      const regex = /^[ 0-9]*$/
      if (!regex.test(this.input)) {
        this.$toast.error('Le champ ne peut contenir que des chiffres.', {
          timeout: 2000,
        })
      } else {
        if (this.phone) {
          this.loading = true
          if (!this.user.phone) this.addPhone()
          else this.update()
        } else
          this.$toast.error('Numéro de téléphone non valide', {
            timeout: 2000,
          })
      }
    },
    addPhone() {
      apiPhoneAdd(this.phone, this.$store.getters.authToken)
        .then((result) => {
          this.$toast.success(result.message, {
            timeout: 2000,
          })
          this.callback()
        })
        .catch((error) =>
          this.$toast.error(error.message, {
            timeout: 2000,
          })
        )
        .finally(() => (this.loading = false))
    },
    update() {
      updatePhone(this.phone, this.$store.getters.authToken)
        .then((result) => {
          this.$toast.success(result.message, {
            timeout: 2000,
          })
          this.callback()
        })

        .catch((error) =>
          this.$toast.error(error.message, {
            timeout: 2000,
          })
        )
        .finally(
          () => ((this.loading = false), this.$store.dispatch('fetchUserPhone'))
        )
    },
    validEvent(result) {
      if (result.valid) this.phone = result.number
    },
  },
  props: {
    title: {
      type: String,
      default: 'Téléphone',
    },
    description: {
      type: String,
    },
    // callback function when the form is submitted
    callback: {
      type: Function,
      default: () => null,
    },
  },
}
</script>

<style src="vue-tel-input/dist/vue-tel-input.css"></style>
