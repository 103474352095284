<template>
  <GridBlockBase id="conversations-list" title="Conversations">
    <template #icon>
      <ConversationIcon color="black" filled />
    </template>
    <template #body>
      <LoadingContent v-if="isConversationsPendingRef && selectedGroupIdRef" />
      <EmptyContent
        v-else-if="(!isConversationsPendingRef && conversationsRef.length === 0) || !selectedGroupIdRef"
        :text="t('chat.conversationsList.empty')"
      >
        <template #icon>
          <ConversationIcon color="black" />
        </template>
      </EmptyContent>
      <div v-else class="conversations-list__content">
        <ConversationItem
          v-for="conversation in conversationsRef"
          :key="conversation.conversationId"
          :conversation="conversation"
        />
      </div>
    </template>
  </GridBlockBase>
</template>

<script setup lang="ts">
import ConversationItem from '@/v1.5/features/chat/components/conversations-list/conversation-item/conversation-item.vue'
import useConversations from '@/v1.5/features/chat/hooks/use-conversations.hook'
import { selectGetSelectedGroupId } from '@/v1.5/features/groups/stores/groups/groups.selectors'
import groupsStore from '@/v1.5/features/groups/stores/groups/groups.store'
import EmptyContent from '@/v1.5/features/ui/components/empty-content/empty-content.vue'
import GridBlockBase from '@/v1.5/features/ui/components/grid-block-base/grid-block-base.vue'
import ConversationIcon from '@/v1.5/features/ui/components/icons/conversation-icon.vue'
import LoadingContent from '@/v1.5/features/ui/components/loading-content/loading-content.vue'
import { useI18n } from '@/v1.5/lib/i18n'

const { t } = useI18n()

const { conversationsRef, isPendingRef: isConversationsPendingRef } = useConversations()
const selectedGroupIdRef = selectGetSelectedGroupId(groupsStore)
</script>

<style lang="scss">
@import './conversations-list.scss';
</style>
