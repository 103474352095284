<!-- eslint-disable -->
<!-- @ts-nocheck -->
<template>
  <div
    class="mt-4"
    v-if="
      (profile.MentorProfil &&
        profile.MentorProfil.visible &&
        profile.MentorBranchSubjects.length) ||
      profile.Identity.userId == $store.getters.authUserId
    "
  >
    <div class="bg-light p-3 rounded border-main">
      <div class="d-flex justify-content-between">
        <h2 class="text-uppercase fs-5 mb-4">Matières & notes</h2>
        <div
          v-if="profile.Identity.userId == $store.getters.authUserId"
          class="edit rounded-circle c-pointer"
          @click="switchModal({ name: 'MentorProfilSubject', show: true })"
        >
          <span class="material-symbols-outlined"> edit </span>
        </div>
      </div>
      <template v-if="profile.MentorBranchSubjects">
        <p
          v-for="(subject, index) in profile.MentorBranchSubjects"
          :key="index"
          class="mb-2"
        >
          <span class="badge bg-secondary fs-6">{{
            subject?.Subject?.name
          }}</span>
          <span v-if="subject?.grade" class="fst-italic">
            {{ subject?.grade }}</span
          >
        </p>
      </template>
      <div v-if="profile.Identity.userId == $store.getters.authUserId">
        <hr class="my-4" />
        <div class="d-flex justify-content-between">
          <h2 class="text-uppercase fs-5 mb-4">Disponibilités</h2>
          <div
            class="edit rounded-circle c-pointer"
            @click="switchModal({ name: 'MentorProfilSubject', show: true })"
          >
            <span class="material-symbols-outlined"> edit </span>
          </div>
        </div>
      </div>
      <template v-if="profile.MentorLesson">
        <div v-for="lesson in profile.MentorLesson.type" :key="lesson.id">
          <p v-if="lesson == 'Physique'" class="badge bg-secondary mb-2 fs-6">
            Présentiel
          </p>
          <p v-else class="badge bg-secondary mb-2 fs-6">Visioconférence</p>
          <span
            class="ms-2"
            v-if="lesson == 'Physique' && profile.MentorLesson.location"
          >
            <i class="fas fa-map-marker-alt"></i>
            {{ profile.MentorLesson.location }}</span
          >
        </div>
      </template>
    </div>
    <div @click="show" v-if="isUserProfil" class="update">
      <p class="text-center">
        <i class="fas fa-pencil-alt"></i> <br />
        Modifier
      </p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'MentorProfilSubject',
  data() {
    return {
      modalName: 'subjects',
      allSubjects: [],
      branchList: null,
      isPhysical: null,
      isOnline: null,
      subjects: null,
      lessons: null,
    }
  },
  props: {
    isUserProfil: Boolean,
  },
  computed: {
    ...mapGetters(['profile']),
  },
  methods: {
    ...mapActions(['switchModal']),
  },
}
</script>

<style lang="scss" scoped>
.main-container {
  position: relative;
  @media all and (max-width: 767px) {
    flex-direction: column;
    align-items: center;
  }
}

.update {
  display: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: calc(var(--bs-gutter-x) * 0.5);
  width: calc(100% - calc(var(--bs-gutter-x) * 0.5 * 2));
  background-color: rgba(26, 35, 127, 0.4);
  height: 100%;
  justify-content: center;
  align-items: center;
  color: #fff;
  transition-duration: 250ms;
}

.main-container:hover .update {
  display: flex;
  transition-duration: 250ms;
}

.edit {
  background-color: #f1f2f5;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  &-profil-picture {
    position: absolute;
    bottom: 5px;
    right: 5px;
  }
  &:hover {
    background-color: var(--color-secondary);
    color: var(--color-primary);
  }
}
</style>
