<!-- eslint-disable -->
<!-- @ts-nocheck -->
<template>
  <PageOnboarding class="page-onboarding" pictureLeft="student-write.jpg">
    <template v-slot:right>
      <form @submit.prevent="submitForm" class="register-form">
        <div v-show="backError" class="alert alert-danger" role="alert">
          {{ backError }}
        </div>

        <h1 class="fs-5 fw-normal text-center">Créer un compte</h1>
        <p class="text-center">
          Saisissez l'adresse e-mail et le mot de passe que vous souhaitez
          associer à votre compte.
        </p>

        <div>
          <label for="" class="form-label">E-mail</label>

          <div class="input-group has-validation">
            <input
              required
              class="form-control"
              type="email"
              placeholder="Saisissez votre adresse e-mail"
              autocomplete="email"
              v-model="email"
            />
          </div>
        </div>

        <div>
          <label for="password" class="form-label mt-3">Mot de passe</label>

          <div class="input-group has-validation mb-2">
            <input
              ref="password"
              :class="{ 'is-invalid': passwordError }"
              class="form-control"
              type="password"
              placeholder="Créez un mot de passe"
              v-model="password"
              autocomplete="new-password"
              required
              id="password"
              @input="passwordProgress"
            />

            <div class="invalid-feedback">{{ passwordError }}</div>
          </div>
          <div v-show="progress > 0" class="progress">
            <div
              class="progress-bar"
              :class="{
                'bg-danger': progress <= 50,
                'bg-warning': progress > 50 && progress <= 75,
                'bg-success': progress > 75 && progress <= 100,
              }"
              role="progressbar"
              :style="`width: ${progress}%`"
              :aria-valuenow="progress"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>

          <p v-show="progress > 0" class="mb-0" style="font-size: 0.8em">
            <span v-show="progress <= 50"
              >Renforçons ensemble ce mot de passe.</span
            >

            <span v-show="progress > 50 && progress < 100"
              >Nous pouvons encore l'améliorer.</span
            >

            <span v-show="progress == 100">Parfait !</span>
          </p>
        </div>
        <div class="form-check mt-3">
          <input
            class="form-check-input"
            type="checkbox"
            value=""
            id="condition"
            v-model="condition"
          />

          <label class="form-check-label small-text" for="condition">
            J'accepte les
            <a
              class="text-decoration-none"
              target="_blank"
              href="https://constel-divers.s3.fr-par.scw.cloud/Conditions_Generales_Constel_Education_24_aout_2021.pdf"
              >conditions générales du service</a
            >
            et la
            <a
              class="text-decoration-none"
              target="_blank"
              href="https://constel-divers.s3.fr-par.scw.cloud/Conditions_Generales_Constel_Education_24_aout_2021.pdf"
              >politique de confidentialité</a
            >.
          </label>
        </div>

        <div class="d-grid mt-4">
          <button :disabled="loading" type="submit" class="btn btn-primary">
            <span
              v-show="loading"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>

            <span class="ms-2">Créer mon compte</span>
          </button>
        </div>
        <div class="d-flex justify-content-between mt-3 text-sub-btn">
          <p class="form-text text-center w-100">
            Déjà inscrit ?<router-link
              class="text-decoration-none"
              :to="{ name: 'Login' }"
            >
              Connexion
            </router-link>
          </p>
        </div>
      </form>
    </template>
  </PageOnboarding>
</template>

<script>
import PageOnboarding from '@/components/PageOnboarding.vue'
import { SetLoginData } from '@/mixines/SetLoginData'
import { login, register } from '@/services/AuthService'

export default {
  name: 'Register',
  data() {
    return {
      email: '',
      password: null,
      passwordError: '',
      progress: 0,
      condition: true,
      backError: '',
      loading: false,
    }
  },
  mixins: [SetLoginData],
  components: {
    PageOnboarding,
  },

  methods: {
    passwordProgress() {
      this.progress = 0
      if (this.password.match('(?=.*[a-z])')) {
        this.progress += 25
      }
      if (this.password.match('(?=.*[A-Z])')) {
        this.progress += 25
      }
      if (this.password.match('(?=.*[0-9])')) {
        this.progress += 25
      }
      if (this.password.match('(?=.{8,})')) {
        this.progress += 25
      }
    },
    async submitForm() {
      this.loading = true
      const req = await register({
        email: this.email,
        password: this.password,
        cguAccepted: this.condition,
      })
      if (req.message) {
        const result = await login(this.email, this.password)
        localStorage.clear()
        localStorage.setItem('userId', result.user.id)
        localStorage.setItem('token', result.token)
        this.$store.dispatch('authUpdateToken', result.token)
        this.$store.dispatch('authUpdateUserId', result.user.id)
        this.$store.dispatch('authUpdateUserRole', result.user.role)

        // full clean reload on auth register
        window.location.href = '/'

        // await this.$store.dispatch('callAuthData')
        // return this.$router.push({ name: 'confirmAccountInformations' })
      } else this.backError = req.error
      this.loading = false
    },
  },
}
</script>

<style lang="scss" scoped>
.register-form {
  max-width: 400px;
  @media all and (max-width: 425px) {
    max-width: 300px;
  }
}
.progress {
  height: 5px !important;
}
strong {
  color: var(--color-primary);
}

.small-text {
  font-size: 0.9em;
}
</style>
