<!-- eslint-disable -->
<!-- @ts-nocheck -->
<template>
  <Page>
    <template v-slot:middle>
      <Chat />
    </template>
    <template v-slot:outside>
      <SessionDetailsPopup />
    </template>
  </Page>
</template>

<script>
import Page from '@/components/Page.vue'
import Chat from '@/v1.5/(routes)/(protected)/(app)/chat/page.vue'
import SessionDetailsPopup from '@/views/session/SessionDetailsPopup.vue'

export default {
  name: 'DiscussionIndex',
  components: {
    Chat,
    SessionDetailsPopup,
    Page,
  },
}
</script>

<style lang="scss" scoped></style>
