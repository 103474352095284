<!-- eslint-disable -->
<!-- @ts-nocheck -->
<template>
  <div class="onboarding">
    <div class="auth_container p-4 rounded">
      <img class="main_logo mb-4" :src="logo" alt="" />
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'PageOnboarding',
  computed: {
    ...mapGetters(['logo']),
  },
}
</script>

<style lang="scss" scoped>
.onboarding {
  background-color: #f1f2f5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.auth_container {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main_logo {
  max-width: 180px;
}
</style>
