<template>
  <Popover :popover="periodsPopoverRef">
    <template #content>
      <PopoverRow
        v-for="period in programRef?.periods || []"
        :key="period.id"
        :is-active="selectedPeriodIdRef === period.id"
        @emit:click="() => handleSwitchPeriod(period.id)"
      >
        <template #content>
          <CheckFullIcon color="primary" :is-checked="selectedPeriodIdRef === period.id" />
          <p>{{ getCondensedTimeRange(period.startAt, period.endAt) }}</p>
        </template>
      </PopoverRow>
    </template>
  </Popover>
</template>

<script setup lang="ts">
import useProgram from '@/v1.5/features/programs/hooks/use-program.hook'
import {
  selectGetSelectedPeriodId,
  selectGetSelectedProgramId,
} from '@/v1.5/features/programs/stores/programs/programs.selectors'
import programsStore from '@/v1.5/features/programs/stores/programs/programs.store'
import CheckFullIcon from '@/v1.5/features/ui/components/icons/check-full-icon.vue'
import Popover from '@/v1.5/features/ui/components/popover/popover.vue'
import PopoverRow from '@/v1.5/features/ui/components/popover/popover-row/popover-row.vue'
import { selectGetPeriodsPopover } from '@/v1.5/features/ui/store/popover/popover.selectors'
import popoversStore from '@/v1.5/features/ui/store/popover/popover.store'
import { getCondensedTimeRange } from '@/v1.5/utils/dates'

const selectedProgramIdRef = selectGetSelectedProgramId(programsStore)
const selectedPeriodIdRef = selectGetSelectedPeriodId(programsStore)
const { programRef } = useProgram(selectedProgramIdRef)

const periodsPopoverRef = selectGetPeriodsPopover(popoversStore)

const handleSwitchPeriod = (periodId: string) => {
  programsStore.setSelectedPeriodId(periodId)
  popoversStore.togglePopover('periods', false)
}
</script>

<style lang="scss">
@import './periods-popover.scss';
</style>
