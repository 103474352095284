<template>
  <div v-if="accountRef?.role === RoleEnumType.MENTEE && nextSessionRef" class="mentee-join-session">
    <div class="mentee-join-session__content">
      <div class="mentee-join-session__content__header">
        <div class="join-session-header-date">
          <span>{{ extractShortDay(nextSessionRef.from) }}</span>
          <span>{{ extractDayNumber(nextSessionRef.from) }}</span>
        </div>
        <p class="join-session-header-title">Prochaine session planifiée</p>
        <!-- <div
          class="join-session-header-more-options"
          data-action="session-card"
          :data-attached="JSON.stringify(nextSessionRef)"
        >
          <MoreOptionsIcon color="black" />
        </div> -->
      </div>
      <div class="mentee-join-session__content__info">
        <div class="join-session-info-countdown">{{ relativeTimeToSessionStartRef }}</div>
        <div class="join-session-info-schedule">{{ getDurationRange(nextSessionRef.from, nextSessionRef.to) }}</div>
      </div>
      <div class="mentee-join-session__content__people">
        <div class="people-mentor" v-if="mentorRef">
          <UserBadge :user="mentorRef" />
        </div>
        <div class="people-mentees">
          <UsersBadgesStack :users="menteesRef" />
        </div>
      </div>
    </div>
    <div class="mentee-join-session__footer" v-if="isSessionPlannedNextHoursRef">
      <CustomButton usage="button" text="Rejoindre la session" color="primary" @emit:click="joinSession">
        <template #icon>
          <CameraIcon color="white" />
        </template>
      </CustomButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watchEffect } from 'vue'

import useAccount from '@/v1.5/features/auth/hooks/use-account.hook'
import { RoleEnumType } from '@/v1.5/features/auth/types'
import useGroup from '@/v1.5/features/groups/hooks/use-group.hook'
import { selectGetSelectedGroupId } from '@/v1.5/features/groups/stores/groups/groups.selectors'
import groupsStore from '@/v1.5/features/groups/stores/groups/groups.store'
import { getMentees, getMentor } from '@/v1.5/features/groups/utils'
import { getMeetingLink } from '@/v1.5/features/meetings/utils'
import UserBadge from '@/v1.5/features/sessions/components/user-badge/user-badge.vue'
import UsersBadgesStack from '@/v1.5/features/sessions/components/users-badges-stack/users-badges-stack.vue'
import useSessions from '@/v1.5/features/sessions/hooks/use-sessions.hook'
import { getPlannedSessions, sortSessionsByDate } from '@/v1.5/features/sessions/utils'
import CustomButton from '@/v1.5/features/ui/components/button/custom-button.vue'
import CameraIcon from '@/v1.5/features/ui/components/icons/camera-icon.vue'
import useRouter from '@/v1.5/hooks/use-router.hook'
import dayjsClient from '@/v1.5/lib/dayjs'
import { extractDayNumber, extractShortDay, getDurationRange, getRelativeTimeToDate } from '@/v1.5/utils/dates'

const router = useRouter()
const { accountRef } = useAccount()
const selectedGroupIdRef = selectGetSelectedGroupId(groupsStore)
const { groupRef } = useGroup(selectedGroupIdRef)

const { sessionsRef } = useSessions()
const plannedSessionsRef = computed(() => sessionsRef.value.filter(getPlannedSessions))

const nextSessionRef = computed(() => {
  const sessions = plannedSessionsRef.value
  return sessions.sort(sortSessionsByDate)[0]
})

const mentorRef = computed(() => nextSessionRef.value?.users.find(getMentor))
const menteesRef = computed(() => nextSessionRef.value?.users.filter(getMentees))

const relativeTimeToSessionStartRef = ref(getRelativeTimeToDate(nextSessionRef.value?.from))
const isSessionPlannedNextHoursRef = ref(false)

function getSessionTime() {
  relativeTimeToSessionStartRef.value = getRelativeTimeToDate(nextSessionRef.value?.from)

  // check if the session is planned in the next 2 hours (show the join session button)
  const now = dayjsClient()
  const sessionStart = dayjsClient(nextSessionRef.value?.from)
  const diff = sessionStart.diff(now, 'hours')
  isSessionPlannedNextHoursRef.value = diff <= 2
}

watchEffect(() => {
  if (!nextSessionRef.value?.from) return
  getSessionTime()
  const interval = setInterval(getSessionTime, 1000 * 60) // every 1 minutes
  return () => clearInterval(interval)
})

function joinSession() {
  window.location.href = getMeetingLink(nextSessionRef.value)
}
</script>

<style lang="scss">
@import './mentee-join-session.scss';
</style>
