<!-- eslint-disable -->
<!-- @ts-nocheck -->
<template>
  <div ref="popup" class="popup">
    <div @click="close" class="cover"></div>
    <div
      class="popup__body"
      :class="{
        'popup__body--xl': size == 'xl' ? true : false,
        'popup__body--md': size == 'md' ? true : false,
      }"
    >
      <div
        v-if="hasDefaultHeader"
        class="popup-header d-flex justify-content-center p-4 bg-light"
      >
        <span class="c-pointer popup-header-close" @click="close">
          <span class="material-symbols-outlined mobile-only">
            arrow_back
          </span>
          <span class="material-symbols-outlined desktop-only"> close </span>
        </span>
        <p class="popup-header__title mb-0 fw-bold">
          <span>{{ title }}</span>
        </p>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'modal',
  data() {
    return {
      animateTimeout: null,
    }
  },
  watch: {
    'modal.name'(val) {
      if (this.modal.name == this.name && val) this.show()
      if (this.modal.name == this.name && !val) this.close()
    },
    'modal.show'(val) {
      if (this.modal.name == this.name && val) this.show()
      if (this.modal.name == this.name && !val) this.close()
    },
  },
  props: {
    name: {
      type: String,
    },
    hasDefaultHeader: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'md',
    },
    onCloseFn: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapState(['modal']),
  },
  methods: {
    ...mapActions(['switchModal']),
    backgroundClick() {
      this.switchModal({ name: this.name, show: false })
      this.onCloseFn()
    },
    show() {
      clearTimeout(this.animateTimeout)
      // add the animate class for the CSS animation to play
      this.$refs.popup.classList.add('popup--animate')
      this.$refs.popup.classList.add('popup--active')
      // on desktop we disable scroll when on modal
      if (window.innerWidth > 1024) {
        document.body.style.overflow = 'hidden'
      }
    },
    close() {
      // animate out the modal
      this.$refs.popup.classList.remove('popup--animate')

      this.animateTimeout = setTimeout(() => {
        // after CSS animation is done, remove the active class
        this.$refs.popup.classList.remove('popup--active')
        // on desktop we re-enable scroll when on modal
        if (window.innerWidth > 1024) {
          document.body.style.overflow = 'auto'
        }
        // trigger close fns
        this.switchModal({ name: this.name, show: false })
        this.onCloseFn()
      }, 200)
    },
    // close the modal when the user presses the ESC key
    listenForESC(e) {
      if (
        e.key === 'Escape' &&
        this.$refs.popup.classList.contains('popup--active')
      ) {
        this.close()
      }
    },
  },

  mounted() {
    document.addEventListener('keydown', this.listenForESC)
  },
  destroyed() {
    document.removeEventListener('keydown', this.listenForESC)
    if (window.innerWidth > 1024) {
      document.body.style.overflow = 'auto'
    }
  },
}
</script>

<style lang="scss" scoped>
.popup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100svh;
  z-index: 8000;
  opacity: 0;
  pointer-events: none;
  justify-content: center;
  overflow: hidden;

  .cover {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 0;
    opacity: 0;
    transition: all 0.2s ease-in-out;
  }

  &__body {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    background-color: #fff;
    overflow: hidden;
    overflow-y: scroll;
    width: clamp(500px, 50vw, 1000px);
    transition: all 0.2s ease-in-out;
    transform: translateX(105%);
    z-index: 1;
    @media screen and (max-width: 769px) {
      width: 100%;
    }
    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      background-color: #abb5be;
      border-radius: 0.25rem;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--color-primary);
      border-radius: 0.25rem;
    }
    &--md {
    }
    &--xl {
    }
    .popup-header {
      position: sticky;
      top: 0;
      left: 0;
      z-index: 10;
      &-close {
        position: absolute;
        top: 0;
        left: 0;
        padding: 24px;

        .mobile-only {
          display: none;
          @media screen and (max-width: 769px) {
            display: inline;
          }
        }
        .desktop-only {
          display: inline;
          @media screen and (max-width: 769px) {
            display: none;
          }
        }
      }
      &__title {
        text-transform: uppercase;
      }
    }
  }
}

.popup--active {
  opacity: 1;
  pointer-events: all;
}

.popup--animate {
  .popup__body {
    transform: translateX(0);
  }
  .cover {
    opacity: 1;
  }
}
</style>
