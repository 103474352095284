<!-- eslint-disable -->
<!-- @ts-nocheck -->
<template>
  <form @submit.prevent="sendingForm">
    <h2 class="text-uppercase fs-5">Identité</h2>

    <div class="row">
      <div class="form-group col-lg-6">
        <label for="firstname">Prénom</label>
        <input
          type="text"
          class="form-control"
          id="firstname"
          v-model="form.firstname"
          placeholder="Prénom"
        />
      </div>
      <div class="form-group col-lg-6">
        <label for="lastname">Nom</label>
        <input
          type="text"
          class="form-control"
          id="lastname"
          v-model="form.lastname"
          placeholder="Nom"
        />
      </div>
    </div>

    <div class="d-flex justseify-content-end mt-4">
      <button type="submit" class="btn btn-primary">
        <span
          v-show="loading"
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
        <span v-show="loading" class="visually-hidden">Loading...</span>
        Enregistrer
      </button>
    </div>
  </form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { updateIdentity, createIdentity } from '@/services/userService'

export default {
  name: 'Identity',
  data() {
    return {
      form: {},
      loading: false,
    }
  },
  async mounted() {
    if (!this.user.identity) await this.fetchUserIdentity()
    if (this.user.identity) {
      this.form = this.user.identity
      delete this.form.id
    }
  },
  computed: {
    ...mapGetters(['user']),
  },
  methods: {
    ...mapActions(['fetchUserIdentity']),
    sendingForm() {
      this.loading = true

      // Vide l'objet form de toutes les données null avant envoi du formulaire
      Object.keys(this.form).forEach((key) => {
        if (this.form[key] === null) {
          delete this.form[key]
        }
      })
      if (!this.user.identity) this.create()
      else this.update()
    },
    create() {
      createIdentity(
        { ...this.form },
        this.$store.getters.authToken,
        this.$store.getters.authUserId
      )
        .then(async (result) => {
          await this.fetchUserIdentity()
          this.$toast.success(result.message)
        })
        .catch((error) => {
          this.$toast.error(error.message, {
            timeout: 2000,
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    update() {
      updateIdentity(
        { ...this.form },
        this.$store.getters.authToken,
        this.$store.getters.authUserId
      )
        .then(async (result) => {
          await this.fetchUserIdentity()
          this.$toast.success(result.message)
        }, this.$store.getters.authToken)
        .catch((error) => {
          this.$toast.error(error.message, {
            timeout: 2000,
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped></style>
