<template>
  <Modal :modal="groupSettingsModalRef" :steps="[{ component: GroupSettingsModalContent, id: 'group-settings' }]" />
</template>

<script lang="ts" setup>
import GroupSettingsModalContent from '@/v1.5/features/groups/components/group-settings-modal/group-settings-modal-content/group-settings-modal-content.vue'
import Modal from '@/v1.5/features/ui/components/modal/modal.vue'
import { selectGetGroupSettingsModal } from '@/v1.5/features/ui/store/modal/modal.selectors'
import modalsStore from '@/v1.5/features/ui/store/modal/modal.store'

const groupSettingsModalRef = selectGetGroupSettingsModal(modalsStore)
</script>
