<template>
  <ModalStep
    id="matching-no-groups"
    :header="{
      title: `${t('matching.noGroupYet.title')}`,
    }"
  >
    <template #headerBeforeElement>
      <ProgramsDropdown />
    </template>

    <template #bodyInformation>
      <strong>
        {{ t('matching.noGroupYet.body') }}
      </strong>
    </template>

    <template #footer>
      <div class="c-btns-container c-btn-center">
        <CustomButton usage="button" color="white" :text="t('ui.button.close')" @emit:click="handleClick" />
      </div>
    </template>
  </ModalStep>
</template>

<script setup lang="ts">
import ProgramsDropdown from '@/v1.5/features/programs/components/programs-dropdown/programs-dropdown.vue'
import CustomButton from '@/v1.5/features/ui/components/button/custom-button.vue'
import ModalStep from '@/v1.5/features/ui/components/modal/modal-step/modal-step.vue'
import modalsStore from '@/v1.5/features/ui/store/modal/modal.store'
import { useI18n } from '@/v1.5/lib/i18n'

const { t } = useI18n()
function handleClick() {
  modalsStore.toggleShouldHideMatchingModals(true)
}
</script>
