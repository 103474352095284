<!-- eslint-disable -->
<!-- @ts-nocheck -->
<template>
  <div>
    <span
      v-if="userSessionStatus === 'WAIT_CONFIRMATION'"
      class="badge bg-secondary"
    >
      En attente
    </span>
    <span
      v-else-if="userSessionStatus === 'WAIT_REVIEWS'"
      class="badge bg-secondary"
    >
      En attente d'avis
    </span>
    <span v-else-if="userSessionStatus === 'PLANNED'" class="badge bg-success">
      Participe
    </span>
    <span v-else-if="userSessionStatus === 'CANCELLED'" class="badge bg-danger">
      Annulé
    </span>
    <span v-else-if="userSessionStatus === 'FINISHED'" class="badge bg-success">
      Terminé
    </span>
  </div>
</template>

<script>
export default {
  name: 'SessionStatusBadge',
  data() {
    return {
      userSessionStatus: null,
    }
  },
  mounted() {
    if (this.session) {
      this.extractUserStatus()
    }
  },
  methods: {
    extractUserStatus() {
      for (let i = 0; i < this.session.users.length; i++) {
        if (this.session.users[i].id === this.user.id)
          this.userSessionStatus = this.session.users[i].sessionStatus
      }
    },
  },
  props: {
    session: {
      type: Object,
      default: null,
    },
    user: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped></style>
