<!-- eslint-disable -->
<!-- @ts-nocheck -->
<template>
  <div
    class="col-lg-12 text-light p-3 rounded mb-4"
    :class="{ 'bg-danger': !visible, 'bg-success': visible }"
  >
    <div class="form-check form-switch">
      <input
        v-model="visible"
        @change="sendSwitch"
        class="form-check-input"
        type="checkbox"
        id="flexSwitchCheckDefault"
      />
      <label class="form-check-label" for="flexSwitchCheckDefault">{{
        visible ? 'Profil Visible' : 'Profil invisible'
      }}</label>
    </div>
  </div>
</template>

<script>
import { switchVisible } from '@/services/MentorService.js'

import { mapGetters } from 'vuex'

export default {
  name: 'Visibility',
  data() {
    return {
      visible: false,
    }
  },
  computed: {
    ...mapGetters(['profile']),
  },
  mounted() {
    this.visible = this.profile.MentorProfil.visible
  },
  methods: {
    async sendSwitch() {
      const result = await switchVisible(
        this.visible,
        this.$store.getters.authToken
      )
      if (result.message == 'Mentor désactivé.') {
        this.profile.MentorProfil.visible = false
      }
      if (result.message == 'Mentor approuvé.') {
        this.profile.MentorProfil.visible = true
      }
      if (result.error) {
        this.visible = false
        this.$toast.error(result.error, {
          timeout: 2000,
        })
      } else if (result.message != 'Mentor désactivé.') {
        this.$toast.success('Profil visible !', {
          timeout: 2000,
        })
      } else {
        this.$toast.error('Profil invisible', {
          timeout: 2000,
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
