import router from '@/router'
import v1Store from '@/store'
import groupsStore from '@/v1.5/features/groups/stores/groups/groups.store'
import programsStore from '@/v1.5/features/programs/stores/programs/programs.store'
import { PosthogClient } from '@/v1.5/lib/posthog'
import queryClient from '@/v1.5/lib/vue-query'
import { ROUTES } from '@/v1.5/utils/config/constants'

export default async function logout() {
  console.log('logout')
  v1Store.dispatch('storeAuthLogout')
  programsStore.reset()
  groupsStore.reset()
  await queryClient.cancelQueries()
  await queryClient.clear()
  window.localStorage.clear()
  PosthogClient.reset() // https://posthog.com/docs/product-analytics/identify#3-reset-after-logout
  router.push(ROUTES.LOGIN).catch(() => {})
}
