<!-- eslint-disable -->
<!-- @ts-nocheck -->
<template>
  <div class="d-flex align-items-center">
    <div :class="session ? 'profile-picture_session-status-badge' : ''">
      <ProfilePicture
        class="profile-picture"
        :src="user.profilePicture"
        :firstname="user.firstname"
        :lastname="user.lastname"
        :width="40"
        :height="40"
      />
      <SessionStatusBadge
        v-if="session"
        class="session-status-badge"
        :user="user"
        :session="session"
      />
    </div>
    <div class="ms-2">
      <router-link :to="{ name: 'IndexProfile', params: { id: user.id } }">
        {{ user.firstname }} {{ user.lastname }}
      </router-link>
      <p v-if="user.role === 'ROLE_MENTOR'" class="mb-0">Mentor</p>
      <p v-else-if="user.role === 'ROLE_STUDENT'" class="mb-0">Mentoré</p>
    </div>
  </div>
</template>

<script>
import ProfilePicture from '@/components/ProfilePicture.vue'
import SessionStatusBadge from '@/components/SessionStatusBadge.vue'

export default {
  name: 'SessionStatusLargeProfile',
  components: {
    ProfilePicture,
    SessionStatusBadge,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    session: {
      type: Object,
      default: null,
    },
  },
}
</script>

<style lang="scss" scoped>
.profile-picture_session-status-badge {
  position: relative;
  width: 100px;
  height: 3.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-picture {
  display: flex;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  z-index: 0;
}

.session-status-badge {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
</style>
