<template>
  <div class="program-reminder group-reminder">
    <GroupIcon color="black" filled />
    <span>{{ groupRef?.name }}</span>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import useGroup from '@/v1.5/features/groups/hooks/use-group.hook'
import GroupIcon from '@/v1.5/features/ui/components/icons/group-icon.vue'

const props = defineProps<{
  groupId: string | null
}>()

const groupIdRef = computed(() => props.groupId)
const { groupRef } = useGroup(groupIdRef)
</script>
