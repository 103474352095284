<template>
  <InnerContent>
    <div class="chat-content">
      <ConversationsList />
      <MessagesBlock />
    </div>
  </InnerContent>
</template>

<script setup lang="ts">
import { watch } from 'vue'

import ConversationsList from '@/v1.5/features/chat/components/conversations-list/conversations-list.vue'
import MessagesBlock from '@/v1.5/features/chat/components/messages-block/messages-block.vue'
import { selectGetSelectedGroupId } from '@/v1.5/features/groups/stores/groups/groups.selectors'
import groupsStore from '@/v1.5/features/groups/stores/groups/groups.store'
import { selectGetSelectedProgramId } from '@/v1.5/features/programs/stores/programs/programs.selectors'
import programsStore from '@/v1.5/features/programs/stores/programs/programs.store'
import InnerContent from '@/v1.5/features/ui/components/inner-content/inner-content.vue'
import useRouter from '@/v1.5/hooks/use-router.hook'
const selectedGroupIdRef = selectGetSelectedGroupId(groupsStore)
const selectedProgramIdRef = selectGetSelectedProgramId(programsStore)
const router = useRouter()

// when we switch from a mentorship group to another (or program), we redirect the user to the chat page
watch([selectedGroupIdRef, selectedProgramIdRef], ([selectedGroupId, selectedProgramId]) => {
  if (selectedGroupId || selectedProgramId) {
    router?.replace(`/chat/`).catch(() => {})
  }
})
</script>

<style lang="scss">
@import './chat-content.scss';
</style>
