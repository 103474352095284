<template>
  <div class="vue-more-actions-wrapper">
    <div class="more-actions-menu" :style="{ visibility: filteredActionsRef?.length ? 'visible' : 'hidden' }">
      <ul class="more-actions-menu__list">
        <li
          v-for="(action, index) in filteredActionsRef"
          :key="index"
          :class="`action-item ${action.isDanger ? 'danger' : ''}`"
          @click="handleClickAction(action)"
        >
          <component :is="returnIconComponent(action.icon)" color="black" filled></component>
          <span>{{ t(action.label) }}</span>
        </li>
      </ul>
    </div>
    <div class="more-actions-menu-backdrop"></div>
  </div>
</template>

<script setup lang="ts">
import type { Component } from 'vue'
import { computed } from 'vue'

import useAccount from '@/v1.5/features/auth/hooks/use-account.hook'
import type { V2SessionType } from '@/v1.5/features/sessions/types'
import AddFileIcon from '@/v1.5/features/ui/components/icons/add-file-icon.vue'
import CalendarIcon from '@/v1.5/features/ui/components/icons/calendar-icon.vue'
import CancelIcon from '@/v1.5/features/ui/components/icons/cancel-icon.vue'
import ChangeIcon from '@/v1.5/features/ui/components/icons/change-icon.vue'
import ConversationIcon from '@/v1.5/features/ui/components/icons/conversation-icon.vue'
import CopyIcon from '@/v1.5/features/ui/components/icons/copy-icon.vue'
import DownloadIcon from '@/v1.5/features/ui/components/icons/download-icon.vue'
import EditIcon from '@/v1.5/features/ui/components/icons/edit-icon.vue'
import EmailIcon from '@/v1.5/features/ui/components/icons/email-icon.vue'
import LogoutIcon from '@/v1.5/features/ui/components/icons/logout-icon.vue'
import SettingsIcon from '@/v1.5/features/ui/components/icons/settings-icon.vue'
import ShowIcon from '@/v1.5/features/ui/components/icons/show-icon.vue'
import useMoreActionsMenu from '@/v1.5/features/ui/components/more-actions-menu/hooks/use-more-actions-menu.hook'
import type { ActionType } from '@/v1.5/features/ui/components/more-actions-menu/store/more-actions.store.ts'
import type { ConfirmActionModalType } from '@/v1.5/features/ui/store/modal/modal.store'
import modalsStore from '@/v1.5/features/ui/store/modal/modal.store'
import useToast from '@/v1.5/hooks/use-toasts.hook'
import { useI18n } from '@/v1.5/lib/i18n'

const { moreActionsRef } = useMoreActionsMenu()
const { accountRef } = useAccount()
const toast = useToast()
const { t } = useI18n()

function handleClickAction(action: ActionType) {
  const moreActions = moreActionsRef.value
  const toastValue = toast

  if (moreActions && toastValue) {
    // if action is dangerous, show confirm modal

    if (action.isDanger) {
      modalsStore.updateModalAttachedData<ConfirmActionModalType>('confirm-action', {
        fn: () => {
          action.fn({ attachedData: moreActions.attachedData, toast: toastValue, t })
        },
        title: action.confirmLabel,
        content: action.confirmContent,
      })
      modalsStore.toggleModal('confirm-action', true)

      // else just call the action
    } else {
      action.fn({ attachedData: moreActions.attachedData, toast: toastValue, t })
    }
  }
}

// filter actions based on restrictions
const filteredActionsRef = computed(() => {
  return moreActionsRef.value?.actions.filter((action) => {
    // if no restrictions, allow action
    // if role restriction, check if account role is in the list
    // if session status restriction, check if session status is in the list
    if (
      action.restrictions?.roles &&
      accountRef.value?.role &&
      !action.restrictions.roles.includes(accountRef.value.role)
    ) {
      return false
    }
    if (
      action.restrictions?.sessionsStatus &&
      (moreActionsRef.value?.attachedData as V2SessionType | undefined)?.status &&
      !action.restrictions.sessionsStatus.includes((moreActionsRef.value?.attachedData as V2SessionType).status)
    ) {
      return false
    }
    return true
  })
})

function returnIconComponent(icon: ActionType['icon']): Component {
  switch (icon) {
    case 'logout':
      return LogoutIcon
    case 'settings':
      return SettingsIcon
    case 'cancel':
      return CancelIcon
    case 'edit':
      return EditIcon
    case 'copy':
      return CopyIcon
    case 'show':
      return ShowIcon
    case 'conversation':
      return ConversationIcon
    case 'download':
      return DownloadIcon
    case 'add-file':
      return AddFileIcon
    case 'email':
      return EmailIcon
    case 'change':
      return ChangeIcon
    case 'calendar':
      return CalendarIcon

    default:
      return ShowIcon
  }
}
</script>

<style lang="scss">
@import './more-actions-menu.scss';
</style>
