<template>
  <ModalStep
    :id="'phone-modal-content'"
    :header="{
      title: t('auth.phoneModal.title'),
    }"
  >
    <template #bodyInformation>
      <strong>
        {{ t('auth.phoneModal.body') }}
      </strong>
    </template>
    <template #bodyContent>
      <form @submit.prevent="submitForm">
        <CustomInput
          id="phone"
          type="tel"
          :label="`${t('auth.phoneModal.input.label')} *`"
          :placeholder="t('auth.phoneModal.input.placeholder')"
          :value="phoneRef"
          @emit:change="phoneChangeHandler"
          :countryCode="countryCode"
        />
      </form>
    </template>
    <template #footer>
      <div class="c-btns-container">
        <CustomButton
          usage="button"
          type="button"
          color="white"
          @emit:click="doNotAskAgain"
          :text="t('auth.phoneModal.doNotAskAgain')"
        >
        </CustomButton>
        <CustomButton
          usage="button"
          type="button"
          color="primary"
          @emit:click="submitForm"
          :isDisabled="!newPhoneNumberRef"
          :isLoading="isSubmittingRef"
          :text="t('ui.button.confirm')"
        ></CustomButton>
      </div>
    </template>
  </ModalStep>
</template>

<script setup lang="ts">
import { useMutation } from '@tanstack/vue-query'
import type { CountryCode } from 'libphonenumber-js'
import { getCountries, isValidPhoneNumber, ParseError, parsePhoneNumberWithError } from 'libphonenumber-js'
import { computed, ref, watch } from 'vue'
import type { ZodError } from 'zod'
import { z } from 'zod'

import { updateUserMutation } from '@/v1.5/features/auth/api'
import useAccount from '@/v1.5/features/auth/hooks/use-account.hook'
import usePhone from '@/v1.5/features/auth/hooks/use-phone.hook'
import CustomButton from '@/v1.5/features/ui/components/button/custom-button.vue'
import CustomInput from '@/v1.5/features/ui/components/input/custom-input.vue'
import ModalStep from '@/v1.5/features/ui/components/modal/modal-step/modal-step.vue'
import modalsStore from '@/v1.5/features/ui/store/modal/modal.store'
import useToast from '@/v1.5/hooks/use-toasts.hook'
import { useI18n } from '@/v1.5/lib/i18n'
import { DO_NOT_ASK_PHONE_NUMBER_AGAIN } from '@/v1.5/utils/config/constants'
import { invalidatePhone } from '@/v1.5/utils/lib/vue-query'
import getCountryCode from '@/v1.5/utils/misc/get-country-code'

const toast = useToast()
const { accountRef } = useAccount()
const { t } = useI18n()
const { phoneRef } = usePhone()

const newPhoneNumberRef = ref<null | string>(null)
const isSubmittingRef = ref(false)

function normalizePhoneNumber(phoneNumberInput: string): string | null {
  // First, check if it's a French mobile number
  if (phoneNumberInput.startsWith('07') || phoneNumberInput.startsWith('06')) {
    try {
      const phoneNumber = parsePhoneNumberWithError(phoneNumberInput, 'FR' as CountryCode)
      if (phoneNumber.isValid()) {
        return phoneNumber.format('E.164')
      }
    } catch (error) {
      // If it's not a valid French number, continue to general parsing
    }
  }

  // Try to parse the number as-is (works for international format)
  try {
    const phoneNumber = parsePhoneNumberWithError(phoneNumberInput)
    if (phoneNumber.isValid()) {
      return phoneNumber.format('E.164')
    }
  } catch (error) {
    if (!(error instanceof ParseError)) {
      console.error('Unexpected error:', error)
      return null
    }
    // If it's a ParseError, we'll continue to try other methods
  }

  // If parsing as-is failed, try with all supported countries
  const supportedCountries = getCountries()
  for (const country of supportedCountries) {
    try {
      const phoneNumber = parsePhoneNumberWithError(phoneNumberInput, country as CountryCode)
      if (phoneNumber.isValid()) {
        return phoneNumber.format('E.164')
      }
    } catch (error) {
      if (!(error instanceof ParseError)) {
        console.error('Unexpected error:', error)
      }
      // If it's a ParseError, we'll continue to the next country
      continue
    }
  }

  // If no valid number found, return null
  return null
}

function phoneChangeHandler(value: string) {
  newPhoneNumberRef.value = normalizePhoneNumber(value)
}

// watch for the phone number if already set, and set it in the input
watch(
  accountRef,
  () => {
    if (phoneRef.value) {
      newPhoneNumberRef.value = phoneRef.value
    }
  },
  { immediate: true },
)

const validationSchema = z.object({
  // either no value or a valid phone number
  phone: z.string().refine((data) => isValidPhoneNumber(data), {
    message: t('auth.phoneModal.error.invalid'),
  }),
})

const { mutate: updateUser } = useMutation({
  mutationFn: updateUserMutation,
  onError: () => {
    toast?.error(t('error.common'))
  },
  onSuccess: async () => {
    toast?.success(t('auth.phoneModal.success'))
    invalidatePhone()
    modalsStore.toggleModal('phone', false)
  },
  onSettled: () => {
    isSubmittingRef.value = false
  },
})

function doNotAskAgain() {
  window.localStorage.setItem(DO_NOT_ASK_PHONE_NUMBER_AGAIN, 'true')
  modalsStore.toggleModal('phone', false)
}

async function submitForm() {
  try {
    isSubmittingRef.value = true
    const data = await validationSchema.parseAsync({ phone: newPhoneNumberRef.value })
    console.log(data)
    updateUser({
      phone: data.phone,
      userId: accountRef.value!.id!,
    })
  } catch (e: any) {
    // handle zod errors
    const error: ZodError = e
    error.errors.forEach((err) => {
      toast?.error(err.message)
    })
    isSubmittingRef.value = false
  }
}

const countryCode = computed(() => {
  if (newPhoneNumberRef.value) {
    return getCountryCode(newPhoneNumberRef.value)
  }
  return null
})
</script>

<style lang="scss" scoped>
@import './phone-modal-content.scss';
</style>
