<template>
  <Dropdown
    class-name="session-general-informations"
    :title="t('session.generalInformations.title')"
    :default-open="true"
    :is-loaded="!isPendingRef"
  >
    <ProgramReminder :program-id="selectedProgramIdRef" />
    <div class="general-informations-wrapper" v-if="sessionRef">
      <div class="general-informations-wrapper__date-wrapper">
        <div class="date-day">{{ dayjsClient(sessionRef.from).format('dddd') }}</div>
        <div class="date-month">{{ dayjsClient(sessionRef.from).format('DD MMMM') }}</div>
        <div class="date-full-date">{{ dayjsClient(sessionRef.from).format('DD/MM/YYYY') }}</div>
      </div>
      <div class="general-informations-wrapper__time-wrapper">
        <div class="time-location">
          {{ sessionRef.location === 'visio' ? t('session.type.online') : sessionRef.location }}
        </div>
        <div class="time-range">{{ getDurationRange(sessionRef.from, sessionRef.to) }}</div>
      </div>
    </div>
    <div class="description-wrapper">
      <!-- not used right now -->
    </div>
  </Dropdown>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import ProgramReminder from '@/v1.5/features/programs/components/program-reminder/program-reminder.vue'
import { selectGetSelectedProgramId } from '@/v1.5/features/programs/stores/programs/programs.selectors'
import programsStore from '@/v1.5/features/programs/stores/programs/programs.store'
import useSession from '@/v1.5/features/sessions/hooks/use-session.hook'
import Dropdown from '@/v1.5/features/ui/components/dropdown/dropdown.vue'
import { selectGetSessionDetailsModal } from '@/v1.5/features/ui/store/modal/modal.selectors'
import modalsStore from '@/v1.5/features/ui/store/modal/modal.store'
import dayjsClient from '@/v1.5/lib/dayjs'
import { useI18n } from '@/v1.5/lib/i18n'
import { getDurationRange } from '@/v1.5/utils/dates'

const { t } = useI18n()

const selectedProgramIdRef = selectGetSelectedProgramId(programsStore)

const sessionDetailsModalRef = selectGetSessionDetailsModal(modalsStore)

const sessionIdRef = computed(() => sessionDetailsModalRef.value.attachedData.sessionId ?? null)

const { sessionRef, isPendingRef } = useSession(sessionIdRef)
</script>

<style lang="scss">
@import './session-general-information.scss';
</style>
