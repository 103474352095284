<!-- eslint-disable -->
<!-- @ts-nocheck -->
<template>
  <div v-if="users.length" class="d-flex session-status-small-user-group">
    <div class="status-small-tooltip">
      <ul>
        <li v-for="(user, index) in users">
          <router-link :to="'/user/' + user.id"
            >{{ user.firstname }} {{ user.lastname }}</router-link
          >
        </li>
      </ul>
    </div>
    <template v-for="(user, index) in users">
      <ProfilePicture
        :style="`z-index: ${100 - index}; margin-left: -${
          index > 0 ? 20 : 0
        }px;`"
        class="profile-picture status-small"
        :src="user.profilePicture"
        :firstname="user.firstname"
        :lastname="user.lastname"
        :width="40"
        :height="40"
      />
    </template>
    <span class="badge" :class="badgeClass">{{ badgeText }}</span>
  </div>
</template>

<script>
import ProfilePicture from '@/components/ProfilePicture.vue'

export default {
  name: 'SessionStatusSamllTile',
  components: {
    ProfilePicture,
  },
  props: {
    users: {
      type: Array,
      default: () => [],
    },
    badgeClass: {
      type: String,
      default: '',
    },
    badgeText: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
.status-small {
  &:not(:first-child) {
  }
}

.profile-picture {
  width: 3rem;
  height: 3rem;
}

.session-status-small-user-group {
  position: relative;
  .status-small-tooltip {
    position: absolute;
    bottom: calc(100%);
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    height: auto;
    background-color: var(--bs-gray);
    padding: 8px;
    border-radius: 4px;
    z-index: 1000;
    pointer-events: none;
    opacity: 0;
    transition: all 0.2s ease-in-out;
    ul {
      padding: 0;
      margin: 0;
      display: flex;
      gap: 2px;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      li {
        list-style-type: none;
        a {
          white-space: nowrap;
          text-align: center;
          color: var(--bs-dark);
          text-decoration: none;
          &:hover {
            cursor: pointer;
            color: var(--bs-primary);
            text-decoration: underline;
          }
        }
      }
    }
    &:before {
      content: '';
      position: absolute;
      bottom: -16px;
      left: 50%;
      transform: translateX(-50%);
      border: 8px solid transparent;
      border-top-color: var(--bs-gray);
    }
    &:after {
      content: '';
      position: absolute;
      bottom: -16px;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      height: 16px;
    }
  }
  &:hover {
    .status-small-tooltip {
      opacity: 1;
      bottom: calc(100% + 12px);
      pointer-events: all;
    }
  }
  .badge {
    position: absolute;
    bottom: -10px;
    transform: translateX(-50%);
    left: 50%;
    z-index: 101;
  }
}
.session-status-small-user-group:not(:first-child) {
  margin-left: 40px;
}
</style>
