// @ts-nocheck 
// eslint-disable
import axios from 'axios'
import { ApiFetchAllCareers } from '@/services/userService'

function initialState() {
  return {
    profile: {},
    careers: null,
  }
}

const state = initialState()

const getters = {
  profile: (state) => state.profile,
  careers: (state) => state.careers,
}

const actions = {
  async fetchProfile({ commit }, payload) {
    const url = payload
      ? `${import.meta.env.VITE_API_URL}/v1/user/${payload.userId}`
      : `${import.meta.env.VITE_API_URL}/v1/user/${this.getters.authUserId}`

    const profile = await axios.get(url, {
      headers: {
        authorization: `Bearer ${this.getters.authToken}`,
      },
    })
    commit('SET_PROFILE', profile.data)
  },
  clearProfile({ commit }) {
    commit('CLEAR_CAREER')
  },
  async fetchCareer({ commit }, payload) {
    const careers = await ApiFetchAllCareers(
      payload.userId,
      this.getters.authToken
    )
    if (careers) {
      commit('SET_CAREER', careers)
    }
  },
  clearCareer({ commit }) {
    commit('CLEAR_CAREER')
  },
  storeProfileResetState({ commit }) {
    commit('PROFILE_RESET_STATE')
  },
}

const mutations = {
  SET_PROFILE(state, profile) {
    state.profile = profile
  },
  SET_CAREER(state, career) {
    state.careers = career
  },
  CLEAR_CAREER(state) {
    state.careers = null
  },
  CLEAR_PROFILE(state) {
    state.profile = {}
  },
  PROFILE_RESET_STATE(state) {
    // acquire initial state
    const s = initialState()
    Object.keys(s).forEach((key) => {
      state[key] = s[key]
    })
  },
}

export const moduleProfile = {
  state,
  getters,
  actions,
  mutations,
}
