// @ts-nocheck 
// eslint-disable
import axios from 'axios'

const apiURL = import.meta.env.VITE_API_URL
const instance = axios.create({
  baseURL: `${apiURL}/v1/chat`,
})
// 👊 ✓
export async function getConversation(authToken) {
  const res = await instance
    .get('/', {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
    .catch(function (error) {
      if (error.response) {
        return error.response
      }
    })
  return res.data
}

// 👊 ✓
export async function createConversation(id, authToken) {
  const res = await instance
    .post(
      '/conversation',
      {
        sendAt: id,
      },
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    )
    .catch(function (error) {
      if (error.response) {
        return error.response
      }
    })
  return res.data
}

// 👊 ✓
export async function sendMessage(data, authToken) {
  const res = await instance
    .post(
      '/',
      {
        conversationId: data.conversationId,
        message: data.message,
      },
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    )
    .catch(function (error) {
      if (error.response) {
        return error.response
      }
    })
  return res.data
}
