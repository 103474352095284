<template>
  <div class="deliverable-container">
    <LoadingContent v-if="isDeliverablePendingRef" />
    <div v-else-if="userId && deliverableRef?.file" class="deliverable-wrapper">
      <div class="deliverable-info-wrapper">
        <FileIcon color="primary" filled />
        <div class="deliverable-info">
          <p class="deliverable-info__name">{{ deliverableRef?.file }}</p>
          <p class="deliverable-info__sub-info">
            {{ t('session.deliverable.sentAt') }} {{ getDate(deliverableRef?.updatedAt) }}
          </p>
        </div>
      </div>
      <div class="deliverable-dl-button">
        <CustomButton
          usage="button"
          color="primary"
          text="Télécharger"
          @emit:click="
            downloadDeliverable({
              menteeId: userId,
              deliverableId: deliverableRef?.id,
              sessionId: sessionIdRef,
              toast,
            })
          "
        >
          <template #icon>
            <DownloadIcon color="white" />
          </template>
        </CustomButton>
      </div>
    </div>
    <div v-else class="deliverable-empty">
      <p>{{ t('session.deliverable.noDeliverable') }}</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import useDeliverable from '@/v1.5/features/sessions/hooks/use-deliverable.hook'
import { downloadDeliverable } from '@/v1.5/features/sessions/utils'
import CustomButton from '@/v1.5/features/ui/components/button/custom-button.vue'
import DownloadIcon from '@/v1.5/features/ui/components/icons/download-icon.vue'
import FileIcon from '@/v1.5/features/ui/components/icons/file-icon.vue'
import LoadingContent from '@/v1.5/features/ui/components/loading-content/loading-content.vue'
import useToast from '@/v1.5/hooks/use-toasts.hook'
import { useI18n } from '@/v1.5/lib/i18n'
import { getDate } from '@/v1.5/utils/dates'

const props = defineProps<{
  userId: string | null
  sessionId: string | null
}>()

const toast = useToast()
const { t } = useI18n()

const sessionIdRef = computed(() => props.sessionId)
const userIdRef = computed(() => props.userId)

const { deliverableRef, isPendingRef: isDeliverablePendingRef } = useDeliverable({
  userId: userIdRef,
  sessionId: sessionIdRef,
})
</script>

<style lang="scss">
@import './deliverable.scss';
</style>
