<template>
  <section class="sessions-content">
    <GridBlockBase id="sessions-listing">
      <template #icon>
        <calendarIcon color="black" filled />
      </template>
      <template #body>
        <SessionsList :sessions="filteredSessionsRef" :isPending="isPendingRef" order="desc" />
      </template>
      <template #headerExtra>
        <CustomSelect
          id="sessions-status"
          :value="selectedStatusRef"
          :options="sessionsListOptionsRef"
          @emit:change="onChange"
        >
        </CustomSelect>
        <CustomButton
          v-if="selectedGroupIdRef && accountRef?.role === RoleEnumType.MENTOR"
          color="primary"
          :text="t('ui.layout.nav.newSession')"
          usage="button"
          @emit:click="openNewSessionModal"
        >
          <template #icon>
            <AddCalendarIcon color="black" filled />
          </template>
        </CustomButton>
      </template>
    </GridBlockBase>
  </section>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import useAccount from '@/v1.5/features/auth/hooks/use-account.hook'
import { RoleEnumType } from '@/v1.5/features/auth/types'
import { selectGetSelectedGroupId } from '@/v1.5/features/groups/stores/groups/groups.selectors'
import groupsStore from '@/v1.5/features/groups/stores/groups/groups.store'
import SessionsList from '@/v1.5/features/sessions/components/sessions-list/sessions-list.vue'
import useSessions from '@/v1.5/features/sessions/hooks/use-sessions.hook'
import { SessionStatusEnumType } from '@/v1.5/features/sessions/types'
import type { SessionsStatusesOptionsType } from '@/v1.5/features/sessions/utils'
import {
  getCanceledSessions,
  getCompletedSessions,
  getPlannedSessions,
  getWaitConfirmationSessions,
  getWaitReviewSessions,
  SessionOptionEnumDefaultAllType,
  sessionStatusesOptionsMap,
} from '@/v1.5/features/sessions/utils'
import CustomButton from '@/v1.5/features/ui/components/button/custom-button.vue'
import GridBlockBase from '@/v1.5/features/ui/components/grid-block-base/grid-block-base.vue'
import AddCalendarIcon from '@/v1.5/features/ui/components/icons/add-calendar-icon.vue'
import calendarIcon from '@/v1.5/features/ui/components/icons/calendar-icon.vue'
import CustomSelect from '@/v1.5/features/ui/components/select/custom-select.vue'
import modalsStore from '@/v1.5/features/ui/store/modal/modal.store'
import { useI18n } from '@/v1.5/lib/i18n'

const { t } = useI18n()

const { accountRef } = useAccount()
const { sessionsRef, isPendingRef } = useSessions()
const selectedGroupIdRef = selectGetSelectedGroupId(groupsStore)

const plannedSessionsRef = computed(() => sessionsRef.value.filter(getPlannedSessions))
const waitConfirmationSessionsRef = computed(() => sessionsRef.value.filter(getWaitConfirmationSessions))
const waitReviewSessionsRef = computed(() => sessionsRef.value.filter(getWaitReviewSessions))
const completedSessionsRef = computed(() => sessionsRef.value.filter(getCompletedSessions))
const canceledSessionsRef = computed(() => sessionsRef.value.filter(getCanceledSessions))

const sessionsListOptionsRef = computed(() => {
  return [
    {
      label: `${t(sessionStatusesOptionsMap[SessionOptionEnumDefaultAllType.ALL])} · ${sessionsRef.value.length ?? 0}`,
      value: SessionOptionEnumDefaultAllType.ALL,
    },
    {
      label: `${t(sessionStatusesOptionsMap[SessionStatusEnumType.PLANNED])} · ${plannedSessionsRef.value.length ?? 0}`,
      value: SessionStatusEnumType.PLANNED,
    },
    {
      label: `${t(
        sessionStatusesOptionsMap[SessionStatusEnumType.WAIT_CONFIRMATION],
      )} · ${waitConfirmationSessionsRef.value.length ?? 0}`,
      value: SessionStatusEnumType.WAIT_CONFIRMATION,
    },
    {
      label: `${t(
        sessionStatusesOptionsMap[SessionStatusEnumType.WAIT_REVIEW],
      )} · ${waitReviewSessionsRef.value.length ?? 0}`,
      value: SessionStatusEnumType.WAIT_REVIEW,
    },
    {
      label: `${t(sessionStatusesOptionsMap[SessionStatusEnumType.FINISHED])} · ${completedSessionsRef.value.length ?? 0}`,
      value: SessionStatusEnumType.FINISHED,
    },
    {
      label: `${t(sessionStatusesOptionsMap[SessionStatusEnumType.CANCELLED])} · ${canceledSessionsRef.value.length ?? 0}`,
      value: SessionStatusEnumType.CANCELLED,
    },
  ] satisfies { label: string; value: SessionsStatusesOptionsType }[]
})

// current selected status
const selectedStatusRef = ref<SessionsStatusesOptionsType>(SessionOptionEnumDefaultAllType.ALL)

// function to filter the sessions
const filteredSessionsRef = computed(() => {
  switch (selectedStatusRef.value) {
    case SessionOptionEnumDefaultAllType.ALL:
      return sessionsRef.value
    default:
      return sessionsRef.value.filter((session) => session.status === selectedStatusRef.value)
  }
})

// on select change, fitler the sessions
function onChange(value: SessionsStatusesOptionsType) {
  selectedStatusRef.value = value
}

// open new session modal
function openNewSessionModal() {
  modalsStore.toggleModal('new-session', true)
}
</script>

<style lang="scss">
@import './sessions-content.scss';
</style>
