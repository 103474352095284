<template>
  <ModalStep
    id="group-structure-content"
    :header="{
      title: !!groupIdRef
        ? `${t('admin.groups.groupStructureModal.existing.title')}`
        : `${t('admin.groups.groupStructureModal.new.title')}`,
    }"
  >
    <template #headerAfterElement>
      <ProgramsDropdown v-if="!groupIdRef" />
    </template>
    <template #bodyContent>
      <LoadingContent v-if="isGroupPendingRef && !!groupIdRef" />
      <template v-else>
        <div class="group-structure-listing">
          <div class="c-info-wrapper manager-listing">
            <p class="c-info-label">{{ t('common.manager.capitalize', 2) }}</p>
            <template v-if="newManagersRef.length">
              <UserCard v-for="manager in newManagersRef" :user="manager" :key="manager.id">
                <template #actions>
                  <div
                    v-if="!isUserAccountManagerRef"
                    class="group-structure-user-remove-btn"
                    data-action=""
                    @click="newManagersRef = newManagersRef.filter((m) => m.id !== manager.id)"
                  >
                    <CrossIcon color="danger" />
                  </div>
                </template>
              </UserCard>
            </template>
            <CustomButton
              v-if="!isManagerSearchEnabledRef && !isUserAccountManagerRef"
              usage="button"
              type="button"
              color="secondary"
              :text="t('admin.groups.groupStructureModal.body.addManager')"
              @emit:click="isManagerSearchEnabledRef = true"
            >
              <template #icon>
                <CrossIcon color="primary" :rotate="45" />
              </template>
            </CustomButton>

            <UsersSearch
              :onMountFocus="true"
              :filterByRole="RoleEnumType.MANAGER"
              :remove-from-results="[...newManagersRef]"
              v-if="isManagerSearchEnabledRef"
              :search-callback="managerSearchCallback"
              @emit:result="addManager"
            />
          </div>
          <div class="c-info-wrapper mentor-listing">
            <p class="c-info-label">{{ t('common.mentor.capitalize') }}</p>
            <UserCard v-if="newMentorRef" :user="newMentorRef">
              <template #actions>
                <div class="group-structure-user-remove-btn" data-action="" @click="newMentorRef = null">
                  <CrossIcon color="danger" />
                </div>
              </template>
            </UserCard>
            <CustomButton
              v-if="!newMentorRef && !isMentorSearchEnabledRef"
              usage="button"
              type="button"
              color="secondary"
              :text="t('admin.groups.groupStructureModal.body.addMentor')"
              @emit:click="isMentorSearchEnabledRef = true"
            >
              <template #icon>
                <CrossIcon color="primary" :rotate="45" />
              </template>
            </CustomButton>

            <UsersSearch
              :onMountFocus="true"
              :filterByRole="RoleEnumType.MENTOR"
              :remove-from-results="newMentorRef ? [newMentorRef] : []"
              v-if="isMentorSearchEnabledRef"
              :search-callback="mentorSearchCallback"
              @emit:result="addMentor"
            />
          </div>
          <div class="c-info-wrapper mentee-listing">
            <p class="c-info-label">{{ t('common.mentee.capitalize', 2) }}</p>
            <template v-if="newMenteesRef.length">
              <UserCard v-for="mentee in newMenteesRef" :user="mentee" :key="mentee.id">
                <template #actions>
                  <div
                    class="group-structure-user-remove-btn"
                    data-action=""
                    @click="newMenteesRef = newMenteesRef.filter((m) => m.id !== mentee.id)"
                  >
                    <CrossIcon color="danger" />
                  </div>
                </template>
              </UserCard>
            </template>
            <CustomButton
              v-if="!isMenteeSearchEnabledRef"
              usage="button"
              type="button"
              color="secondary"
              :text="t('admin.groups.groupStructureModal.body.addMentee')"
              @emit:click="isMenteeSearchEnabledRef = true"
            >
              <template #icon>
                <CrossIcon color="primary" :rotate="45" />
              </template>
            </CustomButton>

            <UsersSearch
              :onMountFocus="true"
              :filterByRole="RoleEnumType.MENTEE"
              :remove-from-results="[...newMenteesRef]"
              v-if="isMenteeSearchEnabledRef"
              :search-callback="menteeSearchCallback"
              @emit:result="addMentee"
            />
          </div>
        </div>
      </template>
    </template>
    <template #footer>
      <div class="c-btns-container">
        <CustomButton
          usage="button"
          type="button"
          color="white"
          @emit:click="onClose"
          :text="t('ui.button.cancel')"
        ></CustomButton>
        <CustomButton
          v-if="newManagersRef.length > 0 || newMentorRef !== null || newMenteesRef.length > 0"
          usage="button"
          type="button"
          color="primary"
          :isLoading="isSubmittingRef"
          @emit:click="onSubmit"
          :text="!!groupIdRef ? t('ui.button.save') : t('ui.button.create')"
        ></CustomButton>
        <CustomButton
          v-else
          usage="button"
          type="button"
          color="danger"
          @emit:click="onDelete"
          :text="t('ui.button.delete')"
        ></CustomButton>
      </div>
    </template>
  </ModalStep>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { ref } from 'vue'
import { watch } from 'vue'

import {
  addUserToGroupMutation,
  createGroupMutation,
  removeUserFromGroupMutation,
} from '@/v1.5/features/admin/groups/api'
import useAccount from '@/v1.5/features/auth/hooks/use-account.hook'
import { RoleEnumType } from '@/v1.5/features/auth/types'
import useGroup from '@/v1.5/features/groups/hooks/use-group.hook'
import type { V2GroupType } from '@/v1.5/features/groups/types'
import { getManager, getMentees, getMentor } from '@/v1.5/features/groups/utils'
import ProgramsDropdown from '@/v1.5/features/programs/components/programs-dropdown/programs-dropdown.vue'
import { selectGetSelectedProgramId } from '@/v1.5/features/programs/stores/programs/programs.selectors'
import programsStore from '@/v1.5/features/programs/stores/programs/programs.store'
import CustomButton from '@/v1.5/features/ui/components/button/custom-button.vue'
import CrossIcon from '@/v1.5/features/ui/components/icons/cross-icon.vue'
import LoadingContent from '@/v1.5/features/ui/components/loading-content/loading-content.vue'
import ModalStep from '@/v1.5/features/ui/components/modal/modal-step/modal-step.vue'
import UsersSearch from '@/v1.5/features/ui/pages/admin/users/users-search/users-search.vue'
import { selectGetAdminGroupStructureModal } from '@/v1.5/features/ui/store/modal/modal.selectors'
import modalsStore from '@/v1.5/features/ui/store/modal/modal.store'
import UserCard from '@/v1.5/features/users/components/user-card/user-card.vue'
import useUser from '@/v1.5/features/users/hooks/use-user.hook'
import type { V2UserDataType } from '@/v1.5/features/users/types'
import useToast from '@/v1.5/hooks/use-toasts.hook'
import { useI18n } from '@/v1.5/lib/i18n'
import { invalidateAdminUsers, invalidateGroup, invalidateGroups } from '@/v1.5/utils/lib/vue-query'

const { t } = useI18n()
const toast = useToast()
const groupStructureModalRef = selectGetAdminGroupStructureModal(modalsStore)
const selectedProgramIdRef = selectGetSelectedProgramId(programsStore)
const groupIdRef = computed(() => groupStructureModalRef.value.attachedData.groupId ?? null)

const { groupRef, isPendingRef: isGroupPendingRef } = useGroup(groupIdRef)

const { accountRef } = useAccount()
const accountIdRef = computed(() => accountRef.value?.id)
const { userRef: userAccountRef } = useUser(accountIdRef)
const isUserAccountManagerRef = computed(() => userAccountRef.value?.role === RoleEnumType.MANAGER)

const usersRef = computed<V2GroupType['users']>(() => groupRef.value?.users ?? [])

const managersRef = computed(() => usersRef.value.filter(getManager))
const mentorRef = computed(() => usersRef.value.find(getMentor))
const menteesRef = computed(() => usersRef.value.filter(getMentees))

const newManagersRef = ref<V2UserDataType[]>([])
const newMentorRef = ref<V2UserDataType | null>(null)
const newMenteesRef = ref<V2UserDataType[]>([])

const isManagerSearchEnabledRef = ref(false)
const isMentorSearchEnabledRef = ref(false)
const isMenteeSearchEnabledRef = ref(false)

watch(selectedProgramIdRef, () => {
  newManagersRef.value = []
  newMentorRef.value = null
  newMenteesRef.value = []
})
// if the user is a manager, they are automatically added to the group
watch(
  [isUserAccountManagerRef],
  async () => {
    if (isUserAccountManagerRef.value) {
      newManagersRef.value = [userAccountRef.value as V2UserDataType]
    }
  },
  { immediate: true },
)

const managerSearchCallback = () => {
  // ..
}
const addManager = (user: V2UserDataType | null) => {
  if (!user) return
  newManagersRef.value = [...newManagersRef.value, user]
  isManagerSearchEnabledRef.value = false
}

const mentorSearchCallback = () => {
  // ..
}
const addMentor = (user: V2UserDataType | null) => {
  if (!user) return
  newMentorRef.value = user
  isMentorSearchEnabledRef.value = false
}

const menteeSearchCallback = () => {
  // ..
}
const addMentee = (user: V2UserDataType | null) => {
  if (!user) return
  newMenteesRef.value = [...newMenteesRef.value, user]
  isMenteeSearchEnabledRef.value = false
}

const isBaseUsersLoadedRef = ref(false)
// load the base users
watch(
  [managersRef, mentorRef, menteesRef],
  ([manager, mentor, mentees]) => {
    if (!usersRef.value?.length) return
    if (isBaseUsersLoadedRef.value) return
    isBaseUsersLoadedRef.value = true
    newManagersRef.value = manager ?? null
    newMentorRef.value = mentor ?? null
    newMenteesRef.value = mentees ?? []
  },
  { deep: true, immediate: true },
)

enum SubmitStatusType {
  STAYED = 'stayed',
  NEW = 'new',
  DELETED = 'deleted',
}

const isSubmittingRef = ref(false)

const onDelete = async () => {
  modalsStore.toggleModal('admin-group-structure', false)

  modalsStore.toggleModal('confirm-action', true)
  modalsStore.updateModalAttachedData('confirm-action', {
    fn: () => {
      onSubmit()
    },
    title: t('moreActions.admin.groups.delete.confirmLabel'),
    content: t('moreActions.admin.groups.delete.confirmContent'),
  })
}

const onSubmit = async () => {
  // for every user, I want to know if they are new, or deleted, or stayed the same
  // compare the base users with the new users
  // add a property on each user called status that is either new, deleted, or stayed
  const newUsers = [...newManagersRef.value, newMentorRef.value, ...newMenteesRef.value].filter(
    Boolean,
  ) as V2UserDataType[]
  const baseUsers = [...managersRef.value, mentorRef.value, ...menteesRef.value].filter(Boolean) as V2UserDataType[]

  // tag new users or users that stayed
  const users = newUsers.map((newUser) => {
    const baseUser = baseUsers.find((user) => user.id === newUser.id)
    if (!baseUser) {
      return { ...newUser, status: SubmitStatusType.NEW }
    }
    return { ...newUser, status: SubmitStatusType.STAYED }
  })

  // tag deleted users
  baseUsers.forEach((baseUser) => {
    const newUser = newUsers.find((user) => user.id === baseUser.id)
    if (!newUser) {
      users.push({ ...baseUser, status: SubmitStatusType.DELETED })
    }
  })

  try {
    isSubmittingRef.value = true

    let groupId = groupIdRef.value
    // if group does not exist, create it
    if (!groupId) {
      const createGroupResponseData = await createGroupMutation({
        programId: selectedProgramIdRef.value!,
      })
      groupId = createGroupResponseData.groupId
    }

    const usersToSubmitPromises = users.map(async (user) => {
      switch (user.status) {
        case SubmitStatusType.NEW:
          return addUserToGroupMutation({ userId: user.id, groupId })
        case SubmitStatusType.DELETED:
          return removeUserFromGroupMutation({ userId: user.id, groupId })
        default:
          // do nothing
          break
      }
    })

    //then update users
    await Promise.all(usersToSubmitPromises)
    await invalidateGroup(groupId)
    await invalidateGroups(selectedProgramIdRef.value!)
    await invalidateAdminUsers(selectedProgramIdRef.value!)

    // toast update and close modal
    if (groupIdRef.value) {
      toast?.success(t('admin.groups.groupStructureModal.existing.sucess'))
    } else {
      toast?.success(t('admin.groups.groupStructureModal.new.sucess'))
    }

    modalsStore.toggleModal('admin-group-structure', false)
  } catch (e) {
    toast?.error(t('error.common'))
  } finally {
    isSubmittingRef.value = false
  }
}
const onClose = () => {
  modalsStore.toggleModal('admin-group-structure', false)
}
</script>

<style lang="scss">
@import './group-structure-modal-content.scss';
</style>
