// @ts-nocheck
// eslint-disable
import 'bootstrap'
import '@/assets/css/style.css'
import './plugins/vueGoodTable'

import * as Sentry from '@sentry/vue'
import { VueQueryPlugin } from '@tanstack/vue-query'
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import InstantSearch from 'vue-instantsearch'
import Toast from 'vue-toastification'

import { setupI18n } from './v1.5/lib/i18n'
import App from './App.vue'
import router from './router'
import store from './store'

import { initPosthog } from '@/v1.5/lib/posthog'
import queryClient from '@/v1.5/lib/vue-query'
import convertHexToRGB from '@/v1.5/utils/misc/hex-to-rgb'

// internationalization
Vue.use(VueI18n, { bridge: true }) // you must specify '{ bridge: true }' plugin option when install vue-i18n (vue 2.7 compatibility)
export const i18nInstance = setupI18n()

async function init() {
  Vue.use(VueQueryPlugin, {
    queryClient,
  })

  // verify if the user is logged with SAML method
  var url_string = window.location.href
  var url = new URL(url_string)
  if (url.searchParams.get('SAML') && url.searchParams.get('userId')) {
    localStorage.clear()
    store.dispatch('authLoginWithToken', {
      token: url.searchParams.get('SAML'),
      userId: url.searchParams.get('userId'),
    })
  }

  // dispatch ici les infos de l'application
  await store.dispatch('fetchAppData')
  await store.dispatch('authGetUserData')
  // setFavicon
  const linkFavicon = document.createElement('link')
  linkFavicon.rel = 'icon'
  linkFavicon.href = store.getters.favicon
  document.querySelector('head').appendChild(linkFavicon)
  // set custom style + set colors
  const style = document.createElement('style')
  style.innerHTML = `:root {--color-white-label-primary: ${convertHexToRGB(store.getters.colorPrimary)}; --color-white-label-secondary: ${convertHexToRGB(store.getters.colorSecondary)}; --bs-primary-focus: rgba(${convertHexToRGB(store.getters.colorPrimary)}, 0.5); --bs-primary-light: rgba(${convertHexToRGB(store.getters.colorPrimary)}, 0.7); --color-primary: rgb(${convertHexToRGB(store.getters.colorPrimary)});--color-secondary: rgb(${convertHexToRGB(store.getters.colorSecondary)});--bs-primary: rgb(${convertHexToRGB(store.getters.colorPrimary)});--bs-secondary: rgb(${convertHexToRGB(store.getters.colorSecondary)});}`
  style.innerHTML += store.getters.customStyle
  document.querySelector('head').appendChild(style)
  // setAppTitle
  document.querySelector('title').innerHTML = store.getters.appTitle

  // disable all analytics tools for the user
  const ANALYTICS_DISABLED_KEY = 'constel-analytics-disabled'

  if (window.localStorage.getItem(ANALYTICS_DISABLED_KEY)) {
    console.warn('***********************')
    console.warn('ANALYTICS ARE DISABLED FOR THIS USER')
    console.warn('***********************')
  }

  // inject brevo only in production
  // and if analytics aren't disabled
  if (import.meta.env.VITE_ENV === 'production' && !window.localStorage.getItem(ANALYTICS_DISABLED_KEY)) {
    const brevo = document.createElement('script')
    brevo.type = 'text/javascript'
    brevo.innerHTML = `
        !function(e,n,o){n.BrevoConversationsID="611ce099bcb2d0387209fc56",n[o]=n[o]||function(){(n[o].q=n[o].q||[]).push(arguments)};var s=e.createElement("script");s.async=!0,s.src="https://conversations-widget.brevo.com/brevo-conversations.js",e.head&&e.head.appendChild(s)}(document,window,"BrevoConversations");
        `
    document.querySelector('head').appendChild(brevo)

    window.BrevoConversationsSetup = {
      colors: {
        buttonText: '#f0f0f0',
        buttonBg: `${store.getters.colorPrimary}`,
      },
      customWidgetButton: '.custom-chat-button',
      locale: {
        customStrings: {
          welcome: `Une question ? Nous sommes là pour vous aider ! \n\n Envoyez-nous un message ici ou contactez-nous par téléphone au ${store.getters.supportPhone}.`,
          offlineNote: `Nous sommes hors ligne. Laissez-nous un message ici ou par e-mail sur ${store.getters.supportEmail}.`,
        },
      },
    }
  }

  Vue.config.productionTip = false
  Vue.use(InstantSearch)

  Vue.use(Toast, {})

  Vue.use(i18nInstance)

  if (!window.localStorage.getItem(ANALYTICS_DISABLED_KEY)) {
    initPosthog()
  }

  if (import.meta.env.VITE_ENV === 'production' && !window.localStorage.getItem(ANALYTICS_DISABLED_KEY)) {
    Sentry.init({
      Vue,
      dsn: 'https://b981e456aa676c7d459c801ca47f1477@o995149.ingest.sentry.io/4506739714752512',
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
        }),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    })
  }

  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount('#app')
}

init()
