import { parsePhoneNumberFromString } from 'libphonenumber-js'

export default function getIsoCountryCode(phoneNumber: string): string | null {
  try {
    const parsedNumber = parsePhoneNumberFromString(phoneNumber)
    return parsedNumber ? (parsedNumber.country as string) : null
  } catch (error) {
    console.error('Invalid phone number:', error)
    return null
  }
}
