<!-- eslint-disable -->
<!-- @ts-nocheck -->
<template>
  <Modal
    :onCloseFn="closePopup"
    class="popup"
    name="changeMentor"
    :title="
      isLoading
        ? 'Chargement...'
        : 'Changer le mentor de ' +
          mentee?.Identity?.firstname +
          ' ' +
          mentee?.Identity?.lastname
    "
  >
    <div v-if="isLoading" class="content p-4">
      <p>Chargement...</p>
    </div>
    <div v-else class="content p-4">
      <div class="main-info">
        <div class="search-container">
          <span>
            <span class="icon material-symbols-outlined"> search </span>
          </span>
          <input
            class="search-input"
            type="text"
            placeholder="Rechercher un mentor"
            v-model="searchTerm"
          />
        </div>
        <div class="mentors-result">
          <p v-if="!mentorsResult.length">Aucun mentor trouvé</p>
          <ul v-else>
            <li
              v-for="mentor in mentorsResult"
              :key="mentor.id"
              @click="selectNewMentor(mentor)"
              :class="{ selected: mentor.id === selectedNewMentor?.id }"
            >
              <div class="mentor-info">
                <span class="material-symbols-outlined"> person </span>
                <p class="name">
                  {{ mentor.Identity.firstname }}
                  {{ mentor.Identity.lastname }}
                  (
                  {{ mentor.email }}
                  )
                </p>
              </div>
            </li>
          </ul>
        </div>
        <div class="btns-container">
          <button class="btn btn-light" @click="closePopup">
            <span>Annuler</span>
          </button>
          <button
            class="btn btn-primary"
            :disabled="!selectedNewMentor"
            @click="confirmChangeNewMentor"
          >
            <span>Confirmer</span>
          </button>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Modal from '@/components/Modal.vue'

export default {
  name: 'ChangeMentorPopup',
  data() {
    return {
      searchTerm: '',
      mentee: null,
      selectedNewMentor: null,
      mentors: [],
      ...mapGetters(['isChangeMentorPopupOpen', 'menteeId']),
    }
  },
  computed: {
    isLoading() {
      // Return true when mentee is undefined or mentors array is empty
      return !(this.mentee && this.mentors.length > 0)
    },
    mentorsResult() {
      if (!this.searchTerm.length) return []

      return (
        this.mentors
          .filter((mentor) => {
            const mentorName = `${mentor.Identity.firstname} ${mentor.Identity.lastname}`
            return mentorName
              .toLowerCase()
              .includes(this.searchTerm.toLowerCase())
          })
          // limit the results to the first 8
          .filter((_, index) => index < 8)
      )
    },
  },
  methods: {
    ...mapActions(['switchModal', 'changeMentor', 'setMenteeId']),
    // select the new mentor in the list
    selectNewMentor(mentor) {
      this.selectedNewMentor = mentor
    },
    // This method is called when the user clicks on the "Confirmer" button
    async confirmChangeNewMentor() {
      try {
        await this.changeMentor({
          mentorId: this.selectedNewMentor.id,
          menteeId: this.mentee.id,
          oldMentorId: this.mentee.mentor.id,
        })
        this.$toast.success(`Le mentor a été changé avec succès`)
        // we invalidate the mentees store
        this.$store.dispatch('getMentees')
        this.closePopup()
      } catch (e) {
        this.$toast.error(
          `Une erreur est survenue lors du changement du mentor`
        )
        console.error('Error updating the new mentor', e)
      }
    },
    // close the popup
    closePopup() {
      this.switchModal({ name: 'changeMentor', show: false })
      this.setMenteeId(null)
      this.resetPopupData()
    },
    resetPopupData() {
      this.mentee = null
      this.mentors = []
    },
  },
  watch: {
    '$store.getters.menteeId'() {
      if (!this.$store.getters.menteeId || !this.$store.getters.mentees) return
      this.mentee = this.$store.getters.mentees.find(
        (mentee) => mentee.id === this.$store.getters.menteeId
      )
    },
    '$store.getters.mentors'() {
      this.mentors = this.$store.getters.mentors
    },
  },
  components: {
    Modal,
  },
}
</script>
<style lang="scss" scoped>
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.modal-header {
  position: relative;
  &-close {
    position: absolute;
    top: 0;
    left: 0;
    padding: 24px;
  }
}
.content {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 16px;
  .main-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    .btns-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: end;
      gap: 16px;
      width: 100%;
    }
    .search-container {
      position: relative;
      width: 100%;
      height: 40px;

      .icon {
        position: absolute;
        top: 50%;
        left: 8px;
        transform: translateY(-50%);
        z-index: 1;
        pointer-events: none;
      }
      .search-input {
        width: 100%;
        padding-left: 40px;
        height: 100%;
      }
    }
    .mentors-result {
      width: 100%;
      ul {
        padding-left: 0;
        li {
          padding: 16px;
          list-style-type: none;
          cursor: pointer;
          transition: background-color 0.2s ease-in-out;
          &:not(:last-child) {
            border-bottom: 1px solid var(--bs-gray);
          }
          &:hover {
            background-color: var(--bs-gray);
          }
          &.selected {
            background-color: var(--bs-primary);
            color: var(--bs-white);
          }

          .mentor-info {
            display: flex;
            align-items: center;
            gap: 8px;
            .name {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}
</style>
