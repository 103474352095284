<template>
  <div class="notifications-badge" v-if="!!notificationCountRef">
    <span>{{ notificationCountRef }}</span>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps<{
  numberOfNotifications: any
}>()

const NOTIFICATIONS_LIMIT = 9
const notificationCountRef = computed(() => {
  if (props.numberOfNotifications && props.numberOfNotifications > NOTIFICATIONS_LIMIT) {
    return `${NOTIFICATIONS_LIMIT}+`
  }
  return props.numberOfNotifications
})
</script>

<style lang="scss">
@import './notifications-badge.scss';
</style>
