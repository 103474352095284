import { useQuery } from '@tanstack/vue-query'
import { computed, type ComputedRef } from 'vue'

import { V1_USER_QUERY_KEY, V1GetUser } from '@/v1.5/features/users/api'
import type { V1UserDataType, V2UserDetailsDataType } from '@/v1.5/features/users/types'

export function V1ToV2UserDataMiddleware(data?: Partial<V1UserDataType>): Partial<V2UserDetailsDataType> | undefined {
  if (!data) return undefined
  return {
    id: data.identity?.userId,
    firstName: data.identity?.firstname,
    lastName: data.identity?.lastname,
    picture: data.personalInformation?.profilPicture,
    role: data.role,
    title: data.mentorProfil?.title,
    description: data.mentorProfil?.description,
    matchingCriteria: data.matchingCriterias?.[0]?.value,
  }
}

const useUser = (userIdRef: ComputedRef<string | undefined>) => {
  const isUserIdDefinedRef = computed(() => !!userIdRef.value)
  const userQuery = useQuery({
    queryKey: [V1_USER_QUERY_KEY, { userId: userIdRef }],
    queryFn: () => V1GetUser(userIdRef.value!),
    enabled: isUserIdDefinedRef,
  })

  const data = computed(() => V1ToV2UserDataMiddleware(userQuery.data.value))

  return {
    userRef: data,
    isPendingRef: userQuery.isPending,
  }
}

export default useUser
