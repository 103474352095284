import type { StoreApi } from 'zustand'

import type { GroupsStoreValuesType } from '@/v1.5/features/groups/stores/groups/groups.store'
import { INITIAL_MENTORSHIP_GROUPS_STORE_VALUES } from '@/v1.5/features/groups/stores/groups/groups.store'

export function setSelectedGroupIdAction(
  get: StoreApi<GroupsStoreValuesType>['getState'],
  set: StoreApi<GroupsStoreValuesType>['setState'],
  groupId: string | null,
) {
  set({ selectedGroupId: groupId })
}

export function resetGroupsStoreAction(set: StoreApi<GroupsStoreValuesType>['setState']) {
  set(INITIAL_MENTORSHIP_GROUPS_STORE_VALUES)
}
