<!-- eslint-disable -->
<!-- @ts-nocheck -->
<template>
  <div class="">
    <template v-if="!register">
      <router-link
        class="card"
        :to="{ name: 'IndexProfile', params: { id: userId } }"
      >
        <div class="mentor-card_picture">
          <img
            :src="picture"
            class="card-img-top"
            :alt="`Photo profil de ${firstName}`"
          />
          <div class="ms-3 mb-3 mentor-card_picture_information text-light">
            <h2 class="fw-bold mb-0">{{ firstName }}</h2>
            <p class="fw-bold">
              {{ currentEstablishment }}
              <span v-if="oldEstablishment">et</span> {{ oldEstablishment }}
            </p>
          </div>
        </div>
        <div class="card-body mentor-card_body">
          <h5 class="card-title mt-3 fs-5">
            {{
              title && title.length > 55 ? `${title.substr(0, 55)}..` : title
            }}
          </h5>
          <div class="mentor-card_body_subject mt-2">
            <span v-for="subject in subjects" :key="subject.id" class="badge">{{
              subject
            }}</span>
          </div>
        </div>
      </router-link>
    </template>
    <template v-else>
      <router-link class="card" :to="{ name: 'Register' }">
        <div class="mentor-card_picture">
          <img
            :src="picture"
            class="card-img-top"
            :alt="`Photo profil de ${firstName}`"
          />
          <div class="ms-3 mb-3 mentor-card_picture_information text-light">
            <h2 class="fw-bold mb-0">{{ firstName }}</h2>
            <p class="fw-bold">
              {{ currentEstablishment }}
              <span v-if="oldEstablishment">et</span> {{ oldEstablishment }}
            </p>
          </div>
        </div>
        <div class="card-body mentor-card_body">
          <h5 class="card-title mt-3 fs-5">
            {{
              title && title.length > 55 ? `${title.substr(0, 55)}..` : title
            }}
          </h5>
          <div class="mentor-card_body_subject mt-2">
            <span v-for="subject in subjects" :key="subject.id" class="badge">{{
              subject
            }}</span>
          </div>
        </div>
      </router-link>
    </template>
  </div>
</template>

<script>
import StarRating from 'vue-star-rating'

export default {
  name: 'MentorTile',
  props: {
    firstName: String,
    currentEstablishment: String,
    oldEstablishment: String,
    picture: String,
    subjects: Array,
    userId: Number,
    nbReview: Number,
    title: String,
    register: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    StarRating,
  },
}
</script>

<style lang="scss" scoped>
.mentor-card {
  & a {
    color: #212529 !important;
    text-decoration: none;
  }
  &_picture {
    height: 240px;
    position: relative;
    & img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
    &_information {
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
  .test {
    display: inline-flex;
  }
  &_body {
    &_subject .badge {
      margin-right: 5px;
      background-color: var(--color-secondary);
    }
    &_gift {
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(255, 255, 255, 0.7);
      padding: 5px 10px;
      color: var(--color-primary) !important;
    }
  }
}
</style>
