// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// DEBOUNCE JS

export default function debounce(func: (...args) => void, wait: number, immediate: boolean) {
  let timeout

  return (...args) => {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const context = this

    const later = () => {
      timeout = null
      if (!immediate) func.apply(context, args)
    }

    const callNow = immediate && !timeout

    clearTimeout(timeout)

    timeout = setTimeout(later, wait)

    if (callNow) func.apply(context, args)
  }
}
