// @ts-nocheck 
// eslint-disable
import { mapActions } from 'vuex'

export const SetLoginData = {
  methods: {
    ...mapActions([
      'updateUserId',
      'updateUserData',
      'fetchUserIdentity',
      'fetchUserPhone',
      'fetchUser',
      'readModuleUserProfilePicture',
    ]),
    async startSetLoginData() {
      // Récupère l'identité de l'utilisateur
      await this.updateUserId(localStorage.getItem('userId'))
      this.fetchUser()
      this.fetchUserIdentity()
      this.fetchUserPhone()
      this.readModuleUserProfilePicture()
      // Transmet le userId au store
      // recupère certaines infos sur l'utilisateur et les envois au store
      if (localStorage.getItem('userId')) this.updateUserData()
    },
  },
}
