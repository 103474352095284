<!-- eslint-disable -->
<!-- @ts-nocheck -->
<template>
  <Page>
    <template v-slot:middle>
      <div class="mentors-page-container rounded bg-light p-4">
        <div class="info">
          <h1>Tous vos mentorés</h1>
          <h2>Visualisez vos mentorés inscrits sur la plateforme</h2>
        </div>
        <hr />
        <MenteesTable />
      </div>
    </template>
    <template v-slot:outside>
      <ChangeMentorPopup />
    </template>
  </Page>
</template>

<script>
import Page from '@/components/Page.vue'
import MenteesTable from '@/components/admin/mentees/MenteesTable.vue'
import ChangeMentorPopup from '@/components/admin/mentees/ChangeMentorPopup.vue'

export default {
  name: 'AdminMentees',
  data() {
    return {}
  },
  components: {
    Page,
    MenteesTable,
    ChangeMentorPopup,
  },
  computed: {},
  methods: {},
}
</script>
