<!-- eslint-disable -->
<!-- @ts-nocheck -->
<template>
  <div
    class="icon_event_status"
    :class="{
      'icon_event_status--success':
        status === 'FINISHED' ||
        status ===
          ($store.getters.userRole === 'ROLE_STUDENT'
            ? 'WAIT_MENTOR_REVIEW'
            : 'WAIT_STUDENT_REVIEW') ||
        status === 'PLANNED',
      'icon_event_status--warning':
        status === 'WAIT_REVIEWS' ||
        status ===
          ($store.getters.userRole === 'ROLE_STUDENT'
            ? 'WAIT_STUDENT_REVIEW'
            : 'WAIT_MENTOR_REVIEW') ||
        status === 'WAIT_CONFIRMATION',
    }"
    :style="
      size == 24 ? `width: 40px; height: 40px;` : `width: 32px; height: 32px;`
    "
  >
    <template v-if="status === 'CANCELLED'">
      <span class="material-symbols-outlined" :style="`font-size: ${size}px`">
        event_busy
      </span>
    </template>
    <template
      v-if="
        status === 'FINISHED' ||
        status ===
          ($store.getters.userRole === 'ROLE_STUDENT'
            ? 'WAIT_MENTOR_REVIEW'
            : 'WAIT_STUDENT_REVIEW')
      "
    >
      <span
        class="material-symbols-outlined"
        :style="`font-size: ${size}px`"
        style="
          font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 48;
        "
      >
        event_available
      </span>
    </template>
    <template v-if="status === 'PLANNED'">
      <span class="material-symbols-outlined" :style="`font-size: ${size}px`">
        event
      </span>
    </template>
    <template
      v-if="
        status === 'WAIT_REVIEWS' ||
        status ===
          ($store.getters.userRole === 'ROLE_STUDENT'
            ? 'WAIT_STUDENT_REVIEW'
            : 'WAIT_MENTOR_REVIEW')
      "
    >
      <span class="material-symbols-outlined" :style="`font-size: ${size}px`">
        event_note
      </span>
    </template>
    <template v-if="status === 'WAIT_CONFIRMATION'">
      <span class="material-symbols-outlined" :style="`font-size: ${size}px`">
        hourglass_bottom
      </span>
    </template>
  </div>
</template>

<script>
export default {
  name: 'SessionStatusIcon',
  props: {
    status: {
      type: String,
    },
    size: {
      type: Number,
      default: 24,
    },
  },
}
</script>

<style lang="scss" scoped>
.icon_event_status {
  border: 1px solid var(--bs-gray-dark);
  border-radius: 50%;
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  &--success {
    border: 1px solid var(--bs-success);
    color: var(--bs-success);
  }
  &--warning {
    border: 1px solid var(--bs-warning);
    color: var(--bs-warning);
  }
}

.material-symbols-outlined {
  font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 48;
}
</style>
