// @ts-nocheck 
// eslint-disable
import axios from 'axios'

const apiURL = import.meta.env.VITE_API_URL
const instance = axios.create({
  baseURL: `${apiURL}/v1/data/branch`,
})

// GET "/"
// 👊 ✓
export async function getBranchList() {
  const res = await instance.get()
  return res.data
}
// 👊 ✓
export async function apiCreateBranchList(payload, authToken) {
  const req = await fetch(`${apiURL}/v1/data/branch`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify(payload),
  })
  return req.json()
}
