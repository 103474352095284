<template>
  <div :class="['mobile-menu', { active: isMobileMenuOpenRef }]">
    <div class="mobile-menu__logo">
      <img :src="whiteLabelRef.logo" alt="logo" />
    </div>
    <div class="mobile-menu__down-block">
      <PeriodsDropdown />
      <GroupsDropdown />
      <ProgramsDropdown />
      <MainActionWidget />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, watch } from 'vue'

import GroupsDropdown from '@/v1.5/features/groups/components/groups-dropdown/groups-dropdown.vue'
import PeriodsDropdown from '@/v1.5/features/programs/components/periods-dropdown/periods-dropdown.vue'
import ProgramsDropdown from '@/v1.5/features/programs/components/programs-dropdown/programs-dropdown.vue'
import MainActionWidget from '@/v1.5/features/ui/components/layout/main-action-widget/main-action-widget.vue'
import menuStore from '@/v1.5/features/ui/store/menu/menu.store'
import useWhiteLabel from '@/v1.5/hooks/use-white-label.hook'
import useWindowSize from '@/v1.5/hooks/use-window-size.hook'
import { BREAKPOINTS } from '@/v1.5/utils/config/constants'

const { whiteLabelRef } = useWhiteLabel()
const { widthRef } = useWindowSize()
const isMobileMenuOpenRef = computed(() => menuStore.isMobileMenuOpen)

watch([widthRef, isMobileMenuOpenRef], ([width, isMobileMenuOpen]) => {
  // select all siblings of .mobile-menu
  const otherElements = document.querySelectorAll('.mobile-menu ~ *')

  if (isMobileMenuOpen && width < BREAKPOINTS.DESKTOP) {
    otherElements.forEach((el) => {
      el.classList.add('c-display-none')
    })
  } else {
    otherElements.forEach((el) => {
      el.classList.remove('c-display-none')
    })
  }
})
</script>

<style lang="scss" scoped>
@import './mobile-menu.scss';
</style>
