<!-- eslint-disable -->
<!-- @ts-nocheck -->
<template>
  <PageOnboarding class="page-onboarding" pictureLeft="student-write.jpg">
    <template v-slot:right>
      <h1 class="fs-5 mb-4">Ces informations sont-elles exactes ?</h1>
      <form @submit.prevent="submitForm" class="register-form">
        <div class="row mb-4">
          <div class="col-lg-12">
            <label for="" class="form-label">E-mail *</label>
            <div class="input-group has-validation">
              <input
                required
                class="form-control"
                type="email"
                placeholder="Saisissez votre adresse e-mail.."
                autocomplete="email"
                v-model="email"
              />
            </div>
          </div>
          <div class="col-6 mt-2">
            <label for="" class="form-label">Prénom *</label>

            <input
              ref="firstName"
              placeholder="Votre prénom"
              v-model="firstname"
              type="text"
              class="form-control"
              required
            />
          </div>

          <div class="col-6 mt-2">
            <label for="" class="form-label">Nom *</label>

            <input
              placeholder="Votre nom"
              v-model="lastname"
              type="text"
              class="form-control"
              required
            />
          </div>

          <div class="col-lg-12">
            <label for="" class="form-label mt-3">Téléphone mobile</label>

            <div class="input-group">
              <vue-tel-input
                :inputOptions="tel_options"
                :defaultCountry="phonePrefix"
                aria-placeholder="test"
                class="phone"
                v-model="phoneNumber"
                @validate="phoneValidEvent"
              ></vue-tel-input>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-between align-items-center">
          <p class="mb-0">* champs obligatoires</p>
          <button class="btn btn-primary" :disabled="loading">
            <span
              v-show="loading"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            Suivant
          </button>
        </div>
      </form>
    </template>
  </PageOnboarding>
</template>

<script>
import PageOnboarding from '@/components/PageOnboarding.vue'
import { VueTelInput } from 'vue-tel-input'
import { updateUser, updateIdentity } from '@/services/userService'
import { updatePhone } from '@/services/PhoneService'

export default {
  name: 'confirmAccountInformations',
  components: {
    PageOnboarding,
    VueTelInput,
  },
  watch: {
    '$store.getters.userFirstname'(v) {
      return (this.firstname = v)
    },
    '$store.getters.userLastname'(v) {
      return (this.lastname = v)
    },
    '$store.getters.userPhonePrefix'(v) {
      return (this.phonePrefix = v)
    },
    '$store.getters.userPhoneNumber'(v) {
      return (this.phoneNumber = v)
    },
    '$store.getters.userEmail'(v) {
      return (this.email = v)
    },
  },
  data() {
    return {
      tel_options: {
        placeholder: 'Téléphone',
      },
      email: null,
      firstname: null,
      lastname: null,
      phoneNumber: null,
      phonePrefix: 'FR',
      phone: null,
      loading: false,
    }
  },
  mounted() {
    if (this.$store.getters.userFirstname)
      this.firstname = this.$store.getters.userFirstname
    if (this.$store.getters.userLastname)
      this.lastname = this.$store.getters.userLastname
    if (this.$store.getters.userPhonePrefix)
      this.phonePrefix = this.$store.getters.userPhonePrefix
    if (this.$store.getters.userPhoneNumber)
      this.phoneNumber = this.$store.getters.userPhoneNumber
    if (this.$store.getters.userEmail)
      this.email = this.$store.getters.userEmail
  },
  methods: {
    phoneValidEvent(result) {
      if (result.valid) this.phone = result.number
    },
    async submitForm() {
      this.loading = true
      // E-mail update
      let result
      result = await updateUser(
        {
          email: this.email,
        },
        this.$store.getters.authToken
      )
      if (result.error)
        return this.$toast.error(result.error, {
          timeout: 2000,
        })
      this.$store.dispatch('userUpdateEmail', { email: this.email })

      // Firstname, lastname update
      result = await updateIdentity(
        {
          firstname: this.firstname,
          lastname: this.lastname,
        },
        this.$store.getters.authToken,
        this.$store.getters.authUserId
      )
      if (result.error)
        return this.$toast.error(result.error, {
          timeout: 2000,
        })
      this.$store.dispatch('userUpdateFirstname', { firstname: this.firstname })
      this.$store.dispatch('userUpdateLastname', { lastname: this.lastname })

      // Phone update
      if (this.phone) {
        result = await updatePhone(this.phone, this.$store.getters.authToken)
        if (result.error)
          return this.$toast.error(result.error, {
            timeout: 2000,
          })
        this.$store.dispatch('fetchUserPhone')
      }
      this.loading = false
      return this.$router.push({ name: 'RegisterRole' })
    },
  },
}
</script>

<style lang="scss" scoped>
.register-form {
  max-width: 400px;
  @media all and (max-width: 425px) {
    max-width: 300px;
  }
}
.progress {
  height: 5px !important;
}
strong {
  color: var(--color-primary);
}

.link-login-responsive {
  display: none !important;
  @media all and (max-width: 991px) {
    display: flex !important;
  }
}

.small-text {
  font-size: 0.9em;
}

.phone {
  width: 100%;
}
</style>
