<!-- eslint-disable -->
<!-- @ts-nocheck -->
<template>
  <Page>
    <template v-slot:middle>
      <Groups/>
    </template>
    <template v-slot:outside>
      <GroupStructureModal />
    </template>
  </Page>
</template>

<script>
import Page from '@/components/Page.vue'
import Groups from '@/v1.5/(routes)/(protected)/(app)/admin/groups/page.vue'
import GroupStructureModal from '@/v1.5/features/admin/groups/components/group-structure-modal/group-structure-modal.vue'

export default {
  name: 'AdminGroups',
  data() {
    return {}
  },
  components: {
    Page,
    Groups,
    GroupStructureModal,
  },
}
</script>

<style lang="scss" scoped></style>
