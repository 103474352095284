<!-- eslint-disable -->
<!-- @ts-nocheck -->
<template>
  <div class="vh-100">
    <div class="pt-4 vh-100 d-flex flex-column align-items-center">
      <img :src="$store.getters.logo" alt="Logo" />
      <div class="mt-4 text-center" v-html="$store.getters.maintenance"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Maintenance',
}
</script>

<style lang="scss" scoped>
img {
  width: 168px;
}
</style>
