<template>
  <section class="admin-sessions-content">
    <UsersSearch :search-callback="searchCallback" @emit:result="setEmail" />
    <GridBlockBase id="sessions-listing">
      <template #icon>
        <calendarIcon color="black" filled />
      </template>
      <template #body>
        <SessionsList :sessions="filteredSessionsRef" :isPending="isPendingRef" order="desc" />
      </template>
      <template #headerExtra>
        <CustomSelect
          :is-disabled="true"
          style="opacity: 0"
          id="sessions-status"
          :value="selectedStatusRef"
          :options="sessionsListOptionsRef"
          @emit:change="onFilterType"
        />
        <CustomButton
          :is-loading="adminExportSessionsActionIsloadingRef"
          icon-position="right"
          color="primary"
          text="Exporter"
          usage="button"
          data-action="admin-sessions-export"
          :data-attached="JSON.stringify({ program: selectedProgramRef, periodId: selectedPeriodIdRef })"
        >
          <template #icon>
            <DropdownIcon color="black" />
          </template>
        </CustomButton>
      </template>
      <template #footer>
        <div>
          <span v-if="isPendingRef" class="table-sub-info">{{ t('ui.message.loading') }}</span>
          <span v-else class="table-sub-info">
            {{
              t('ui.pagination.resultsOfOn', {
                start: Math.min(Math.max(1, perPageRef * (pageRef - 1) + 1), totalSessionsRef ?? 1),
                end: Math.min(perPageRef * pageRef, totalSessionsRef ?? 1),
                total: totalSessionsRef,
              })
            }}
          </span>
        </div>
        <div class="page-select-wrapper">
          <CustomSelect id="page-select" :value="`${pageRef}`" :options="pagesOptionsRef" @emit:change="onPageChange" />
          <span v-if="isPendingRef" class="table-sub-info"> {{ t('ui.message.loading') }} </span>
          <span v-else class="table-sub-info">
            {{ t('ui.pagination.totalPages', { total: totalPagesRef }) }}
          </span>
        </div>
      </template>
    </GridBlockBase>
  </section>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import type { AdminSessionCardType } from '@/v1.5/features/admin/sessions/components/session-card/session-card.vue'
import SessionsList from '@/v1.5/features/admin/sessions/components/sessions-list/sessions-list.vue'
import useProgram from '@/v1.5/features/programs/hooks/use-program.hook'
import {
  selectGetSelectedPeriodId,
  selectGetSelectedProgramId,
} from '@/v1.5/features/programs/stores/programs/programs.selectors'
import programsStore from '@/v1.5/features/programs/stores/programs/programs.store'
import useSessions from '@/v1.5/features/sessions/hooks/use-sessions.hook'
import { SessionStatusEnumType } from '@/v1.5/features/sessions/types'
import type { SessionsStatusesOptionsType } from '@/v1.5/features/sessions/utils'
import { sessionAdminStatusesOptionsMap, SessionOptionEnumDefaultAllType } from '@/v1.5/features/sessions/utils'
import CustomButton from '@/v1.5/features/ui/components/button/custom-button.vue'
import GridBlockBase from '@/v1.5/features/ui/components/grid-block-base/grid-block-base.vue'
import calendarIcon from '@/v1.5/features/ui/components/icons/calendar-icon.vue'
import DropdownIcon from '@/v1.5/features/ui/components/icons/dropdown-icon.vue'
import { selectGetMoreActions } from '@/v1.5/features/ui/components/more-actions-menu/store/more-actions.selectors'
import type { AdminSessionsExportMoreActionsType } from '@/v1.5/features/ui/components/more-actions-menu/store/more-actions.store'
import moreActionsStore from '@/v1.5/features/ui/components/more-actions-menu/store/more-actions.store'
import CustomSelect from '@/v1.5/features/ui/components/select/custom-select.vue'
import UsersSearch from '@/v1.5/features/ui/pages/admin/users/users-search/users-search.vue'
import type { V2UserAdminDataType } from '@/v1.5/features/users/types'
import { useI18n } from '@/v1.5/lib/i18n'

const { t } = useI18n()

const userMailToFilterRef = ref<string | null>(null)

const setEmail = (user: V2UserAdminDataType | undefined) => {
  userMailToFilterRef.value = user?.email ?? null
}

const searchCallback = () => {
  pageRef.value = 1
}

const pageRef = ref<number>(1)
const perPageRef = ref<number>(20)
const { sessionsRef, totalPagesRef, totalSessionsRef, isPendingRef } = useSessions({
  filterEmailSearchRef: userMailToFilterRef,
  pageRef,
  perPageRef,
})

const selectedProgramIdRef = selectGetSelectedProgramId(programsStore)
const selectedPeriodIdRef = selectGetSelectedPeriodId(programsStore)
const { programRef: selectedProgramRef } = useProgram(selectedProgramIdRef)

// check if the export action is loading
const adminExportSessionsMoreActionsRef = selectGetMoreActions(moreActionsStore, 'admin-sessions-export')
const adminExportSessionsActionIsloadingRef = computed(
  () =>
    (adminExportSessionsMoreActionsRef.value as AdminSessionsExportMoreActionsType)?.attachedData?.isLoading ?? false,
)

function onPageChange(value: number) {
  pageRef.value = value
}

// current selected status
const selectedStatusRef = ref<SessionsStatusesOptionsType>(SessionOptionEnumDefaultAllType.ALL)

// on select change, fitler the sessions
function onFilterType(value: SessionsStatusesOptionsType) {
  selectedStatusRef.value = value
}

const sessionsListOptionsRef = computed(() => {
  return [
    {
      label: `${sessionAdminStatusesOptionsMap[SessionOptionEnumDefaultAllType.ALL]}`,
      value: SessionOptionEnumDefaultAllType.ALL,
    },
    {
      label: `${sessionAdminStatusesOptionsMap[SessionStatusEnumType.PLANNED]}`,
      value: SessionStatusEnumType.PLANNED,
    },
    {
      label: `${sessionAdminStatusesOptionsMap[SessionStatusEnumType.ONGOING]}`,
      value: SessionStatusEnumType.ONGOING,
    },
    {
      label: `${sessionAdminStatusesOptionsMap[SessionStatusEnumType.FINISHED]}`,
      value: SessionStatusEnumType.FINISHED,
    },
    {
      label: `${sessionAdminStatusesOptionsMap[SessionStatusEnumType.CANCELLED]}`,
      value: SessionStatusEnumType.CANCELLED,
    },
  ] satisfies { label: string; value: SessionsStatusesOptionsType }[]
})

const pagesOptionsRef = computed(() => {
  return Array.from({ length: totalPagesRef.value ?? 0 }, (_, i) => ({
    label: `${t('ui.pagination.page')} ${i + 1}`,
    value: `${i + 1}`,
  })) satisfies { label: string; value: string }[]
})

// function to filter the sessions
const filteredSessionsRef = computed<AdminSessionCardType[]>(() => {
  switch (selectedStatusRef.value) {
    // case SessionStatusEnumType.PLANNED:
    //   return plannedSessionsRef.value
    // case SessionStatusEnumType.FINISHED:
    //   return completedSessionsRef.value
    // case SessionStatusEnumType.CANCELLED:
    //   return canceledSessionsRef.value
    // case SessionStatusEnumType.ONGOING:
    //   return onGoingSessionsRef.value

    // default case ALL
    default:
      return [
        ...sessionsRef.value,
        // ...plannedSessionsRef.value,
        // ...completedSessionsRef.value,
        // ...canceledSessionsRef.value,
        // ...onGoingSessionsRef.value,
      ]
  }
})
</script>

<style lang="scss">
@import './sessions-content.scss';
</style>
