import 'dayjs/locale/fr' // Import the French locale

import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import duration from 'dayjs/plugin/duration'
import isBetween from 'dayjs/plugin/isBetween'
import relativeTime from 'dayjs/plugin/relativeTime'

import { I18N_DEFAULT_LOCALE, I18N_LOCAL_STORAGE_KEY } from '@/v1.5/utils/config/constants'

// make instance of dayjs
const dayjsClient = dayjs

// Extend dayjs with the isBetween plugin.
dayjsClient.extend(isBetween)
dayjsClient.extend(customParseFormat)
dayjsClient.extend(duration)
dayjsClient.extend(relativeTime)

// use locale
const paramsLocal = window.localStorage.getItem(I18N_LOCAL_STORAGE_KEY) || I18N_DEFAULT_LOCALE
dayjsClient.locale(paramsLocal)

export default dayjsClient
