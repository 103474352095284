<!-- eslint-disable -->
<!-- @ts-nocheck -->
<template>
  <div v-if="indiceMentor">
    <ais-instant-search
      :search-client="searchClient"
      :index-name="indiceMentor"
    >
      <div class="mentor-card mt-3">
        <!-- <div class=" searchbar ms-auto me-2 mb-5">
          <div class="input-group" style="box-shadow: 0 3px 6px 0 rgb(0 0 0 / 11%);">
            <div class="input-autocomplete col-12 ">
              <input @blur="sendAnalyticsData" type="text" placeholder="Matière ? Essayez Maths, Géopo, Physique..." aria-label="Matière" class="rounded form-control bg-light fs-5 py-3 d-desktop" v-model="subjects" @input="updateQuery('subjects')" />
              <input @blur="sendAnalyticsData" type="text" placeholder="Matières ? Maths, Anglais..." aria-label="Matière" class="rounded form-control bg-light fs-5 py-3 d-mobile" v-model="subjects" @input="updateQuery('subjects')" />
            </div>
          </div>
          <button class="btn btn-primary btn-lg btn-search d-desktop" type="submit">
            Rechercher
          </button>
          <button class="btn btn-primary btn-lg btn-search d-mobile" type="submit">
            <i class="fas fa-search"></i>
          </button>
        </div> -->

        <ais-stats>
          <p class="mb-4 fs-5 col-lg-12" slot-scope="{ nbHits }">
            Plus de {{ nbHits }} mentors disponibles
          </p>
        </ais-stats>

        <ais-configure :hits-per-page.camel="12" />

        <div v-show="false">
          <ais-search-box v-model="query" />
        </div>

        <ais-hits
          :class-names="{
            'ais-Hits-item': 'col-lg-4 mb-4 ',
            'ais-Hits-list': 'row',
          }"
        >
          <div
            class="bg-light border-main border-main--shadow"
            style="height: 100%"
            slot="item"
            slot-scope="{ item }"
          >
            <MentorTile
              :title="item.title"
              :nbReview="item.nbFeedback"
              :key="item.id"
              :firstName="item.firstName"
              :currentEstablishment="item.currentEstablishment"
              :oldEstablishment="item.oldEstablishment"
              :picture="item.picture"
              :subjects="item.subjects"
              :userId="item.id"
            />
          </div>
        </ais-hits>

        <div
          class="d-flex justify-content-between align-items-center pagination-container pe-0"
        >
          <ais-stats>
            <p slot-scope="{ hitsPerPage, nbHits, page, nbSortedHits }">
              {{ page * 24 }} -
              {{
                hitsPerPage * page + hitsPerPage > nbHits
                  ? nbHits
                  : hitsPerPage * page + hitsPerPage
              }}
              sur plus de {{ nbHits }} résultats {{ nbSortedHits }}
            </p>
          </ais-stats>

          <ais-pagination
            :class-names="{
              'ais-Pagination-list': 'pagination justify-content-end',
              'ais-Pagination-item': 'page-item',
              'ais-Pagination-link': 'page-link',
              'ais-Pagination-item--selected': 'active',
              'ais-Pagination-item--disabled': 'disabled',
            }"
          />
        </div>
      </div>
    </ais-instant-search>
  </div>
</template>

<script>
import MentorTile from './MentorTile.vue'
import algoliasearch from 'algoliasearch/lite'
// import VueSlider from 'vue-slider-component'
import { getBranchList } from '../services/BranchListService'

import { mapGetters } from 'vuex'
// import { mapState } from 'vuex'

export default {
  name: 'TheMentorList',
  data() {
    return {
      mentors: null,
      searchClient: algoliasearch(
        '7LVHY3ES88',
        '5bcc32d4b5752dc7b5a095e220b2b3b6'
      ),
      query: '',
      subjects: '',
      branch: '',
      newEstablishment: '',
      branchList: null,
    }
  },
  components: {
    MentorTile,
    // VueSlider
  },
  watch: {
    '$route.query.s'() {
      this.query = this.$route.query.s
    },
  },
  async mounted() {
    if (this.$route.query.s) this.query = this.$route.query.s
    this.branchList = await getBranchList()
  },
  computed: {
    ...mapGetters(['indiceMentor']),
  },
  methods: {
    // Permet de lier les deux inputs matieres et filiere vers le champ de recherche algolia caché
    updateQuery(input) {
      const searchSentences = []

      if (this.subjects != '') searchSentences.push(this.subjects)
      if (this.branch != '') searchSentences.push(this.branch)
      if (this.newEstablishment != '')
        searchSentences.push(this.newEstablishment)

      let i = null
      if (input == 'subjects') {
        i = searchSentences.indexOf(this.subjects)
        searchSentences.splice(i, 1)
        searchSentences.push(this.subjects)
      } else if (input == 'branch') {
        i = searchSentences.indexOf(this.branch)
        searchSentences.splice(i, 1)
        searchSentences.push(this.branch)
      } else if (input == 'newEstablishment') {
        i = searchSentences.indexOf(this.newEstablishment)
        searchSentences.splice(i, 1)
        searchSentences.push(this.newEstablishment)
      }

      this.query = searchSentences.join(' ')
    },
  },
}
</script>

<style lang="scss">
$themeColor: var(--color-primary);
@import 'vue-slider-component/lib/theme/default.scss';
</style>

<style lang="scss" scoped>
.searchbar {
  position: relative;
  & button {
    position: absolute;
    z-index: 10;
    top: 50%;
    transform: translateY(-50%);
    right: 17px;
  }
}
.form-control {
  border: none;
}

.mentor-filters {
  // border-bottom: 1px solid #e0ded9;
  position: fixed;
  width: 100%;
  top: 91px;
  left: 12px;
  z-index: 11;
  @media all and (max-width: 991px) {
    margin: 0;
    left: 0;
  }
  &_item {
    &_dropdown {
      width: 320px;
      &_send {
        cursor: pointer;
        color: var(--color-primary);
      }
    }
  }
}
.spaceLeft {
  left: 292px;
  @media all and (max-width: 991px) {
    margin: 0;
    left: 0;
  }
}

.vue-slider {
  width: calc(100% - 14px) !important;
  margin: auto;
}

.pagination-container {
  @media all and (max-width: 991px) {
    flex-direction: column;
  }
}
</style>
