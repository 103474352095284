// @ts-nocheck 
// eslint-disable
const apiURL = import.meta.env.VITE_API_URL

// 👊 ✓
export async function apiReadRoomUrl(id, authToken) {
  let dataAdmin = new FormData()
  dataAdmin.append('sessionId', id)
  const req = await fetch(
    `${import.meta.env.VITE_API_URL}/v1/meeting/OpenMeeting`,
    {
      method: 'POST',
      headers: { authorization: `Bearer ${authToken}` },
      body: dataAdmin,
    }
  )
  return await req.json()
}
// 👊 ✓
export async function apiAddMeetingTracking(id, userId, authToken) {
  let dataAdmin = new FormData()
  dataAdmin.append('meetingRoomId', id)
  dataAdmin.append('userId', userId)
  const req = await fetch(`${import.meta.env.VITE_API_URL}/v1/meeting/join`, {
    method: 'POST',
    headers: { authorization: `Bearer ${authToken}` },
    body: dataAdmin,
  })
  return await req.json()
}
// 👊 ✓
export async function apiExitRoom(id, authToken) {
  let dataAdmin = new FormData()
  dataAdmin.append('id', id)
  const req = await fetch(`${import.meta.env.VITE_API_URL}/v1/meeting/exit`, {
    method: 'POST',
    headers: { authorization: `Bearer ${authToken}` },
    body: dataAdmin,
  })
  return await req.json()
}
// 👊 ✓
export async function apiAddToggleMicrophone(id, authToken) {
  let dataAdmin = new FormData()
  dataAdmin.append('id', id)
  const req = await fetch(
    `${import.meta.env.VITE_API_URL}/v1/meeting/toggleMicrophone`,
    {
      method: 'POST',
      headers: { authorization: `Bearer ${authToken}` },
      body: dataAdmin,
    }
  )
  return await req.json()
}
// 👊 ✓
export async function apiAddToggleCamera(id, authToken) {
  let dataAdmin = new FormData()
  dataAdmin.append('id', id)
  const req = await fetch(
    `${import.meta.env.VITE_API_URL}/v1/meeting/toggleCamera`,
    {
      method: 'POST',
      headers: { authorization: `Bearer ${authToken}` },
      body: dataAdmin,
    }
  )
  return await req.json()
}

export async function apiAuthMeeting(authToken, sessionId) {
  const req = await fetch(`${apiURL}/v1/meeting/auth?sessionId=${sessionId}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
  })
  return await req.json()
}
