<template>
  <div class="top-nav">
    <div class="top-nav__left-block">
      <h2></h2>
    </div>
    <div class="top-nav__right-block">
      <div class="top-nav__dropdowns-desktop">
        <PeriodsDropdown />
        <GroupsDropdown />
        <ProgramsDropdown />
      </div>
      <div class="top-nav__avatar-wrapper" data-action="account" :data-attached="JSON.stringify(accountRef)">
        <Avatar :user="accountRef" />
      </div>
      <button class="mobile-hamburger" @click="toggleMobileMenu">
        <CrossIcon v-if="menuStore.isMobileMenuOpen" color="black" />
        <HamburgerMenuIcon v-else color="black" />
        <div
          v-if="!menuStore.isMobileMenuOpen && doesUserHaveNotificationsInOtherGroupsRef"
          class="c-notification-badge"
        ></div>
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import useAccount from '@/v1.5/features/auth/hooks/use-account.hook'
import GroupsDropdown from '@/v1.5/features/groups/components/groups-dropdown/groups-dropdown.vue'
import useGroups from '@/v1.5/features/groups/hooks/use-groups.hook'
import { selectGetSelectedGroupId } from '@/v1.5/features/groups/stores/groups/groups.selectors'
import groupsStore from '@/v1.5/features/groups/stores/groups/groups.store'
import PeriodsDropdown from '@/v1.5/features/programs/components/periods-dropdown/periods-dropdown.vue'
import ProgramsDropdown from '@/v1.5/features/programs/components/programs-dropdown/programs-dropdown.vue'
import CrossIcon from '@/v1.5/features/ui/components/icons/cross-icon.vue'
import HamburgerMenuIcon from '@/v1.5/features/ui/components/icons/hamburger-menu-icon.vue'
import menuStore from '@/v1.5/features/ui/store/menu/menu.store'
import Avatar from '@/v1.5/features/users/components/avatar/avatar.vue'
import useNotifications from '@/v1.5/hooks/use-notifications.hook'

const { accountRef } = useAccount()
const { allNotificationsPerGroupRef } = useNotifications()
const { groupsRef } = useGroups()

const selectedGroupIdRef = selectGetSelectedGroupId(groupsStore)

// check if the user has notifications in other groups
const doesUserHaveNotificationsInOtherGroupsRef = computed(() => {
  return groupsRef.value.some(
    (group) =>
      group.id !== selectedGroupIdRef.value &&
      allNotificationsPerGroupRef?.value?.find((notifPerGroup) => notifPerGroup.mentorshipGroupId === group.id)
        ?.notifications.total,
  )
})

function toggleMobileMenu() {
  menuStore.toggleMobileMenu()
}
</script>

<style lang="scss" scoped>
@import './top-nav.scss';
</style>
