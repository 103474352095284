<!-- eslint-disable -->
<!-- @ts-nocheck -->
<template>
  <Page>
    <template v-slot:middle>
      <Profile />
    </template>
    <template v-slot:outside>
<EditPasswordModal/>
<ProfileInformationModal/>
    </template>
  </Page>
</template>

<script>
import Page from '@/components/Page.vue'
import Profile from '@/v1.5/(routes)/(protected)/(app)/profile/page.vue'
import EditPasswordModal from '@/v1.5/features/auth/components/edit-password-modal/edit-password-modal.vue'
import ProfileInformationModal from '@/v1.5/features/auth/components/profile-information-modal/profile-information-modal.vue'

export default {
  name: 'IndexProfile',
  components: {
    Page,
    Profile,
    EditPasswordModal,
    ProfileInformationModal,
  },
}
</script>
