<template>
  <div :id="id" class="modal-step">
    <div class="modal-header">
      <slot name="headerBeforeElement"></slot>
      <div class="modal-header__title-wrapper">
        <div class="back-button" @click="onExitHandler ? onExitHandler() : undefined">
          <ArrowIcon color="black" rotation="180" />
        </div>
        <h2 v-html="header.title"></h2>
      </div>
      <slot name="headerAfterElement"></slot>
    </div>
    <div class="modal-content">
      <div v-if="slots.bodyInformation" class="modal-information">
        <slot name="bodyInformation"></slot>
      </div>

      <slot name="bodyContent"></slot>
    </div>
    <div class="modal-footer" v-if="slots.footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useSlots } from 'vue'

import ArrowIcon from '@/v1.5/features/ui/components/icons/arrow-icon.vue'
const slots = useSlots()

defineProps<{
  id: string
  header: {
    title: string
  }
  onExitHandler?: () => void
}>()
</script>

<style lang="scss">
@import './modal-step.scss';
</style>
