<template>
  <ModalStep
    id="users-import-confirmation-modal-content"
    :header="{
      title: t('admin.users.excelConfirmationModal.title'),
    }"
  >
    <template #bodyContent>
      <div v-if="usersRef">
        <div class="users-import-table">
          <div class="table-header">
            <div class="table-header__element" v-for="row in tableHeaderRowsRef" :key="row.key">
              <span>{{ row.label }}</span>
            </div>
          </div>
          <div class="table-content">
            <div class="table-row" v-for="user in usersRef" :key="user.email">
              <div class="table-row__element" v-for="headerRow in tableHeaderRowsRef" :key="headerRow.key">
                <template v-if="headerRow.key === 'programId'">
                  <span>{{ programsRef.find((program) => program.id === user[headerRow.key])?.name }}</span>
                </template>
                <template v-if="headerRow.key === 'sso'">
                  <span>
                    <template v-if="user[headerRow.key] === true">Oui</template>
                    <template v-else-if="user[headerRow.key] === false">Non</template>
                    <template v-else>-</template>
                  </span>
                </template>
                <template v-else>
                  <span>{{ user[headerRow.key] }}</span>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template #footer>
      <div class="c-btns-container">
        <CustomButton usage="button" type="submit" color="white" :text="t('ui.button.back')" @emit:click="onPrev" />
        <CustomButton
          :is-loading="isImportAdminUsersPendingRef"
          usage="button"
          type="submit"
          color="primary"
          :text="t('ui.button.confirm')"
          @emit:click="onConfirm"
        >
        </CustomButton>
      </div>
    </template>
  </ModalStep>
</template>

<script setup lang="ts">
import { useMutation } from '@tanstack/vue-query'
import { computed, ref } from 'vue'

import { importAdminUsersMutation } from '@/v1.5/features/admin/users/api'
import { ImportUserKeysEnumType } from '@/v1.5/features/admin/users/types'
import usePrograms from '@/v1.5/features/programs/hooks/use-programs.hook'
import {
  selectGetSelectedPeriodId,
  selectGetSelectedProgramId,
} from '@/v1.5/features/programs/stores/programs/programs.selectors'
import programsStore from '@/v1.5/features/programs/stores/programs/programs.store'
import CustomButton from '@/v1.5/features/ui/components/button/custom-button.vue'
import ModalStep from '@/v1.5/features/ui/components/modal/modal-step/modal-step.vue'
import { selectGetAdminUsersImportModal } from '@/v1.5/features/ui/store/modal/modal.selectors'
import type { AdminUsersImportModalType } from '@/v1.5/features/ui/store/modal/modal.store'
import modalsStore from '@/v1.5/features/ui/store/modal/modal.store'
import useToast from '@/v1.5/hooks/use-toasts.hook'
import { useI18n } from '@/v1.5/lib/i18n'
import { invalidateAdminUsers } from '@/v1.5/utils/lib/vue-query'

const toast = useToast()
const { t } = useI18n()

const selectedProgramIdRef = selectGetSelectedProgramId(programsStore)
const selectedPeriodIdRef = selectGetSelectedPeriodId(programsStore)

const adminUsersImportModalRef = selectGetAdminUsersImportModal(modalsStore)

const { programsRef } = usePrograms()

const usersRef = computed(() => adminUsersImportModalRef.value.attachedData.users) as any // any because of the html code

const tableHeaderRowsRef = ref<{ key: ImportUserKeysEnumType; label: string }[]>([
  {
    key: ImportUserKeysEnumType.email,
    label: t('admin.users.excelConfirmationModal.key.email'),
  },
  {
    key: ImportUserKeysEnumType.firstName,
    label: t('admin.users.excelConfirmationModal.key.firstName'),
  },
  {
    key: ImportUserKeysEnumType.lastName,
    label: t('admin.users.excelConfirmationModal.key.lastName'),
  },
  {
    key: ImportUserKeysEnumType.role,
    label: t('admin.users.excelConfirmationModal.key.role'),
  },
  {
    key: ImportUserKeysEnumType.phone,
    label: t('admin.users.excelConfirmationModal.key.phone'),
  },
  {
    key: ImportUserKeysEnumType.matchingCriteria,
    label: t('admin.users.excelConfirmationModal.key.matchingCriteria'),
  },
  {
    key: ImportUserKeysEnumType.sso,
    label: t('admin.users.excelConfirmationModal.key.sso'),
  },
])

const { mutate: importAdminUsers, isPending: isImportAdminUsersPendingRef } = useMutation({
  mutationFn: importAdminUsersMutation,
  onError: () => {
    toast?.error(t('error.common'))
  },
  onSuccess: async () => {
    await invalidateAdminUsers(selectedProgramIdRef.value!)
    toast?.success(t('admin.users.excelConfirmationModal.success'))
    modalsStore.toggleModal('admin-users-import', false)
  },
})

function onConfirm() {
  // do stuff
  importAdminUsers({
    programId: selectedProgramIdRef.value!,
    periodId: selectedPeriodIdRef.value!,
    users: usersRef.value,
  })
}

function onPrev() {
  modalsStore.setModalStep<AdminUsersImportModalType>('admin-users-import', 'users-import')
}
</script>

<style lang="scss">
@import './users-import-confirmation-modal-content.scss';
</style>
