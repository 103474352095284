// @ts-nocheck
// eslint-disable
import axios from 'axios'

const apiURL = import.meta.env.VITE_API_URL
const instance = axios.create({
  baseURL: `${apiURL}/v1/admin`
})
export async function fetchMentors(authToken, pogramId) {
  let formData = new FormData()

  const url = `${import.meta.env.VITE_API_URL}/v1/admin/mentors?programId=${pogramId}`

  const res = await axios.get(url, {
    headers: {
      authorization: `Bearer ${authToken}`
    }
  })

  return res.data
}
export async function updateMentorApprovalMutation({ userId, value, authToken }) {
  let data = new FormData()
  data.append('user', userId)
  data.append('value', value)

  const url = `${import.meta.env.VITE_API_URL}/v1/admin/Visibility`

  const res = await axios.post(url, data, {
    headers: {
      //TODO RESET TO BASE AUTHORIZATION TOKEN FROM LOCALSTORAGE
      authorization: `Bearer ${authToken}`
    }
  })
}
export async function changeMentorMutation({ menteeId, mentorId, groupId, oldMentorId, programId, programPeriodId, authToken }) {
  let dataAdmin = new FormData()
  dataAdmin.append('id', menteeId)
  dataAdmin.append('groupId', groupId)
  dataAdmin.append('mentor', mentorId)
  dataAdmin.append('oldMentor', oldMentorId)
  dataAdmin.append('programId', programId)
  const req = await axios.put(`${import.meta.env.VITE_API_URL}/v2/matching/changeMentor`, dataAdmin, {
    headers: {
      //TODO RESET TO BASE AUTHORIZATION TOKEN FROM LOCALSTORAGE
      authorization: `Bearer ${authToken}`
    }
  })
}
export async function fetchMentorProfile({ user, authToken }) {
  let dataMentor = new FormData()
  dataMentor.append('user', user)

  let req = await axios.post(`${import.meta.env.VITE_API_URL}/v1/admin/UserInfo`, dataMentor, {
    headers: {
      //TODO RESET TO BASE AUTHORIZATION TOKEN FROM LOCALSTORAGE
      authorization: `Bearer ${authToken}`
    }
  })
  return req.data.mentor
}
export async function fetchMentees(authToken, programId) {
  const req = await axios.get(`${import.meta.env.VITE_API_URL}/v1/admin/student?programId=${programId}`, {
    headers: {
      //TODO RESET TO BASE AUTHORIZATION TOKEN FROM LOCALSTORAGE
      authorization: `Bearer ${authToken}`
    }
  })

  return req.data.students
}
export async function fetchAllSessions(authToken, programId, periodId, page = 1, perPage = 10, search = '') {
  const req = await axios.get(`${import.meta.env.VITE_API_URL}/v1/admin/session?programId=${programId}&periodId=${periodId}&page=${page}&perPage=${perPage}&search=${search}`, {
    headers: {
      //TODO RESET TO BASE AUTHORIZATION TOKEN FROM LOCALSTORAGE
      authorization: `Bearer ${authToken}`
    }
  })
  return req.data
}

export async function apiAdminGetStatistics(authToken, programId, periodId) {
  const req = await fetch(`${import.meta.env.VITE_API_URL}/v1/data/statistics?programId=${programId}&periodId=${periodId}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`
    }
  })
  return await req.json()
}

export async function fetchGroups(authTOken, programId) {
  const req = await fetch(`${import.meta.env.VITE_API_URL}/v2/groups?programId=${programId}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: `Bearer ${authTOken}`
    }
  })
  return await req.json()
}

export async function apiImportUsers(authToken, programId, periodId, users) {
  const url = `${import.meta.env.VITE_API_URL}/v1/auth/register/batch/?programId=${programId}&periodId=${periodId}`
  const res = await axios.post(
    url,
    { users },
    {
      headers: {
        authorization: `Bearer ${authToken}`
      }
    }
  )
}
