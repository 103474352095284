import { V1AdminExportSessions } from '@/v1.5/features/sessions/api'
import dayjsClient from '@/v1.5/lib/dayjs'

export function createFileName({ programName }: { programName: string | undefined }) {
  let formattedProgramName = programName ?? 'program'
  formattedProgramName = formattedProgramName.replace(' ', '_')
  formattedProgramName = formattedProgramName.toLowerCase()
  return `sessions_programme_${formattedProgramName}`
}

export async function fetchAndPrepareSessionsForExport({
  programId,
  periodId,
}: {
  programId: string | undefined
  periodId: string | undefined
}) {
  if (!programId || !periodId) {
    throw new Error('Program or period not selected')
  }
  const sessions = await V1AdminExportSessions({
    programId,
    periodId,
  })

  // assign a readable human date to the session
  sessions.forEach((session) => {
    if (session.sessionDate) {
      session.sessionDate = dayjsClient(session.sessionDate).format(`DD/MM/YYYY [à] HH:mm`)
    }
  })

  return sessions
}
