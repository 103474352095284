export enum RoleEnumType {
  GUEST = 'ROLE_GUEST',
  MENTOR = 'ROLE_MENTOR',
  MENTEE = 'ROLE_STUDENT',
  ADMIN = 'ROLE_ADMIN',
  MANAGER = 'ROLE_MANAGER',
}

export enum PermissionsGroupsEnumType {
  ADMIN = 'ADMIN',
  USER = 'USER',
}

export type V1AccountDataType = {
  createdAt: string
  role: RoleEnumType
  identity?: {
    firstname: string
    lastname: string
    userId: string
  }
  personalInformation?: { profilPicture: string }
}

export type V2AccountDataType = {
  id: string
  supportId: string
  createdAt: string
  role: RoleEnumType
  firstName: string
  lastName: string
  picture: string
  email: string
  hasSAML: boolean
  hasAcceptedTerms: boolean
}

export type V1AuthUserType = {
  email: string
  id: string
  role: RoleEnumType
  samlId: string
  supportId: string
  cguAccepted: boolean
}

export type V1ChangePasswordDTOType = {
  userId: string
  confirmPassword: string
  password: string
  oldPassword: string
}

export type V1PhoneType = {
  country: string
  number: string
  prefix: string
  id: string
  userId: string
}

export type LoginDTOType = {
  email: string
  password: string
}

export type LoginResponseType = {
  token: string
  user: {
    id: string
    email: string
    role: RoleEnumType
    samlId: string
  }
}

export type ResetPasswordDTOType = {
  token: string
  password: string
  confirmPassword: string
}

export type RegisterDTOType = {
  cguAccepted: boolean
  email: string
  password: string
}

export type V1UpdateUserDTOType = Partial<{
  email: string
  // firstName: string
  // lastName: string
  // phone: string
  // picture: File
}>

export type UpdateUserDescriptionDTOType = {
  userId: string
  description: string
}

export type UpdateUserDTOType = Partial<{
  userId: string
  email: string
  firstName: string
  lastName: string
  phone: string
  picture: File
  role: RoleEnumType
}>

export type V1UpdateUserIdentityDTOType = {
  firstname: string
  lastname: string
}

export type V1UpdateUserRoleDTOType = {
  role: RoleEnumType
}

export type V1UpdatePhoneDTOType = {
  phone: string
  userId: string
}

export type V1UpdateUserPictureDTOType = FormData

export type DoesUserExistDTOType = {
  email: string
}

export type DoesUserExistResponseType = {
  isExist: boolean
  userId: string
}
