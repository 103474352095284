// @ts-nocheck
// eslint-disable
import {
  apiAdminGetStatistics,
  changeMentorMutation,
  fetchGroups,
  fetchMentees,
  fetchMentorProfile,
  fetchMentors,
  updateMentorApprovalMutation,
} from '../../services/AdminService'

function initialState() {
  return {
    mentors: [],
    mentees: [],
    groups: [],
    mentorId: null,
    menteeId: null,
    mentorProfile: null,
    adminDashboardStatistics: null,
    adminSessions: [],
    adminTotalSessions: null,
    adminTotalSessionsPages: null,
  }
}

const state = initialState()

const getters = {
  mentees: (state) => state.mentees,
  mentors: (state) => state.mentors,
  groups: (state) => state.groups,
  mentorId: (state) => state.mentorId,
  menteeId: (state) => state.menteeId,
  adminSessions: (state) => state.adminSessions,
  adminTotalSessions: (state) => state.adminTotalSessions,
  adminTotalSessionsPages: (state) => state.adminTotalSessionsPages,
  mentorProfile: (state) => state.mentorProfile,
  adminDashboardStatistics: (state) => state.adminDashboardStatistics,
}

const actions = {
  async adminGetStatistics({ commit }, payload) {
    const statistics = await apiAdminGetStatistics(
      this.getters.authToken,
      this.getters.currentProgram.id,
      this.getters.currentPeriod.id,
    )
    commit('SET_ADMIN_DASHBOARD_STATISTICS', statistics)
  },

  resetAdminStatistics({ commit }) {
    commit('SET_ADMIN_DASHBOARD_STATISTICS', null)
  },

  async getMentors({ commit }, payload) {
    //TODO Mocked data for the current company
    const mentors = await fetchMentors(this.getters.authToken, this.getters.currentProgram.id)
    commit('SET_MENTORS', mentors)
  },

  async updateMentorApproval({ commit, dispatch }, payload) {
    //TODO Mocked data for the current company

    await updateMentorApprovalMutation({
      userId: payload.userId,
      value: payload.value,
      authToken: this.getters.authToken,
    })
  },

  async changeMentor({ commit }, payload) {
    await changeMentorMutation({
      menteeId: payload.menteeId,
      mentorId: payload.mentorId,
      groupId: payload.groupId,
      oldMentorId: payload.oldMentorId,
      programId: payload.programId,
      authToken: this.getters.authToken,
    })
  },

  async getMentorProfile({ commit }, payload) {
    //TODO Mocked data for the current company

    return fetchMentorProfile({
      user: payload,
      authToken: this.getters.authToken,
    })
  },

  async getMentees({ commit }) {
    const mentees = await fetchMentees(this.getters.authToken, this.getters.currentProgram.id)
    commit('SET_MENTEES', mentees)
  },

  async getGroups({ commit }) {
    const groups = await fetchGroups(this.getters.authToken, this.getters.currentProgram.id)
    commit('SET_GROUPS', groups)
  },

  async adminGetSessions({ commit }, payload) {
    commit('SET_ADMIN_TOTAL_SESSIONS', payload.totalSessions)
    commit('SET_ADMIN_SESSIONS', payload.sessions)
    commit('SET_ADMIN_TOTAL_SESSIONS_PAGES', payload.totalPages)
  },

  setMentorId({ commit }, payload) {
    commit('SET_MENTOR_ID', payload)
  },

  setMenteeId({ commit }, payload) {
    commit('SET_MENTEE_ID', payload)
  },
}

const mutations = {
  SET_MENTORS(state, mentors) {
    // sorting the mentors before setting them
    // by firstName
    let sortedMentors = []
    sortedMentors = mentors.sort((a, b) => (a.Identity.firstname < b.Identity.firstname ? -1 : 1))
    state.mentors = sortedMentors
  },

  SET_MENTEES(state, mentees) {
    // sorting the mentees before setting them
    // by fullname
    let sortedMentees = []
    sortedMentees = mentees.sort((a, b) => (a.fullname < b.fullname ? -1 : 1))
    state.mentees = sortedMentees
  },

  SET_GROUPS(state, groups) {
    state.groups = groups
  },

  SET_ADMIN_SESSIONS(state, sessions) {
    // sorting the sessions before setting them
    // by date
    let sortedSessions = []
    sortedSessions = sessions.sort((a, b) => (a.date < b.date ? -1 : 1))
    state.adminSessions = sortedSessions
  },

  SET_MENTOR_ID(state, mentorId) {
    state.mentorId = mentorId
  },

  SET_MENTEE_ID(state, menteeId) {
    state.menteeId = menteeId
  },
  SET_ADMIN_DASHBOARD_STATISTICS(state, value) {
    state.adminDashboardStatistics = value
  },
  SET_ADMIN_TOTAL_SESSIONS(state, value) {
    state.adminTotalSessions = value
  },
  SET_ADMIN_TOTAL_SESSIONS_PAGES(state, value) {
    state.adminTotalSessionsPages = value
  },
}

export const moduleAdmin = {
  state,
  getters,
  actions,
  mutations,
}
