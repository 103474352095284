<template>
  <div :class="`input-wrapper ${id} ${hasErrors ? 'has-errors' : ''}`">
    <!-- input label -->
    <label v-if="label" class="input-label" :for="id">
      <span>{{ label }}</span>
    </label>
    <!-- input element -->
    <div class="input-el-wrapper">
      <input
        class="input"
        :type="type"
        v-model="valueRef"
        :data-value="valueRef"
        :placeholder="placeholder"
        @input="onChange"
        @click="onClick"
      />
      <!-- input aside elements -->
      <div v-if="type === 'tel' && countryCode" class="input-flag-wrapper">
        <CountryFlag :iso="countryCode" />
      </div>
    </div>
    <div v-if="errors" class="input-errors-wrapper">{{ errors }}</div>
  </div>
</template>

<script setup lang="ts">
import '@dzangolab/vue-country-flag-icon/dist/CountryFlag.css' // import stylesheet

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import CountryFlag from '@dzangolab/vue-country-flag-icon'
import { computed, ref } from 'vue'

import debounce from '@/v1.5/utils/misc/debounce'

interface InputPropsType {
  id: string
  errors?: string
  label?: string
  countryCode?: string | null
  type?: string
  placeholder: string
  value?: string | null
  defaultOnChangeDelay?: number
}

const props = defineProps<InputPropsType>()

// set default value v-model
const valueRef = ref(props.value || '')

defineExpose({
  clear() {
    valueRef.value = ''
  },
  set(message: string) {
    valueRef.value = message
  },
})

const emit = defineEmits(['emit:change'])

const onChange = debounce(
  (event: Event) => {
    const value = (event.target as HTMLInputElement).value
    // Emit the new value to the parent with the event name 'update:modelValue'
    emit('emit:change', value)
  },
  props.defaultOnChangeDelay || 150,
  false,
)

const onClick = (event: Event) => {
  if (props.type === 'date' || props.type === 'time') {
    const input = event.target as HTMLInputElement
    input.showPicker()
  }
}

// Computed properties for conditional logic
const hasErrors = computed(() => !!props.errors)
</script>

<style scoped lang="scss">
@import './custom-input.scss';
</style>
