<template>
  <Modal
    :no-exit-on-backdrop="true"
    :modal="phoneModalRef"
    :steps="[{ component: PhoneModalContent, id: 'ask-phone' }]"
  />
</template>

<script lang="ts" setup>
import { watchEffect } from 'vue'

import usePhone from '@/v1.5/features/auth/hooks/use-phone.hook'
import PhoneModalContent from '@/v1.5/features/ui/components/layout/phone-modal/phone-modal-content/phone-modal-content.vue'
import Modal from '@/v1.5/features/ui/components/modal/modal.vue'
import { selectGetPhoneModal } from '@/v1.5/features/ui/store/modal/modal.selectors'
import modalsStore from '@/v1.5/features/ui/store/modal/modal.store'
import { DO_NOT_ASK_PHONE_NUMBER_AGAIN } from '@/v1.5/utils/config/constants'

const phoneModalRef = selectGetPhoneModal(modalsStore)

const { phoneRef, isPendingRef } = usePhone()

watchEffect(() => {
  const doNotAskAgain = window.localStorage.getItem(DO_NOT_ASK_PHONE_NUMBER_AGAIN) === 'true'

  // if the user has not been asked for his phone number or if he has not declined this popup, show the phone modal
  if (!phoneRef.value && !doNotAskAgain && !isPendingRef.value) {
    modalsStore.toggleModal('phone', true)
  } else {
    modalsStore.toggleModal('phone', false)
  }
})
</script>
