import { useQuery } from '@tanstack/vue-query'
import type { Ref } from 'vue'
import { computed } from 'vue'

import useAccount from '@/v1.5/features/auth/hooks/use-account.hook'
import { PermissionsGroupsEnumType } from '@/v1.5/features/auth/types'
import { getSession, SESSION_QUERY_KEY } from '@/v1.5/features/sessions/api'
import type { GetSessionResponseType } from '@/v1.5/features/sessions/types'
import { SessionStatusEnumType, type V2SessionType } from '@/v1.5/features/sessions/types'
import { setAdminSessionStatus } from '@/v1.5/features/sessions/utils'
import checkPermissionsGroup from '@/v1.5/features/users/utils'
function V1ToV2DataMiddleware({
  data,
  userId,
}: {
  data: GetSessionResponseType | undefined
  userId: string | undefined
}): V2SessionType | null {
  const session = data?.session

  if (!session) return null

  const users = session.users
    // get ONLY the mentor and mentees
    .filter((user) => checkPermissionsGroup(user.role, PermissionsGroupsEnumType.USER))
    .map((user) => ({
      id: user.id,
      role: user.role,
      firstName: user.identity.firstname,
      lastName: user.identity.lastname,
      email: user.email,
      picture: user.personalInformation?.profilPicture ?? null,
      sessionStatus: session.statuses?.find((status) => status.userId === user.id)!.status,
    }))

  let sessionStatus: SessionStatusEnumType
  const isUserInSession = users.some((user) => user.id === userId)

  // if the user is in the session, we get his status
  if (isUserInSession) {
    sessionStatus = session.statuses.find((status) => status.userId === userId)?.status || SessionStatusEnumType.NONE
    // else if it's admin, we get the status of the session
  } else {
    sessionStatus = setAdminSessionStatus(session)
  }

  return {
    id: session.id,
    mentoringGroupId: session.mentoringGroupId,
    meetingRoomId: session.mentorshipGroup.meetingRoomId,
    at: session.from,
    from: session.from,
    to: session.to,
    location: session.location,
    duration: session.duration,
    status: sessionStatus,
    report: session.report ?? null,
    users,
    createdAt: session.createdAt,
    updatedAt: session.updatedAt,
    reviews: session.reviews,
    presences: session.presences.map((presence) => ({
      userId: presence.userId,
      wasPresent: presence.isPresent,
    })),
    deliverables: session.deliverables,
  } satisfies V2SessionType
}

const useSession = (sessionIdRef: Ref<string | null>) => {
  const { accountRef } = useAccount()

  // Check if all the required data is available to make the query
  const isSessionQueryEnabledRef = computed(() => !!sessionIdRef.value)

  const sessionQuery = useQuery({
    queryKey: [
      SESSION_QUERY_KEY,
      {
        id: sessionIdRef,
      },
    ],
    queryFn: () =>
      getSession({
        id: sessionIdRef.value!,
      }),
    enabled: isSessionQueryEnabledRef,
  })

  const data = computed(() => {
    return V1ToV2DataMiddleware({
      data: sessionQuery.data.value,
      userId: accountRef.value?.id,
    })
  })

  return {
    sessionRef: data,
    isPendingRef: sessionQuery.isPending,
  }
}

export default useSession
