// @ts-nocheck
// eslint-disable
import io from 'socket.io-client'
import Vue from 'vue'
import Vuex from 'vuex'

import { getOneMentor } from '@/services/MentorService.js'
import { getOne } from '@/services/userService.js'
import { moduleAdmin } from '@/store/modules/moduleAdmin.js'
import { moduleApp } from '@/store/modules/moduleApp.js'
import { moduleAuth } from '@/store/modules/moduleAuth.js'
import { moduleChat } from '@/store/modules/moduleChat.js'
import { moduleMeeting } from '@/store/modules/moduleMeeting.js'
import { moduleMentoringGroup } from '@/store/modules/moduleMentoringGroup.js'
import { moduleProfile } from '@/store/modules/moduleProfile.js'
import { moduleProgram } from '@/store/modules/moduleProgram.js'
import { moduleSession } from '@/store/modules/moduleSession.js'
import { moduleUser } from '@/store/modules/ModuleUser.js'

Vue.use(Vuex)

const getters = {
  mentorData: (state) => state.mentorData,
  socket: (state) => state.socket,
}

const initialState = () => ({
  userId: null,
  userData: {},
  mentorData: null,
  modal: {
    name: null,
    show: false,
  },
  socket: io(import.meta.env.VITE_API_URL, {
    reconnectionAttempts: import.meta.env.VITE_ENV === 'development' ? 1 : 100,
  }),
})

const state = initialState()

const mutations = {
  UPDATE_USER_ID(state, payload) {
    state.userId = payload
  },
  UPDATE_USER_DATA(state, payload) {
    state.userData = payload
  },
  UPDATE_MENTOR_DATA(state, payload) {
    state.mentorData = payload
  },
  SWITCH_MODAL(state, payload) {
    state.modal.name = payload.name
    state.modal.show = payload.show
  },

  FETCH_USER_PHONE(state, phone) {
    state.phone = phone
  },
  CLEAR_MENTOR_DATA(state) {
    state.mentorData = null
  },
  INDEX_RESET_STATE(state) {
    const s = initialState()
    Object.keys(s).forEach((key) => {
      state[key] = s[key]
    })
  },
}

const actions = {
  updateUserId({ commit }, responses) {
    commit('UPDATE_USER_ID', responses)
  },
  async updateUserData({ commit }) {
    const user = await getOne(this.getters.authToken)
    commit('UPDATE_USER_DATA', user)
  },
  async fetchMentorData({ commit }, id) {
    const MENTOR = await getOneMentor(id, this.getters.authToken)
    commit('UPDATE_MENTOR_DATA', MENTOR)
  },
  updateMentorData({ commit }, responses) {
    commit('UPDATE_MENTOR_DATA', responses)
  },
  switchModal({ commit }, payload) {
    commit('SWITCH_MODAL', payload)
  },
  clearMentorData({ commit }) {
    commit('CLEAR_MENTOR_DATA')
  },
  storeIndexResetState({ commit }) {
    commit('INDEX_RESET_STATE')
  },
}

export default new Vuex.Store({
  state,
  actions,
  mutations,
  getters,
  modules: {
    moduleUser,
    moduleProfile,
    moduleApp,
    moduleAuth,
    moduleSession,
    moduleMeeting,
    moduleChat,
    moduleMentoringGroup,
    moduleAdmin,
    moduleProgram,
  },
})
