<template>
  <Modal
    :modal="newMatchingModalRef"
    :steps="[
      {
        component: NewMatchingInformationModalContent,
        id: 'new-matching-info',
      },
      {
        component: NewMatchingFillCriteriaModalContent,
        id: 'new-matching-fill-criteria',
      },
      {
        component: MenteeMatchingLoading,
        id: 'new-matching-loading',
      },
      {
        component: NewMatchingConfirmation,
        id: 'new-matching-confirmation',
      },
    ]"
  />
</template>

<script setup lang="ts">
import MenteeMatchingLoading from '@/v1.5/features/matching/components/matching-modals/mentee-matching-modal/mentee-matching-loading/mentee-matching-loading.vue'
import NewMatchingConfirmation from '@/v1.5/features/matching/components/new-matching-modal/new-matching-confirmation/new-matching-confirmation.vue'
import NewMatchingFillCriteriaModalContent from '@/v1.5/features/matching/components/new-matching-modal/new-matching-fill-criteria-modal-content/new-matching-fill-criteria-modal-content.vue'
import NewMatchingInformationModalContent from '@/v1.5/features/matching/components/new-matching-modal/new-matching-information-modal-content/new-matching-information-modal-content.vue'
import Modal from '@/v1.5/features/ui/components/modal/modal.vue'
import { selectGetNewMatchingModal } from '@/v1.5/features/ui/store/modal/modal.selectors'
import modalsStore from '@/v1.5/features/ui/store/modal/modal.store'

const newMatchingModalRef = selectGetNewMatchingModal(modalsStore)
</script>
