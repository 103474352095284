import posthog from 'posthog-js'

import type { V2AccountDataType } from '@/v1.5/features/auth/types'
import programsStore from '@/v1.5/features/programs/stores/programs/programs.store'
import type { V2ProgramType } from '@/v1.5/features/programs/types'

export const PosthogClient = posthog

// analytics events
// naming convention: category:object_action
//posthog.com/product-engineers/5-ways-to-improve-analytics-data#1-implement-a-naming-convention
export const ANALYTICS_EVENTS = {
  /**
   * Triggered when the user completes all mandatory steps of the session review and clicks submit
   */
  USER_CLICK_COMPLETE_SESSION_REVIEW: 'sessions:complete_review_click',

  /**
   * Triggered when the user clicks on "Fill Later" in the session review modal
   * @property {string} step - The id of the current step on which the user clicked "Fill Later"
   */
  USER_CLICK_SESSION_REVIEW_FILL_LATER: 'sessions:review_step_fill_later_click',
}

export function initPosthog() {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-expect-error
  const apiKey = import.meta.env.VITE_POSTHOG_API_KEY as string
  if (!apiKey) {
    return
  }
  // posthog only works when the key is set (could be a dev or prod key)
  PosthogClient.init(apiKey, {
    api_host: 'https://eu.i.posthog.com',
    person_profiles: 'identified_only', // or 'always' to create profiles for anonymous users as well
  })
}

export function identifyUser(account: Partial<V2AccountDataType>, programs: V2ProgramType[]) {
  PosthogClient.identify(account.id, {
    role: account.role,
    // current_program_id is changed to the selected program id that the user is currently on
    current_program_id: programsStore.selectedProgramId,
    programs_ids: programs.map((program) => program.id).join(','),
  })
}

export function trackEvent(event: string, properties: Record<string, any> = {}) {
  PosthogClient.capture(event, properties)
}
