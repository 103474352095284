// @ts-nocheck 
// eslint-disable
import axios from 'axios'

const apiURL = import.meta.env.VITE_API_URL
const instance = axios.create({
  baseURL: `${apiURL}/v1/user`,
})
// payload : { role: string }
// role: 'ROLE_STUDENT' | 'ROLE_MENTOR'
// 👊 ✓
export async function apiUpdateUserRole(payload, authToken) {
  const req = await fetch(`${apiURL}/v1/user/role`, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify(payload),
  })
  return await req.json()
}

// 👊 ✓
export async function readApiUserProfilePicture(authToken, authUserId) {
  const req = await fetch(`${apiURL}/v1/user/${authUserId}/profile-picture`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
  })
  return await req.json()
}

// 👊 ✓
export async function updateUser(payload, authToken) {
  const req = await fetch(`${apiURL}/v1/user/user`, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify(payload),
  })
  return await req.json()
}

// 👊  ✓
export async function readUser(authToken) {
  const req = await fetch(`${apiURL}/v1/user/user`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
  })
  return await req.json()
}

// 👊 ✓
export async function deleteCareer(careerId, authToken, authUserId) {
  return instance.delete(`/${authUserId}/career/${careerId}`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  })
}

// 👊 ✓
export async function apiCreateCareer(payload, authToken) {
  const req = await fetch(`${apiURL}/v1/user/career`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify(payload),
  })
  return await req.json()
}

// 👊 ✓
export async function ApiFetchAllCareers(userId, authToken) {
  const req = await fetch(`${apiURL}/v1/user/${userId}/career`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
  })
  return await req.json()
}

// 👊 ✓
export async function readIdentity(authToken, authUserId) {
  const req = await fetch(`${apiURL}/v1/user/${authUserId}/identity`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
  })
  return await req.json()
}

// 👊 ✓
export async function createIdentity(payload, authToken, authUserId) {
  const req = await fetch(`${apiURL}/v1/user/${authUserId}/identity`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify(payload),
  })
  return await req.json()
}

// 👊 ✓
export async function updateIdentity(payload, authToken) {
  const req = await fetch(`${apiURL}/v1/user/identity`, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify(payload),
  })
  return await req.json()
}

// 👊 ✓
export async function getOne(authToken) {
  const res = await instance
    .get('/', {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
    .catch(function (error) {
      if (error.response) {
        return error.response
      }
    })

  return res.data
}

// 👊 ✓
export async function changePassword(
  oldPassword,
  password,
  confirmPassword,
  authToken,
  authUserId
) {
  const req = await fetch(`${apiURL}/v1/user/${authUserId}/password`, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify({ oldPassword, password, confirmPassword }),
  })
  return await req.json()
}

// 👊 ✓
export async function apiMatching(authToken) {
  const req = await fetch(`${apiURL}/v1/user/matching`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  })
  return req.json()
}

// 👊 ✓
export async function apiReadSchool(authToken, id) {
  const req = await fetch(`${apiURL}/v1/school/${id}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
  })
  return req.json()
}

// 👊 ✓
export async function apiCreateSchool(payload, authToken) {
  const req = await fetch(`${apiURL}/v1/school`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify(payload),
  })
  return req.json()
}
