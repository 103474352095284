<template>
  <div
    :class="`admin-session-card session-card session-card-status--${sessionStatusClassNamesMap[session.status]}`"
    @click="openSessionDetails"
  >
    <div class="session-card__date">
      <span>{{ extractShortDay(session.from) }}</span>
      <span>{{ extractDayNumber(session.from) }}</span>
    </div>
    <div class="session-card__content">
      <div class="session-card__content__info">
        <div class="session-card-title">
          <span>{{ t('common.session.capitalize') }}</span>
          <span
            v-if="
              session.status === SessionStatusEnumType.WAIT_CONFIRMATION ||
              session.status === SessionStatusEnumType.WAIT_REVIEW
            "
            class="c-notification-badge"
          ></span>
        </div>
        <div class="session-card-extra-info">
          <!-- <span class="session-card-extra-info__duration-range" v-if="session.deliverables.length > 0">
            Moyenne des notes :
            {{
              Math.round(
                (session?.deliverables
                  .filter((deliverable) => typeof deliverable.rating === 'number')
                  .reduce((acc, deliverable) => acc + (deliverable.rating ?? 0), 0) /
                  session?.deliverables.filter((deliverable) => typeof deliverable.rating === 'number').length) *
                  10,
              ) / 10
            }}/20
          </span> -->
          <StatusBadge :status="session.status" badge-type="session" />
        </div>
      </div>
      <div class="session-card__users">
        <UserBadge v-if="mentorRef" :user="mentorRef" />
        <UsersBadgesStack :users="menteesRef" />
      </div>
    </div>
    <div class="session-card__options" data-action="session-card" :data-attached="JSON.stringify(session)">
      <MoreOptionsIcon color="black" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { getMentees, getMentor } from '@/v1.5/features/groups/utils'
import UserBadge from '@/v1.5/features/sessions/components/user-badge/user-badge.vue'
import UsersBadgesStack from '@/v1.5/features/sessions/components/users-badges-stack/users-badges-stack.vue'
import type { V2SessionType } from '@/v1.5/features/sessions/types'
import { SessionStatusEnumType } from '@/v1.5/features/sessions/types'
import { sessionStatusClassNamesMap } from '@/v1.5/features/sessions/utils'
import MoreOptionsIcon from '@/v1.5/features/ui/components/icons/more-options-icon.vue'
import StatusBadge from '@/v1.5/features/ui/components/status-badge/status-badge.vue'
import type { SessionDetailsModalType } from '@/v1.5/features/ui/store/modal/modal.store'
import modalsStore from '@/v1.5/features/ui/store/modal/modal.store'
import { useI18n } from '@/v1.5/lib/i18n'
import { extractDayNumber, extractShortDay } from '@/v1.5/utils/dates'

export type AdminSessionCardType = V2SessionType & { status: SessionStatusEnumType }

const props = defineProps<{
  session: AdminSessionCardType
}>()

const { t } = useI18n()

const mentorRef = computed(() => props.session.users.find(getMentor))
const menteesRef = computed(() => props.session.users.filter(getMentees))

function openSessionDetails(e: MouseEvent) {
  // guard to not open the modal when clicking on the more options icon
  if ((e.target as HTMLElement).closest('[data-action="session-card"]')) {
    return
  }

  modalsStore.updateModalAttachedData<SessionDetailsModalType>('session-details', {
    sessionId: props.session.id,
  })
  modalsStore.toggleModal('session-details', true)
}
</script>

<style lang="scss">
@import './session-card.scss';
</style>
