// @ts-nocheck 
// eslint-disable
import {
  apiReadRoomUrl,
  apiAddMeetingTracking,
  apiExitRoom,
  apiAddToggleCamera,
  apiAddToggleMicrophone,
} from '../../services/MeetingService'

const initialState = () => ({
  meeting: null,
  meetingTracker: null,
})

const state = initialState()

const getters = {
  meeting: (state) => state.meeting,
  meetingTracker: (state) => state.meetingTracker,
}

const actions = {
  async readRoomUrl({ commit }, payload) {
    let authToken = this.getters.authToken
    const Url = await apiReadRoomUrl(payload.sessionId, authToken)
    commit('SET_MEETING', Url)
  },
  async exitRoom({ commit }, payload) {
    let authToken = this.getters.authToken
    const Url = await apiExitRoom(payload.meetingId, authToken)
    if (commit) return Url
    // commit('SET_MEETING',Url)
  },
  async addNewMeetingTracking({ commit }, payload) {
    let authToken = this.getters.authToken
    const reponse = await apiAddMeetingTracking(
      payload.meetingId,
      payload.userId,
      authToken
    )
    commit('SET_MEETINGTRACKER', reponse.id)
  },
  async AddToggleCamera({ commit }, payload) {
    let authToken = this.getters.authToken
    const Url = await apiAddToggleCamera(payload.meetingId, authToken)
    if (commit) return Url
    // commit('SET_MEETING',Url)
  },
  async AddToggleMicrophone({ commit }, payload) {
    let authToken = this.getters.authToken
    const Url = await apiAddToggleMicrophone(payload.meetingId, authToken)
    if (commit) return Url
    // commit('SET_MEETING',Url)
  },
  storeMeetingResetState({ commit }) {
    commit('MEETING_RESET_STATE')
  },
}

const mutations = {
  SET_MEETING(state, meeting) {
    state.meeting = meeting
  },
  SET_MEETINGTRACKER(state, value) {
    state.meetingTracker = value
  },
  MEETING_RESET_STATE(state) {
    const s = initialState()
    Object.keys(s).forEach((key) => {
      state[key] = s[key]
    })
  },
}

export const moduleMeeting = {
  state,
  getters,
  actions,
  mutations,
}
