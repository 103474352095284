<!-- eslint-disable -->
<!-- @ts-nocheck -->
<template>
  <div class="bg-light list p-3 rounded">
    <template v-if="$store.getters.chatRooms">
      <div
        v-for="(room, index) in $store.getters.chatRooms"
        :key="index"
        class="text-dark text-decoration-none"
      >
        <div
          v-if="room.users"
          @click="openChatRoom(room.id)"
          class="text-decoration-none text-dark p-2 rounded conversation c-pointer"

          :class="{
            active: room.id == $route.params.roomId,
          }"
        >
        <div
        class="notification-badge"
          v-if="$store.getters.mentoringGroup?.some(mg => mg.conversationId === room.id) && !room.messages.length"
        >
        1
      </div>

          <DiscussionName :room="room"></DiscussionName>
        </div>
        <!-- <p v-if="room.lastMessage" class="mb-0 form-text">
          <template v-if="room.lastMessage.includes('***session=')">
            Demande de réservation -
            <span class="">{{ formatDate(room.lastMessageCreateAt) }}</span>
          </template>

          <template v-else>
            {{ room.lastMessage.substr(0, 21)
            }}{{ room.lastMessage.length > 21 ? '...' : '' }} -
            <span class="">{{ formatDate(room.lastMessageCreateAt) }}</span>
          </template>
        </p> -->

        <hr
          class="m-0 my-1"
          v-if="index != $store.getters.chatRooms.length - 1"
        />
      </div>
    </template>

    <div v-if="isLoading" class="d-flex justify-content-center">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>

    <div v-if="!$store.getters.chatRooms.length && !isLoading">
      <p class="mb-0 text-center">Aucune conversation pour le moment</p>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone'
import { mapActions } from 'vuex'

import DiscussionName from './DiscussionName.vue'

export default {
  name: 'DiscussionList',
  data() {
    return {
      conversations: {},
      isLoading: false,
    }
  },
  components: {
    DiscussionName,
  },

  methods: {
    ...mapActions(['switchModal']),
    // this.switchModal({ name: 'discussion', show: false })
    openChatRoom(roomId) {
      if (roomId != this.$route.params.roomId) {
        const width = window.innerWidth
        if (width < 768) this.switchModal({ name: 'discussion', show: true })
        this.$router.push({ name: 'DiscussionIndex', params: { roomId } })
      }
    },
    formatDate(date) {
      return moment(date)
        .locale('FR')
        .fromNow()
        .replace('il y a ', '')
        .replace(' heures', ' h')
        .replace(' minutes', ' min')
        .replace(' jours', ' j')
        .replace(' semaine', ' sem')
        .replace('quelques secondes', '1 min')
        .replace('une minute', '1 min')
        .replace('une heure', '1 h')
        .replace('un jour', '1 j')
        .replace('une semaine', '1 sem')
        .replace('un mois', '1 mois')
    },
  },
}
</script>

<style lang="scss" scoped>
.discussion-list-container {
  height: 100vh;
}
.list {
  overflow: hidden;
  overflow-y: scroll;
  height: 100%;
  @media all and (max-width: 768px) {
    height: 100dvh;
  }
}

.conversation {
  position: relative;
}

.conversation:hover {
  background-color: #f1f2f5;
}

.active:hover {
  color: var(--color-primary) !important;
  background-color: rgba(var(--color-white-label-primary), 0.1);
}

img {
  width: 45px;
  height: 45px;

  object-fit: cover;
}

.active {
  color: var(--color-primary) !important;
  background-color: rgba(var(--color-white-label-primary), 0.1);
  @media all and (max-width: 767px) {
    background-color: #fff;
    color: #212529 !important;
  }
}

.notification-badge {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  background-color: rgb(var(--color-danger));
  color: #fff;
  border-radius: 50%;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;

  @media all and (min-width: 1024px) {
    top: 0;
    transform: translate(50%, -50%);
  }
}
</style>
