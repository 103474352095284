import type { V1UserDataType } from '@/v1.5/features/users/types'
import fetchClient from '@/v1.5/lib/fetch'

export const V1_USER_QUERY_KEY = 'V1_USER_QUERY_KEY'
export function V1GetUser(userId: string): Promise<V1UserDataType> {
  return fetchClient(`/v1/user/${userId}`, {
    method: 'GET',
  })
}

export function deleteUserFromProgram(userId: string, programId: string): Promise<void> {
  return fetchClient(`/v1/programs/${programId}/users/${userId}`, {
    method: 'DELETE',
  })
}
