import { useQuery } from '@tanstack/vue-query'
import { computed, type ComputedRef } from 'vue'

import { DELIVERABLES_QUERY_KEY, getAllDeliverables } from '@/v1.5/features/sessions/api'

export default function useDeliverables(sessionIdRef: ComputedRef<string | null | undefined>) {
  const isGetDeliverablesQueryEnabled = computed(() => !!sessionIdRef.value)

  const { data, isPending } = useQuery({
    queryKey: [DELIVERABLES_QUERY_KEY, { sessionId: sessionIdRef }],
    queryFn: () => getAllDeliverables({ sessionId: sessionIdRef.value! }),
    enabled: isGetDeliverablesQueryEnabled,
    gcTime: 0,
  })

  return {
    deliverablesRef: data,
    isPendingRef: isPending,
  }
}
