// @ts-nocheck
// eslint-disable
import { endpointGetListProgram, endpointGetUserPrograms } from '../../services/ProgramService.js'

function initialState() {
  return {
    programs: [],
    userPrograms: [],
    currentProgram: null,
    currentPeriod: null,
  }
}

const state = initialState()

const getters = {
  programs: (state) => state.programs,
  userPrograms: (state) => state.userPrograms,
  currentProgram: (state) => state.currentProgram,
  currentPeriod: (state) => state.currentPeriod,
}

const actions = {
  async storeProgramGetListProgram({ commit }, payload) {
    if (!this.getters.programs.length) {
      const response = await endpointGetListProgram(this.getters.authToken)
      if (response) {
        commit('SET_PROGRAMS', response)
        // commit('SET_CURRENT_PROGRAM', response[0])
        // select current period in date now if exist
        let currentPeriodAlreadySelected = false
        for (const period of response[0].periods) {
          if (new Date(period.startAt) <= new Date() && new Date(period.endAt) >= new Date()) {
            // commit('SET_CURRENT_PERIOD', period)
            currentPeriodAlreadySelected = true
            return
          }
        }

        if (!currentPeriodAlreadySelected) commit('SET_CURRENT_PERIOD', response[0].periods[0])
      }
    }
  },
  async storeGetUserPrograms({ commit }) {
    const response = await endpointGetUserPrograms(this.getters.authUserId, this.getters.authToken)
    // const response = [
    //   {
    //     id: '874a0058-0b80-46b3-9162-314ff3f38e3d',
    //     name: 'Orientation (DEV)',
    //     description: 'this is a description',
    //     userPerGroup: 2,
    //     createdAt: '2023-12-20T09:36:54.943Z',
    //     updatedAt: '2023-12-20T09:36:54.943Z',
    //     joined: true,
    //     periods: [
    //       {
    //         id: '74b16f59-f74c-4971-8a61-3725f64f5cc8',
    //         name: '',
    //         startAt: '2023-10-01T01:40:08.000Z',
    //         endAt: '2023-12-31T01:40:08.000Z',
    //         totalSession: 0,
    //         programId: '874a0058-0b80-46b2-9162-314ff3f38e3d',
    //         createdAt: '2023-12-20T09:36:54.973Z',
    //         updatedAt: '2023-12-20T09:36:54.973Z',
    //       },
    //     ],
    //   },
    // ]
    if (response) {
      commit('SET_USER_PROGRAMS', response)
    }
  },
  storeProgramUpdateCurrentProgramWithId({ commit }, programId) {
    const program = this.getters.programs.find((program) => program.id === programId)
    if (!program) return
    commit('SET_CURRENT_PROGRAM', program)
  },
  storeProgramUpdateCurrentPeriodWithId({ commit }, periodId) {
    const period = this.getters.currentProgram?.periods.find((period) => period.id === periodId)
    if (!period) return
    commit('SET_CURRENT_PERIOD', period)
  },
}

const mutations = {
  SET_PROGRAMS(state, payload) {
    state.programs = payload
  },
  SET_CURRENT_PROGRAM(state, payload) {
    state.currentProgram = payload
  },
  SET_CURRENT_PERIOD(state, payload) {
    state.currentPeriod = payload
  },
  SET_USER_PROGRAMS(state, payload) {
    state.userPrograms = payload
  },
  PROGRAM_RESET_STATE(state) {
    const s = initialState()
    Object.keys(s).forEach((key) => {
      state[key] = s[key]
    })
  },
}

export const moduleProgram = {
  state,
  getters,
  actions,
  mutations,
}
