<!-- eslint-disable -->
<!-- @ts-nocheck -->
<!-- <template>
  <Page>
    <template v-slot:middle>
      <div class="mentors-page-container rounded bg-light p-4">
        <div class="info">
          <h1>Vos Utilisateurs</h1>
          <h2>Importer de nouveaux utilisateurs depuis un fichier</h2>
        </div>
        <hr />
        <div class="mt-4">
          <UsersImport />
        </div>
      </div>
    </template>
    <template v-slot:outside> </template>
  </Page>
</template>

<script>
import UsersImport from '@/components/admin/users/UsersImport.vue'
import Page from '@/components/Page.vue'
export default {
  name: 'AdminUsers',
  data() {
    return {}
  },
  components: {
    Page,
    UsersImport,
  },
}
</script> -->

<template>
  <Page>
    <template v-slot:middle>
        <Users/>
    </template>
    <template v-slot:outside>
      <UsersImportModal/>
      <ManualUserImportModal />
    </template>
  </Page>
</template>

<script>
import Page from '@/components/Page.vue'
import Users from '@/v1.5/(routes)/(protected)/(app)/admin/users/page.vue'
import ManualUserImportModal from '@/v1.5/features/ui/pages/admin/users/manual-user-import-modal/manual-user-import-modal.vue'
import UsersImportModal from '@/v1.5/features/ui/pages/admin/users/users-import-modal/users-import-modal.vue'

export default {
  name: 'AdminUsers',
  data() {
    return {}
  },
  components: {
    Page,
    Users,
    UsersImportModal,
    ManualUserImportModal,
  },
}
</script>
