<!-- eslint-disable -->
<!-- @ts-nocheck -->
<template>
  <Page>
    <template v-slot:middle>
      <TheMentorList />
    </template>
  </Page>
</template>

<script>
import Page from '@/components/Page.vue'
import TheMentorList from '@/components/TheMentorList.vue'

export default {
  components: {
    Page,
    TheMentorList,
  },
}
</script>

<style lang="scss" scoped>
.title {
  margin-top: 91px;
}
</style>
