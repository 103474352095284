<!-- eslint-disable -->
<!-- @ts-nocheck -->
<template>
  <div class="d-flex align-items-center">
    <div
      v-if="room.users.length > 2"
      class="initials-wrapper rounded-circle d-flex justify-content-center align-items-center flex-shrink-0"
      style="width: 3rem; height: 3rem"
    >
      <span class="material-symbols-outlined"> group </span>
    </div>
    <template v-else>
      <template v-for="user in room.users">
        <template v-if="user.id != $store.getters.authUserId">
          <ProfilePicture
            :firstname="user.firstname"
            :lastname="user.lastname"
            :src="user.profilePicture"
            :width="40"
            :height="40"
          />
        </template>
      </template>
    </template>

    <div class="ms-3">
      <p v-if="room.users.length > 2" class="mb-0">
        Groupe de
        <template v-for="(user, index) in room.users">
          <template v-if="user.role == 'ROLE_MENTOR'">
            {{ user.firstname }} {{ user.lastname }}
          </template>
        </template>
      </p>
      <p v-else class="mb-0">
        <template v-for="user in room.users">
          <template v-if="user.id != $store.getters.authUserId">
            {{ user.firstname }} {{ user.lastname }}
          </template>
        </template>
      </p>
    </div>
  </div>
</template>

<script>
import ProfilePicture from '@/components/ProfilePicture.vue'

export default {
  name: 'DiscussionName',
  data() {
    return {}
  },
  computed: {},
  methods: {},
  components: {
    ProfilePicture,
  },
  props: {
    room: {
      type: Object,
    },
  },
}
</script>

<style lang="scss" scoped></style>
