<template>
  <div :class="`users-badges-stack ${variant ? 'users-badges-stack--small' : ''}`">
    <UserBadge
      v-for="user in users.slice(0, SLICE_LIMIT)"
      :key="user.id"
      :user="user"
      :noStatus="noStatus"
      :variant="variant"
    />
    <UserBadge
      v-if="overflowUsersLengthRef > 0"
      :user="{
        firstName: `${overflowUsersLengthRef}`,
        lastName: '+',
        id: 'XXX_XXX',
        picture: null,
        role: RoleEnumType.GUEST,
      }"
      :variant="variant"
      :noStatus="true"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { RoleEnumType } from '@/v1.5/features/auth/types'
import UserBadge from '@/v1.5/features/sessions/components/user-badge/user-badge.vue'
import type { V2SessionType } from '@/v1.5/features/sessions/types'
import type { V2UserDataType } from '@/v1.5/features/users/types'

const props = defineProps<{
  users: (V2UserDataType & { sessionStatus?: V2SessionType['users'][number]['sessionStatus'] })[]
  variant?: 'small'
  noStatus?: boolean
}>()

const SLICE_LIMIT = 4
// if there are more than SLICE_LIMIT users, we display a badge with the number of users
const overflowUsersLengthRef = computed(() => props.users.slice(SLICE_LIMIT).length)
</script>

<style lang="scss">
@import './users-badges-stack.scss';
</style>
