// import { useQueries } from '@tanstack/react-query'

import { useQuery } from '@tanstack/vue-query'
import { computed } from 'vue'

import { V1_PHONE_QUERY_KEY, V1GetPhone } from '@/v1.5/features/auth/api'
import useAccount from '@/v1.5/features/auth/hooks/use-account.hook'
import type { V1PhoneType } from '@/v1.5/features/auth/types'

function V1ToV2DataMiddleware(data?: Partial<V1PhoneType>): string | undefined {
  if (!data) return undefined

  return data?.prefix && `+${data?.prefix}${data?.number}`
}

function usePhone() {
  const { accountRef } = useAccount()
  const accountIdRef = computed(() => accountRef.value?.id)
  const isPhoneQueryEnabledRef = computed(() => !!accountIdRef.value)

  const { data, isPending } = useQuery({
    queryKey: [V1_PHONE_QUERY_KEY],
    queryFn: () => V1GetPhone(accountIdRef.value!),
    enabled: isPhoneQueryEnabledRef,
  })

  const phoneDataRef = computed(() => {
    const phoneData = {
      prefix: data.value?.prefix,
      number: data.value?.number,
    }
    return V1ToV2DataMiddleware({
      ...phoneData,
    })
  })

  return {
    phoneRef: phoneDataRef,
    isPendingRef: isPending,
  }
}

export default usePhone
