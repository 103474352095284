import type {
  AddUserToProgramDTOType,
  DoesUserExistsInProgramDTOType,
  DoesUserExistsInProgramResponseType,
  V1ProgramType,
} from '@/v1.5/features/programs/types'
import fetchClient from '@/v1.5/lib/fetch'

export const V1_PROGRAMS_QUERY_KEY = 'v1-programs'
export async function V1GetPrograms(userId?: string): Promise<V1ProgramType[]> {
  // todo remove userId? optional
  if (!userId) {
    return fetchClient('/v1/programs', {
      method: 'GET',
    })
  }
  return fetchClient(`/v1/programs?userId=${userId}`, {
    method: 'GET',
  })
}

export async function doesUserExistsInProgramMutation({
  userId,
  programId,
}: DoesUserExistsInProgramDTOType): Promise<DoesUserExistsInProgramResponseType> {
  return fetchClient(`/v1/programs/${programId}/user/${userId}/exist-in-program`, {
    method: 'GET',
  })
}

export async function addUserToProgramMutation({ userId, programId }: AddUserToProgramDTOType): Promise<void> {
  return fetchClient(`/v1/programs/${programId}/user/`, {
    method: 'POST',
    body: JSON.stringify({ userId, programId }),
  })
}
