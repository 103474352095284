<template>
  <Dropdown
    class-name="session-pending-actions"
    :title="t('session.pendingActions.title')"
    :default-open="true"
    :is-loaded="!isSessionPendingRef"
  >
    <div class="session-pending-actions__stat">
      <div class="session-pending-actions__stat__donut">
        <DonutProgressIcon
          color="success"
          :progress="
            (pendingActionsRef.filter((action) => action.status === 'done').length /
              Math.max(1, pendingActionsRef.length)) *
            100
          "
        />
        <div class="stat-number">
          <span>{{ pendingActionsRef.filter((action) => action.status === 'done').length }}</span>
          <span>/{{ pendingActionsRef.length }}</span>
        </div>
      </div>
      <div class="session-pending-actions__stat__title" v-html="t('session.pendingActions.finishedActions')"></div>
    </div>
    <div class="session-pending-actions__breadcrumb-wrapper">
      <div
        v-for="(action, index) in pendingActionsRef"
        :key="action.title"
        :class="`breadcrumb-item breadcrumb-${action.status === 'pending' ? (pendingActionsRef.findIndex((a) => a === action) === index ? 'active' : 'pending') : 'done'}`"
      >
        <div class="breadcrumb-item__status">
          <div class="breadcrumb-item__status__icon">
            <CheckFullIcon color="success" :is-checked="true" />
          </div>
          <div class="breadcrumb-item__status__line"></div>
        </div>
        <div class="breadcrumb-item__title">{{ action.title }}</div>
      </div>
    </div>
    <CustomButton
      v-if="pendingActionsRef.map((action) => action.status).includes('pending')"
      usage="button"
      @emit:click="onClickCompletePendingActions"
      :text="t('session.pendingActions.completeSession')"
      color="primary"
    >
      <template #icon>
        <EditIcon color="white" />
      </template>
    </CustomButton>
  </Dropdown>
</template>

<script setup lang="ts">
import { useSessionInReview } from '@/v1.5/features/sessions/hooks/use-in-review-session.hook'
import CustomButton from '@/v1.5/features/ui/components/button/custom-button.vue'
import Dropdown from '@/v1.5/features/ui/components/dropdown/dropdown.vue'
import CheckFullIcon from '@/v1.5/features/ui/components/icons/check-full-icon.vue'
import DonutProgressIcon from '@/v1.5/features/ui/components/icons/donut-progress-icon.vue'
import EditIcon from '@/v1.5/features/ui/components/icons/edit-icon.vue'
import { useI18n } from '@/v1.5/lib/i18n'

const { t } = useI18n()

const { pendingActionsRef, isSessionPendingRef, onClickCompletePendingActions } = useSessionInReview()
</script>

<style lang="scss">
@import './session-pending-actions.scss';
</style>
