<template>
  <Modal
    :modal="groupStructureModalRef"
    :steps="[
      {
        id: 'group-structure',
        component: GroupStructureModalContent,
      },
    ]"
  />
</template>

<script lang="ts" setup>
import GroupStructureModalContent from '@/v1.5/features/admin/groups/components/group-structure-modal/group-structure-modal-content/group-structure-modal-content.vue'
import Modal from '@/v1.5/features/ui/components/modal/modal.vue'
import { selectGetAdminGroupStructureModal } from '@/v1.5/features/ui/store/modal/modal.selectors'
import modalsStore from '@/v1.5/features/ui/store/modal/modal.store'

const groupStructureModalRef = selectGetAdminGroupStructureModal(modalsStore)
</script>
