import { create } from 'zustand'
import { combine } from 'zustand/middleware'

import { setAuthTokenAction } from './auth.actions'

import subscribeToStore from '@/v1.5/lib/zustand'

export type AuthStoreValuesType = {
  authToken: string | null
}

export const INITIAL_AUTH_STORE_VALUES: AuthStoreValuesType = {
  authToken: window.localStorage.getItem('token'),
}

export const AUTH_STORAGE_KEY = 'auth'

const authStore = subscribeToStore(
  create(
    combine(INITIAL_AUTH_STORE_VALUES, (set, get) => ({
      setAuthToken: (authToken: string) => setAuthTokenAction(get, set, authToken),
    })),
  ),
)

export default authStore
