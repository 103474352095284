import type { StoreApi } from 'zustand'

import type { PopoversStoreValuesType, PopoverStoreType } from '@/v1.5/features/ui/store/popover/popover.store'

export function togglePopoverAction(
  get: StoreApi<PopoversStoreValuesType>['getState'],
  set: StoreApi<PopoversStoreValuesType>['setState'],
  name: PopoverStoreType['name'],
  isOpen: PopoverStoreType['isOpen'],
  event?: MouseEvent,
) {
  const popovers = get().popovers

  const targetPopover = popovers[name]

  if (targetPopover) {
    set({
      popovers: {
        ...popovers,
        [name]: {
          ...targetPopover,
          isOpen,
          event,
        },
      },
    })
  }
}
