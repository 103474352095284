export default function convertHexToRGB(hex: string): string {
  // Remove the '#' from the beginning of the hex string
  hex = hex.slice(1)

  // Split the hex string into three parts (each representing a color channel)
  const red = parseInt(hex.substring(0, 2), 16)
  const green = parseInt(hex.substring(2, 4), 16)
  const blue = parseInt(hex.substring(4, 6), 16)

  // Return the RGB format as a string
  return `${red}, ${green}, ${blue}`
}
