// @ts-nocheck 
// eslint-disable
// 👊 ✓
export async function getAppData(authToken) {
  const req = await fetch(`${import.meta.env.VITE_API_URL}/v1/data/app`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
  })
  return await req.json()
}
