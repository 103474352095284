<!-- eslint-disable -->
<!-- @ts-nocheck -->
<template>
  <div>
    <p class="mb-0 fw-bold">Confirmation de présence *</p>
    <p>Indiquer qui était présent lors de cette session.</p>
    <div>
      <div class="users-container">
        <div class="user" v-for="user in users" :key="user.id">
          <div class="d-flex align-items-center">
            <div :class="session ? 'profile-picture_session-status-badge' : ''">
              <ProfilePicture
                class="profile-picture"
                :src="user.profilePicture"
                :firstname="user.firstname"
                :lastname="user.lastname"
                :width="40"
                :height="40"
              />
            </div>
            <div>
              <router-link
                class="ms-2"
                :to="{ name: 'IndexProfile', params: { id: user.id } }"
              >
                {{ user.firstname }} {{ user.lastname }}
              </router-link>
              <p v-if="user.role === 'ROLE_MENTOR'" class="mb-0 ms-2">Mentor</p>
              <p v-else-if="user.role === 'ROLE_STUDENT'" class="mb-0 ms-2">
                Mentoré
              </p>
            </div>
          </div>
          <input
            class="attendance-checkbox"
            type="checkbox"
            @change="updateAttendees(user, $event.target.checked)"
            checked="true"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'

import ProfilePicture from '@/components/ProfilePicture.vue'
import SessionStatusBadge from '@/components/SessionStatusBadge.vue'
export default {
  name: 'AttendanceConfirmation',
  data() {
    return {
      attendees: [],
    }
  },
  methods: {
    ...mapActions(['updateSelectedSessionInReview']),
    // update the attendees list in the session review store
    updateAttendees(attendee, isPresent) {
      // const attendees = this.attendees.map((user) => {
      //   let isUserPresent = user.isPresent ?? true
      //   if (user.id === attendee.id) {
      //     isUserPresent = isPresent
      //   }

      //   return {
      //     userId: user.id,
      //     isPresent: isUserPresent,
      //   }
      // })
      // console.log(this.attendees.map((u) => u.isPresent).toString())

      this.attendees = this.attendees.map((a) => {
        const isUserPresent = a.isPresent ?? true

        if (a.userId === attendee.id) {
          return {
            ...a,
            userId: a.userId,
            isPresent: isPresent,
          }
        } else {
          return {
            ...a,
            userId: a.userId,
            isPresent: isUserPresent,
          }
        }
      })

      this.updateSelectedSessionInReview({
        attendees: this.attendees,
      })
    },
  },
  watch: {
    '$store.getters.selectedSessionInReview'() {
      this.attendees = this.$store.getters.selectedSessionInReview.attendees
    },
  },

  computed: {
    mentoringGroup() {
      return this.$store.getters.mentoringGroup.find((mentoringGroup) => mentoringGroup.id === this.mentoringGroupId)
    },
    session() {
      return this.$store.getters.sessions.find((session) => session.id === this.sessionId)
    },
    users() {
      return this.mentoringGroup.users.filter((u) => u.role === 'ROLE_STUDENT') ?? []
    },
  },
  // when the component is mounted, set each user has present during the session by default
  mounted() {
    // we set and update the attendees presence
    this.attendees = this.users.map((u) => ({
      userId: u.id,
    }))

    this.attendees.forEach((user) => {
      this.updateAttendees(user, true)
    })
  },

  props: {
    mentoringGroupId: {
      type: String,
      required: true,
    },
    sessionId: {
      type: String,
      default: null,
    },
  },
  components: {
    SessionStatusBadge,
    ProfilePicture,
  },
}
</script>
<style lang="scss" scoped>
.users-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 48px;
  align-items: center;
  margin: 32px 0;
  .user {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    .attendance-checkbox {
      width: 24px;
      height: 24px;
    }
  }
}
</style>
