import { useQuery } from '@tanstack/vue-query'
import type { ComputedRef } from 'vue'
import { computed } from 'vue'

import { getGroup, GROUP_QUERY_KEY } from '@/v1.5/features/groups/api'
import type { V1GroupDataType, V2GroupType } from '@/v1.5/features/groups/types'
import { formGroupName } from '@/v1.5/features/groups/utils'

function V1ToV2DataMiddleware({ group }: { group: V1GroupDataType | undefined }): V2GroupType | null {
  if (!group) return null
  return {
    id: group.id,
    idByMentor: group.idByMentor,
    conversationId: group.conversationId,
    meetingRoomId: group.meetingRoomId,
    programId: group.programId,
    name: formGroupName(group),
    users: group.users.map((user) => ({
      id: user.id,
      firstName: user.identity?.firstname,
      lastName: user.identity?.lastname,
      picture: user.personalInformation?.profilPicture,
      email: user.email,
      role: user.role,
    })),
    completedSessions: group.sessions.length,
  } satisfies V2GroupType
}

const useGroup = (groupIdRef: ComputedRef<string | null>) => {
  const isGroupQueryEnabledRef = computed(() => !!groupIdRef.value)

  const { data, isPending } = useQuery({
    queryKey: [GROUP_QUERY_KEY, { groupId: groupIdRef }],
    queryFn: () => getGroup({ groupId: groupIdRef.value! }),
    enabled: isGroupQueryEnabledRef,
  })

  const groupRef = computed(() => V1ToV2DataMiddleware({ group: data.value }))

  return {
    groupRef,
    isPendingRef: isPending,
  }
}

export default useGroup
