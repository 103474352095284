<template>
  <AppLayout v-bind="$attrs">
    <template v-for="(slot, name) in $scopedSlots" v-slot:[name]="slotProps">
      <slot :name="name" v-bind="slotProps"></slot>
    </template>
  </AppLayout>
</template>

<script>
import AppLayout from '@/v1.5/(routes)/(protected)/(app)/layout.vue'

export default {
  name: 'Page',
  components: {
    AppLayout,
  },
}
</script>

<style lang="scss" scoped>
.page-container {
  margin-top: 24px;
  @media all and (max-width: 768px) {
    margin-top: 0;
    // padding: 0 !important;
  }
}
#page {
  background-color: #f1f2f5 !important;
  // padding: 24px 0;
  @media all and (max-width: 768px) {
    padding: 84px 0 87px 0;
  }
}
</style>
