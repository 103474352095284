<!-- eslint-disable -->
<!-- @ts-nocheck -->
<template>
  <PageOnboarding colLeft="col-lg-4" colRight="col-lg-8">
    <template v-slot:right>
      <div class="align-items-center" style="width: 500px; height: max-content">
        <h5 class="d-flex justify-content-center">
          <p>
            <strong v-if="isMentor()"
              >Veuillez remplir ces informations complémentaires qui nous
              aiderons à trouver vos futurs mentorés.
            </strong>
            <strong v-else>
              Veuillez remplir ces informations complémentaires qui nous
              aiderons à trouver votre futur mentor.
            </strong>
          </p>
        </h5>
        <div v-if="!loading && fillInformation" class="fill-info-container">
          <p v-html="prepareFillInfoItemHTML()"></p>
        </div>
        <div v-else class="d-flex justify-content-center">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div class="d-flex align-item-center">
          <div class="w-100"></div>
          <div class="p-2 flex-shrink-1">
            <button
              class="btn btn-primary"
              :disabled="matchingLoading"
              @click="submit()"
            >
              <span
                v-show="matchingLoading"
                class="spinner-border spinner-border-sm"
                aria-hidden="true"
              ></span>
              <span v-show="!matchingLoading"> Suivant </span>
            </button>
          </div>
        </div>
      </div>
    </template>
  </PageOnboarding>
</template>

<script lang="ts">
import PageOnboarding from '@/components/PageOnboarding.vue'
import { endpointMatchingCreateCriteria, match } from '@/services/matching.endpoints.js'
import { slugify } from '@/v1.5/utils/misc/slugify'

export default {
  name: 'FillMatchInformations',
  data() {
    return {
      fillInformation: null,
      regex: /%{(.*?)}%/gs,
      loading: true,
      matchingLoading: false,
    }
  },
  watch: {
    '$store.getters.userPrograms'() {
      this.fetchFillInformations()
    },
  },
  async mounted() {
    if (this.$store.getters.userPrograms) this.fetchFillInformations()
  },

  methods: {
    prepareFillInfoItemHTML() {
      // return the response in a string format with all input placeholders with keys
      return this.fillInformation.replace(this.regex, (match) => {
        const matchText = match.replace(/%/g, '').replace(/{/g, '').replace(/}/g, '')
        const matchClass = slugify(matchText)
        return `<input class="${matchClass}" placeholder="${matchText}" />`
      })
    },
    isMentor() {
      return this.$store.getters.userRole === 'ROLE_MENTOR'
    },
    retrieveFillCriteria() {
      const matchToExtract = Array.from(this.fillInformation.matchAll(this.regex)).map((match) => match)

      // get all inputs values
      const fillValues = {}
      matchToExtract.forEach(([key, matchText]) => {
        fillValues[key] = document.querySelector(`.${slugify(matchText)}`).value ?? null
      })

      // verify if all inputs are filled
      //      if (Array.from(Object.values(fillValues)).some((value) => !value)) {
      //       this.$toast.error('Veuillez remplir tous les champs')
      //      return null
      //   }

      let fillText = this.fillInformation
      // replace all inputs placeholders with values
      Array.from(Object.entries(fillValues)).forEach(([key, value]) => {
        fillText = fillText.replace(key, value)
      })

      // return the response in a string format
      return fillText
    },
    async submit() {
      const criteria = this.retrieveFillCriteria()
      if (!criteria) return

      try {
        this.matchingLoading = true
        const payload = {
          userId: this.$store.getters.authUserId,
          programId: this.$route.params.id,
          criteria,
        }
        if (this.isMentor()) {
          // if the user is mentor
          await endpointMatchingCreateCriteria(payload, this.$store.getters.authToken)
        } else {
          // if the user is mentee
          await match(payload, this.$store.getters.authToken)
        }
        // invalide and refetch user datas
        await this.$store.dispatch('storeGetUserPrograms')
        await this.$store.dispatch('storeGetMentoringGroup')
        await this.$store.dispatch('readAllChatRooms')

        this.$router.push({ name: 'IndexMentoringGroup' })

        // if (this.isMentor()) {
        //   this.$router.push({ name: 'IndexMentoringGroup' })
        // } else {
        //   this.$router.push({ name: 'MatchingLoading' })
        // }
      } catch (error) {
        if (error.message === 'No mentor available') {
          this.$toast.error('Pas de mentor disponible pour ce programme')
          return this.$router.push({ name: 'IndexMentoringGroup' })
        }
        this.$toast.error('Une erreur est survenue')
      } finally {
        this.matchingLoading = false
      }
    },
    async fetchFillInformations() {
      const fillInformations = {
        mentor: '',
        mentee: '',
      }
      const currentProgram = this.$store.getters.userPrograms.find((program) => program.id === this.$route.params.id)

      if (currentProgram)
        for (const setting of currentProgram.settings) {
          if (setting.name === 'MATCHING_MENTEE_CRITERIA') {
            fillInformations.mentee = setting.value
          } else if (setting.name === 'MATCHING_MENTOR_CRITERIA') {
            fillInformations.mentor = setting.value
          }
        }

      this.fillInformation = this.isMentor() ? fillInformations.mentor : fillInformations.mentee

      this.loading = false
    },
  },
  components: { PageOnboarding },
}
</script>

<style lang="scss" scoped>
.search {
  background-color: #f1f2f5;
  max-height: 270px;
  overflow-y: scroll;
  scrollbar-color: #6969dd #e0e0e0;
  &-tile {
    padding: calc(1rem - 2px);
    border: 2px solid #fff;
    &:hover {
      cursor: pointer;
      border: 2px solid var(--color-primary);
    }
  }
}

.search::-webkit-scrollbar {
  width: 10px;
}
.search::-webkit-scrollbar-track {
  background-color: #abb5be;
  border-radius: 0.25rem;
}

.search::-webkit-scrollbar-thumb {
  background-color: var(--color-primary);
  border-radius: 0.25rem;
}
</style>
