<template>
  <Modal :onExit="onExit" id="review-session" :modal="reviewSessionModalRef" :steps="reviewStepsRef" />
</template>

<script setup lang="ts">
import { watch } from 'vue'

import { useSessionInReview } from '@/v1.5/features/sessions/hooks/use-in-review-session.hook'
import useSessions from '@/v1.5/features/sessions/hooks/use-sessions.hook'
import { getWaitReviewSessions } from '@/v1.5/features/sessions/utils'
import Modal from '@/v1.5/features/ui/components/modal/modal.vue'
import { selectGetReviewSessionModal } from '@/v1.5/features/ui/store/modal/modal.selectors'
import type { SessionDetailsModalType } from '@/v1.5/features/ui/store/modal/modal.store'
import modalsStore from '@/v1.5/features/ui/store/modal/modal.store'
import { DO_NOT_ASK_REVIEW_AGAIN } from '@/v1.5/utils/config/constants'

const reviewSessionModalRef = selectGetReviewSessionModal(modalsStore)

const { reviewStepsRef, onClickCompletePendingActions } = useSessionInReview()

function onExit() {
  sessionStorage.setItem(DO_NOT_ASK_REVIEW_AGAIN, 'true')
}

// check for sessions, if there is one that needs to be reviewed, show the modal
const { sessionsRef } = useSessions()
watch(sessionsRef, () => {
  const waitReviewSessions = sessionsRef.value.filter(getWaitReviewSessions)
  if (
    waitReviewSessions.length &&
    !reviewSessionModalRef.value.isOpen &&
    sessionStorage.getItem(DO_NOT_ASK_REVIEW_AGAIN) !== 'true'
  ) {
    modalsStore.updateModalAttachedData<SessionDetailsModalType>('session-details', {
      sessionId: waitReviewSessions[0].id,
    })
    setTimeout(() => {
      onClickCompletePendingActions()
    }, 200)
  }
})
</script>
