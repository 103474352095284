<template>
  <ModalStep
    id="user-profile-modal-content"
    :header="{
      title: `${userRef?.firstName} ${userRef?.lastName}`,
    }"
    :on-exit-handler="onExitHandler"
  >
    <template #bodyInformation>
      <LoadingContent v-if="isUserPendingRef || isAccountPendingRef" :text="t('ui.message.loading')" />
    </template>
    <template #bodyContent v-if="userRef">
      <div class="user-profile__info-card">
        <div class="user-profile__info-card__avatar">
          <Avatar :user="userRef" />
        </div>
        <div class="user-profile__info-card__info">
          <p class="info-role" v-if="userRef.role">{{ t(userRolesNamesMap[userRef.role]) }}</p>
          <p class="info-name">{{ userRef.firstName }} {{ userRef.lastName }}</p>
        </div>
      </div>
      <div class="c-info-wrapper">
        <p class="c-info-label">
          <strong>{{ t('user.profile.description') }}</strong>
        </p>
        <div v-if="hasDescriptionRef" class="user-profile__description" id="user-profile-description"></div>
        <p v-else class="c-info-text user-profile__description">{{ t('user.profile.description.empty') }}</p>
      </div>
      <CustomTextarea
        ref="userProfileNoteTextAreaRef"
        v-if="canUserSeeNoteRef"
        :label="t('user.profile.sharedNotes')"
        id="user-profile-note"
        :placeholder="t('user.profile.sharedNotes.add.placeholder')"
        :value="userNotesRef"
        @emit:change="changeNoteHandler"
      />
    </template>
    <template #footer>
      <div class="c-btns-container c-btn-center">
        <CustomButton
          v-if="canUserSeeNoteRef"
          :is-disabled="userNotesRef === (userNoteDataRef?.content || null)"
          :is-loading="isUpdateUserNotesPendingRef"
          usage="button"
          type="button"
          color="primary"
          :text="t('user.profile.sharedNotes.saveChanges')"
          @emit:click="saveChanges"
        >
          <template #icon>
            <!-- <EditIcon color="white" filled/> -->
          </template>
        </CustomButton>
        <CustomButton
          v-if="accountRef?.id === userRef?.id"
          usage="button"
          type="button"
          color="primary"
          :text="t('user.profile.edit')"
          @emit:click="goToProfile"
        >
          <template #icon>
            <!-- <EditIcon color="white" filled/> -->
          </template>
        </CustomButton>
        <CustomButton
          v-else-if="
            checkPermissionsGroup(accountRef?.role, PermissionsGroupsEnumType.USER) &&
            accountRef?.role !== userRef?.role
          "
          usage="button"
          type="button"
          color="primary"
          :text="t('user.profile.sendMessage')"
          @emit:click="goToMessage"
        >
          <template #icon>
            <MessageIcon color="white" filled />
          </template>
        </CustomButton>
      </div>
    </template>
  </ModalStep>
</template>

<script setup lang="ts">
import { useMutation, useQuery } from '@tanstack/vue-query'
import { computed } from 'vue'
import { ref } from 'vue'
import { watch } from 'vue'

import { GetUserNotes, UpdateUserNotesMutation, USER_NOTE_QUERY_KEY } from '@/v1.5/features/admin/users/api'
import useAccount from '@/v1.5/features/auth/hooks/use-account.hook'
import { PermissionsGroupsEnumType } from '@/v1.5/features/auth/types'
import { createConversationMutation } from '@/v1.5/features/chat/api'
import groupsStore from '@/v1.5/features/groups/stores/groups/groups.store'
import CustomButton from '@/v1.5/features/ui/components/button/custom-button.vue'
import CustomTextarea from '@/v1.5/features/ui/components/custom-textarea/custom-textarea.vue'
import MessageIcon from '@/v1.5/features/ui/components/icons/message-icon.vue'
import LoadingContent from '@/v1.5/features/ui/components/loading-content/loading-content.vue'
import ModalStep from '@/v1.5/features/ui/components/modal/modal-step/modal-step.vue'
import { selectGetUserProfileModal } from '@/v1.5/features/ui/store/modal/modal.selectors'
import type { UserProfileModalType } from '@/v1.5/features/ui/store/modal/modal.store'
import modalsStore from '@/v1.5/features/ui/store/modal/modal.store'
import Avatar from '@/v1.5/features/users/components/avatar/avatar.vue'
import useProfileDescription from '@/v1.5/features/users/hooks/use-profile-description.hook'
import useUser from '@/v1.5/features/users/hooks/use-user.hook'
import checkPermissionsGroup, { userRolesNamesMap } from '@/v1.5/features/users/utils'
import useRouter from '@/v1.5/hooks/use-router.hook'
import useToast from '@/v1.5/hooks/use-toasts.hook'
import { useI18n } from '@/v1.5/lib/i18n'
import queryClient from '@/v1.5/lib/vue-query'
import { ROUTES } from '@/v1.5/utils/config/constants'
import { invalidateConversations } from '@/v1.5/utils/lib/vue-query'

defineProps<{
  onExitHandler: (callback?: () => void) => void
}>()

const { t } = useI18n()

const userProfileModal = selectGetUserProfileModal(modalsStore)
const attachedDataUserIdRef = computed(() => (userProfileModal?.value as UserProfileModalType).attachedData?.userId)

const { accountRef, isPendingRef: isAccountPendingRef } = useAccount()
const { userRef, isPendingRef: isUserPendingRef } = useUser(attachedDataUserIdRef)

const { hasDescriptionRef } = useProfileDescription(attachedDataUserIdRef, 'user-profile-description')

const router = useRouter()
const toast = useToast()

async function goToMessage() {
  const userId = userRef.value?.id
  if (userId) {
    const newConversation = await createConversationMutation({ sendAt: userId! })
    await invalidateConversations(groupsStore.selectedGroupId!)
    router?.push(ROUTES.CHAT(newConversation.conversationId))
  }
}

function goToProfile() {
  const userId = userRef.value?.id
  if (userId) {
    router?.push(ROUTES.PROFILE)
  }
}

const userProfileNoteTextAreaRef = ref<any | null>(null)
const userNotesRef = ref<string | null>(null)

// if the user is an admin and the account is not the user, show the note
const canUserSeeNoteRef = computed(() => {
  return (
    !!userRef.value &&
    accountRef.value?.id !== userRef.value?.id &&
    checkPermissionsGroup(accountRef.value?.role, PermissionsGroupsEnumType.ADMIN)
  )
})

const { data: userNoteDataRef } = useQuery({
  queryKey: [USER_NOTE_QUERY_KEY, { userId: attachedDataUserIdRef.value! }],
  queryFn: () => GetUserNotes({ userId: attachedDataUserIdRef.value! }),
  enabled: canUserSeeNoteRef,
})

// watch for changes in the user note data
watch(
  [userNoteDataRef, userProfileNoteTextAreaRef],
  () => {
    const textArea = userProfileNoteTextAreaRef.value?.$el.querySelector('textarea') as HTMLTextAreaElement

    // if the user has a note, set the note in the textarea
    if (textArea && userNoteDataRef.value?.content) {
      userNotesRef.value = userNoteDataRef.value?.content
      textArea.value = userNoteDataRef.value?.content
    }
  },
  { immediate: true },
)

const { mutate: updateUserNotes, isPending: isUpdateUserNotesPendingRef } = useMutation({
  mutationFn: UpdateUserNotesMutation,
  onSuccess: () => {
    toast?.success(t('user.profile.sharedNotes.success'))
    // remove the query from the cache
    queryClient.invalidateQueries({
      queryKey: [USER_NOTE_QUERY_KEY, { userId: attachedDataUserIdRef.value! }],
    })
  },
  onError: () => {
    toast?.error(t('error.common'))
  },
})

function changeNoteHandler(value: string) {
  userNotesRef.value = value
}

function saveChanges() {
  updateUserNotes({ userId: attachedDataUserIdRef.value!, content: userNotesRef.value || '' })
}
</script>

<style lang="scss">
@import './user-profile-modal-content.scss';
</style>
