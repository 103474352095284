<template>
  <Modal :modal="passwordModalRef" :steps="[{ component: EditPasswordContent, id: 'change-password' }]" />
</template>

<script lang="ts" setup>
import EditPasswordContent from '@/v1.5/features/auth/components/edit-password-modal/edit-password-content/edit-password-content.vue'
import Modal from '@/v1.5/features/ui/components/modal/modal.vue'
import { selectGetPasswordModal } from '@/v1.5/features/ui/store/modal/modal.selectors'
import modalsStore from '@/v1.5/features/ui/store/modal/modal.store'

const passwordModalRef = selectGetPasswordModal(modalsStore)
</script>
