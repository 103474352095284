<template>
  <ModalStep
    :id="'terms-modal-content'"
    :header="{
      title: t('terms.modal.title'),
    }"
  >
    <template #bodyInformation>
      <strong>
        {{ t('terms.modal.bodyInformation') }}
      </strong>
    </template>
    <template #bodyContent>
      <LoadingContent v-if="isPendingRef" />
      <iframe
        v-else
        class="terms-iframe"
        :src="`${whiteLabelRef.termsUrl}#toolbar=0&view=FitH`"
        frameborder="0"
        allowfullscreen
      ></iframe>
    </template>
    <template #footer>
      <div class="c-btns-container c-btn-center">
        <CustomButton
          usage="button"
          type="button"
          color="primary"
          @emit:click="submitForm"
          :is-disabled="isPendingRef"
          :isLoading="isAcceptTermsPendingRef"
          :text="t('terms.modal.accept')"
        ></CustomButton>
      </div>
    </template>
  </ModalStep>
</template>

<script setup lang="ts">
import { useMutation } from '@tanstack/vue-query'

import { acceptTermsMutation } from '@/v1.5/features/legal/api'
import CustomButton from '@/v1.5/features/ui/components/button/custom-button.vue'
import LoadingContent from '@/v1.5/features/ui/components/loading-content/loading-content.vue'
import ModalStep from '@/v1.5/features/ui/components/modal/modal-step/modal-step.vue'
import modalsStore from '@/v1.5/features/ui/store/modal/modal.store'
import useToast from '@/v1.5/hooks/use-toasts.hook'
import useWhiteLabel from '@/v1.5/hooks/use-white-label.hook'
import { useI18n } from '@/v1.5/lib/i18n'
import { invalidateAccount } from '@/v1.5/utils/lib/vue-query'

const toast = useToast()
const { t } = useI18n()
const { whiteLabelRef, isPendingRef } = useWhiteLabel()

const { mutate: acceptTerms, isPending: isAcceptTermsPendingRef } = useMutation({
  mutationFn: acceptTermsMutation,
  onError: (error) => {
    console.log(error)
    toast?.error(t('error.common'))
  },
  onSuccess: async () => {
    await invalidateAccount()
    toast?.success(t('terms.modal.success'))
    modalsStore.toggleModal('terms', false)
  },
})

async function submitForm() {
  acceptTerms()
}
</script>

<style lang="scss" scoped>
@import './terms-modal-content.scss';
</style>
