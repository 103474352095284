import { computed, onMounted, onUnmounted, ref } from 'vue'

function useWindowSize() {
  const windowWidth = ref(window.innerWidth)
  const windowHeight = ref(window.innerHeight)

  const onWidthChange = () => (windowWidth.value = window.innerWidth)
  const onHeightChange = () => (windowHeight.value = window.innerHeight)

  onMounted(() => window.addEventListener('resize', onWidthChange))
  onMounted(() => window.addEventListener('resize', onHeightChange))

  onUnmounted(() => window.removeEventListener('resize', onWidthChange))
  onUnmounted(() => window.removeEventListener('resize', onHeightChange))

  const widthRef = computed(() => windowWidth.value)
  const heightRef = computed(() => windowHeight.value)

  return { widthRef, heightRef }
}

export default useWindowSize
