// @ts-nocheck
// eslint-disable
import { apiAuthGetUserData, login } from '@/services/AuthService'
import authStore from '@/v1.5/features/auth/stores/auth/auth.store'

const initialState = () => ({
  authToken: localStorage.getItem('token') || '',
  authUserId: localStorage.getItem('userId') || '',
  authUserRole: null,
  authUserSupportId: null,
})

const state = initialState()

const getters = {
  isAuthenticated: (state) => !!state.authToken,
  authToken: (state) => state.authToken,
  authUserId: (state) => state.authUserId,
  authUserSupportId: (state) => state.authUserSupportId,
  authUserRole: (state) => state.authUserRole,
}

const actions = {
  async authLogin({ commit }, payload) {
    const req = await login(payload.email, payload.password)
    if (req.error) return { error: req.error }
    authStore.setAuthToken(req.token)
    commit('SET_AUTH_TOKEN', req.token)
    commit('SET_AUTH_USER_ID', req.user.id)
    commit('SET_AUTH_USER_ROLE', req.user.role)
    commit('SET_AUTH_USER_SUPPORT_ID', req.user.supportId)
    localStorage.setItem('token', req.token)
    localStorage.setItem('userId', req.user.id)
    await this.dispatch('callAuthData')
    return true
  },
  authLoginWithToken({ commit }, payload) {
    localStorage.setItem('token', payload.token)
    localStorage.setItem('userId', payload.userId)
    commit('SET_AUTH_TOKEN', payload.token)
    authStore.setAuthToken(payload.token)
    commit('SET_AUTH_USER_ID', payload.userId)
    this.dispatch('authUpdateToken', payload.token)
  },
  async authGetUserData({ commit }) {
    const user = await apiAuthGetUserData(this.getters.authToken)
    if (user.error) this.dispatch('storeAuthLogout')
    commit('SET_AUTH_USER_ID', user.id)
    commit('SET_AUTH_USER_ROLE', user.role)
    commit('SET_AUTH_USER_SUPPORT_ID', user.supportId)
  },
  async callAuthData() {
    await this.dispatch('updateUserData')

    await this.dispatch('fetchUser')
    await this.dispatch('fetchUserIdentity')
    await this.dispatch('fetchUserPhone')
    await this.dispatch('readModuleUserProfilePicture')

    await this.dispatch('storeGetUserPrograms')

    if (this.getters.authUserRole === 'ROLE_STUDENT' || this.getters.authUserRole === 'ROLE_MENTOR') {
      await this.dispatch('storeGetMentoringGroup')
      await this.dispatch('storeReadSessions')
    }
    await this.dispatch('readAllChatRooms')
  },
  authUpdateToken({ commit }, token) {
    commit('SET_AUTH_TOKEN', token)
  },
  authUpdateUserId({ commit }, userId) {
    commit('SET_AUTH_USER_ID', userId)
  },
  authUpdateUserRole({ commit }, role) {
    commit('SET_AUTH_USER_ROLE', role)
  },
  storeAuthResetState({ commit }) {
    commit('AUTH_RESET_STATE')
  },
  storeAuthLogout({ commit }) {
    localStorage.removeItem('token')
    localStorage.removeItem('userId')
    authStore.setAuthToken(null)
    this.dispatch('storeUserResetState')
    this.dispatch('storeSessionResetState')
    this.dispatch('storeProfileResetState')
    this.dispatch('storeMeetingResetState')
    this.dispatch('storeChatResetState')
    this.dispatch('storeAuthResetState')
    this.dispatch('storeIndexResetState')
  },
}

const mutations = {
  SET_AUTH_TOKEN(state, token) {
    state.authToken = token
  },
  SET_AUTH_USER_ID(state, userId) {
    userId = userId
    state.authUserId = userId
  },
  SET_AUTH_USER_ROLE(state, role) {
    state.authUserRole = role
  },
  SET_AUTH_USER_SUPPORT_ID(state, supportId) {
    state.authUserSupportId = supportId
  },
  AUTH_RESET_STATE(state) {
    const s = initialState()
    Object.keys(s).forEach((key) => {
      state[key] = s[key]
    })
  },
}

export const moduleAuth = {
  state,
  getters,
  actions,
  mutations,
}
