<template>
  <Modal
    :modal="noProgramModal"
    noExitOnBackdrop
    :steps="[{ component: NoProgramContent, id: 'no-program-available' }]"
  />
</template>

<script setup lang="ts">
import { watchEffect } from 'vue'

import NoProgramContent from '@/v1.5/features/programs/components/no-program-modal/no-program-content/no-program-content.vue'
import usePrograms from '@/v1.5/features/programs/hooks/use-programs.hook'
import Modal from '@/v1.5/features/ui/components/modal/modal.vue'
import {
  selectGetNoProgramModal,
  selectGetShouldHideMatchingModals,
} from '@/v1.5/features/ui/store/modal/modal.selectors'
import modalsStore from '@/v1.5/features/ui/store/modal/modal.store'

const noProgramModal = selectGetNoProgramModal(modalsStore)
const { programsRef, isPendingRef, isFetchedRef } = usePrograms()

const shouldHideMatchingModalsRef = selectGetShouldHideMatchingModals(modalsStore)

watchEffect(() => {
  // hide the modal if the user manually select to hide matching modals
  if (shouldHideMatchingModalsRef.value) {
    modalsStore.toggleModal('no-program', false)
    return
  }

  if (isFetchedRef.value && !isPendingRef.value && programsRef.value.length === 0) {
    modalsStore.toggleModal('no-program', true)
  } else if (isFetchedRef.value && !isPendingRef.value && programsRef.value.length > 0) {
    modalsStore.toggleModal('no-program', false)
  }
})
</script>
