<!-- eslint-disable -->
<!-- @ts-nocheck -->
<template>
  <div>
    <hr class="my-4" />
    <p class="fw-bold">Livrable de fin de session</p>
    <p>
      Si votre session et votre mentor l'exigent, vous pouvez déposer un
      livrable de fin de session. <br />
      <span class="fst-italic"
        >Ces informations seront transmises au mentor pour évaluation.</span
      >
    </p>
    <div
      class="drop-zone"
      :class="isUploading || (file && isFileFullyUploaded) ? 'has-deliverable' : ''"
    >
      <div v-if="isUploading || (file && isFileFullyUploaded)" class="drop-zone__deliverable">
        <span class="deliverable-icon material-symbols-outlined">
          file_present
        </span>
        <div class="deliverable-info">
          <span class="deliverable-info__name">{{ file.name }}</span>
          <div
            class="deliverable-info__upload"
            :class="isUploading ? 'uploading' : 'done'"
          >
            <template v-if="isUploading"
              >⚠️ L'importation peut être longue, merci de patienter....
              <div class="spinner-border spinner-border-sm" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </template>
            <template v-else>Réceptionné !</template>
          </div>

          <div v-if="!isUploading" class="deliverable-info__delete">
            <span class="material-symbols-outlined"> delete </span>
          </div>
        </div>
      </div>
      <div v-else class="drop-zone__info">
        <span class="material-symbols-outlined"> cloud_upload </span>
        <p>Glisser-déposer un fichier ou cliquer ici.</p>
      </div>
      <input type="file" class="file-input" accept="*" />
    </div>
    <div class="drop-zone--extra">
      <span
        >Si vous rencontrez une difficulté pour importer votre livrable
        contactez-nous sur le <a href="#brevoConversationsExpanded">support</a>
      </span>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import { checkDeliverableImportationFn } from '@/services/sessionService'

export default {
  name: 'SessionMenteeDeliverable',
  data() {
    return {
      file: null,
      isFileFullyUploaded: false,
      deliverableId: null,
      isUploading: false,
    }
  },
  async mounted() {
    const _this = this

    // get user deliverable if he already uploaded one
    await _this.fetchUserDeliverableAlreadyUploaded()

    // Get the drop zone element
    var dropZone = document.querySelector('.drop-zone')
    const fileInput = document.querySelector('.file-input')

    // Add event listeners for drag events
    dropZone.addEventListener('dragover', function (e) {
      e.preventDefault() // Prevent default behavior (Prevent file from being opened)
      dropZone.classList.add('hover')
    })

    dropZone.addEventListener('dragleave', function (e) {
      e.preventDefault()
      dropZone.classList.remove('hover')
    })

    dropZone.addEventListener('drop', async function (e) {
      e.preventDefault()
      dropZone.classList.remove('hover')

      if (dropZone.classList.contains('has-deliverable')) {
        _this.$toast.error("Vous ne pouvez uploader qu'un seul fichier")
        return
      }

      var files = e.dataTransfer.files // Get the files that were dropped
      _this.handleFiles(files)
      fileInput.value = ''
    })

    // Click event listener for manual uploads
    dropZone.addEventListener('click', function (e) {
      // if the user click on the drop zone and there is already a deliverable
      // do nothing and alert the user
      if (dropZone.classList.contains('has-deliverable') && e.target === dropZone) {
        e.preventDefault()
        _this.$toast.error("Vous ne pouvez uploader qu'un seul fichier")
        return
      }

      if (_this.file) {
        // delete the deliverable
        _this.handleDeleteDeliverable()
        return
      }

      fileInput.click() // Simulate click on hidden input
    })

    // Input change event to handle files after they are selected via click
    fileInput.addEventListener('change', async function (e) {
      var files = e.target.files // Get the files that were selected
      await _this.handleFiles(files)
      fileInput.value = ''
    })

    // Before unload event listener
    window.addEventListener('beforeunload', function (e) {
      if (_this.isUploading) {
        var confirmationMessage = 'Are you sure you want to leave? Your current upload will be canceled.'
        e.returnValue = confirmationMessage // Standard for most browsers
        return confirmationMessage // For some older browsers
      }
    })
  },
  watch: {
    // set the isUploading to the global session store
    isUploading() {
      this.setIsDeliverableUploading(this.isUploading)
    },
  },
  methods: {
    ...mapActions(['setIsDeliverableUploading', 'uploadDeliverable', 'deleteDeliverable', 'getDeliverableByUserId']),
    async fetchUserDeliverableAlreadyUploaded() {
      const userDeliverableAlreadyUploaded = await this.getDeliverableByUserId({
        sessionId: this.sessionId,
        userId: this.$store.getters.user.identity.userId,
      })

      // if a deliverable is found, check if the file has been fully uploaded
      if (userDeliverableAlreadyUploaded.file) {
        const deliverableImportationData = await checkDeliverableImportationFn(
          userDeliverableAlreadyUploaded.id,
          this.$store.getters.authToken,
        )

        if (deliverableImportationData.delivrableIsImported) {
          this.file = {
            name: userDeliverableAlreadyUploaded.file,
          }
          this.isFileFullyUploaded = deliverableImportationData.delivrableIsImported
          this.deliverableId = userDeliverableAlreadyUploaded.id
        }
      }
    },
    async handleFiles(files) {
      // user can upload only one file
      if (files.length > 1) {
        this.$toast.error("Vous ne pouvez uploader qu'un seul fichier")
        return
      }

      const file = files[0]

      this.isUploading = true
      this.file = file
      try {
        const res = await this.uploadDeliverable({
          sessionId: this.sessionId,
          mimetype: file.type,
        })
        if (res.url) {
          await fetch(res.url, {
            method: 'PUT',
            headers: {
              'Access-Control-Allow-Origin': '*',
              'x-amz-acl': 'public-read',
              'Content-Type': file.type,
            },
            body: file,
          })

          // we confirm the import of the deliverable
          await checkDeliverableImportationFn(res.id, this.$store.getters.authToken)
        }

        this.deliverableId = res.id
        this.isFileFullyUploaded = true

        this.$toast.success('Fichier envoyé avec succès.')
      } catch (e) {
        this.$toast.error("Une erreur est survenue lors de l'envoi du fichier.")
        this.file = null
        this.isFileFullyUploaded = false
      } finally {
        this.isUploading = false
      }
    },
    async handleDeleteDeliverable() {
      const res = await this.deleteDeliverable({
        sessionId: this.sessionId,
        deliverableId: this.deliverableId,
      })
      this.file = null
      this.isFileFullyUploaded = false
      console.log(res)
      this.$toast.success('Fichier supprimé avec succès.')
    },
  },
  props: {
    sessionId: {
      type: String,
    },
  },
}
</script>

<style lang="scss" scoped>
.drop-zone {
  width: 100%;
  height: 150px;
  border: 2px dashed var(--bs-primary);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: var(--bs-primary);
  font-family: Arial, sans-serif;
  transition: background-color 0.3s ease;
  &.has-deliverable {
    pointer-events: none;
  }
  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
  }
  &:not(.has-deliverable):hover,
  &:not(.has-deliverable).hover {
    cursor: pointer;
    background-color: var(--bs-primary-focus);
  }
  .file-input {
    display: none;
  }

  &__deliverable {
    position: relative;
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: var(--bs-secondary);
    border-radius: 4px;
    padding: 16px 32px;
    .deliverable-icon {
      font-size: 56px;
    }
    .deliverable-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      &__name {
        font-weight: bold;
        font-size: 20px;
      }
      &__upload {
        color: var(--bs-white);
        border-radius: 2px;
        padding: 2px 8px;
        font-size: 14px;
        &.uploading {
          background-color: var(--bs-danger);
        }
        &.done {
          background-color: var(--bs-success);
        }
      }
      &__delete {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(50%, -50%);
        color: var(--bs-white);
        background-color: var(--bs-danger);
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 24px;
        pointer-events: all;
        &:hover {
          cursor: pointer;
        }
        span {
          font-size: 20px;
        }
      }
    }
  }
  &--extra {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
    width: 100%;
    color: var(--bs-primary);
    opacity: 0.5;
    text-align: center;
  }
}
</style>
